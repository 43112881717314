import React from 'react'
import SurveyImageTask from '../../pos/forms/surveys/SurveyImageTask'
import SurveyTextTask from '../../pos/forms/surveys/SurveyTextTask'
import SurveyNumberTask from '../../pos/forms/surveys/SurveyNumberTask'
import SurveyComponentMultiple from '../../pos/forms/surveys/SurveyComponentMultiple'
import SurveyComponentUnique from '../../pos/forms/surveys/SurveyComponentUnique'

export default function PosTaskSurvey({ data , answer , images}) {
  const dataWithAnswers = data.map(item => {
    const foundAnswer = answer.find(ans => ans.question === item.question);

    if (foundAnswer) {
      if (item.type === 'image' && images) {
        item.response = images;

      }else if (item.type === 'multiplechk') {
        const answerOptions = foundAnswer.answer.split(',').map(opt => opt.trim());
        item.options.forEach(option => {
          option.selected = answerOptions.includes(option.text);
        });
      } else if (item.type === 'multiplerb') {
        item.options.forEach(option => {
          option.selected = option.text === foundAnswer.answer;
        });
      } else {
        item.response = foundAnswer.answer;
      }
    }

    return item;
  });

  return (
    <div>
        {dataWithAnswers.map((survey, index) => (
          <div key={index}>
            {renderSurveyComponent(survey.type, survey,
              { //CSS Details Surveys
                container: 'survey-text-task-container-details',
                headers: 'headers-details',
                formsgroup:'form-group-details blue-border-input-details',
                response:'response-details response-details-border response-surveys-details',
                responseplace: 'response-surveys-details form-control-details',
                fontsquestion: 'question-input-details form-control-details',
                fontsdescriptions :'description-input-details form-control-details',
                checkbox:'chkrd-and-input-details blue-border-input-details',
                checkradtext:'form-control form-control-details',
                surveysdetails:'details-surveys',
                optionContainer: 'options-surveys',
              },
              index,
              )}
          </div>
        ))}
    </div>
  )
}

function renderSurveyComponent(type, data, classNames, index) {
  switch (type) {
    case 'text':
      return (
        <div>
          <SurveyTextTask
            data={data}
            classNames={classNames}
            showOptionsButton={false}
            disableQuestion={true}
            isDetailsSurvey={true}
            showDescriptionsDetails={true}
            questionNumber={index + 1}
            showresponsePostask={true}
          />
        </div>
      )
    case 'number':
      return (
        <div>
          <SurveyNumberTask
            data={data}
            classNames={classNames}
            showOptionsButton={false}
            disableQuestion={true}
            isDetailsSurvey={true}
            showDescriptionsDetails={true}
            questionNumber={index + 1}
            showresponsePostask={true}
          />
        </div>
      )
    case 'image':
      return (
        <div>
          <SurveyImageTask
            data={data}
            classNames={classNames}
            showOptionsButton={false}
            disableQuestion={true}
            isDetailsSurvey={true}
            showDescriptionsDetails={true}
            questionNumber={index + 1}
            showresponsePostask={true}
          />
        </div>
      )
    case 'multiplechk':
      return (
        <div>
          <SurveyComponentMultiple
            data={data}
            classNames={classNames}
            showOptionsButton={false}
            disableQuestion={true}
            isDetailsSurvey={true}
            showDescriptionsDetails={true}
            questionNumber={index + 1}
            showresponsePostask={true}
          />
        </div>
      )
    case 'multiplerb':
      return (
        <div>
          <SurveyComponentUnique
            data={data}
            classNames={classNames}
            showOptionsButton={false}
            disableQuestion={true}
            isDetailsSurvey={true}
            showDescriptionsDetails={true}
            questionNumber={index + 1}
            showresponsePostask={true}
          />
        </div>
      )
    default:
      return null
  }
}
