import React, { useState, useEffect } from 'react'
import cookie from 'react-cookies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretUp,
  faCaretDown,
  faCheckCircle,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'
import { Button, Modal } from 'react-bootstrap'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const ConsolidationIndexView = (props) => {
  const [data, setData] = useState([])
  const [isFetching, setIsFetching] = useState(true)
  const [isSelectMode, SetChooseSalesmenButton] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [isButtonEnabled, setConsolidateButton] = useState(false)
  const [displayErrorPostMessage, setDisplayErrorPostMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isChecked, setIsChecked] = useState({})
  const [allChecked, setAllChecked] = useState(false)
  const [isActiveFilter, setFilterToggle] = useState(false)
  const [filterData, setFilterData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [branchFilter, setBranchFilter] = useState({})
  const [typeFilter, setTypeFilter] = useState({})
  const [searchTerm2, setSearchTerm2] = useState('')
  const [isPosting, setIsPosting] = useState(false) // determines submit POST salesman_consolidation  state
  const [isPostCompleted, setIsPostCompleted] = useState(false)
  const [isUpdatingRoutes, setIsUpdatingRoutes] = useState(false)
  // additional information for popups
  const [totalLines, setTotalLines] = useState(0)
  const [linesConfirmed, setLinesConfirmed] = useState(0)
  const [consolidatedBy, setConsolidatedby] = useState('')
  const [timestampConsolidationStart, setTimestampConsolidationStart] = useState('')
  const [timestampConsolidationEnd, setTimestampConsolidationEnd] = useState('')
  const [numberStepProcess, setNumberStepProcess] = useState(1)
  const [numberLinesProcessed, setNumberLinesProcessed] = useState(0)
  const [numberTotalLinesInProcess, setNumberTotalLinesInProcess] = useState(0)
  // additional information for screen
  const [totalOriginalLinesSelected, setTotalOriginalLinesSelected] = useState(0)
  const [totalOriginalTransactionsSelected, setTotalOriginalTransactionsSelected] = useState(0)
  const [timestampDataUpdated, setTimestampDataUpdated] = useState('')

  const handleAllCheck = (e) => {
    setAllChecked(e.target.checked)
    setConsolidateButton(isChecked)
  }

  const handleSingleCheck = (e) => {
    setIsChecked({ ...isChecked, [e.target.name]: e.target.checked })
    setConsolidateButton(isChecked)
  }

  const getFormattedDate = (date) => {
    const d = new Date(date)
    return d.toLocaleString()
  }

  const calculateTotalForFixedBar = (list) => {
    const all_db_refs_selected = list.map((set) => set[0])
    const results = data.filter((item) => all_db_refs_selected.includes(item.db_ref))
    const sum_lines = results.reduce((a, b) => a + b.lineas_totales, 0)
    const sum_headers = results.reduce(
      (a, b) => a + b.pedidos_acumulados + b.devoluciones_acumulados,
      0
    )
    setTotalOriginalLinesSelected(sum_lines)
    setTotalOriginalTransactionsSelected(sum_headers)
  }

  useEffect(() => {
    const checkedOnes = Object.entries(isChecked).filter((value) => value[1] === true)
    const isButtonEnabled = checkedOnes.length > 0
    setConsolidateButton(isButtonEnabled)
    calculateTotalForFixedBar(checkedOnes)
  }, [isButtonEnabled, isChecked])

  useEffect(() => {
    async function fetchData() {
      await fetchDataFromServer()
    }

    fetchData()
  }, [])

  useEffect(() => {
    async function fetchData() {
      await fetchFilterDataFromServer()
    }

    fetchData()
  }, [])

  useEffect(() => {
    async function fetchData() {
      await postSalesmanConsolidation()
    }

    fetchData()
  }, [])

  useEffect(() => {
    setIsChecked((current) => {
      const nextIsChecked = {}
      Object.keys(current).forEach((key) => {
        nextIsChecked[key] = allChecked
      })
      return nextIsChecked
    })
  }, [allChecked])

  useEffect(() => {
    let values = []
    let type_values = []
    let results = {}
    if (branchFilter) {
      values = Object.entries(branchFilter)
        .filter((value) => value[1] === true)
        .map((k, v) => k[0])
    }
    results = data.filter((item) => {
      if (values.length > 0) {
        const branchName = item.sucursal || ''
        return values.map((k) => branchName === k).some((k) => k === true)
      } else {
        return data
      }
    })

    if (typeFilter) {
      type_values = Object.entries(typeFilter)
        .filter((value) => value[1] === true)
        .map((k, v) => k[0])
      results = results.filter((item) => {
        if (type_values.length > 0) {
          const typeName = item.type_of_salesman || ''
          return type_values.map((k) => typeName === k).some((k) => k === true)
        } else {
          return data
        }
      })
    }
    setSearchResults(results)
    setStateOfSelection(results)
  }, [searchTerm, branchFilter, data, searchTerm2, typeFilter])

  const fetchDataFromServer = () => {
    fetch(
      `${BACKEND_URL}/businesses/${cookie.load('business_id')}
        /reports/order_consolidation?business_tenant_uid= 
        ${cookie.load('business_tenant_uid')}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => {
        if (response.status === 401) {
          cookie.remove('token', { path: '/' })
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => {
        setData(data['result'])
        setTimestampDataUpdated(getFormattedDate(data['timestampDataRequested']))
        setIsFetching(false)
        setIsUpdatingRoutes(false)
      })
      .catch((e) => console.log(e))
  }

  const fetchFilterDataFromServer = () => {
    fetch(`${BACKEND_URL}/businesses/${cookie.load('business_id')}/reports/filters_by_branches?business_tenant_uid=${cookie.load('business_tenant_uid')}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => {
        if (response.status === 401) {
          cookie.remove('token', { path: '/' })
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => {
        setFilterData(data['result'])
      })
      .catch((e) => console.log(e))
  }

  const postSalesmanConsolidation = (body) => {
    fetch(
      `${BACKEND_URL}/businesses/${cookie.load('business_id')}
        /salesman_consolidation?
        business_tenant_uid=
        ${cookie.load('business_tenant_uid')}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify(body)
      }
    )
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => evaluateResponse(obj, body))
      .catch((e) => console.log(e))
  }

  const evaluateResponse = (obj, body) => {
    // evaluate responses for status 422 or 500
    if (obj.status === 500) {
      // in case of an error has been rescued
      showErrorResponseFromServer()
      setErrorMessage('Error en el servidor. La consolidación no se ejecutó.')
    } else if (obj.status === 404) {
      // this status is not included in returned statueses currently
      showErrorResponseFromServer()
      setErrorMessage('Ruta de consolidación no encontrada')
    } else if (obj.status === 200) {
      // when consolidation process has been created or just requesting the progrees
      checkPostStatus(obj)
    } else if (obj.status === 400) {
      // bad request, does not have transactions to be consolidated
      showErrorResponseFromServer()
      setErrorMessage('Hubo un error al momento de consolidar. La consolidación no se ejecutó.')
    } else {
      showErrorResponseFromServer() // any other statuses
      setErrorMessage('Contacte a soporte@ubiqua.me')
    }
  }

  const checkPostStatus = (obj) => {
    setConsolidatedby(obj.body.consolidated_by)
    setTimestampConsolidationStart(obj.body.start_time)
    if (obj.body.end_time === null) {
      setIsPosting(true)
      setTimeout(postSalesmanConsolidation, 10000)
      setNumberStepProcess(obj.body.step_number || 1)
      setNumberLinesProcessed(obj.body.number_lines_processed || 0)
      setNumberTotalLinesInProcess(obj.body.number_total_lines || 0)
    } else {
      showConfirmationMessage(obj.body)
    }
  }

  const showErrorResponseFromServer = () => {
    setIsPostCompleted(true)
    setDisplayErrorPostMessage(true)
  }

  const showConfirmationMessage = (body) => {
    setIsPostCompleted(true)
    setDisplayErrorPostMessage(false)
    setTimestampConsolidationEnd(body.end_time)
    setTotalLines(body.total_lines)
    setLinesConfirmed(body.number_lines_confirmed)
  }

  const reloadPage = () => {
    window.location = '/consolidaciones'
  }

  const enableSelectSalesmen = () => {
    setIsUpdatingRoutes(true)
    updateRoutes()
  }

  const disableSelectSalesmen = () => {
    SetChooseSalesmenButton(false)
  }

  const updateRoutes = () => {
    fetchDataFromServer()
    SetChooseSalesmenButton(true)
    // will reset select mode after 10 minutes 600000
    setTimeout(function () {
      SetChooseSalesmenButton(false)
    }, 600000)
  }

  const consolidateButton = (event) => {
    setIsPosting(true)
    setIsPostCompleted(false)
    setTotalLines(0)
    setLinesConfirmed(0)
    setConsolidatedby('')
    setTimestampConsolidationStart('')
    setTimestampConsolidationEnd('')
    let toProcess = {}
    toProcess = Object.entries(isChecked)
      .filter((value) => value[1] === true)
      .map((k, v) => k[0])
    const body = {}
    body['salesman'] = toProcess
    body['consolidated_by'] = cookie.load('user_email')
    body['original_lines'] = totalOriginalLinesSelected
    postSalesmanConsolidation(body)
  }

  const setStateOfSelection = (values) => {
    const initialIsChecked = values
      .filter(
        (value) =>
          value['estado_ruta'] === 'RUTA EN PROCESO' ||
          (value['estado_ruta'] === 'FIN DE RUTA' &&
            value['pedidos_acumulados'] + value['devoluciones_acumulados'] > 0)
      )
      .reduce((acc, d) => {
        acc[d.db_ref] = false
        return acc
      }, {})
    setIsChecked(initialIsChecked)
    setAllChecked(false)
  }

  const getColourOfStatus = (status) => {
    if (status === 'RUTA EN PROCESO') {
      return '#FD893D'
    }
    if (status === 'FIN DE RUTA') {
      return '#149717'
    }
    if (status === 'RUTA CONSOLIDADA') {
      return '#27588D'
    }
  }

  const filterByBranch = (e) => {
    setBranchFilter({ ...branchFilter, [e.target.name]: e.target.checked })
    setSearchTerm(branchFilter)
  }

  const filterByType = (e) => {
    setTypeFilter({ ...typeFilter, [e.target.name]: e.target.checked })
    setSearchTerm2(typeFilter)
  }

  const ConsolidateButtons = () => (
    <div style={{ float: 'right' }}>
      <button
        className={
          isButtonEnabled && !isPosting
            ? 'btn btn-default download-report-button'
            : 'btn btn-default disabled-consolidation-button'
        }
        onClick={consolidateButton}
        disabled={isButtonEnabled && !isPosting && !isUpdatingRoutes ? null : 'disabled'}
      >
        Consolidar
      </button>
      <button
        className="btn btn-default cancel-consolidation-button"
        style={{ marginRight: '11.85px' }}
        disabled={!isUpdatingRoutes ? null : 'disabled'}
        onClick={disableSelectSalesmen}
      >
        Cancelar
      </button>
    </div>
  )

  const toggleFilters = () => {
    setFilterToggle(!isActiveFilter)
  }

  const DisplayFilters = () => (
    <div className="row" style={{ marginLeft: '10px' }}>
      {filterData['sucursal'].length > 0 && (
        <div>
          <div
            style={{
              float: 'left',
              padding: '10px',
              width: '15%',
              color: '#27588D',
              fontSize: '16px',
              fontWeight: 'bold'
            }}
          >
            Sucursal
            {filterData['sucursal'].map((model, index) => (
              <p
                key={index}
                style={{
                  marginTop: '4px',
                  color: '#5F5F5F',
                  fontSize: '14px',
                  fontWeight: 'normal'
                }}
              >
                <input
                  type="checkbox"
                  name={model['sucursal']}
                  checked={branchFilter[model['sucursal']]}
                  onChange={filterByBranch}
                />{' '}
                {model['sucursal']}{' '}
              </p>
            ))}
          </div>
        </div>
      )}
      {filterData['type'].length > 0 && (
        <div
          style={{
            float: 'left',
            padding: '10px',
            width: '55%',
            color: '#27588D',
            fontSize: '16px',
            fontWeight: 'bold'
          }}
        >
          Tipos
          <div style={{ columnCount: 3 }}>
            {filterData['type'].map((model, index) => (
              <p key={index} style={{ color: '#5F5F5F', fontSize: '14px', fontWeight: 'normal' }}>
                <input
                  type="checkbox"
                  name={model['type_of_salesman']}
                  checked={typeFilter[model['type_of_salesman']]}
                  onChange={filterByType}
                />{' '}
                {model['type_of_salesman']}{' '}
              </p>
            ))}
          </div>
        </div>
      )}
      {filterData.length === 0 && <p style={{ marginTop: '9px' }}>No existen filtros.</p>}
    </div>
  )

  if (isFetching) {
    return (
      <div className="main-view">
        <div className="index-header">
          <br />
          <p>Obteniendo datos ....</p>
        </div>
      </div>
    )
  } else {
    var percentageProgress =
      ((numberLinesProcessed / numberTotalLinesInProcess) * 100).toFixed(0) + '%'
    return (
      <div className="main-view">
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="modal-50w"
          show={isPosting}
          centered="true"
        >
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-sm">
              <p>
                <b>Proceso de Consolidación</b>
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: 'center', color: '#27588D', padding: '20px' }}>
            {isPostCompleted && displayErrorPostMessage && (
              <div>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{ color: 'red', fontSize: '75px', marginBottom: '20px' }}
                />
                <br />
                <p>
                  <b>{errorMessage}</b>
                </p>
                <p>
                  <small>Por favor intente nuevamente.</small>
                </p>
                <button
                  className="btn btn-default"
                  style={{
                    backgroundColor: '#FF893D',
                    color: 'white',
                    marginTop: '20px',
                    width: '250px'
                  }}
                  onClick={reloadPage}
                >
                  Aceptar
                </button>
              </div>
            )}
            {isPostCompleted && !displayErrorPostMessage && (
              <div>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{ color: 'green', fontSize: '75px', marginBottom: '20px' }}
                />
                <br />
                <p>
                  <b>Se ha finalizado el proceso de consolidación</b>
                </p>
                <p>
                  Líneas originales: <b>{totalLines}</b>
                </p>
                <p>
                  Líneas consolidadas: <b>{linesConfirmed}</b>
                </p>
                <p>
                  <small>(Por líneas se refiere a líneas de pedidos y devoluciones)</small>
                </p>
                <br />
                <p>
                  <b>Usuario: </b>
                  {consolidatedBy}
                </p>
                <p>
                  <b>Hora de inicio: </b>
                  {getFormattedDate(timestampConsolidationStart) == 'Invalid Date'
                    ? ''
                    : getFormattedDate(timestampConsolidationStart)}
                </p>
                <p>
                  <b>Hora de fin: </b>
                  {getFormattedDate(timestampConsolidationEnd) == 'Invalid Date'
                    ? ''
                    : getFormattedDate(timestampConsolidationEnd)}
                </p>
                <button
                  className="btn btn-default"
                  style={{
                    backgroundColor: '#FF893D',
                    color: 'white',
                    marginTop: '20px',
                    width: '250px'
                  }}
                  onClick={reloadPage}
                >
                  Aceptar
                </button>
              </div>
            )}
            {!isPostCompleted && (
              <div>
                <p>
                  <b>{numberStepProcess == 1 ? 'Paso 1 de 2' : 'Paso 2 de 2'}</b>
                </p>
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped active"
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: percentageProgress }}
                  >
                    {percentageProgress}
                  </div>
                </div>
                <p style={{ textAlign: 'left' }}>
                  {numberLinesProcessed}/{numberTotalLinesInProcess}
                </p>
                <p style={{ textAlign: 'left' }}>
                  <b>
                    {numberStepProcess == 1
                      ? 'Preparando la consolidación de pedidos y notas de crédito'
                      : 'Enviando la consolidación'}
                  </b>
                </p>
                <p style={{ textAlign: 'left' }}>Por favor espere</p>
                <br />
                <p>
                  <b>Usuario: </b>
                  {consolidatedBy}
                </p>
                <p>
                  <b>Hora de inicio: </b>
                  {getFormattedDate(timestampConsolidationStart) == 'Invalid Date'
                    ? ''
                    : getFormattedDate(timestampConsolidationStart)}
                </p>
              </div>
            )}
          </Modal.Body>
        </Modal>
        <div className="index-header">
          <h2 className="mb-20 float-unset">{props.title}</h2>
        </div>
        <div className="index-table">
          <div className="show-area">
            <div className="range-dates">
              <span
                style={{
                  color: '#43425D',
                  fontSize: '18px',
                  marginRight: '15px'
                }}
              >
                Rutas por consolidar
              </span>
              {isSelectMode ? (
                <ConsolidateButtons />
              ) : (
                <div style={{ float: 'right', display: 'inline-flex', fontSize: 'large' }}>
                  <div style={{ marginTop: '10px', marginRight: '20px' }}>
                    Fecha y hora de última actualización: <b>{timestampDataUpdated}</b>
                  </div>
                  <button
                    className="btn btn-default download-report-button"
                    onClick={enableSelectSalesmen}
                  >
                    Escoger Rutas
                  </button>
                </div>
              )}
            </div>
            <div className="">
              <span
                style={{
                  color: '#27588D',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  marginRight: '7px'
                }}
              >
                Filtros
                <FontAwesomeIcon
                  icon={isActiveFilter ? faCaretDown : faCaretUp}
                  onClick={toggleFilters}
                />
              </span>
            </div>
            {isActiveFilter && <DisplayFilters />}
            <hr className="mt-20" />
            <div>
              <table className="table table-hover">
                <thead>
                  <tr>
                    {isSelectMode ? (
                      <th>
                        <input
                          type="checkbox"
                          id="checkAll"
                          checked={allChecked}
                          onChange={handleAllCheck}
                          style={{ marginLeft: '35px' }}
                        />
                      </th>
                    ) : (
                      <th className="text-align-left">SELECCIONAR</th>
                    )}
                    <th className="text-align-left">RUTA</th>
                    <th className="text-align-left">GRUPO DE VENTA</th>
                    <th className="text-align-left">TIPO</th>
                    <th className="text-align-left">SUCURSAL</th>
                    <th className="text-align-left">VENDEDOR</th>
                    <th className="text-align-center">PEDIDOS</th>
                    <th className="text-align-center">N/C</th>
                    <th className="text-align-left">ESTADO DE RUTA</th>
                    <th className="text-align-left">ULTIMO CONSOLIDADO</th>
                    <th className="text-align-left">USUARIO</th>
                  </tr>
                </thead>
                <tbody>
                  {isUpdatingRoutes && (
                    <tr>
                      <td colSpan="11">Obteniendo datos...</td>
                    </tr>
                  )}
                  {!isUpdatingRoutes &&
                    searchResults.length > 0 &&
                    searchResults.map((model, index) => (
                      <tr className="" key={index}>
                        <td>
                          <input
                            type="checkbox"
                            name={model.db_ref}
                            checked={isChecked[model.db_ref]}
                            className={isSelectMode ? '' : 'hide-components'}
                            style={{ marginLeft: '35px' }}
                            onChange={handleSingleCheck}
                            disabled={
                              model.pedidos_acumulados + model.devoluciones_acumulados > 0
                                ? false
                                : true
                            }
                          />
                        </td>
                        <td className="text-align-left">{model['route_code']}</td>
                        <td className="text-align-left">{model['sales_group']}</td>
                        <td className="text-align-left">{model['type_of_salesman']}</td>
                        <td className="text-align-left">{model['sucursal']}</td>
                        <td className="text-align-left">{model['nombre']}</td>
                        <td className="text-align-center">{model['pedidos_acumulados']}</td>
                        <td className="text-align-center">{model['devoluciones_acumulados']}</td>
                        <td
                          className="text-align-left"
                          style={{ color: getColourOfStatus(model['estado_ruta']) }}
                        >
                          {model['estado_ruta']}
                        </td>
                        <td className="text-align-left" style={{ fontSize: '14px' }}>
                          {model['fecha_consolidacion'] &&
                            getFormattedDate(model['fecha_consolidacion'])}
                        </td>
                        <td className="text-align-center">{model['consolidated_by']}</td>
                      </tr>
                    ))}
                  {!isUpdatingRoutes && searchResults.length === 0 && (
                    <tr>
                      <td colSpan="11">No existe información para consolidar.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {isSelectMode && (
          <div id="info-fixed-bar-bottom">
            <b>
              <span className="mr-85">Total de lineas: {totalOriginalLinesSelected}</span>
              <span>Total de transacciones: {totalOriginalTransactionsSelected}</span>
            </b>
          </div>
        )}
      </div>
    )
  }
}

export default ConsolidationIndexView
