import React, { useRef, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

export default function ReactFilter({
  title,
  options,
  identifier,
  onChange,
  resetWatcher,
  disabled
}) {
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState(new Set())
  const [search, setSearch] = useState('')
  const [selectAll, setSelectAll] = useState(true)
  const filterContainer = useRef(null)

  const handleOutsideClick = (e) => {
    if (filterContainer.current && !filterContainer.current.contains(e.target)) {
      setDropdownOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  })

  useEffect(() => {
    if (disabled) {
      setDropdownOpen(false)
    }
  }, [disabled])

  useEffect(() => {
    setSearch('')
    setSelectedOptions(new Set())
  }, [resetWatcher])

  useEffect(() => {
    checkIfShouldSelectAll()
    onChange({ identifier, selectedOptions: Array.from(selectedOptions) })
  }, [selectedOptions])

  const selectOption = (idx, option) => () => {
    if (disabled) return
    let selected = new Set(selectedOptions)
    if (selected.has(option)) {
      selected.delete(option)
    } else {
      selected.add(option)
    }
    setSelectedOptions(selected)
  }

  const handleFilterSearch = (e) => {
    if (disabled) return
    setSearch(e.target.value)
  }

  const shouldDisplay = (option) => {
    if (search === '') return true
    return option.toLowerCase().includes(search.toLowerCase())
  }

  const checkIfShouldSelectAll = () => {
    if (options?.length == selectedOptions?.size && selectedOptions?.size != 0) {
      return setSelectAll(false)
    }
    if (selectedOptions?.size > 0) return setSelectAll(true)
    return setSelectAll(true)
  }

  const selDesAll = async () => {
    let selected = new Set(selectedOptions)
    for (const o of options) {
      if (selectAll) {
        selected.add(o)
      } else {
        selected.delete(o)
      }
    }
    setSelectedOptions(selected)
  }

  const filterOptions = () => {
    return options?.filter((option) => shouldDisplay(option))
  }

  const DropdownOption = () => {
    return (
      <div
        className="react-filter-dropdown-box"
        style={{
          transition: 'all 0.2s',
          opacity: isDropdownOpen ? 1 : 0,
          visibility: isDropdownOpen ? 'visible' : 'hidden'
        }}
        ref={filterContainer}
      >
        <div className="filter-search-input-container">
          <input
            type="search"
            className="filter-search-input"
            placeholder="Palabras claves..."
            value={search}
            onChange={handleFilterSearch}
            disabled={disabled}
          />
          {search && (
            <div
              title="Limpiar texto"
              onClick={() => setSearch('')}
              className="filter-search-input-x"
            >
              X
            </div>
          )}
        </div>
        <div className="react-filter-dropdown-box-items">
          {!search && options?.length <= 50 && (
            <div className="react-filter-option" onClick={selDesAll}>
              <input type="checkbox" className="filter-checkbox" readOnly checked={!selectAll} />
              <div className="filter-checkbox-label">
                {selectAll ? 'Seleccionar' : 'Deseleccionar'} todos
              </div>
            </div>
          )}
          {filterOptions()?.map((option, idx) => (
            <div
              key={idx}
              title={option}
              className="react-filter-option"
              onClick={selectOption(idx, option)}
            >
              <input
                type="checkbox"
                id={'filter-checkbox-' + idx}
                className="filter-checkbox"
                readOnly
                checked={selectedOptions?.has(option) || false}
                value={option || ''}
                disabled={disabled}
              />
              <div className="filter-checkbox-label">{option}</div>
            </div>
          ))}
          {filterOptions()?.length == 0 && search && (
            <div className="react-filter-option">
              <div className="filter-checkbox-label">No hubo resultados</div>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className={`react-filter-box ${disabled ? 'react-filter-disabled' : ''}`}>
      <div
        title={title}
        onClick={() => !disabled && setDropdownOpen(!isDropdownOpen)}
        className="react-filter-box-clickable-zone"
        ref={filterContainer}
      >
        <div className="react-filter-titles">
          <div className="filter-title-overflow">{title?.toUpperCase()}</div>{' '}
          {selectedOptions?.size > 0 && (
            <span className="text-orange">{`(${selectedOptions?.size})`}</span>
          )}
          {!isDropdownOpen && <FontAwesomeIcon className="fadeIn" icon={faChevronDown} />}
          {isDropdownOpen && <FontAwesomeIcon className="fadeIn" icon={faChevronUp} />}
        </div>
      </div>
      {DropdownOption()}
    </div>
  )
}
