import React, { useState, useEffect } from 'react'
import cookie from 'react-cookies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { validateAccessRole } from '../helpers/userRole'

const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
const BACKEND_URL = USE_BUSINESS_URL
  ? `${process.env.REACT_APP_BACKEND_URL}/businesses/${cookie.load('business_id')}`
  : process.env.REACT_APP_BACKEND_URL

const ProductPriceListRow = ({ obj, id }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [price, setPrice] = useState(obj.price)

  const startEditing = () => {
    setIsEditing(true)
  }

  const cancelEditing = () => {
    setIsEditing(false)
  }

  const unassignPriceList = (event) => {
    const val = window.confirm(
      'Estas desasignando una lista de precio para este producto. Deseas continuar?'
    )
    if (val === true) {
      event.preventDefault()

      fetch(`${BACKEND_URL}/product_pricelist/${obj.id}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          id: obj.id,
          product_id: id,
          price: 0.0,
          active: false
        })
      })
        .then((response) => response.json())
        .then(() => (window.location = `/productos/${id}/listas_de_precios`))
        .catch((e) => console.log(e))
    }
  }

  const editPrice = (event) => {
    event.preventDefault()

    fetch(`${BACKEND_URL}/product_pricelist/${obj.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      },
      body: JSON.stringify({
        id: obj.id,
        price: price,
        active: true,
        product_id: id
    
      })
    })
      .then((response) => response.json())
      .then(() => (window.location = `/productos/${id}/listas_de_precios`))
      .catch((e) => console.log(e))
  }

  const handleInputChange = (event) => {
    setPrice(event.target.value)
  }

  return (
    <tr>
      <td className="table-body-cell" style={{ verticalAlign: 'middle', padding: '10px 25px' }}>
        {obj.db_ref}
      </td>

      <td className="table-body-cell">{obj.name}</td>

      <td sclassName="table-body-cell">
        {!isEditing && <span id="current_price_span">{obj.price}</span>}
        {isEditing && (
          <input
            required
            onChange={handleInputChange}
            className="pricelist-input"
            name="price"
            step="0.01"
            type="number"
            placeholder={obj.price}
            value={price}
          />
        )}
      </td>
      <td className="">
        {!isEditing && validateAccessRole(cookie.load('user_role')?.product_module)?.can_write && (
          <div className="table-body-cell">
            <input
              onClick={startEditing}
              className="edit-pricelist-button"
              value="Actualizar"
              type="submit"
            />
            <button
              onClick={unassignPriceList}
              className="unassign-price-list-button"
              type="button"
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        )}
        {isEditing && (
          <div className="table-body-cell">
            <input
              onClick={cancelEditing}
              id="cancel-edit"
              className="cancel-edit-price-button"
              value="Cancelar"
              type="submit"
            />
            <input
              onClick={editPrice}
              className="edit-price-button"
              type="submit"
              value="Guardar"
            />
          </div>
        )}
      </td>
    </tr>
  )
}

export default ProductPriceListRow
