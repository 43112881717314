import React, { useState, useEffect } from 'react'
import Pagination from '../layout/Pagination'
import cookie from 'react-cookies'
import { validateAccessRole } from '../helpers/userRole'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faSearch, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import ReactFilter from '../components/inputs/ReactFilterList'
import { Table } from 'react-bootstrap'

const ProductIndexView = ({
  collapsed,
  isFetching,
  title,
  productData,
  fetchDataStatus,
  filters,
  filterVersion,
  searchInput,
  handleSearch,
  handleEnterSearch,
  resetReactFilters,
  isAnyFilterSelected,
  productFilter,
  handlePaginationClick,
  pagination,
  onSearch,
  onSearchInput,
  updateAllSelectedOptions
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showCreateProductPage = () => {
    window.location = '/crear_producto'
  }

  const showModel = (event) => {
    event.preventDefault()
    const id = event.target.id
    window.open(`/productos/${id}/detalles`)
  }

  const handleFilterChange = (identifier, selectedOptions) => {
    const selectedLabels = Array.from(selectedOptions)
    updateAllSelectedOptions(identifier, selectedLabels)
  }

  const filtersArray = Array.isArray(filters[0]) ? filters[0] : Array.from(filters[0] || [])
  const anyFilterEmpty = filtersArray.some((filter) => filter.value.length === 0)

  if (isFetching) {
    return (
      <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
        <div className="index-header">
          <br />
          <p> Obteniendo datos...</p>
        </div>
      </div>
    )
  } else {
    return (
      <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
        <div className="index-header detailed-container">
          <h2 className="text-header-tittle">{title}</h2>
          <div className="index-buttons">
            {validateAccessRole(cookie.load('user_role')?.product_module)?.can_write && (
              <button className="text-header-tittle" onClick={showCreateProductPage}>
                Crear producto nuevo
              </button>
            )}
          </div>
        </div>

        <div className="index-table">
          <div className="show-area">
            <h4 className="text-sub-title">Filtrar productos por:</h4>
            <div className="filters-search-container mb-10">
              <FontAwesomeIcon className="filter-icon mr-10" icon={faFilter} />
              <div className="filters-container">
                {filters &&
                  Array.from(filters).map((filter, indx) => (
                    <div key={`flr-${indx}`} className="mr-10">
                      <ReactFilter
                        className="mr-10"
                        title={filter.key}
                        identifier={filter.key}
                        options={filter.value}
                        resetWatcher={filterVersion}
                        onSelectionChange={handleFilterChange}
                        disabled={anyFilterEmpty}
                      />
                    </div>
                  ))}
              </div>
              <div className="search-input-containter">
                <input
                  value={searchInput}
                  onChange={handleSearch}
                  onKeyDown={handleEnterSearch}
                  className="round-search-input"
                  style={{ fontSize: 12 }}
                  placeholder="Buscar por código o nombre"
                />
                {searchInput === '' && (
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    className="search-icon"
                    style={{ marginTop: -1 }}
                  />
                )}
                {searchInput && (
                  <div
                    title="Limpiar texto"
                    onClick={resetReactFilters}
                    className="round-search-input-x"
                  >
                    X
                  </div>
                )}
              </div>
            </div>
            <div className="mb-20">
              {isAnyFilterSelected && (
                <a onClick={resetReactFilters} className="reset-filter cursor-pointer mb-20">
                  Restablecer filtros aplicados
                </a>
              )}
            </div>
            <div></div>
            <Table hover responsive>
              <thead className="table-header-gray">
                <tr>
                  <th style={{ verticalAlign: 'middle', padding: '10px 25px', width: '100px' }}>
                    <span className="th-header-text ">CÓDIGO</span>
                  </th>

                  <th style={{ verticalAlign: 'middle', padding: '10px', width: '300px' }}>
                    <span className="th-header-text">NOMBRE DEL PRODUCTO</span>
                  </th>

                  {(productFilter?.byBrand || productFilter?.byCategory) && (
                    <>
                      <th style={{ verticalAlign: 'middle', padding: '10px', width: '150px' }}>
                        <span className="th-header-text">UNIDAD DE VENTA 1</span>
                      </th>
                      <th style={{ verticalAlign: 'middle', padding: '10px', width: '150px' }}>
                        <span className="th-header-text">UNIDAD DE VENTA 2</span>
                      </th>
                    </>
                  )}

                  {!productFilter?.byCategory && (
                    <th style={{ verticalAlign: 'middle', padding: '10px', width: '200px' }}>
                      <span className="th-header-text">MARCA</span>
                    </th>
                  )}

                  {!productFilter?.byBrand && (
                    <th style={{ verticalAlign: 'middle', padding: '10px', width: '200px' }}>
                      <span className="th-header-text">CATEGORÍA</span>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {productData && productData.length > 0 ? (
                  productData.map((model, index) => (
                    <tr key={index}>
                      <td
                        className="table-body-cell  td-text-limited-space"
                        data-label="Código:"
                        id={model.id}
                        onClick={showModel}
                      >
                        {model.db_ref}
                      </td>
                      <td
                        className="table-body-cell"
                        data-label="Nombre del Producto:"
                        id={model.id}
                        onClick={showModel}
                      >
                        {model.name}
                      </td>
                      {productFilter?.byBrand || productFilter?.byCategory ? (
                        <>
                          <td
                            className="table-body-cell"
                            data-label="Unidad de Venta 1:"
                            id={model.id}
                            onClick={showModel}
                          >
                            {model.packages[0]?.unit || '-'}
                          </td>
                          <td
                            className="table-body-cell"
                            data-label="Unidad de Venta 2:"
                            id={model.id}
                            onClick={showModel}
                          >
                            {model.packages[1]?.unit || '-'}
                          </td>
                        </>
                      ) : null}
                        {!productFilter?.byCategory && (
                          <td className="td-text-limited" id={model['id']} onClick={showModel}>
                            {model['brand'] && model['brand']['description']}
                            {productFilter?.brandName &&
                              productFilter.brandName}
                            {productFilter?.byCategory &&
                              productFilter.categoryName}
                          </td>
                        )}
                        {!productFilter?.byBrand && (
                          <td className="td-text-limited" id={model['id']} onClick={showModel}>
                            {productFilter?.byCategory? productFilter.categoryName : model['product_type_id'] ?(
                                  <div key={model['category']['id']}>
                                    {model['category']['description'] || 'SIN ASIGNAR'}
                                  </div>
                                )
                              : 'SIN ASIGNAR'}
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        className="td-text-limited"
                        colSpan="1"
                      >
                        No hay resultados
                      </td>
                    </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>

        <nav className="index-table">
          {pagination.total_pages > 1 && (
            <div onClick={handlePaginationClick}>
              <Pagination
                current_page={pagination.current_page}
                total_pages={pagination.total_pages}
              />
            </div>
          )}
        </nav>
      </div>
    )
  }
}

export default ProductIndexView
