import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import SegmentIndexView from './SegmentIndexView'

const SegmentIndexApp = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false)

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isUpdating) {
        const message = 'Estamos procesando tu solicitud. Por favor, espera unos segundos... No cierres o recargues la página.';
        event.returnValue = message;
        return message;
      }
    };
  
    window.addEventListener("beforeunload", handleBeforeUnload);
  
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isUpdating]);


  const handleToggleCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed)
    localStorage.setItem('collapsedState', newCollapsed)
  }

  useEffect(() => {
    const collapsedFromStorage = localStorage.getItem('collapsedState') === 'true'
    setCollapsed(collapsedFromStorage)
  }, [])

  return (
    <div>
      <div>
        <div>
          <TopBar
            onToggleCollapse={handleToggleCollapse}
            collapsed={collapsed}
          />
        </div>
        <div>
          <SegmentIndexView 
            collapsed={collapsed} 
            setIsUpdating={setIsUpdating}
          />
        </div>
      </div>
      <div>
        <NavBar activeItem="Segmentos" isEditing={false} isUpdating={isUpdating}  collapsed={collapsed} />
      </div>
    </div>
  );
};

export default SegmentIndexApp;
