import React, { useState } from 'react'
import BaseCard from './BaseCard'
import { VictoryPie } from 'victory'
import { useEffect } from 'react'

const colors = ['#EEC873', '#D5422D', '#FE883D', '#6DCBFB', '#27AE60']

const ColorDash = ({ originalIndex, selectedIndex }) => {
  let selected = selectedIndex === originalIndex
  return (
    <div
      className="motive-dash"
      style={{
        backgroundColor: colors[originalIndex % colors.length],
        height: selected ? '8px' :  '4px'
      }}
    ></div>
  )
}

const CircleLabelNumber = ({ number }) => {
  return (
    <div className="circle-label-number">
      <p className="p-card-circle-label-number">{number}</p>
    </div>
  )
}

const ReportMotiveCard = ({ reportMotives, selectedTab }) => {
  let maxValue = reportMotives.reduce((max, motive) => {
    return motive.value > max ? motive.value : max
  }, 0)
  let totalValue = reportMotives.reduce((total, motive) => {
    return total + motive.value
  }, 0)

  const [numberValue, setNumberValue] = useState(totalValue)
  const [hoverIndex, setHoverIndex] = useState(-1)

  if (selectedTab == 1) return null

  let maxColors = colors.length
  

  const calculateValue = (value) => {
    return (value * 5) / maxValue
  }

  const buildReportMotives = () => {
    const motives = reportMotives.map((motive, index) => {
      return {
        x: motive.text,
        y: calculateValue(motive.value)
      }
    })
    return motives
  }

  const sortReportMotives = () => {
    const motives = [...reportMotives].sort((a, b) => {
      return a.value < b.value ? 1 : -1
    })
    return motives
  }

  const findOriginalIndex = (motiveTxt) => {
    let idx = reportMotives.findIndex((motive) => {
      return motive.text === motiveTxt
    })
    return idx
  }

  useEffect(()=> {
    setNumberValue(totalValue)
  }, [selectedTab])

  return (
    <BaseCard>
      <p className="p-card-title">Motivos reporte de visita</p>
      <div className="report-container">
        <div className="victory-pie">
          <VictoryPie
            style={{ labels: { fill: 'black' } }}
            padAngle={({ datum }) => 2}
            colorScale={colors}
            innerRadius={85}
            radius={({ datum }) => 85 + datum.y * 15}
            labels={({ datum }) => ''}
            data={buildReportMotives()}
            events={[
              {
                target: 'data',
                eventHandlers: {
                  onMouseOver: () => {
                    return [
                      {
                        target: 'labels',
                        mutation: (props) => {
                          setNumberValue(reportMotives[props.index].value)
                          setHoverIndex(props.index)
                          return null
                        }
                      }
                    ]
                  },
                  onMouseLeave: () => {
                    return [
                      {
                        target: 'labels',
                        mutation: (props) => {
                          setNumberValue(totalValue)
                          setHoverIndex(-1)
                          return null
                        }
                      }
                    ]
                  }
                }
              }
            ]}
          />
          <CircleLabelNumber number={numberValue} />
        </div>
        <div className="motives-container">
          {sortReportMotives().map((motive, index) => (
            <div
              key={index}
              className="motive"
              onMouseOver={() => {
                let idx = findOriginalIndex(motive.text)
                setNumberValue(reportMotives[idx].value)
                setHoverIndex(idx)
              }}
              onMouseLeave={() => {
                setNumberValue(totalValue)
                setHoverIndex(-1)
              }}
            >
              <ColorDash
                originalIndex={findOriginalIndex(motive.text)}
                selectedIndex={hoverIndex}
              />
              <p className="p-card-motive" title={motive.text}>
                <b>{motive.value} </b> {motive.text}
              </p>
            </div>
          ))}
        </div>
      </div>
    </BaseCard>
  )
}

export default ReportMotiveCard
