import React from 'react'
import BaseCard from './BaseCard'

const AmountCard = ({ title, subtitle, icon, selectedTab, displayTab }) => {
  if (selectedTab == 1 || displayTab.includes(selectedTab)) {
    return (
      <BaseCard>
        <div className="amount-container">
          <img draggable={false} className="amount-icon" src={`assets/${icon}.svg`} />
          <div className="amount-container-details">
            <div>
              <p className="p-card-amount-subtitle">{title}</p>
            </div>
            <div>
              <p className="p-card-amount-number"> {subtitle}</p>
            </div>
          </div>
        </div>
      </BaseCard>
    )
  }
  return null
}

export default AmountCard
