import React, { useState } from 'react';

const TextInput = (props) => {
  const [hasError, setHasError] = useState(false);

  const handleBlur = () => {
    if (props.required && !props.value) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };

  let formControl = props.inputstyle;

  if (props.touched === 'true' && props.valid === 'false') {
    formControl = formControl + ' valid-control-error';
  }

  if (hasError) {
    formControl = formControl + ' valid-control-error';
  }

  return (
    <div>
      <div className="form-group">
        <input
          type={props.type ? props.type : 'text'}
          maxLength={props.maxLength}
          className={formControl}
          required={props.required}
          onBlur={handleBlur}
          {...props}
        />
        {hasError && <span className="error-message">Este campo es requerido</span>}
      </div>
    </div>
  );
};

export default TextInput;