export const ShowTabs = {
  All: 0, // display in all tabs
  Resumen: 1,
  Pedidos: 2,
  Devoluciones: 3,
  Cobros: 4,
  Entregas: 5,
  Pos: 6
}

export const DicRoles = {
  resume: 'Resumen',
  order: 'Pedidos',
  refund: 'Devoluciones',
  charge: 'Cobros',
  delivery: 'Entregas',
  pos_task_activity: 'Pos'
}

export const RoleLabels = {
  [ShowTabs.Resumen]: 'Resumen',
  [ShowTabs.Pedidos]: 'Pedidos',
  [ShowTabs.Devoluciones]: 'Devoluciones',
  [ShowTabs.Cobros]: 'Cobros',
  [ShowTabs.Entregas]: 'Entregas',
  [ShowTabs.Pos]: 'Ejecución en punto de venta'
}

export const DicRolesTab = {
  [ShowTabs.Resumen]: 'resume',
  [ShowTabs.Pedidos]: 'order',
  [ShowTabs.Devoluciones]: 'refund',
  [ShowTabs.Cobros]: 'charge',
  [ShowTabs.Entregas]: 'delivery',
  [ShowTabs.Pos]: 'pos_task_activity'
}

export const DetailShowTabs = {
  All: 0, // display in all tabs
  ResumenVisita: 1,
  ControlHorario: 2
}

export const DetailDicRoles = {
  ResumenVisita: 'Resumen de visita',
  ControlHorario: 'Control de horario'
}

export const DetailDicRolesTab = {
  [DetailShowTabs.ResumenVisita]: 'visit_resume',
  [DetailShowTabs.ControlHorario]: 'timestamp_control'
}
