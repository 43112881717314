import React, { useState, useEffect, useCallback } from 'react'
import cookie from 'react-cookies'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import PriceListIndexView from './PriceListIndexView'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const PriceListIndexApp = (props) => {
  const [userName] = useState('Ana Sofia Lau Hou')
  const [perPage] = useState(25)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [isFetching, setIsFetching] = useState(true)
  const [data, setData] = useState(null)
  const [searchPlaceholder] = useState('Buscar listas de precios por código o nombre')
  const [title, setTitle] = useState('Listas de Precios')
  const [query, setQuery] = useState('')
  const [collapsed, setCollapsed] = useState(false)

  useEffect(() => {
    const collapsedState = localStorage.getItem('collapsedState') === 'true'
    setCollapsed(collapsedState)
    setQueryState()
    setPaginationParamsState()
  }, [])

  const setQueryState = () => {
    if (window.location.href.indexOf('query') >= 0) {
      const query = decodeURI(
        window.location.href.split('query=')[window.location.href.split('query=').length - 1]
      )
      setQuery(query)
      fetchDataFromServer(query, currentPage)
    } else {
      setQuery('')
      fetchDataFromServer('', currentPage)
    }
  }

  const setPaginationParamsState = () => {
    if (window.location.href.indexOf('pagina') >= 0) {
      const page = window.location.href.split('pagina=')
      setCurrentPage(parseInt(page[page.length - 1].split('&')[0], 10))
      fetchDataFromServer(query, parseInt(page[page.length - 1].split('&')[0], 10))
    } else {
      setCurrentPage(1)
      fetchDataFromServer(query, 1)
    }
  }

  const fetchDataFromServer = useCallback((query, page) => {
    setIsFetching(true)
    const url = query
      ? `${BACKEND_URL}/businesses/${cookie.load('business_id')}/price_lists?q=${query}&page=${page}&per_page=${perPage}`
      : `${BACKEND_URL}/businesses/${cookie.load('business_id')}/price_lists?page=${page}&per_page=${perPage}`

    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => {
        setData(data['table'])
        setIsFetching(false)
        setTotalPages(Math.ceil(data['count'] / perPage))
        setTitle(query ? `Resultados de la búsqueda: ${query}` : 'Listas de Precios')
      })
      .catch((e) => console.log(e))
  }, [perPage])

  const handlePaginationClick = (event) => {
    if (event.target.tagName === 'A') {
      handlePagination(event.target)
    } else if (event.target.tagName === 'svg') {
      handlePagination(event.target.parentNode)
    } else if (event.target.tagName === 'path') {
      handlePagination(event.target.parentNode.parentNode)
    }
  }

  const handlePagination = (target) => {
    if (target.id === 'first-page' && currentPage !== 1) {
      window.location = `/listas_de_precios?pagina=1&query=${query}`
    } else if (target.id === 'last-page' && currentPage !== totalPages) {
      window.location = `/listas_de_precios?pagina=${totalPages}&query=${query}`
    } else if (target.id === 'previous-page' && currentPage !== 1) {
      window.location = `/listas_de_precios?pagina=${currentPage - 1}&query=${query}`
    } else if (target.id === 'next-page' && currentPage !== totalPages) {
      window.location = `/listas_de_precios?pagina=${currentPage + 1}&query=${query}`
    }
  }

  const handleSearch = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      const newQuery = event.target.value
      if (newQuery !== '') {
        window.location = `/listas_de_precios?query=${newQuery}`
      }
    }
  }

  const handleToggleCollapse = (collapsed) => {
    setCollapsed(collapsed)
    localStorage.setItem('collapsedState', collapsed)
  }

  const handleSearchUpdate = (newQuery) => {
    setQuery(newQuery)
    fetchDataFromServer(newQuery, currentPage)
  }

  return (
    <div>
      <div>
        <div onKeyDown={handleSearch}>
          <TopBar
            searchPlaceholder={searchPlaceholder}
            userName={userName}
            onToggleCollapse={handleToggleCollapse}
            collapsed={collapsed}
          />
        </div>
        <div onClick={handlePaginationClick}>
          <PriceListIndexView
            data={data}
            isFetching={isFetching}
            title={title}
            total_pages={totalPages}
            onSearchUpdate={handleSearchUpdate}
            current_page={currentPage}
            collapsed={collapsed}
          />
        </div>
      </div>
      <div>
        <NavBar activeItem="Listas de Precios" collapsed={collapsed} />
      </div>
    </div>
  )
}

export default PriceListIndexApp
