import React, { useEffect, useReducer, useState } from 'react'
import { CreateDispatchContext } from './CreateDispatchContext'
import { v4 as uuid } from 'uuid';
import { TYPES } from '../state/Types'
import cookie from 'react-cookies'
import validate from '../../helpers/validation'
import * as _ from 'lodash'
import moment from 'moment'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const CreateDispatchProvider = ({
  children,
  orderDetails,
  deliveryDetails,
  position,
  transactionUuid,
  enableButton,
  disableButton
}) => {
  /*
    UseState
    https://reactjs.org/docs/hooks-reference.html#usestate
   */
  const [resultClient, setResultClient] = useState({})
  const [isFetching, setIsFetching] = useState(true)
  const [priceList, setPriceList] = useState({})
  const [failedCreationInfo, setFailedCreationInfo] = useState('')
  // if driver name exists, it means that it is update dispatch
  const [formIsValid, setFormIsValid] = useState(!!deliveryDetails)
  const [formControls, setFormControls] = useState({
    deliverer: {
      value: deliveryDetails ? deliveryDetails.driver_name : '',
      placeholder: '',
      postValue: deliveryDetails ? deliveryDetails.driver_id : '',
      valid: !!deliveryDetails,
      touched: false,
      validationRules: {
        isNumRequired: true
      }
    },
    date: {
      value: deliveryDetails ? deliveryDetails.delivery_date : '',
      placeholder: '',
      valid: !!deliveryDetails,
      touched: false,
      validationRules: {
        minLength: 1,
        isRequired: true
      }
    }
  })

  /*
  Effect
  https://reactjs.org/docs/hooks-effect.html
*/
  useEffect(() => {
    const url = `${BACKEND_URL}/businesses/${cookie.load('business_id')}/clients/${orderDetails.client_id}`;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setResultClient(data)
        setPriceList(data.price_list)
        setIsFetching(false)
      })
      .catch((e) => console.log(e))
  }, [])

  /*
      Reducer
      https://reactjs.org/docs/hooks-reference.html#usereducer
   */
  const initialTasksState = {
    products: [],
    deliverer: {},
    completedTasks: []
  }

  const tasksReducer = (state, action) => {
    switch (action.type) {
      case TYPES.ADD_TASK:
        return {
          ...state,
          products: [...state.products, action.task]
        }
      case TYPES.DELETE_TASK:
        return {
          ...state,
          products: state.products.filter((t) => t.product_db_ref !== action.task.product_db_ref)
        }
      case TYPES.CHANGE_QUANTITY:
        return {
          ...state,
          products: state.products.map((item) =>
            item.id === action.task.id
              ? {
                  ...item,
                  quantity: action.quantityValue,
                  validQuantity: minNumberValidator(action.quantityValue),
                  productSubTotal: item.useWeight
                    ? item.productSubTotal
                    : action.quantityValue * item.unit_price
                }
              : item
          )
        }
      case TYPES.CHANGE_WEIGHT:
        return {
          ...state,
          products: state.products.map((item) =>
            item.id === action.task.id
              ? {
                  ...item,
                  weight: action.weightValue,
                  validWeight: minNumberValidator(action.weightValue),
                  productSubTotal: item.useWeight
                    ? action.weightValue * item.unit_price
                    : item.productSubTotal
                }
              : item
          )
        }
      case TYPES.ADD_DELIVERER:
        return {
          ...state,
          deliverer: action.deliverer
        }
      default:
        return state
    }
  }

  const minNumberValidator = (value) => value > 0

  /*
    Load pre orders products
   */
  const readStoredTasks = () => {
    let newOrder
    if (deliveryDetails) {
      newOrder = deliveryDetails.details.map((obj) => {
        obj.id = uuid()
        obj.validQuantity = true
        obj.validWeight = true
        obj.useWeight = ['KILO', 'LIBRA'].includes(obj.price_unit)
        obj.productSubTotal = obj.useWeight
          ? obj.weight * obj.unit_price
          : obj.quantity * obj.unit_price
        return obj
      })
    } else {
      newOrder = orderDetails.details.map((obj) => {
        obj.id = uuid()
        obj.validQuantity = true
        obj.validWeight = true
        obj.useWeight = ['KILO', 'LIBRA'].includes(obj.price_unit)
        obj.productSubTotal = obj.useWeight
          ? obj.weight * obj.unit_price
          : obj.quantity * obj.unit_price
        return obj
      })
    }
    const tasksMap = {
      products: newOrder
    }

    return tasksMap ? tasksMap : initialTasksState
  }

  const storedTasks = readStoredTasks()
  const [state, dispatch] = useReducer(tasksReducer, storedTasks)

  /*
    Handle change input
   */
  const changeFormHandler = (event) => {
    const name = event.target.name
    const value = event.target.value

    const updatedControls = {
      ...formControls
    }
    const updatedFormElement = {
      ...updatedControls[name]
    }

    updatedFormElement.value = value
    updatedFormElement.touched = true
    updatedFormElement.postValue = value
    updatedFormElement.valid = validate(value, updatedFormElement.validationRules)

    updatedControls[name] = updatedFormElement

    let formIsValid = true
    for (const inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid
    }
    setFormControls(updatedControls)
    setFormIsValid(formIsValid)
  }

  const getNewProduct = (obj) => {
    const product = {}
    product.extended_price = obj.productSubTotal.toFixed(2)
    product.calculated_tax_price = calculateTaxPrice(obj.productSubTotal.toFixed(2), obj.tax_rate)
    product.unit_price = obj.unit_price
    product.sales_unit_id = obj.price_unit_id
    product.product_id = obj.product_id
    product.quantity = obj.quantity
    product.weight = obj.weight
    product.tax_rate = obj.tax_rate
    return product
  }

  const calculateTaxPrice = (priceWithoutTax, tax) => {
    const taxToSum = priceWithoutTax * tax
    return priceWithoutTax + taxToSum
  }

  const onCreateDispatch = (e, validWeight) => {
    e.preventDefault()

    const quantityZero = _.find(state.products, (o) => o.quantity <= 0)

    let weightZero = false
    if (validWeight) {
      weightZero = _.find(state.products, (o) => o.weight <= 0)
    }

    if (!formIsValid) {
      setFailedCreationInfo('Por favor introduzca los campos obligatorios')
    } else if (state.products.length === 0) {
      setFailedCreationInfo('Debe despachar al menos un producto')
    } else if (quantityZero || weightZero) {
      setFailedCreationInfo('La cantidad y/o el peso del producto no pueden estar en 0')
    } else {
      disableButton()
      setFailedCreationInfo('')
      const newObj = state.products.map((obj) => getNewProduct(obj))
      let order_subtotal = 0.0
      state.products.map((obj) => (order_subtotal += obj.productSubTotal))

      return fetch(`${BACKEND_URL}/businesses/${cookie.load('business_id')}/deliveries`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          business_id: 1,
          transaction_uuid: transactionUuid,
          created_date: new Date(),
          client_id: resultClient.id,
          driver_id: formControls.deliverer.value,
          comments: '',
          delivery_date: moment(formControls.date.value).format('YYYY-MM-DD 23:59:59'),
          total: order_subtotal.toFixed(2),
          details: newObj
        })
      })
        .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
        .then((obj) => evaluateResponse(obj, true))
        .catch((e) => console.log(e))
    }
  }

  const onUpdateDispatch = (e, validWeight) => {
    e.preventDefault()
    const quantityZero = _.find(state.products, (o) => o.quantity <= 0)

    let weightZero = false
    if (validWeight) {
      weightZero = _.find(state.products, (o) => o.weight <= 0)
    }

    if (!formIsValid) {
      setFailedCreationInfo('Por favor introduzca los campos obligatorios')
    } else if (state.products.length === 0) {
      setFailedCreationInfo('Debe despachar al menos un producto')
    } else if (quantityZero || weightZero) {
      setFailedCreationInfo('La cantidad y/o el peso del producto no pueden estar en 0')
    } else {
      setFailedCreationInfo('')
      const newObj = state.products.map((obj) => getNewProduct(obj))
      let order_subtotal = 0.0
      state.products.map((obj) => (order_subtotal += obj.productSubTotal))
      return fetch(`${BACKEND_URL}/businesses/${cookie.load('business_id')}/deliveries/${deliveryDetails.uuid}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          business_id: 1,
          client_id: resultClient.id,
          driver_id: formControls.deliverer.postValue,
          comments: '',
          delivery_date: moment(formControls.date.value).format('YYYY-MM-DD 23:59:59'),
          total: order_subtotal.toFixed(2),
          details: newObj
        })
      })
        .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
        .then((obj) => evaluateResponse(obj, false))
        .catch((e) => console.log(e))
    }
  }

  const evaluateResponse = (obj, newDispatch) => {
    switch (obj.status) {
      case 422:
        enableButton()
        setFailedCreationInfo('Error en los tipos de datos enviados al sistema ' + obj.body.message)
        break
      case 409:
        enableButton()
        setFailedCreationInfo(obj.body.message)
        break
      case 404:
        enableButton()
        setFailedCreationInfo('Servidor fuera de servicio, no se encontro la ruta')
        break
      case 500:
        enableButton()
        setFailedCreationInfo('Error interno en el servicio')
        break
      default:
        if (newDispatch) {
          window.location = `/pedidos/${transactionUuid}/create`;
        } else {
          window.location = `/pedidos/${transactionUuid}/create`;
        }
        break
    }
  }
  return (
    <CreateDispatchContext.Provider
      value={{
        client: resultClient,
        priceList: priceList,
        failedCreationInfo: failedCreationInfo,
        formControls: formControls,
        state,
        changeFormHandler: changeFormHandler,
        dispatch,
        orderDetails: orderDetails,
        onCreateDispatch: onCreateDispatch,
        onUpdateDispatch: onUpdateDispatch
      }}
    >
      {children}
    </CreateDispatchContext.Provider>
  )
}

export default CreateDispatchProvider
