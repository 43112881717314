import React, { useContext, useEffect, useState } from 'react'
import cookie from 'react-cookies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faTrash } from '@fortawesome/free-solid-svg-icons'
import { PriceListCreateContext } from '../context/PriceListCreateProvider'
import { v4 as uuid } from 'uuid'
import { TYPES } from '../context/state/Types'
import AsyncSelect from 'react-select/async'
import IconOption from '../../order/component/IconOption'
import { SubmitCreateProductContext } from '../context/SubmitCreateProductProvider'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const CreateProductPriceListStep = () => {
  const { state, dispatch } = useContext(PriceListCreateContext)
  const { showWarningPage2 } = useContext(SubmitCreateProductContext)
  const [data, setData] = useState([])
  const [fetching, setFetching] = useState(true)

  useEffect(() => {
    fetchDataToCreate()
  }, [])

  const fetchDataToCreate = () => {
    fetch(`${BACKEND_URL}/businesses/${cookie.load('business_id')}/products/new`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data)
        setFetching(false)
      })
      .catch((e) => console.log(e))
  }

  const addNewPriceListBlock = () => {
    const newPriceList = { price_list_id: '', price_list_name: '', price: 0, id: uuid() }
    dispatch({
      type: TYPES.ADD_PRICE_LIST,
      newPriceList
    })
  }

  const removePriceListBlock = (id) => {
    dispatch({ type: TYPES.DELETE_PRICE_LIST, id })
  }

  const updatePriceListPrice = (event, pr) => {
    const quantityValue = event.target.value
    dispatch({ type: TYPES.CHANGE_PRICE, pr, quantityValue })
  }

  const updatePriceListName = (event, pr) => {
    const selectPriceList = event.priceListObject
    dispatch({ type: TYPES.CHANGE_PRICE_LIST_NAME, pr, selectPriceList })
  }

  const searchForProduct = (inputValue) => {
    const result = data.price_lists.filter(
      (t) =>
        t.description.toLowerCase().includes(inputValue.toLowerCase()) ||
        t.db_ref.toLowerCase().includes(inputValue.toLowerCase())
    )
    return result.map((i) => ({
      value: i.description,
      label: i.db_ref.concat(' ' + i.description),
      priceListObject: i
    }))
  }

  const promiseProductOptions = (inputValue) =>
    // eslint-disable-next-line no-undef
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(searchForProduct(inputValue))
      }, 1000)
    })

  const getPriceList = (object, index) => (
    <>
      <div className="row align-items-start mt-20">
        <div className="col-md-8">
          <div className="mb-2">
            <b>Lista de precio: </b>
          </div>
          <AsyncSelect
            placeholder={'Buscar listas de precios por nombre o código'}
            noOptionsMessage={() => 'Escriba para poder buscar y agregar listas de precios'}
            loadingMessage={() => 'Cargando listas de precios...'}
            cacheOptions
            defaultOptions={false}
            name="selectOption"
            onChange={(e) => updatePriceListName(e, object)}
            components={{ Option: IconOption }}
            loadOptions={promiseProductOptions}
            className="async-select-custom"
          />
        </div>
        <div className="col-md-2">
          <div className="mb-2">
            <b>Precio: </b>
          </div>
          <div className="d-flex align-items-center">
            <span className="icon-dollar mr-2"></span>
            <input
              onChange={(e) => updatePriceListPrice(e, object)}
              name="price"
              className="form-control price-input-custom"
              value={object.price}
              placeholder="0.00"
              type="number"
              step="0.1"
              autoComplete="off"
            />
          </div>
        </div>
        <div className="col-md-2 d-flex align-items-end">
          {state.priceList.length > 1 && (
            <button
              className="delete-ubq-product-input btn btn-danger mt-31"
              onClick={() => removePriceListBlock(object.id)}
              type="button"
            >
              <FontAwesomeIcon className="delete-price-list-button" icon={faTrash} />
              <h7 className="text-buttom">Borrar lista</h7>
            </button>
          )}
        </div>
      </div>
    </>
  )

  return (
    <>
      <div className="">
        {fetching === false && (
          <div>
            <hr className="mb-30" />
            <div className="container-fluid">
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>
                    <b style={{ paddingRight: '10px', marginTop: '-5px' }}>Paso 2 de 2:</b>
                  </span>
                  Seleccione la(s) lista(s) de precio que desea asignarle al producto.
                </div>
                {showWarningPage2 && (
                  <div className="red">
                    Por favor llene todos los campos precio y nombre de lista de precio para crear
                    producto.
                  </div>
                )}
              </div>

              {state.priceList.map((object, index) => (
                <div key={index} className="row mt-20">
                  {getPriceList(object, index)}
                </div>
              ))}
              <div className="row">
                <div className="col-md-6">
                  <button
                    onClick={addNewPriceListBlock}
                    className="mt-33 float-left add-price-list-button"
                    type="button"
                  >
                    <span className="fs-20">+</span> Agregar lista de precio
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {fetching && (
          <div className="container-fluid">
            <p>Obteniendo datos</p>
          </div>
        )}
      </div>
    </>
  )
}

export default CreateProductPriceListStep
