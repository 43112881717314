export const DENOMINATIONS_MAPPING = {
    "100": "count_100_bill",
    "50": "count_50_bill",
    "20": "count_20_bill",
    "10": "count_10_bill",
    "5": "count_5_bill",
    "1": "count_1_bill",
    "0.5": "count_50_cent",
    "0.25": "count_25_cent",
    "0.1": "count_10_cent",
    "0.05": "count_5_cent",
    "0.01": "count_1_cent",
  };