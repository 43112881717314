import React, { createContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getBusiness, getQueryState, handleErrors } from '../services/apiUserService'
import validate from '../../helpers/validation'
import * as apiStatusCodes from '../../constants/apiStatusCodes'
import cookie from 'react-cookies'

const initialState = {}

const UserFlagCreateContext = createContext(initialState)

const UserFlagCreateProvider = (props) => {
  const [failedCreationInfo, setFailedCreationInfo] = useState(null)
  const [formIsValid, setFormIsValid] = useState(false)

  const [isFetching, setFetching] = useState(true)
  const [businessData, setBusinessData] = useState({})
  const [formMessage, setFormMessage] = useState('')

  const [configFormControlsUbiquaApp, setConfigFormControlsUbiquaApp] = useState({
    // TODO: we should define a better way to handle this
    suggested_products: {
      title: 'Pedidos sugerdios',
      explanation: 'Habilita soporte para pedidos sugeridos',
      role: 'role.salesman',
      value: 'false',
      placeholder: '',
      valid: true,
      touched: false,
      options: [
        { value: true, displayValue: 'Si' },
        { value: false, displayValue: 'No' }
      ]
    },

    client_segment: {
      title: 'Segmentación de clientes',
      explanation: 'Habilita la segmentación por clientes',
      role: 'role.salesman',
      value: 'false',
      placeholder: '',
      valid: true,
      touched: false,
      options: [
        { value: true, displayValue: 'Si' },
        { value: false, displayValue: 'No' }
      ]
    },

    cobros_one: {
      title: 'Flag de cobros 1',
      explanation: 'Habilita soporte para Flag de cobro 1',
      role: 'role.charge',
      value: 'false',
      placeholder: '',
      valid: true,
      touched: false,
      options: [
        { value: true, displayValue: 'Si' },
        { value: false, displayValue: 'No' }
      ]
    },

    cobros_two: {
      title: 'Flag de cobros 2',
      explanation: 'Habilita soporte para Flag de cobro 2',
      role: 'role.charge',
      value: 'false',
      placeholder: '',
      valid: true,
      touched: false,
      options: [
        { value: true, displayValue: 'Si' },
        { value: false, displayValue: 'No' }
      ]
    },

    entregas_one: {
      title: 'Flag de cobros 1',
      explanation: 'Habilita soporte para Flag de entregas 1',
      role: 'role.delivery',
      value: 'false',
      placeholder: '',
      valid: true,
      touched: false,
      options: [
        { value: true, displayValue: 'Si' },
        { value: false, displayValue: 'No' }
      ]
    },

    entregas_two: {
      title: 'Flag de entregas 2',
      explanation: 'Habilita soporte para Flag de entregas 2',
      role: 'role.delivery',
      value: 'false',
      placeholder: '',
      valid: true,
      touched: false,
      options: [
        { value: true, displayValue: 'Si' },
        { value: false, displayValue: 'No' }
      ]
    }
  })

  const [configFormControls, setConfigFormControls] = useState({
    can_access_refunds_app: {
      value: 'false',
      placeholder: '',
      valid: true,
      touched: false,
      options: [
        { value: true, displayValue: 'Si' },
        { value: false, displayValue: 'No' }
      ]
    },
    can_make_quotes: {
      value: 'false',
      placeholder: '',
      valid: true,
      touched: false,
      options: [
        { value: true, displayValue: 'Si' },
        { value: false, displayValue: 'No' }
      ]
    },
    can_access_suggested_products: {
      value: 'false',
      placeholder: '',
      valid: true,
      touched: false,
      options: [
        { value: true, displayValue: 'Si' },
        { value: false, displayValue: 'No' }
      ]
    },
    can_report_no_sales: {
      value: 'false',
      placeholder: '',
      valid: true,
      touched: false,
      options: [
        { value: true, displayValue: 'Si' },
        { value: false, displayValue: 'No' }
      ]
    },
    can_see_client_location: {
      value: 'false',
      placeholder: '',
      valid: true,
      touched: false,
      options: [
        { value: true, displayValue: 'Si' },
        { value: false, displayValue: 'No' }
      ]
    },
    can_change_prices: {
      value: 'false',
      placeholder: '',
      valid: true,
      touched: false,
      options: [
        { value: true, displayValue: 'Si' },
        { value: false, displayValue: 'No' }
      ]
    },
    can_change_refund_prices: {
      value: 'false',
      placeholder: '',
      valid: true,
      touched: false,
      options: [
        { value: true, displayValue: 'Si' },
        { value: false, displayValue: 'No' }
      ]
    },
    can_set_prices_on_quote_items: {
      value: 'false',
      placeholder: '',
      valid: true,
      touched: false,
      options: [
        { value: true, displayValue: 'Si' },
        { value: false, displayValue: 'No' }
      ]
    },
    has_fixed_route: {
      value: 'false',
      placeholder: '',
      valid: true,
      touched: false,
      options: [
        { value: true, displayValue: 'Si' },
        { value: false, displayValue: 'No' }
      ]
    },
    can_consolidate_orders: {
      value: 'false',
      placeholder: '',
      valid: true,
      touched: false,
      options: [
        { value: true, displayValue: 'Si' },
        { value: false, displayValue: 'No' }
      ]
    },
    can_see_customer_segment: {
      value: 'false',
      placeholder: '',
      valid: true,
      touched: false,
      options: [
        { value: true, displayValue: 'Si' },
        { value: false, displayValue: 'No' }
      ]
    },
    enable_fiscal_printer: {
      value: 'false',
      placeholder: '',
      valid: true,
      touched: false,
      options: [
        { value: true, displayValue: 'Si' },
        { value: false, displayValue: 'No' }
      ]
    },
    can_select_discount: {
      value: 'false',
      placeholder: '',
      valid: true,
      touched: false,
      options: [
        { value: true, displayValue: 'Si' },
        { value: false, displayValue: 'No' }
      ]
    }
  })

  useEffect(() => {
    getBusinessConfig()
  }, [])

  const getBusinessConfig = () => {
    setFetching(true)
    const queryParams = getQueryState()
    getBusiness()
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((data) => {
        setFetching(false)
        setBusinessData(data)
      })
      .catch((error) => {
        if (error.status === apiStatusCodes.UNAUTHORIZED) {
          cookie.remove('token', { path: '/' })
          window.location = '/'
        }
      })
  }

  const changeConfigHandler = (event) => {
    const name = event.target.name
    const value = event.target.value

    const updatedControls = {
      ...configFormControls
    }
    const updatedFormElement = {
      ...updatedControls[name]
    }

    updatedFormElement.value = value
    updatedFormElement.touched = true
    updatedFormElement.valid = validate(value, updatedFormElement.validationRules)

    updatedControls[name] = updatedFormElement

    let formIsValid = true
    for (const inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid
    }

    setConfigFormControls(updatedControls)
    setFormIsValid(formIsValid)
  }

  const changeConfigHandlerUbiquaApp = (event) => {
    const name = event.target.name
    const value = event.target.value

    const updatedControls = {
      ...configFormControlsUbiquaApp
    }
    const updatedFormElement = {
      ...updatedControls[name]
    }

    updatedFormElement.value = value
    updatedFormElement.touched = true
    updatedFormElement.valid = validate(value, updatedFormElement.validationRules)

    updatedControls[name] = updatedFormElement

    let formIsValid = true
    for (const inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid
    }

    setConfigFormControlsUbiquaApp(updatedControls)
    setFormIsValid(formIsValid)
  }

  return (
    <UserFlagCreateContext.Provider
      value={{
        configFormControls,
        configFormControlsUbiquaApp,
        failedCreationInfo,
        formIsValid,
        businessData,
        formMessage,
        changeConfigHandler,
        changeConfigHandlerUbiquaApp
      }}
    >
      {props.children}
    </UserFlagCreateContext.Provider>
  )
}
export { UserFlagCreateContext, UserFlagCreateProvider }
