import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { sendEmail } from '../../services/apiUserService'
import cookie from 'react-cookies'

const SuccessCreateUserModal = (props) => {
  const [showText, setShowText] = useState(false)
  const [showModal, setShowModal] = useState(true)
  const handleClose = () => setShowModal(false)

  const sendUserEmail = (event) => {
    event.preventDefault()
    sendEmail(props.userInfo, 'new_user')
      .then((r) =>
        r.json().then((data) => {
          setShowModal(false)
        })
      )
      .then((obj) => console.log(obj))
      .catch((e) => {
        setShowModal(false)
        console.log(e)
      })
  }

  return (
    <div>
      <Modal onHide={handleClose} backdrop="static" keyboard={false} size="lg" show={showModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="title-green-modal-icon text-align-center">
              <FontAwesomeIcon icon={faCheckCircle} />
              <b className="ft-20">El nuevo usuario ha sido creado!</b>
            </p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: '30px' }}>
          <div>
            <div className="container-fluid modal-container">
              <div className="row">
                <p>
                  <b>Aviso:</b> Para notificar al usuario de la creación de la cuenta, le puede dar
                  clic al botón de enviar correo a usuario.
                </p>
                <p>
                  Para ingresar a la aplicación ingrese a esta dirección:
                  <a href={`${cookie.load('business_url')}`} target="_blank" rel="noreferrer">
                    {' '}
                    {`${cookie.load('business_url')}`}
                  </a>
                </p>
                <br />
              </div>

              <div className="row">
                <p>
                  <b>Usuario:</b>
                </p>
                <p>{props.userInfo.email}</p>
              </div>

              <div className="row">
                <b>Contraseña</b>
                <div>
                  {showText && <span className="bold pl-5">{props.userInfo.password}</span>}
                  {!showText && <span className="bold pl-5">**************</span>}

                  {!showText && (
                    <button
                      onClick={() => setShowText(!showText)}
                      name="remove"
                      value="1"
                      type="submit"
                      className="font-tiny- btn-link link-bold pl-10 ft-12"
                    >
                      VER CONTRASEÑA
                    </button>
                  )}

                  {showText && (
                    <button
                      onClick={() => setShowText(!showText)}
                      name="remove"
                      value="1"
                      type="submit"
                      className="font-tiny btn-link link-bold pl-10 ft-12"
                    >
                      OCULTAR CONTRASEÑA
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <input
                  onClick={sendUserEmail}
                  type="button"
                  className="btn float-right ubq-modal-button"
                  value="Enviar correo a usuario"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default SuccessCreateUserModal
