import React, { useState, useEffect } from 'react'
import Pagination from '../layout/Pagination'
import { validateAccessRole } from '../helpers/userRole'
import cookie from 'react-cookies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import ReactFilter from '../components/inputs/ReactFilterList'
import { Table } from 'react-bootstrap'

const ClientIndexView = ({
  collapsed,
  title,
  data,
  filters,
  fetchDataStatus,
  isFetching,
  searchInput,
  handleSearch,
  handleEnterSearch,
  resetReactFilters,
  isAnyFilterSelected,
  onSearch,
  onSearchInput,
  updateAllSelectedOptions,
  filterVersion,
  pagination,
  resetFilters,
  handlePaginationClick
}) => {
  const redirectToCreateClient = () => {
    window.location = '/crear_cliente'
  }

  const showModel = (event) => {
    event.preventDefault()
    const id = event.target.id
    window.open(`/clientes/${id}/detalles`, '_blank')
  }

  const handleFilterChange = (identifier, selectedOptions) => {
    const selectedLabels = Array.from(selectedOptions)
    updateAllSelectedOptions(identifier, selectedLabels)
  }

  const filtersArray = Array.isArray(filters) ? filters : Array.from(filters || [])
  const anyFilterEmpty = filtersArray.some((filter) => !filter?.value || filter.value.length === 0)

  if (isFetching) {
    return (
      <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
        <div className="index-header">
          <br />
          <p>Obteniendo datos ...</p>
        </div>
      </div>
    )
  } else {
    return (
      <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
        <div className="index-header detailed-container">
          <h2>{title}</h2>
          <div className="index-buttons">
            {validateAccessRole(cookie.load('user_role')?.clients_module)?.can_write && (
              <button onClick={redirectToCreateClient}>Crear cliente nuevo</button>
            )}
          </div>
        </div>
        <div className="index-table">
          <div className="show-area">
            <h4 className="text-sub-title">Filtrar clientes por:</h4>
            <div className="filters-search-container mb-10">
              <FontAwesomeIcon className="filter-icon mr-10" icon={faFilter} />
              <div className="filters-container">
                {filtersArray?.map((filter, indx) => (
                  <div key={`flr-${indx}`} className="mr-10">
                    <ReactFilter
                      className="mr-10"
                      title={filter.key}
                      identifier={filter.key}
                      options={filter.value}
                      resetWatcher={resetReactFilters}
                      onSelectionChange={updateAllSelectedOptions}
                      disabled={anyFilterEmpty}
                    />
                  </div>
                ))}
              </div>
              <div className="search-input-containter">
                <input
                  value={searchInput}
                  onChange={handleSearch}
                  onKeyDown={handleEnterSearch}
                  className="round-search-input"
                  style={{ fontSize: 12 }}
                  placeholder="Buscar por código o nombre de cliente"
                />
                {searchInput === '' && (
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    className="search-icon"
                    style={{ marginTop: -1 }}
                  />
                )}
                {searchInput && (
                  <div
                    title="Limpiar texto"
                    onClick={resetFilters}
                    className="round-search-input-x"
                  >
                    X
                  </div>
                )}
              </div>
            </div>
            <div className="mb-20">
              {isAnyFilterSelected && (
                <a onClick={resetFilters} className="reset-filter cursor-pointer mb-20">
                  Restablecer filtros aplicados
                </a>
              )}
            </div>
            <Table hover responsive>
              <thead className="table-header-gray">
                <tr>
                  <th style={{ verticalAlign: 'middle', padding: '10px 25px', width: '15%' }}>
                    <span className="th-header-text">CÓDIGO</span>
                  </th>
                  <th style={{ verticalAlign: 'middle', width: '38%' }}>
                    <span className="th-header-text">NOMBRE DEL CLIENTE</span>
                  </th>
                  <th className="th-end-clients" style={{ verticalAlign: 'middle', width: '20%' }}>
                    <span className="th-header-text">LISTA DE PRECIO ASIGNADA</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  data?.map((model, index) => (
                    <tr className="cursor-pointer" key={index}>
                      <td
                        className="td-text-limited"
                        style={{
                          verticalAlign: 'middle',
                          textAlign: 'left',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                        id={model.id}
                        onClick={(e) => showModel(e)}
                      >
                        {model.db_ref}
                      </td>
                      <td
                        className="td-text-limited"
                        style={{
                          verticalAlign: 'middle',
                          textAlign: 'left',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                        id={model.id}
                        onClick={(e) => showModel(e)}
                      >
                        {model.name ? model.name : 'SIN ASIGNAR'}
                      </td>
                      <td
                        className="td-text-limited"
                        style={{
                          verticalAlign: 'middle',
                          textAlign: 'left',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                        id={model.id}
                        onClick={(e) => showModel(e)}
                      >
                        {model.price_list ? model.price_list : 'SIN ASIGNAR'}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="1">No hay resultados</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <nav className="index-table">
          {pagination.total_pages > 1 && (
            <div onClick={handlePaginationClick}>
              <Pagination
                current_page={pagination.current_page}
                total_pages={pagination.total_pages}
              />
            </div>
          )}
        </nav>
      </div>
    )
  }
}

export default ClientIndexView
