import React from 'react'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import UsersIndexView from './UsersIndexView'
import * as apiUserService from './services/apiUserService'
import * as apiStatusCodes from '../constants/apiStatusCodes'
import * as keyboardCodes from '../constants/keyboardCodes'
import cookie from 'react-cookies'
import { PaginationData } from '../global_context/PaginationContext'
import RealPagination from '../layout/RealPagination'
import { withRouter } from 'react-router-dom'
import RoleFilters from './component/RoleFilters'
import { validateAccessRole } from '../helpers/userRole'
import Pagination from '../layout/Pagination'
import SearchInput from '../layout/SearchInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faDownload, faCalendar } from '@fortawesome/free-solid-svg-icons'
import ReactFilter from '../components/inputs/ReactFilter'

const UbiquaAppUserData = React.createContext()
export const UbiquaAppUserConsumer = UbiquaAppUserData.Consumer

class UsersIndexApp extends React.Component {
  _isMounted = false // Se define para controlar los llamados al api especifico para este componente
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.state = {
      per_page: 25,
      current_page: 1,
      total_pages: 1,
      userName: 'Soporte Ubiqua',
      searchPlaceholder: 'Buscar usuarios por código o nombre',
      title: 'Usuarios',
      isFetching: true,
      data: [],
      failedCreationState: false,
      failedCreationInfo: null,
      query: '',
      version: 0,
      isAnyFilterSelected: false,
      paginationParams: {
        initLoad: [],
        getCurrentPage: this.getCurrentPage,
        totalCount: 0
      },
      filters: [],
      filters: [
        { key: 'Roles', value: [] },
        { key: 'ACTIVO', value: ['Sí', 'No'] }
      ],
      active: [],
      collapsed: false,
      featureRoles: [],
      fetchDataStatus: {
        isFetching: true
      }
    }
    this.setAllSelectedOptions = this.setAllSelectedOptions.bind(this)
  }

  setAllSelectedOptions(identifier, selectedLabels) {
    this.setState(
      (prevState) => {
        const selectedIds = selectedLabels.map((label) => prevState.labelToIdMapping[label])
        const allSelectedOptions = new Map(prevState.allSelectedOptions).set(
          identifier,
          selectedIds
        )
        const isAnyFilterSelected = Array.from(allSelectedOptions.values()).some(
          (value) => value.length > 0
        )
        return {
          allSelectedOptions: allSelectedOptions,
          isAnyFilterSelected: isAnyFilterSelected,
          resetTriggeredByDeselection:
            !isAnyFilterSelected && !prevState.resetTriggeredByDeselection
        }
      },
      () => {
        this.buildFiltersQuery()
      }
    )
  }

  buildFiltersQuery = () => {
    let queryParam = ''
    let queryactive = ''

    if (this.state.allSelectedOptions) {
      for (const [key, value] of this.state.allSelectedOptions) {
        if (value.length !== 0) {
          if (key === 'Roles') {
            queryParam += value.join('-')
          } else if (key === 'ACTIVO') {
            queryactive += value.join(',')
          }
        }
      }

      this.setState({ filter: queryParam, active: queryactive }, () => {
        this.fetchDataFromServer()
      })
    }
  }
  componentDidMount() {
    this._isMounted = true
    this.setInitialState()
    this.fetchDataFromServer()
    const collapsed = localStorage.getItem('collapsedState') === 'true'
    this.setState({ collapsed })
    this.fetchFeatureRoles()
  }

  componentWillUnmount() {
    this._isMounted = false //Evita llamadas repetidas al API
  }

  handleToggleCollapse = (collapsed) => {
    this.setState({ collapsed }, () => {
      localStorage.setItem('collapsedState', collapsed)
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.current_page !== this.state.current_page) {
      this.fetchDataFromServer(this.state.current_page)
    }
  }

  getCurrentPage = (page) => {
    this.myRef.current.scrollTo(0, 0)
    this.fetchDataFromServer(page)
  }

  setInitialState = () => {
    if (window.location.href.indexOf('query') >= 0) {
      const query = decodeURI(
        window.location.href.split('query=')[window.location.href.split('query=').length - 1]
      )
      this.setState({ query: query })
    } else {
      this.setState({ query: '' })
    }

    if (window.location.href.indexOf('pagina') >= 0) {
      const page = window.location.href.split('pagina=')

      this.setState(
        {
          current_page: page[page.length - 1].split('&')[0]
        },
        this.fetchDataFromServer
      )
    } else {
      this.setState({ current_page: 1 }, this.fetchDataFromServer)
    }
  }

  resetReactFilters = () => {
    this.setState(
      (prevState) => ({
        allSelectedOptions: new Map(),
        isAnyFilterSelected: false,
        version: prevState.version + 1,
        resetTriggeredByDeselection: false
      }),
      () => {
        this.fetchDataFromServer()
      }
    )
  }

  handlePagination = (target) => {
    let targetId = target.id
    switch (targetId) {
      case 'first-page':
        if (this.state.current_page !== 1) {
          this.setState({ current_page: 1 })
        }
        break
      case 'last-page':
        if (this.state.current_page !== this.state.total_pages) {
          this.setState({ current_page: this.state.total_pages })
        }
        break
      case 'previous-page':
        if (this.state.current_page !== 1) {
          this.setState({ current_page: this.state.current_page - 1 })
        }
        break
      case 'next-page':
        if (this.state.current_page !== this.state.total_pages) {
          this.setState({ current_page: this.state.current_page + 1 })
        }
        break
    }
  }

  handlePaginationClick = (event) => {
    // <a> = when pagination elements were clicked
    if (event.target.tagName === 'A') {
      this.handlePagination(event.target)
    } else if (event.target.tagName === 'svg') {
      this.handlePagination(event.target.parentNode)
    } else if (event.target.tagName === 'path') {
      this.handlePagination(event.target.parentNode.parentNode)
    }
  }

  fetchDataFromServer = (page = 1) => {
    this.setState({ isFetching: true })
    const queryParams = this.state.query
    apiUserService
      .getAllUsers(queryParams, page, this.state.per_page, this.state.filter, this.state.active)
      .then((response) => apiUserService.handleErrors(response))
      .then((response) => response.json())
      .then((data) => {
        if (this._isMounted) {
          // actualiza para llamar solo 1 vez al componente apiUserService

          this.setState({
            data: data?.table,
            isFetching: false,
            totalCount: data['count'],
            total_pages: Math.ceil(data.count / this.state.per_page),
            title: queryParams ? `Resultados de la búsqueda: ${this.state.query}` : `Usuarios`
          })
        }
      })
      .catch((error) => {
        console.log(error)
        if (error.status === apiStatusCodes.UNAUTHORIZED) {
          cookie.remove('token', { path: '/' })
          window.location = '/'
        }
      })
  }

  fetchFeatureRoles = () => {
    const queryParams = this.state.query
    apiUserService
      .getFeatureRoles(queryParams, this.state.filters)
      .then((response) => apiUserService.handleErrors(response))
      .then((response) => response.json())
      .then((data) => {
        if (this._isMounted) {
          // actualiza para llamar solo 1 vez al componente apiUserService

          this.setState(
            {
              featureRoles: data?.table,
              fetchDataStatus: {
                isFetching: false
              }
            },
            () => {
              this.prepareFilterData()
            }
          )
        }
      })
      .catch((error) => {
        console.log(error)
        if (error.status === apiStatusCodes.UNAUTHORIZED) {
          cookie.remove('token', { path: '/' })
          window.location = '/'
        }
      })
  }

  handleSearch = (newQuery) => {
    // Actualiza el estado con la nueva consulta de búsqueda
    this.setState({ query: newQuery }, () => {
      // Llama a fetchDataFromServer para realizar la búsqueda con la nueva consulta
      this.fetchDataFromServer()
    })
  }

  getQueryState = () => {
    if (window.location.href.indexOf('query') >= 0) {
      return decodeURI(
        window.location.href.split('query=')[window.location.href.split('query=').length - 1]
      )
    } else {
      return ''
    }
  }

  handleCreateNewUser = () => {
    window.open('/crear_usuario_configuracion')
  }

  handleFilters = (filters) => {
    const selectedFilters =
      Object.entries(filters)
        .map(([k, v]) => (v ? k : null))
        .filter((e) => e != null) || [] // [ 'role.salesman' , 'role.charge' ]
    this.setState({ filters: selectedFilters, current_page: 1 }, this.fetchDataFromServer)
  }

  handleSearchUpdate = (newQuery) => {
    this.setState({ query: newQuery }, this.fetchDataFromServer)
  }

  prepareFilterData = () => {
    const labelToIdMapping = {}

    const roleOptions = this.state.featureRoles.map((role) => {
      labelToIdMapping[role.name] = role.key
      return role.name
    })

    const statusOptions = [
      { name: 'Sí', value: true },
      { name: 'No', value: false }
    ]
    statusOptions.forEach((option) => {
      labelToIdMapping[option.name] = option.value
    })

    const filters = [
      { key: 'Roles', value: roleOptions },
      { key: 'ACTIVO', value: statusOptions.map((option) => option.name) }
    ]

    this.setState({
      filters,
      labelToIdMapping,
      fetchDataStatus: {
        ...this.state.fetchDataStatus,
        isFetching: false
      }
    })
  }

  render() {
    const { filters, isFetching } = this.state
    return (
      <div>
        <div>
          <div onKeyDown={this.handleSearch}>
            <TopBar
              searchPlaceholder={this.state.searchPlaceholder}
              userName={this.state.userName}
              onToggleCollapse={this.handleToggleCollapse}
              collapsed={this.state.collapsed}
            />
          </div>
          <UbiquaAppUserData.Provider value={this.state}>
            <UbiquaAppUserConsumer>
              {({ failedCreationState, failedCreationInfo }) => (
                <>
                  {failedCreationState ? (
                    <div className={`main-view ${this.state.collapsed ? 'collapsed' : ''}`}>
                      <div className="alert alert-danger" role="alert">
                        <strong>{failedCreationInfo}</strong>
                      </div>
                    </div>
                  ) : (
                    <div
                      ref={this.myRef}
                      className={`main-view ${this.state.collapsed ? 'collapsed' : ''}`}
                    >
                      <div className="index-header detailed-container">
                        <h2>{this.state.title}</h2>
                        <div className="index-buttons">
                          {validateAccessRole(cookie.load('user_role')?.can_config_user)
                            ?.can_write && (
                            <button onClick={() => this.handleCreateNewUser()}>
                              Crear usuario nuevo
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="index-table">
                        <div className="show-area">
                          <h4 className="text-sub-title">Filtrar roles por:</h4>
                          <div className="filters-search-container mb-search-user">
                            <FontAwesomeIcon className="filter-icon mr-10" icon={faFilter} />
                            <div className="filters-container">
                              {filters &&
                                filters.map((filter, indx) => (
                                  <div key={`flr-${indx}`} className="mr-10">
                                    <ReactFilter
                                      title={filter.key}
                                      identifier={filter.key}
                                      options={filter.value}
                                      resetWatcher={this.state.version}
                                      disabled={isFetching || filter.value.length === 0}
                                      onChange={(e) => {
                                        this.setAllSelectedOptions(e.identifier, e.selectedOptions)
                                      }}
                                    />
                                  </div>
                                ))}
                            </div>

                            <div className="search-input-containter ">
                              <SearchInput
                                query={this.state.query}
                                onSearch={this.handleSearch}
                                placeholder="Buscar usuarios por nombre o código"
                              />
                            </div>
                          </div>
                          <div className="mt-reset-filter-user">
                            {this.state.isAnyFilterSelected && (
                              <a
                                onClick={this.resetReactFilters}
                                className="reset-filter cursor-pointer"
                              >
                                Restablecer filtros aplicados
                              </a>
                            )}
                          </div>
                          <div></div>
                          <br />

                          <UsersIndexView />
                          {this.state.total_pages > 1 && (
                            <div onClick={this.handlePaginationClick}>
                              <Pagination
                                current_page={this.state.current_page}
                                total_pages={this.state.total_pages}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </UbiquaAppUserConsumer>
          </UbiquaAppUserData.Provider>
        </div>
        <div>
          <NavBar activeItem="Usuarios" isEditing={false} collapsed={this.state.collapsed} />
        </div>
      </div>
    )
  }
}
export default withRouter(UsersIndexApp)
