import React, { useContext } from 'react'
import SelectInput from '../../components/inputs/SelectInput'
import { UserConfigContext } from '../context/UserConfigProvider'
import { UserInfoContext } from '../context/UserInfoProvider'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//Vista de tab "Configuraciones" de Detalles de Usuario en Usuario

const UserConfigInfoDetailTab = () => {
  const {
    businessData,
    isEditingUserConfigTab,
    isFetching,
    handleUpdateConfigFlags,
    formControl,
    changeHandler,
    formSubmitUpdate,
    handleCancelConfigFlags
  } = useContext(UserConfigContext)

  const newConfigRow = (
    title,
    salesman_flag_value,
    description,
    business_flag = true,
    isEditing = false
  ) => (
    <tr
      className={business_flag ? 'cursor-pointer-list' : 'font-color-disable cursor-pointer-list'}
    >
      <td className="col-md-2">{title}</td>
      <td className="col-md-1">
        {isEditing
          ? configUserConfigTemplate(business_flag, salesman_flag_value)
          : configUserInfoTemplate(business_flag, salesman_flag_value)}
      </td>
      <td className="col-md-4">{description}</td>
    </tr>
  )

  const configUserInfoTemplate = (business_flag, salesman_flag_value) =>
    business_flag ? <>{salesman_flag_value ? 'Si' : 'No'}</> : <>*No Disponible</>

  const configUserConfigTemplate = (business_flag = true, salesman_flag_value) =>
    business_flag ? <>{salesman_flag_value}</> : <>*No Disponible</>
  return (
    <>
      <UserInfoContext.Consumer>
        {({ userInfoData }) => (
          <>
            <div>
              {isEditingUserConfigTab === false && (
                <div className="show-area-tab">
                  <div className="show-header">
                    <div className="ml-12">
                      <h2>Rol de Usuario activo(s):</h2>
                      <div className="show-buttons">
                        <input
                          type="submit"
                          onClick={(e) => handleUpdateConfigFlags(e, userInfoData)}
                          value="Actualizar configuraciones"
                        />
                      </div>
                    </div>
                  </div>
                  <hr className="mt-70" />
                  <h5 style={{fontWeight: 'bold'}}>PEDIDO</h5>
                  <>
                    <div className="">
                      <table className="table">
                        <thead className="list-table">
                          <tr className="th-list-table">
                            <th>NOMBRE</th>
                            <th>ACTIVO</th>
                            <th>DESCRIPCIÓN</th>
                          </tr>
                        </thead>
                        <tbody>
                          {newConfigRow(
                            'Pedido Sugerido',
                            'Si',
                            'El usuario puede ver un pedido sugerido para cada cliente.'
                          )}
                          {newConfigRow(
                            'Cotización',
                            businessData.can_make_quotes,
                            'El usuario puede crear cotizaciones a los clientes.',
                            businessData.can_make_quotes
                          )}
                          {/* {newConfigRow(
                            'Crear devoluciones',
                            userInfoData.can_access_refunds_app,
                            'El usuario puede crear devoluciones a los clientes.',
                            businessData.can_access_refunds_app
                          )}
                          {newConfigRow(
                            'Crear reportes de visita',
                            businessData.can_report_no_sales,
                            'El usuario puede escoger una incidencia y enviar un reporte de visita.',
                            businessData.can_report_no_sales
                          )}
                          {newConfigRow(
                            'Ubicación del cliente',
                            businessData.can_see_client_location,
                            'El usuario puede dar clic en ubicacion del cliente y utilizar una aplicacion como Google Maps para llegar a la ubicación del cliente.',
                            businessData.can_see_client_location
                          )} */}
                        </tbody>
                      </table>
                    </div>
                  </>
                  <hr className="mt-25" />
                  <h5 style={{fontWeight: 'bold'}}>DEVOLUCIONES</h5>
                  <>
                    <div className="">
                      <table className="table">
                        <thead className="list-table">
                          <tr className="th-list-table">
                            <th>NOMBRE</th>
                            <th>ACTIVO</th>
                            <th>DESCRIPCIÓN</th>
                          </tr>
                        </thead>
                        <tbody>
                          {newConfigRow(
                            'No existen configuraciones disponibles para este usuario.'
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>

                  <hr className="mt-25" />
                  <h5 style={{fontWeight: 'bold'}}>COBROS</h5>
                  <>
                    <div className="">
                      <table className="table">
                        <thead className="list-table">
                          <tr className="th-list-table">
                            <th>NOMBRE</th>
                            <th>ACTIVO</th>
                            <th>DESCRIPCIÓN</th>
                          </tr>
                        </thead>
                        <tbody>
                          {newConfigRow(
                            'No existen configuraciones disponibles para este usuario.'
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>

                  <hr className="mt-25" />
                  <h5 style={{fontWeight: 'bold'}}>ENTREGAS</h5>
                  <>
                    <div className="">
                      <table className="table">
                        <thead className="list-table">
                          <tr className="th-list-table">
                            <th>NOMBRE</th>
                            <th>ACTIVO</th>
                            <th>DESCRIPCIÓN</th>
                          </tr>
                        </thead>
                        <tbody>
                          {newConfigRow(
                            'No existen configuraciones disponibles para este usuario.'
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>

                  {/* <h5 className="mt-33">
                    Las configuraciones que están en estado de activo “Si” pero no se pueden editar,
                    están activadas para todos los usuarios por política de empresa.
                  </h5>
                  <h5 className="font-color-disable">
                    *Aviso: Si desea activar alguna de las funcionalidades que dice “No Disponible”
                    por favor comunicarse con el equipo de Soporte Ubiqua a soporte@ubiqua.me
                  </h5> */}
                </div>
              )}
              {isEditingUserConfigTab === true && (
                <div className="show-area-tab">
                  <form onSubmit={(e) => formSubmitUpdate(e, userInfoData)}>
                    <div className="inline-block full-width">
                      <div className="float-left">
                        <h4>Rol de Usuario activo(s):</h4>
                      </div>
                      <div className="float-right">
                        <button
                          className="shadow-cancel-button"
                          value="Cancelar"
                          onClick={handleCancelConfigFlags}
                        >
                          {' '}
                          Cancelar
                        </button>
                        <input className="save-button" value="Guardar" type="submit" />
                      </div>
                    </div>
                    <hr />
                    <span>
                      <FontAwesomeIcon className="information-icon" icon={faInfoCircle} size="lg" />
                      <h5>
                        Importante: Al dar clic al boton de Guardar, cualquier cambio que hizo va a
                        sacar al usuario de la aplicacion inmediatamente.
                      </h5>
                    </span>
                    <hr className="mt-25" />
                    <h5 style={{fontWeight: 'bold'}}>PEDIDO</h5>
                    <>
                      <div className="">
                        <table className="table">
                          <thead className="list-table">
                            <tr className="th-list-table">
                              <th>NOMBRE</th>
                              <th>ACTIVO</th>
                              <th>DESCRIPCIÓN</th>
                            </tr>
                          </thead>
                          <tbody>

                            {newConfigRow(
                              'Pedido Sugerido',
                              <SelectInput
                                name="can_access_refunds_app"
                                value={formControl.can_access_refunds_app.value}
                                onChange={changeHandler}
                                options={formControl.can_access_refunds_app.options}
                                touched={formControl.can_access_refunds_app.touched}
                                formClass="-"
                                valid={formControl.can_access_refunds_app.valid}
                              />,
                              'El usuario puede ver un pedido sugerido para cada cliente.',
                              businessData.can_access_refunds_app,
                              isEditingUserConfigTab
                            )}

                            {newConfigRow(
                              'Cotización',
                              <SelectInput
                                name="can_access_refunds_app"
                                value={formControl.can_access_refunds_app.value}
                                onChange={changeHandler}
                                options={formControl.can_access_refunds_app.options}
                                touched={formControl.can_access_refunds_app.touched}
                                formClass="-"
                                valid={formControl.can_access_refunds_app.valid}
                              />,
                              'El usuario puede crear cotizaciones a los clientes.',
                              businessData.can_access_refunds_app,
                              isEditingUserConfigTab
                            )}

                            {/* {newConfigRow(
                              'Crear devoluciones',
                              <SelectInput
                                name="can_access_refunds_app"
                                value={formControl.can_access_refunds_app.value}
                                onChange={changeHandler}
                                options={formControl.can_access_refunds_app.options}
                                touched={formControl.can_access_refunds_app.touched}
                                formClass="-"
                                valid={formControl.can_access_refunds_app.valid}
                              />,
                              'El usuario puede crear devoluciones a los clientes.',
                              businessData.can_access_refunds_app,
                              isEditingUserConfigTab
                            )}

                            {newConfigRow(
                              'Crear reportes de visita',
                              businessData.can_report_no_sales,
                              'El usuario puede escoger una incidencia y enviar un reporte de visita.',
                              businessData.can_report_no_sales
                            )}

                            {newConfigRow(
                              'Ubicación del cliente',
                              businessData.can_see_client_location,
                              'El usuario puede dar clic en ubicacion del cliente y utilizar una aplicacion como Google Maps para llegar a la ubicación del cliente.',
                              businessData.can_see_client_location
                            )} */}
                          </tbody>
                        </table>
                      </div>
                    </>
                    <hr className="mt-25" />
                  <h5 style={{fontWeight: 'bold'}}>DEVOLUCIONES</h5>
                  <>
                    <div className="">
                      <table className="table">
                        <thead className="list-table">
                          <tr className="th-list-table">
                            <th>NOMBRE</th>
                            <th>ACTIVO</th>
                            <th>DESCRIPCIÓN</th>
                          </tr>
                        </thead>
                        <tbody>
                          {newConfigRow(
                            'No existen configuraciones disponibles para este usuario.'
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>

                  <hr className="mt-25" />
                  <h5 style={{fontWeight: 'bold'}}>COBROS</h5>
                  <>
                    <div className="">
                      <table className="table">
                        <thead className="list-table">
                          <tr className="th-list-table">
                            <th>NOMBRE</th>
                            <th>ACTIVO</th>
                            <th>DESCRIPCIÓN</th>
                          </tr>
                        </thead>
                        <tbody>
                          {newConfigRow(
                            'No existen configuraciones disponibles para este usuario.'
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>

                  <hr className="mt-25" />
                  <h5 style={{fontWeight: 'bold'}}>ENTREGAS</h5>
                  <>
                    <div className="">
                      <table className="table">
                        <thead className="list-table">
                          <tr className="th-list-table">
                            <th>NOMBRE</th>
                            <th>ACTIVO</th>
                            <th>DESCRIPCIÓN</th>
                          </tr>
                        </thead>
                        <tbody>
                          {newConfigRow(
                            'No existen configuraciones disponibles para este usuario.'
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                  </form>
                </div>
              )}{' '}
              {false && (
                <div className="index-table">
                  <div className="show-area-tab">
                    <p style={{ textAlign: 'center' }}>Obteniendo datos ...</p>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </UserInfoContext.Consumer>
    </>
  )
}

export default UserConfigInfoDetailTab
