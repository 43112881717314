import React, { useContext } from 'react'
import SelectInput from '../../components/inputs/SelectInput'
import { UserFlagCreateContext } from '../context/UserFlagCreateProvider'
import { UserConfigCreateContext } from '../context/UserConfigCreateProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

//Formulacion de creacion de usuario, parte 2 configuraciones.

const CreateUserConfigDetailStep = () => {
  const {
    configFormControls,
    configFormControlsUbiquaApp,
    businessData,
    changeConfigHandler,
    changeConfigHandlerUbiquaApp
  } = useContext(UserFlagCreateContext)
  const { getValues, featureRoles } = useContext(UserConfigCreateContext)
  const step1FormValues = getValues()
  const configKeys = Object.keys(configFormControlsUbiquaApp)
  const configValues = Object.values(configFormControlsUbiquaApp)

  const isEditingUserConfigTab = true

  const getRoleName = (roleValue) => {
    const role = featureRoles.find((e) => e.value == roleValue)
    return role?.role
  }

  const newConfigRow = (
    title,
    salesman_flag_value,
    description,
    business_flag = true,
    isEditing = false
  ) => (
    <tr
      key={title}
      className={business_flag ? 'cursor-pointer-list' : 'font-color-disable cursor-pointer-list'}
    >
      <td className="col-md-2">{title}</td>
      <td className="col-md-1">
        {isEditing
          ? configUserConfigTemplate(business_flag, salesman_flag_value)
          : configUserInfoTemplate(business_flag, salesman_flag_value)}
      </td>
      <td className="col-md-4">{description}</td>
    </tr>
  )

  const configUserInfoTemplate = (business_flag, salesman_flag_value) =>
    business_flag ? <>{salesman_flag_value ? 'Si' : 'No'}</> : <>*No Disponible</>

  const configUserConfigTemplate = (business_flag = true, salesman_flag_value) =>
    business_flag ? <>{salesman_flag_value}</> : <>*No Disponible</>
  return (
    <>
      <div className="">
        <hr />
        <div className="flex-inline-message full-width">
          <FontAwesomeIcon icon={faInfoCircle} className="icon" />
          <span>
            <b>Importante:</b>
          </span>
          Al dar clic al botón de Guardar, cualquier cambio que hizo, va a sacar al usuario de la
          aplicación inmediatamente.
        </div>
        {step1FormValues?.role.map((role, index) => (
          <div key={`role-${index}`}>
            <h5 style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{getRoleName(role)}</h5>
            <div className="">
              <table className="table">
                <thead className="list-table">
                  <tr className="th-list-table">
                    <th>NOMBRE</th>
                    <th>ACTIVO</th>
                    <th>DESCRIPCIÓN</th>
                  </tr>
                </thead>
                <tbody>
                  {configKeys?.length >= 1 &&
                    configKeys.map(
                      (key) =>
                        role == configFormControlsUbiquaApp[key].role &&
                        newConfigRow(
                          configFormControlsUbiquaApp[key].title,
                          <SelectInput
                            name={key}
                            value={configFormControlsUbiquaApp[key].value}
                            onChange={changeConfigHandlerUbiquaApp}
                            options={configFormControlsUbiquaApp[key].options}
                            touched={configFormControlsUbiquaApp[key].touched}
                            formClass="-"
                            valid={configFormControlsUbiquaApp[key].valid}
                          />,
                          configFormControlsUbiquaApp[key].explanation,
                          true,
                          isEditingUserConfigTab
                        )
                    )}
                  {configValues.some((e) => e.role === role) ? null : (
                    <tr>
                      <td colSpan="3">No hay configuraciones disponibles para este usuario</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <hr className="mt-25" />
          </div>
        ))}
      </div>
    </>
  )
}

export default CreateUserConfigDetailStep
