import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBars } from '@fortawesome/free-solid-svg-icons';

export default class TopBar extends React.Component {
  render() {
    const collapsedClass = this.props.collapsed ? 'collapsed' : '';
    const searchClass = this.props.searchPlaceholder === '' ? 'search-deactivate' : '';
    const tooltipText = this.props.collapsed ? 'Mostrar panel de navegación' : 'Ocultar panel de navegación'; 
    return (
      <div className={`top-bar ${collapsedClass}`}>
        <FontAwesomeIcon
          className={`icon-bars ${searchClass}`}
          icon={faBars}
          onClick={() => this.props.onToggleCollapse(!this.props.collapsed)}
          title={tooltipText} 
        />
      </div>
    );
  }
}