import React, { useEffect, useState } from 'react'
import cookie from 'react-cookies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowUp, faCircleXmark, faImage, faTrash } from '@fortawesome/free-solid-svg-icons'
import ImageModal from '../components/image_modal/ImageModalProducts'

import ImageUploading from 'react-images-uploading'
import { validateAccessRole } from '../helpers/userRole'
import { Table } from 'react-bootstrap'
import { convertByteToMb } from '../helpers/utils'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'

const ProductPhotoTab = (props) => {
  const [isFetching, setFetching] = useState(true)
  const [data, setData] = useState([])
  const [images, setImages] = useState([])
  const [failedCreation, setFailedCreationState] = useState(false)
  const [isAddingImage, setAddingImageState] = useState(false)
  const [showImage, setShowImage] = useState(false)
  const maxNumber = 1
  const maxMbFileSize = 5242880

  useEffect(() => {
    setImages([])
    fetchDataFromServer()
  }, [])

  const fetchDataFromServer = () => {
    setFetching(true)
    fetch(
      `${BACKEND_URL}/businesses/${cookie.load('business_id')}/products/${props.id}/product_photos`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData(data)
        setFetching(false)
      })
      .catch((e) => console.log(e))
  }

  const setAddingImage = () => {
    setAddingImageState(true)
  }

  const unsetAddingImage = () => {
    setAddingImageState(false)
    setImages([])
  }

  const deleteImage = (event) => {
    if (window.confirm('Estas removiendo la imagen para este producto. Deseas continuar?')) {
      event.preventDefault()
      const photo_id = event.currentTarget.id
      fetch(
        `${BACKEND_URL}/businesses/${cookie.load('business_id')}/products/${props.id}/product_photos/${photo_id}`,
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          },
          body: JSON.stringify({
            business_id: cookie.load('business_id'),
            product_id: props.id,
            photo_name: data.table[0].photo_name
          })
        }
      )
        .then(() => (window.location = `/productos/${props.id}/imagenes`))
        .catch((e) => console.log(e))
    }
  }

  const setFailedCreation = () => {
    setFailedCreationState(failedCreation)
    setTimeout(hideFailedCreationMessage, 5000)
  }

  const hideFailedCreationMessage = () => {
    setFailedCreationState(false)
  }

  const handleSubmitImage = (e) => {
    e.preventDefault()
    if (images.length > 0) {
      const url = USE_BUSINESS_URL
        ? `${BACKEND_URL}/businesses/${cookie.load('business_id')}/products/${props.id}/product_photos`
        : `${BACKEND_URL}/product_photos`

      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          business_id: cookie.load('business_id'),
          product_id: props.id,
          photo_name: images[0].file.name,
          url: images[0].data_url
        })
      })
        .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
        .then((obj) => evaluateResponse(obj))
        .catch((e) => console.log(e))
    }
  }

  const evaluateResponse = (obj) => {
    if (obj.status === 201) {
      window.location = `/productos/${props.id}/imagenes`
    } else {
      setFailedCreation()
    }
  }

  const onChangeImage = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList)
  }

  return (
    <div>
      {failedCreation === true && (
        <div className="alert alert-danger" role="alert">
          <strong>No se pudo agregar imagen al producto. Error en el sistema.</strong>
        </div>
      )}
      {isAddingImage === true && (
        <div>
          <form onSubmit={handleSubmitImage}>
            <div className="show-header show-product-header">
              <p className="left-header-form-title">Imagen del producto</p>
              <div className="cancel-save-block">
                <input
                  id="cancel-add"
                  onClick={() => unsetAddingImage()}
                  className="cancel-edit-button"
                  value="Cancelar"
                  type="submit"
                />
                <input
                  value="Guardar"
                  className="save-button"
                  type="submit"
                  disabled={images.length === 0}
                />
              </div>
            </div>
            <hr />
            <div className="form-group row show-data-form">
              <div className="col-md-3">
                <p style={{ fontSize: 13, color: '#AAAAAA' }}></p>
              </div>
              <div
                className="col-sm-8 ml-15"
                style={{ maxWidth: 'calc(67% - 30px)', margin: '0 auto' }}
              >
                <ImageUploading
                  multiple
                  value={images}
                  onChange={onChangeImage}
                  maxNumber={maxNumber}
                  maxFileSize={maxMbFileSize}
                  resolutionWidth={800}
                  resolutionHeight={800}
                  resolutionType={'less'}
                  acceptType={['jpg', 'png']}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                    errors
                  }) => (
                    <div className="upload__image-wrapper">
                      {imageList.length < 1 && (
                        <div className="form-group">
                          <div className="">
                            <div
                              className="product-upload-drop-zone-tab"
                              id="drop-zone"
                              style={isDragging ? { background: '#F0F4F7' } : undefined}
                              {...dragProps}
                            >
                              <div className="drag-drop-product-icon-tab">
                                <FontAwesomeIcon icon={faCloudArrowUp} />
                              </div>
                              <div className="planimetry-drop-text">
                                <b>Seleccione una sola imagen o arrástrela y suéltela aquí</b>
                              </div>
                              <div className="planimetry-drop-subtext">
                                JPG o PNG, tamaño del archivo no mayor a 5MB
                              </div>
                              <div className="planimetry-drop-subtext">
                                Tamaño máximo de 800px x 800 px
                              </div>
                              <div className="planimetry-drop-subtext">ó</div>
                              <button
                                onClick={onImageUpload}
                                className="select-photo-button"
                                type="button"
                              >
                                Seleccionar imagen
                              </button>
                            </div>
                          </div>
                          {errors && (
                            <div className="img-error-message">
                              {errors.maxNumber && <span>Máximo de 1 imagen por tarea</span>}
                              {errors.maxFileSize && (
                                <span>
                                  Asegúrate de que la imagen sea en formato .png o .jpg y tenga un
                                  tamaño no mayor a 5MB.
                                </span>
                              )}
                              {errors.acceptType && (
                                <span>
                                  Asegúrate de que la imagen sea en formato .png o .jpg y tenga un
                                  tamaño no mayor a 5MB.
                                </span>
                              )}
                              {errors.resolution && (
                                <span>
                                  Verifique que la imagen sea formato .png .jpg y que las
                                  dimensiones sean por debajo de 800px x 800px.
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                      {imageList.map((image, index) => (
                        <div className="promo-form-section" key={index}>
                          <div
                            className="title-promos-form"
                            style={{ marginTop: -30, fontSize: 13, marginBottom: 10 }}
                          >
                            <b>Imagen agregada</b>
                          </div>
                          {image.data_url !== undefined && (
                            <div className="img-load-wrapper">
                              <div className="img-load-div">
                                <div className="img-load-icon">
                                  <FontAwesomeIcon icon={faImage} />
                                </div>
                                <span className="img-load-text">{image.file.name}</span>
                                <button
                                  type="button"
                                  className="img-view-buttom"
                                  onClick={() => {
                                    setShowImage(true)
                                  }}
                                >
                                  Ver imagen
                                </button>
                              </div>
                              <span className="img-load-text" style={{ marginRight: 40 }}>
                                {convertByteToMb(image.file.size)}
                              </span>
                              <button
                                type="button"
                                className="delete-image-button"
                                onClick={() => onImageRemove(index)}
                              >
                                <FontAwesomeIcon icon={faCircleXmark} />
                              </button>
                            </div>
                          )}
                          {image.image_url !== undefined && (
                            <div className="img-load-wrapper">
                              <div className="img-load-div">
                                <div className="img-load-icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="21"
                                    height="22"
                                    viewBox="0 0 21 22"
                                    fill="none"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M14.3451 7.63889C15.5702 7.63889 16.5634 6.61287 16.5634 5.34722C16.5634 4.08157 15.5702 3.05556 14.3451 3.05556C13.1199 3.05556 12.1268 4.08157 12.1268 5.34722C12.1268 6.61287 13.1199 7.63889 14.3451 7.63889ZM14.3451 6.11111C14.7534 6.11111 15.0845 5.76911 15.0845 5.34722C15.0845 4.92534 14.7534 4.58333 14.3451 4.58333C13.9367 4.58333 13.6056 4.92534 13.6056 5.34722C13.6056 5.76911 13.9367 6.11111 14.3451 6.11111Z"
                                      fill="#597FA9"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M3.5493 0C1.58907 0 0 1.64162 0 3.66667V18.3333C0 20.3584 1.58907 22 3.5493 22H17.4507C19.4109 22 21 20.3584 21 18.3333V3.66667C21 1.64162 19.4109 0 17.4507 0H3.5493ZM17.4507 1.83333H3.5493C2.56919 1.83333 1.77465 2.65414 1.77465 3.66667V13.1023L6.17425 8.0316L11.3607 13.8334L14.6209 10.5455L19.2254 14.4085V3.66667C19.2254 2.65415 18.4308 1.83333 17.4507 1.83333ZM19.2254 16.3767L14.7113 12.5894L11.3058 16.0238L6.19058 10.3017L1.77465 15.3913V18.3333C1.77465 19.3459 2.56919 20.1667 3.5493 20.1667H17.4507C18.4308 20.1667 19.2254 19.3459 19.2254 18.3333V16.3767Z"
                                      fill="#597FA9"
                                    />
                                  </svg>
                                </div>
                                <span className="img-load-text">{image.image_reference}</span>
                                <button
                                  type="button"
                                  className="img-view-buttom"
                                  onClick={() => {
                                    setShowImage(true)
                                  }}
                                >
                                  Ver imagen
                                </button>
                              </div>
                              <span className="img-load-text" style={{ marginRight: 40 }}></span>
                              <button
                                type="button"
                                className="delete-image-button"
                                onClick={() => onImageRemove(index)}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ margin: '0 !important' }}
                                >
                                  <circle cx="12" cy="12" r="12" fill="#202020" />
                                  <path
                                    d="M12.6266 12L15.8666 8.75995C15.9395 8.67493 15.9775 8.56556 15.9732 8.45371C15.9689 8.34185 15.9225 8.23575 15.8433 8.15659C15.7642 8.07744 15.6581 8.03107 15.5462 8.02675C15.4344 8.02243 15.325 8.06048 15.24 8.13329L12 11.3733L8.75997 8.12884C8.67495 8.05603 8.56558 8.01798 8.45373 8.0223C8.34187 8.02662 8.23577 8.07299 8.15661 8.15215C8.07746 8.2313 8.03109 8.33741 8.02677 8.44926C8.02245 8.56112 8.0605 8.67049 8.13331 8.75551L11.3733 12L8.12886 15.24C8.08234 15.2798 8.04455 15.3288 8.01787 15.384C7.9912 15.4391 7.97621 15.4992 7.97384 15.5604C7.97148 15.6216 7.98179 15.6826 8.00414 15.7397C8.02648 15.7967 8.06037 15.8485 8.10369 15.8918C8.147 15.9351 8.1988 15.969 8.25583 15.9913C8.31286 16.0137 8.3739 16.024 8.43511 16.0216C8.49632 16.0193 8.55638 16.0043 8.61152 15.9776C8.66666 15.9509 8.71569 15.9131 8.75553 15.8666L12 12.6266L15.24 15.8666C15.325 15.9394 15.4344 15.9775 15.5462 15.9732C15.6581 15.9688 15.7642 15.9225 15.8433 15.8433C15.9225 15.7642 15.9689 15.6581 15.9732 15.5462C15.9775 15.4343 15.9395 15.325 15.8666 15.24L12.6266 12Z"
                                    fill="white"
                                  />
                                </svg>
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
          </form>
        </div>
      )}
      {isAddingImage === false && (
        <>
          <div className="show-header show-product-header">
            <h2>Imágenes del producto:</h2>
            {validateAccessRole(cookie.load('user_role')?.product_module)?.can_write && (
              <>
                {isFetching === false && data.table.length === 0 && (
                  <input
                    onClick={setAddingImage}
                    className="float-right"
                    value="+     Agregar imagen"
                    type="submit"
                  />
                )}
                {isFetching === false && data.table.length > 0 && (
                  <input
                    disabled
                    onClick={setAddingImage}
                    className="inactive float-right"
                    value="Agregar imagen"
                    type="submit"
                  />
                )}
              </>
            )}
          </div>
          <Table hover responsive>
            <thead className="table-header-gray">
              <tr>
                <th style={{ verticalAlign: 'middle', padding: '10px 25px' }}>
                  <span className="th-header-text  ">IMAGEN</span>
                </th>
                <th style={{ verticalAlign: 'middle' }}>
                  <span className="th-header-text">NOMBRE</span>
                </th>
                <th className="th-end-center" style={{ verticalAlign: 'middle' }}>
                  <span className="th-header-text-end-images-products">ACCIÓN</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {isFetching === false &&
                data.table.length > 0 &&
                data.table.map((obj) => (
                  <tr key={obj.id}>
                    <td
                      className="td-text-limited"
                      style={{ verticalAlign: 'middle', padding: '10px 25px' }}
                    >
                      <img className="" alt="foto" src={obj.url} />
                    </td>
                    <td className="td-text-limited">{obj.photo_name}</td>
                    <td className="td-text-limited td-text-limited-center-images">
                      <button
                        className="unassign-price-list-button"
                        id={obj.id}
                        onClick={deleteImage}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
              {isFetching === false && data.table.length === 0 && (
                <tr>
                  <td style={{ verticalAlign: 'middle', padding: '10px 25px' }} colSpan="3">
                    No existen imágenes para este producto.
                  </td>
                </tr>
              )}
              {isFetching === true && (
                <tr>
                  <td colSpan="3">Obteniendo datos...</td>
                </tr>
              )}
            </tbody>
          </Table>
        </>
      )}
      {images.map(
        (image, index) =>
          (image.data_url || image.image_url) && (
            <div key={index}>
              <ImageModal
                imgSrc={image.data_url || image.image_url}
                showImage={showImage}
                setShowImage={setShowImage}
              />
            </div>
          )
      )}
    </div>
  )
}

export default ProductPhotoTab
