import React, { useState, useEffect } from 'react'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import ShowProductView from './ShowProductView'

const ShowProductApp = (props) => {
  const [userName] = useState('Ana Sofia Lau Hou')
  const [searchPlaceholder] = useState('Buscar productos por código o nombre')
  const [isEditing, setIsEditing] = useState(false)
  const [collapsed, setCollapsed] = useState(false)

  const handleSearch = (event) => {
    // search action occurs after ENTER is pressed
    if (event.keyCode === 13) {
      // to disable default submit form
      event.preventDefault()
      const query = event.target.value
      if (query !== '') {
        if (isEditing) {
          const isConfirmation = window.confirm(
            'Esta seguro que quiere continuar? Los cambios editados no seran guardados'
          )
          if (isConfirmation === true) {
            window.location = `/productos?query=${query}`;
          }
        } else {
          window.location = `/productos?query=${query}`;
        }
        event.target.value = ''
      }
    }
  }

  const passEditState = (val) => {
    setIsEditing(val)
  }

  useEffect(() => {
    const collapsedState = localStorage.getItem('collapsedState') === 'true'
    setCollapsed(collapsedState)
  }, [])

  const handleToggleCollapse = (collapsedState) => {
    setCollapsed(collapsedState)
    localStorage.setItem('collapsedState', collapsedState)
  }

  return (
    <div>
      <div>
        <div onKeyDown={handleSearch}>
          <TopBar
            searchPlaceholder={searchPlaceholder}
            userName={userName}
            onToggleCollapse={handleToggleCollapse}
            collapsed={collapsed}
          />
        </div>
        <div>
          <ShowProductView
            editStateChanged={passEditState}
            isEditing={isEditing}
            successCreateForNewProduct={props.successCreateForNewProduct}
            collapsed={collapsed}
          />
        </div>
      </div>
      <div>
        <NavBar activeItem="Productos" isEditing={isEditing} collapsed={collapsed} />
      </div>
    </div>
  )
}

export default ShowProductApp
