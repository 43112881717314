import React, { useState, useMemo } from 'react'
import BaseCard from './BaseCard'
import InvalidDateRangeModal from './modaldetailsmap/InvalidDateRangeModal'
import NoActivityRecordsModal from './modaldetailsmap/NoActivityRecordsModal'
import InvalidCoordinatesModal from './modaldetailsmap/InvalidCoordinatesModal'
import GeoCardMapModal from './modaldetailsmap/GeoCardMapModal'
import {
  getLastActivityTime,
  assignClientIndicesAndFilter,
  allCoordinatesValid
} from '../../helpers/utils'

const GeoCardShowMap = ({ geoData, data, user_name, startDate, endDate }) => {
  const [show, setShow] = useState(false)

  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  const lastActivityTime = useMemo(() => getLastActivityTime(data), [data])
  const processedData = useMemo(() => assignClientIndicesAndFilter(data), [data])
  const isDateRangeInvalid = startDate !== endDate
  const hasAllInvalidCoordinates = allCoordinatesValid(data)

  let ModalComponent = GeoCardMapModal
  if (isDateRangeInvalid) {
    ModalComponent = InvalidDateRangeModal
  } else if (data.length === 0) {
    ModalComponent = NoActivityRecordsModal
  } else if (hasAllInvalidCoordinates) {
    ModalComponent = InvalidCoordinatesModal
  }

  return (
    <BaseCard background={'/assets/blur-map.svg'}>
      <div className="geo-card-container-map">
        <p className="p-card-title-map">Dentro de Geocerca</p>
        <div className="geomap">
          <div className="geo-numbers-container">
            <div>
              <img draggable={false} className="geo-icon" src="assets/location.svg" />
            </div>
            <div>
              <p className="p-card-number-map">{geoData}</p>
            </div>
          </div>
        </div>
        <button onClick={handleShow} className="expand-button-map">
          <span className="expand-icon-text">Haz clic para expandir</span>{' '}
          <span class="material-symbols-outlined expand-icon">open_in_full</span>
        </button>
      </div>

      <ModalComponent
        show={show}
        handleClose={handleClose}
        {...{ startDate, endDate, lastActivityTime, data, processedData }}
        username={user_name}
      />
    </BaseCard>
  )
}

export default React.memo(GeoCardShowMap)
