import React, { useState, useEffect } from 'react'
import { validateAccessRole } from '../../helpers/userRole'
import { ClientConsumer } from '../context/ClientContext'
import { GlobalConsumer } from '../context/GlobalContext'
import cookie from 'react-cookies'

const ShowClientTab = (props) => {
  const [isClientEditing, setIsClientEditing] = useState(props.isClientEditing)
  const [isFetching, setIsFetching] = useState(false)

  const getFormattedDate = (date) => {
    return new Date(date).toLocaleString()
  }

  return (
    <div>
      {isFetching ? (
        <div className="main-view">
          <div className="show-title">
            <p>Obteniendo datos ...</p>
          </div>
        </div>
      ) : (
        <ClientConsumer>
          {({
            clientData,
            failedCreation,
            failedCreationInfo,
            handleClickButton,
            handleSubmitEditClient,
            handleChange,
            clientEditDetailsData
          }) => (
            <GlobalConsumer>
              {({ handleEditingClient, globalEdit }) => (
                <div>
                  {!globalEdit ? (
                    <div>
                      <div className="show-product-header show-header">
                        <h2>Detalles del cliente:</h2>
                        <div>
                          {validateAccessRole(cookie.load('user_role')?.clients_module)
                            ?.can_write && (
                            <div className="show-buttons-responsive">
                              <input
                                type="submit"
                                onClick={(event) => {
                                  handleClickButton(event)
                                  handleEditingClient(event)
                                }}
                                value="Actualizar cliente"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <hr />
                        <div className="">
                          <div>
                            {[
                              {
                                label: 'Grupo de Cliente',
                                key: 'client_group',
                                subKey: 'description'
                              },
                              {
                                label: 'Canal de Ventas',
                                key: 'sales_channel',
                                subKey: 'description'
                              },
                              { label: 'Tipo de Pago', key: 'payment_type', subKey: 'description' },
                              {
                                label: 'Plazo de Crédito',
                                key: 'credit_term',
                                subKey: 'description'
                              },
                              { label: 'Provincia', key: 'province', subKey: 'name' },
                              { label: 'Distrito', key: 'district', subKey: 'name' },
                              { label: 'Corregimiento', key: 'town', subKey: 'name' },
                              {
                                label: 'Lista de Precio que le aplica',
                                key: 'price_list',
                                subKey: 'description'
                              },
                              { label: 'Código', key: 'db_ref' },
                              {
                                label: 'Activo',
                                key: 'active',
                                formatter: (val) => (val ? 'Si' : 'No')
                              },
                              { label: 'Ruc', key: 'taxpayer_id' },
                              { label: 'DV', key: 'taxpayer_verificator_digit' },
                              { label: 'Razón Social', key: 'business_name' },
                              { label: 'Nombre Comercial', key: 'commercial_name' },
                              {
                                label: 'Nombre de cliente en sistema',
                                key: 'customer_name_db_ref'
                              },
                              { label: 'Monto de Crédito', key: 'credit_limit' },
                              { label: 'Representante Legal', key: 'legal_representative' },
                              {
                                label: 'Persona de contacto para cobro',
                                key: 'billing_contact_name'
                              },
                              { label: 'Correo electrónico de pago', key: 'billing_contact_email' },
                              { label: 'Pago Teléfono', key: 'billing_contact_phone' },
                              { label: 'Persona de contacto', key: 'contact_name' },
                              { label: 'Correo electronico', key: 'contact_email' },
                              { label: 'Teléfono', key: 'contact_phone' },
                              { label: 'Ciudad', key: 'city' },
                              { label: 'Calle', key: 'street' },
                              { label: 'Guía de Ubicación', key: 'location_description' },
                              {
                                label: 'Coordenada latitud(decimales)',
                                key: 'geographical_latitude'
                              },
                              {
                                label: 'Coordenada longitud (decimales)',
                                key: 'geographical_longitud'
                              },
                              { label: 'Ruta asignada', key: 'route_id' },
                              {
                                label: 'Fecha de creación',
                                key: 'created_at',
                                formatter: getFormattedDate
                              },
                              {
                                label: 'Fecha de actualización',
                                key: 'updated_at',
                                formatter: getFormattedDate
                              }
                            ].map(({ label, key, subKey, formatter }) => (
                              <div className="show-data" key={key}>
                                <div className="mr-85">
                                  <b>{label}: </b>
                                </div>
                                <div className="text-details-clients">
                                  {formatter
                                    ? formatter(clientData[key])
                                    : subKey
                                      ? clientData[key] &&
                                        clientData[key][subKey] !== undefined &&
                                        clientData[key][subKey] !== null
                                        ? clientData[key][subKey]
                                        : '-'
                                      : clientData[key] !== undefined && clientData[key] !== null
                                        ? clientData[key]
                                        : '-'}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {failedCreation && (
                        <div className="show-product-header">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="alert alert-danger" role="alert">
                                <p>{failedCreationInfo}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="show-product-header show-header">
                        <h2>
                          Actualizar detalles de cliente:{' '}
                          {`${clientData['db_ref']} - ${clientData['customer_name_db_ref']}`}
                        </h2>
                      </div>
                      <div>
                        <div className="inline-block full-width">
                          <form onSubmit={handleSubmitEditClient}>
                            <div className="show-product-header show-header">
                              <h2>Detalles del cliente:</h2>
                              <div className="show-buttons-responsive">
                                <input
                                  type="submit"
                                  onClick={handleEditingClient}
                                  className="cancel-edit-button"
                                  value="Cancelar"
                                />
                                <input type="submit" value="Guardar" />
                              </div>
                            </div>
                            <hr />
                            <div>
                              {[
                                {
                                  label: 'Grupo de Cliente',
                                  name: 'client_group_id',
                                  dataKey: 'client_group',
                                  editDataKey: 'client_group',
                                  descriptionKey: 'description'
                                },
                                {
                                  label: 'Canal de Ventas',
                                  name: 'sales_channel_id',
                                  dataKey: 'sales_channel',
                                  editDataKey: 'sales_channel',
                                  descriptionKey: 'description'
                                },
                                {
                                  label: 'Tipo de Pago',
                                  name: 'payment_type_id',
                                  dataKey: 'payment_type',
                                  editDataKey: 'payment_type',
                                  descriptionKey: 'description'
                                },
                                {
                                  label: 'Plazo de Crédito',
                                  name: 'credit_term_id',
                                  dataKey: 'credit_term',
                                  editDataKey: 'credit_term',
                                  descriptionKey: 'description'
                                },
                                {
                                  label: 'Provincia',
                                  name: 'province_id',
                                  dataKey: 'province',
                                  editDataKey: 'province',
                                  descriptionKey: 'name'
                                },
                                {
                                  label: 'Distrito',
                                  name: 'district_id',
                                  dataKey: 'district',
                                  editDataKey: 'district',
                                  descriptionKey: 'name'
                                },
                                {
                                  label: 'Corregimiento',
                                  name: 'town_id',
                                  dataKey: 'town',
                                  editDataKey: 'town',
                                  descriptionKey: 'name'
                                },
                                {
                                  label: 'Lista de Precio que le aplica',
                                  name: 'price_list_id',
                                  dataKey: 'price_list',
                                  editDataKey: 'price_list',
                                  descriptionKey: 'description',
                                  required: true
                                },
                                {
                                  label: 'Código cliente',
                                  name: 'db_ref',
                                  defaultValue: clientData['db_ref'],
                                  disabled: true,
                                  type: 'text'
                                },
                                {
                                  label: 'Activo',
                                  name: 'active',
                                  options: [
                                    { value: true, label: 'Si' },
                                    { value: false, label: 'No' }
                                  ]
                                },
                                {
                                  label: 'Ruc',
                                  name: 'taxpayer_id',
                                  defaultValue: clientData['taxpayer_id'],
                                  type: 'text',
                                  placeholder: '0000000-0-000000'
                                },
                                {
                                  label: 'DV',
                                  name: 'taxpayer_verificator_digit',
                                  defaultValue: clientData['taxpayer_verificator_digit'],
                                  type: 'text',
                                  placeholder: '00'
                                },
                                {
                                  label: 'Razón Social',
                                  name: 'business_name',
                                  defaultValue: clientData['business_name'],
                                  type: 'text',
                                  placeholder: 'Razón Social'
                                },
                                {
                                  label: 'Nombre Comercial',
                                  name: 'commercial_name',
                                  defaultValue: clientData['commercial_name'],
                                  type: 'text',
                                  placeholder: 'Nombre comercial'
                                },
                                {
                                  label: 'Nombre de cliente en sistema',
                                  name: 'customer_name_db_ref',
                                  defaultValue: clientData['customer_name_db_ref'],
                                  type: 'text',
                                  required: true,
                                  placeholder: 'Nombre cliente en sistema'
                                },
                                {
                                  label: 'Monto de Crédito',
                                  name: 'credit_limit',
                                  defaultValue: clientData['credit_limit'],
                                  type: 'number',
                                  step: '0.01',
                                  max: '999999',
                                  placeholder: '$$'
                                },
                                {
                                  label: 'Representante Legal',
                                  name: 'legal_representative',
                                  defaultValue: clientData['legal_representative'],
                                  type: 'text',
                                  placeholder: 'Nombre representante legal'
                                },
                                {
                                  label: 'Persona de contacto para cobro',
                                  name: 'billing_contact_name',
                                  defaultValue: clientData['billing_contact_name'],
                                  type: 'text',
                                  placeholder: 'Nombre persona de contacto'
                                },
                                {
                                  label: 'Correo electrónico de pago',
                                  name: 'billing_contact_email',
                                  defaultValue: clientData['billing_contact_email'],
                                  type: 'text',
                                  placeholder: 'Correo electrónico de pago'
                                },
                                {
                                  label: 'Pago Teléfono',
                                  name: 'billing_contact_phone',
                                  defaultValue: clientData['billing_contact_phone'],
                                  type: 'text',
                                  placeholder: '0000-0000'
                                },
                                {
                                  label: 'Persona de contacto',
                                  name: 'contact_name',
                                  defaultValue: clientData['contact_name'],
                                  type: 'text',
                                  placeholder: 'Nombre persona de contacto'
                                },
                                {
                                  label: 'Correo electronico',
                                  name: 'contact_email',
                                  defaultValue: clientData['contact_email'],
                                  type: 'text',
                                  placeholder: 'Correo electrónico persona de contacto'
                                },
                                {
                                  label: 'Teléfono',
                                  name: 'contact_phone',
                                  defaultValue: clientData['contact_phone'],
                                  type: 'text',
                                  placeholder: '0000-0000'
                                },
                                {
                                  label: 'Ciudad',
                                  name: 'city',
                                  defaultValue: clientData['city'],
                                  type: 'text',
                                  placeholder: 'Ciudad'
                                },
                                {
                                  label: 'Calle',
                                  name: 'street',
                                  defaultValue: clientData['street'],
                                  type: 'text',
                                  placeholder: 'Calle'
                                },
                                {
                                  label: 'Guía de Ubicación',
                                  name: 'location_description',
                                  defaultValue: clientData['location_description'],
                                  type: 'text',
                                  placeholder: 'Guía de Ubicación'
                                },
                                {
                                  label: 'Coordenadas en grados decimales (latitud)',
                                  name: 'geographical_latitude',
                                  defaultValue: clientData['geographical_latitude'],
                                  type: 'text',
                                  placeholder: '00.000000'
                                },
                                {
                                  label: 'Coordenadas en grados decimales (longitud)',
                                  name: 'geographical_longitud',
                                  defaultValue: clientData['geographical_longitud'],
                                  type: 'text',
                                  placeholder: '00.000000'
                                },
                                {
                                  label: 'Ruta asignada',
                                  name: 'route_id',
                                  defaultValue: clientData['route_id'],
                                  type: 'text'
                                }
                              ].map(
                                ({
                                  label,
                                  name,
                                  dataKey,
                                  editDataKey,
                                  descriptionKey,
                                  defaultValue,
                                  disabled,
                                  type = 'select',
                                  options,
                                  required,
                                  placeholder
                                }) => (
                                  <div className="show-data" key={name}>
                                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                                      {label}: {required && <span className="red">*</span>}
                                    </label>

                                    {type === 'select' ? (
                                      <select
                                        name={name}
                                        onChange={handleChange}
                                        defaultValue={defaultValue}
                                        className={`form-control custom-select-clients  ? 'is-invalid-clients' : ''}`}
                                      >
                                        {!clientData[dataKey] && (
                                          <option value="">Escoja una opción</option>
                                        )}
                                        {clientData[dataKey] && (
                                          <option value={clientData[dataKey].id} hidden>
                                            {clientData[dataKey][descriptionKey]}
                                          </option>
                                        )}
                                        {clientEditDetailsData &&
                                          clientEditDetailsData[editDataKey]?.map((obj) => (
                                            <option key={obj.id} value={obj.id}>
                                              {obj[descriptionKey]}
                                            </option>
                                          ))}
                                        {options &&
                                          options.map((option) => (
                                            <option key={option.value} value={option.value}>
                                              {option.label}
                                            </option>
                                          ))}
                                      </select>
                                    ) : (
                                      <input
                                        type={type}
                                        name={name}
                                        onChange={handleChange}
                                        defaultValue={defaultValue}
                                        disabled={disabled}
                                        placeholder={placeholder}
                                        className="form-control"
                                        style={{
                                          backgroundColor: disabled ? '#f0f0f0' : 'transparent'
                                        }}
                                      />
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </GlobalConsumer>
          )}
        </ClientConsumer>
      )}
    </div>
  )
}

export default ShowClientTab
