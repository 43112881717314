import React, { useContext, useEffect, useState } from 'react'
import { UserInfoContext } from '../context/UserInfoProvider'

const RoleFilters = ({ handleFilters }) => {
  const [selectedFilters, setSelectedFilters] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { featureRoles } = useContext(UserInfoContext);

  const handleCheckBoxChange = (e) => {
    setSelectedFilters({ ...selectedFilters, [e.target.name]: e.target.checked })
  }

  const resetFilters = () => {
    setSelectedFilters({})
  }

  const toggleSelectAll = () => {
    if (Object.values(selectedFilters).includes(false)) {
      const allSelected = featureRoles.reduce((acc, role) => ({ ...acc, [role.value]: true }), {});
      setSelectedFilters(allSelected);
    } else {
      resetFilters();
    }
  };

  useEffect(() => {
    handleFilters(selectedFilters)
  }, [selectedFilters])

  const filteredRoles = searchTerm
    ? featureRoles.filter((role) => role.label.toLowerCase().includes(searchTerm.toLowerCase()))
    : featureRoles;


return (
    <div className="index-filter">
      <div className="title-and-search-container">
        <h4>Filtrar por roles</h4>
        <div className="filter-box-role">
          <div onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            <div className="filter-title-role"> Seleccione roles </div>
          </div>
          {isDropdownOpen && (
            <div className="dropdown-content">
            <div className="search-drop-role">
              <input
                type="text"
                placeholder="Buscar rol..."
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
              />
             </div>

              { <button onClick={toggleSelectAll} className="select-all-button">
                {Object.values(selectedFilters).includes(false) ? 'Seleccionar todos' : 'Deseleccionar todos'}
              </button> }
              {filteredRoles.length === 0 && <p>Cargando filtros...</p>}
              {filteredRoles.map((role, index) => (
                <label key={index} className="role-option">
                  <input
                    type="checkbox"
                    name={role.value}
                    checked={selectedFilters[role.value] || false}
                    onChange={handleCheckBoxChange}
                    className="role-checkbox"
                  />
                  {role.label}
                </label>
              ))}
            </div>
          )}
        </div>
        {JSON.stringify(selectedFilters).includes('true') && (
          <div className="custom">
            <a onClick={resetFilters} className="reset-filters-link">
              Restablecer filtros aplicados
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default RoleFilters
