import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faGripHorizontal, faImage } from '@fortawesome/free-solid-svg-icons'
import React, { useState, useRef, useEffect } from 'react'
import autosize from 'autosize'

const ClientFormTextQuestion = ({
  id,
  onDuplicate,
  onDelete,
  onUpdateQuestion,
  data,
  questionNumber,
  disableQuestion,
  isCreatingForm,
  isShowingFormDetails,
  showresponsePostask,
  provided,
  questionType
}) => {
  const [isVisible, setIsVisible] = useState(true)
  const [showOptions, setShowOptions] = useState(false)
  const optionsContainerRef = useRef(null)

  const [formData, setFormData] = useState({
    question: data.question || '',
    response: data.response || ''
  })

  const [isDuplicating, setIsDuplicating] = useState(false)
  const [rows, setRows] = useState(1)
  const textareaRefQuestion = useRef(null)

  useEffect(() => {
    return () => {
      autosize(textareaRefQuestion.current)
    }
  }, [])

  useEffect(() => {
    if (!isDuplicating) {
      setFormData({
        question: data.question || '',
        response: data.response || ''
      })
    }
  }, [id, isDuplicating])

  useEffect(() => {
    setFormData({
      question: data.question || '',
      response: data.response || ''
    })
  }, [data])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsContainerRef.current && !optionsContainerRef.current.contains(event.target)) {
        setShowOptions(false)
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleDuplicate = () => {
    const duplicatedData = { ...formData }
    onDuplicate(duplicatedData)
    setShowOptions(false)
    setIsDuplicating(true)
  }

  const handleDelete = () => {
    setIsVisible(false)
    setShowOptions(false)
    onDelete(id)
  }

  const handleChange = (e) => {
    const { name, value, scrollHeight } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
    onUpdateQuestion(id, name, value)
    const calculatedRows = Math.floor(scrollHeight / 20)
    setRows(calculatedRows)
  }
  const handleTextareaClick = () => {
    autosize.update(textareaRefQuestion.current)
  }

  const handleToggleOptions = () => {
    setShowOptions(!showOptions)
  }

  return (
    <div>
      {isVisible &&
        (isCreatingForm ? (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className="survey-text-task-container"
          >
            <div className="headers">
              <div className="question-text">
                <p>Pregunta&nbsp;{questionNumber}:</p>
              </div>
              <div
                className="drag-handle drag-handle-move"
                {...provided.dragHandleProps}
                title="Arrastra para reubicar la pregunta"
              >
                <FontAwesomeIcon icon={faGripHorizontal} />
              </div>
              <div className="options-container" ref={optionsContainerRef}>
                <FontAwesomeIcon
                  icon={faEllipsisH}
                  className="ellipsis-icon"
                  onClick={handleToggleOptions}
                />
                {showOptions && (
                  <div className="options-card-surveys">
                    <button onClick={handleDuplicate}>Duplicar</button>
                    <div className="divider-options-card"></div>
                    <button onClick={handleDelete}>Eliminar</button>
                  </div>
                )}
              </div>
            </div>
            <div className="form-group blue-border-input">
              <textarea
                ref={textareaRefQuestion}
                className="form-control form-control-details"
                id={`question-${id}`}
                name="question"
                maxLength="100"
                placeholder="Escriba su pregunta"
                value={formData.question}
                onChange={handleChange}
                onClick={handleTextareaClick}
                required
                disabled={disableQuestion}
                style={{ resize: 'none', minHeight: '30px', height: 'auto' }}
                rows={1}
              />
            </div>
            {questionType == 'text' && (
              <div className={`form-group`}>
                <input
                  disabled
                  type="text"
                  className={`form-control inactive`}
                  placeholder="Respuesta de texto, abierta sin respuestas predefinidas"
                />
              </div>
            )}
            {questionType == 'number' && (
              <div className={`form-group`}>
                <input
                  disabled
                  type="text"
                  className={`form-control inactive`}
                  placeholder="Respuesta numérica"
                />
              </div>
            )}
            {questionType === 'geolocation' && (
              <div className="form-group geolocation-container">
                <span className="material-symbols-outlined geolocation-icon">location_on</span>
                <input
                  disabled
                  type="text"
                  className="form-control geolocation-input inactive"
                  placeholder="Marcar ubicación"
                />
              </div>
            )}
            {questionType == 'image' && (
              <div className="form-group">
                <div
                  className="custom-placeholder"
                  contentEditable="false"
                  suppressContentEditableWarning={true}
                  onClick={() => document.getElementById(`respuestaimage-${id}`).focus()}
                >
                  <div className="placeholder-text1">
                    <FontAwesomeIcon icon={faImage} size="2x" />
                  </div>
                  <span className="placeholder-text2">
                    Permite seleccionar una sola imagen <br />
                  </span>
                  <span className="placeholder-text3">
                    JPG o PNG, tamaño del archivo no mayor a 5MB
                    <br />
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control hidden-input"
                  id={`respuestaimage-${id}`}
                  name="respuestaimage"
                  onChange={handleChange}
                />
              </div>
            )}
          </div>
        ) : (
          <div className={`survey-text-task-container-details`}>
            {isShowingFormDetails && formData.question && (
              <>
                <div className={`form-group-details blue-border-input-details`}>
                  <div className="question-container">
                    <div className="question-number">
                      <span  className='question-input-number'>{questionNumber}.</span >
                    </div>
                    <textarea
                      className={`question-input-details form-control-details responsive-textarea`}
                      name="question"
                      value={formData.question}
                      disabled
                      style={{
                        height: 'auto',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        resize: 'none'
                      }}
                      rows={1}
                    />
                  </div>
                </div>
                {questionType == 'text' && (
                  <div
                    className={`response-details response-details-border response-surveys-details`}
                  >
                    <input
                      disabled
                      type="text"
                      className={`response-surveys-details form-control-details`}
                      placeholder="Respuesta de texto "
                    />
                  </div>
                )}
                {questionType == 'number' && (
                  <div
                    className={`response-details response-details-border response-surveys-details`}
                  >
                    <input
                      disabled
                      type="text"
                      className={`response-surveys-details form-control-details`}
                      placeholder="Respuesta numérica"
                    />
                  </div>
                )}
                {questionType == 'geolocation' && (
                  <div
                    className={`response-details response-details-border response-surveys-details`}
                  >
                    <input
                      disabled
                      type="text"
                      className={`response-surveys-details form-control-details geolocation-input `}
                      placeholder="Marcar ubicación "
                    />
                    <span className="material-symbols-outlined geolocation-icon-details">
                      location_on
                    </span>
                  </div>
                )}
                {questionType == 'image' && (
                  <div className="form-group">
                    <div
                      className="custom-placeholder"
                      contentEditable="false"
                      suppressContentEditableWarning={true}
                    >
                      <div className="placeholder-text1">
                        <FontAwesomeIcon icon={faImage} size="2x" />
                      </div>
                      <span className="placeholder-text2">
                        Permite seleccionar una sola imagen <br />
                      </span>
                      <span className="placeholder-text3">
                        JPG o PNG, tamaño del archivo no mayor a 5MB
                        <br />
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control hidden-input"
                      id={`respuestaimage-${id}`}
                      name="respuestaimage"
                    />
                  </div>
                )}
              </>
            )}

            {showresponsePostask && formData.response && (
              <div className={`form-group-details blue-border-input-details`}>
                <div className="question-container">
                  <div className="question-number">
                    <b>{'Respuesta: '}</b>
                  </div>
                  <input
                    disabled
                    type="text"
                    className={`response-surveys-details form-control-details`}
                    id=""
                    name=""
                    value={formData.response}
                    placeholder="Escriba su respuesta "
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
    </div>
  )
}

export default ClientFormTextQuestion
