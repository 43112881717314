import React, { useState } from 'react'
import { fixDateString, getDay, getMonth } from '../../../helpers/formatDate'
import { RRule, SPANISH } from 'rrule'

function TaskScheduleConfig({ onRecurrenceChange, onStartChange, onEndChange }) {
  const [recurrenceWeekDaysSelection, setRecurrenceWeekDaysSelection] = useState([])
  const [startTime, setStartTime] = useState('')
  const [recurrenceSelection, setRecurrenceSelection] = useState('')

  const [recurrenceInterval, setRecurrenceInterval] = useState(1)
  const [endTime, setEndTime] = useState('')
  const [undefinedEndTime, setUndefinedEndTime] = useState(true)
  const [lastRuleString, setLastRuleString] = useState('')

  const recurrenceWeekDays = [
    { rrlue: RRule.MO, value: 'Lunes', text: 'L' },
    { rrlue: RRule.TU, value: 'Martes', text: 'M' },
    { rrlue: RRule.WE, value: 'Miércoles', text: 'M' },
    { rrlue: RRule.TH, value: 'Jueves', text: 'J' },
    { rrlue: RRule.FR, value: 'Viernes', text: 'V' },
    { rrlue: RRule.SA, value: 'Sábado', text: 'S' },
    { rrlue: RRule.SU, value: 'Domingo', text: 'D' }
  ]

  const recurrenceSelectionType = [
    { value: '', text: 'Escoja una opción', hidden: true },
    { value: 'daily', text: 'Todos los días' },
    { value: 'weekly', text: 'Semanal' }
  ]

  const getFormattedDateOnly = (date) => {
    const d = new Date(fixDateString(date))
    return d.toLocaleDateString()
  }

  const buildRecurrenceMessage = () => {
    let rule = buildRecurrence()
    if (rule && startTime) {
      return (
        <>
          Esta tarea se ejecutará <span className="highlight-blue">{rule.toText(SPANISH)}</span> a
          partir del <span className="highlight-blue">{getFormattedDateOnly(startTime)}</span>{' '}
          {resolveEndTime()}
        </>
      )
    }
  }

  const handleRecurrenceTypeChange = (event) => {
    if (event?.target?.value) {
      setRecurrenceSelection(event?.target?.value)
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 38) {
      increaseRecurrenceInterval()
    } else if (e.keyCode === 40) {
      decreaseRecurrenceInterval()
    }
  }

  const handleStartTimeChange = (event) => {
    if (event?.target?.value) {
      setStartTime(event?.target?.value)
      onStartChange(event.target.value)
    }
  }

  const handleEndTimeChange = (event) => {
    if (event?.target?.value) {
      setEndTime(event?.target?.value)
      onEndChange(event.target.value)
    }
  }

  const buildRecurrence = () => {
    let rule = null
    switch (recurrenceSelection) {
      case 'daily':
        rule = new RRule({
          freq: RRule.DAILY
        })
        break
      case 'weekly':
        rule = new RRule({
          freq: RRule.WEEKLY,
          interval: recurrenceInterval,
          byweekday: recurrenceWeekDaysSelection.map((day) => day.rrlue)
        })
        break
      default:
        return
    }
    const ruleString = rule ? rule.toString() : ''

    if (ruleString !== lastRuleString) {
      if (onRecurrenceChange) {
        onRecurrenceChange(rule)
      }
      setLastRuleString(ruleString)
    }
    return rule
  }

  const onChangeRadios = (event) => {
    if (event.target.value === 'true') {
      setUndefinedEndTime(true)
      setEndTime(null)
    } else {
      setUndefinedEndTime(false)
    }
  }

  const increaseRecurrenceInterval = () => {
    setRecurrenceInterval(recurrenceInterval + 1)
  }

  const decreaseRecurrenceInterval = () => {
    setRecurrenceInterval(recurrenceInterval - 1)
  }

  const handleToggleDay = (day) => {
    const dayVal = day?.value
    const recurrenceWeekDaySelection = recurrenceWeekDays.find((day) => day.value === dayVal)
    if (!recurrenceWeekDaySelection) {
      return
    }

    const index = recurrenceWeekDaysSelection.findIndex(
      (day) => day.value === recurrenceWeekDaySelection.value
    )
    if (index === -1) {
      setRecurrenceWeekDaysSelection([...recurrenceWeekDaysSelection, recurrenceWeekDaySelection])
    } else {
      let arr = [...recurrenceWeekDaysSelection]
      arr.splice(index, 1)
      setRecurrenceWeekDaysSelection(arr)
    }
  }

  const onChangeRecurrenceInterval = (event) => {
    let strNum = event?.target?.value
    if (!strNum) {
      setRecurrenceInterval(1)
      return
    }
    if (strNum) {
      let num = Number(strNum)
      if (isNaN(num)) return
      if (num < 1) num = 1
      if (num > 99) num = 99
      setRecurrenceInterval(num)
    }
  }

  const resolveEndTime = () => {
    if (undefinedEndTime) {
      return (
        <>
          de forma <span className="highlight-blue">indefinida</span>
        </>
      )
    }
    if (endTime != '') {
      return (
        <>
          hasta el <span className="highlight-blue">{getFormattedDateOnly(endTime)}</span>
        </>
      )
    }
  }

  return (
    <>
      <div className="promo-form-section-dates">
        <div className="title-promos-form">
          <b class="bold-step">
            PASO 5: Escoja una fecha de inicio y/o fecha final para esta tarea
          </b>
        </div>
        <hr className="hr-promos-form" />
        <div className="d-flex align-flex-center">
          <div className="float-left">
            <label>Fecha inicio</label>
            <br />
            <input
              min={`${new Date().getFullYear()}-${getMonth()}-${getDay()}`}
              onChange={handleStartTimeChange}
              className="start-date "
              type="date"
              name="startTime"
              required
            />
          </div>
          {!undefinedEndTime && (
            <>
              <div className="dates-separator-2"></div>
              <div className="float-right">
                <label>Fecha final</label>
                <br />
                <input
                  min={startTime}
                  onChange={handleEndTimeChange}
                  className="end-date"
                  type="date"
                  name="endTime"
                  required
                />
              </div>
            </>
          )}
        </div>
        <div className="mt-16">
          <div>
            <input
              onChange={onChangeRadios}
              type="radio"
              name="taskRange"
              id="taskRange-undefined"
              checked={undefinedEndTime}
              value={true}
            />
            <label className="ml-10" htmlFor="taskRange-undefined">
              Tarea indefinida
            </label>
          </div>
          <div>
            <input
              onChange={onChangeRadios}
              type="radio"
              name="taskRange"
              id="taskRange-defined"
              checked={!undefinedEndTime}
              value={false}
            />
            <label className="ml-10" htmlFor="taskRange-defined">
              Con fecha de expiración
            </label>
          </div>
        </div>
        <div className="mb-40" />
      </div>

      <div className="promo-form-section">
        <div className="title-promos-form">
          <p>
            <b class="bold-step">PASO 5.1: Establezca una recurrencia para la tarea</b>
          </p>
        </div>
        <div className="form-control-width">
          <select
            className="form-control-width form-control custom-select"
            name="recurrenceSelectionType"
            required
            onChange={handleRecurrenceTypeChange}
          >
            {recurrenceSelectionType?.map((obj) => (
              <option key={obj.value} name={obj.value} value={obj.value} hidden={obj.hidden}>
                {obj.text}
              </option>
            ))}
          </select>
        </div>
        {recurrenceSelection === 'weekly' && (
          <>
            <div className="form-control-width mt-20">
              <p>
                Se repite cada{' '}
                <div className="number-input-changer-container ">
                  <input
                    className="number-input-changer"
                    type="text"
                    value={recurrenceInterval}
                    onChange={onChangeRecurrenceInterval}
                    onKeyDown={handleKeyDown}
                  />
                  <div
                    onClick={increaseRecurrenceInterval}
                    className="number-input-changer-btn plus"
                  >
                    +
                  </div>
                  <div
                    onClick={decreaseRecurrenceInterval}
                    className="number-input-changer-btn minus"
                  >
                    -
                  </div>
                </div>{' '}
                semana
                {recurrenceInterval && recurrenceInterval > 1 ? 's' : ''}{' '}
              </p>
            </div>
            <div className="form-control-width mt-20">
              <p>Escoja los día(s)</p>
              <div className="days-btn-group">
                {recurrenceWeekDays.map((day, index) => (
                  <button
                    type="button"
                    onClick={() => handleToggleDay(day)}
                    value={day.value}
                    title={day.value}
                    key={index}
                    className={`days-btn ${
                      recurrenceWeekDaysSelection.find((d) => d.value === day.value) && 'active'
                    }`}
                  >
                    {day.text}
                  </button>
                ))}
              </div>
              <div className="fake-validator-container">
                <input
                  className="input-fake-validator"
                  type="text"
                  required
                  value={
                    recurrenceWeekDaysSelection && recurrenceWeekDaysSelection.length > 0
                      ? JSON.stringify(recurrenceWeekDaysSelection)
                      : null
                  }
                />
              </div>
            </div>
          </>
        )}
        <p className="small text-muted mt-20">{buildRecurrenceMessage()}</p>
        <div className="mb-50" />
      </div>
    </>
  )
}

export default TaskScheduleConfig
