import _ from 'lodash'

export const AssortedPromoReducer = (state, action) => {
  const assortedProducts = state
  const product = action.payload

  switch (action.type) {
    case 'ADD_ASSORTED_PRODUCT': {
      const orderPackages = _.sortBy(product?.packages, ['quantity']).reverse()
      product.base_product_ids = [product.id]
      product.base_product_ids = [product.id]
      product.base_product_uid = product.db_ref
      product.base_product_db_ref = product.db_ref
      product.base_product_name = product.name
      product.base_package_uids = orderPackages.map((pk) => pk.uid)
      product.base_package_uid = null
      product.base_quantity = 0
      product.base_packages = orderPackages.map((pk) => pk.unit)
      product.base_package = null
      product.basePackageQuantity = 1
      product.base_package_hash = {}
      product.packages = orderPackages
      return [product, ...assortedProducts]
    }

    case 'REMOVE_ASSORTED_PRODUCT': {
      return assortedProducts.filter((item) => item.id !== product.id)
    }
      
    case 'ADD_ASSORTED_PRODUCT_QUANTITY': {
      assortedProducts.forEach((pr, index) => {
        assortedProducts[index].base_quantity = product.baseQuantity
      })
      return [...assortedProducts]
    }
      
    case 'CHANGE_BASE_PACKAGE_ASSORTED_PRODUCTS': {
      const hash = {}
      assortedProducts.forEach((pr, index) => {
        assortedProducts[index].base_package_uid =
          assortedProducts[index].base_package_uids[product.basePackage]
        assortedProducts[index].base_package =
          assortedProducts[index].base_packages[product.basePackage]
        hash[assortedProducts[index].base_product_db_ref] = assortedProducts[index].base_package_uid
        assortedProducts[index].base_package_hash = hash
      })
      return [...assortedProducts]
    }
    
    case 'CLEAR_ASSORTED_PRODUCTS': {
      return []
    }
      

    default: {
      return state
    }
  }
}