import React, { useContext, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import MapPoint from '../map/MapPoint'
import { MapModalStore } from '../../hook/MapModalProvider'
import { allCoordinatesValidClient } from '../../../helpers/utils'

const API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY

const ShowUserReportMapModalContent = ({ data }) => {
  const [mapApiLoaded, setMapApiLoaded] = useState(false)
  const [mapInstance, setMapInstance] = useState(null)
  const [mapApi, setMapApi] = useState(null)
  const [bounds, setBounds] = useState(null)
  const [zoom, setZoom] = useState(10)

  const { modalMapStyles } = useContext(MapModalStore)

  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds)
      })
    })
  }

 const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();
    places.forEach((place) => {
      if (place !== null) {
        bounds.extend(new maps.LatLng(place.latitude, place.longitude));
      }
    });
    return bounds;
  };
  const apiIsLoaded = (map, maps, places) => {
    const bounds = getMapBounds(map, maps, places)
    map.fitBounds(bounds)
    bindResizeListener(map, maps, bounds)
  }

  const apiHasLoaded = (map, maps) => {
    setMapInstance(map)
    setMapApi(maps)
    setMapApiLoaded(true)
    map.setOptions({ maxZoom: 22 })
  }

  const setZoomProvider = (zoom) => {
    setZoom(zoom)
  }

  const setBoundsProvider = (bounds) => {
    setBounds(bounds)
  }

  const places = data
    .map((d, index) =>
      getOffsetCoordinates(parseFloat(d.latitude), parseFloat(d.longitude), index, data.length)
    )
    .filter(place => place !== null);

  function getOffsetCoordinates(latitude, longitude, index, total, distance = 0.00004) {
    if (latitude === null || longitude === null || isNaN(latitude) || isNaN(longitude)) {
      return null;
    }
    const angle = (index / total) * 2 * Math.PI
    const offsetX = Math.cos(angle) * distance
    const offsetY = Math.sin(angle) * distance
    return { latitude: latitude + offsetY, longitude: longitude + offsetX }
  }

  return (
    <>
      <GoogleMapReact
        bootstrapURLKeys={{ key: API_KEY }}
        onGoogleApiLoaded={({ map, maps }) => {
          map.setOptions({ maxZoom: 22 })
          apiHasLoaded(map, maps)
          apiIsLoaded(map, maps, places)
        }}
        defaultCenter={places[0]}
        defaultZoom={10}
        layerTypes={[]}
        center={mapApiLoaded ? mapInstance.getCenter().toJSON() : [1, 2]}
        options={{ 
        styles: modalMapStyles,
        fullscreenControl: false, 
        }}
        onChange={({ zoom, bounds }) => {
          setZoomProvider(zoom)
          setBoundsProvider([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat])
        }}
      >
        {data
          .filter(
            (activity) =>
              activity.latitude &&
              activity.longitude &&
              activity.latitude !== '0.0' &&
              activity.longitude !== '0.0'
          )
          .map((activity, index) => {
            const { latitude, longitude } = getOffsetCoordinates(
              parseFloat(activity.latitude),
              parseFloat(activity.longitude),
              index,
              data.length
            )
            return (
              <MapPoint
                key={index}
                id={activity.client_index}
                lat={latitude}
                lng={longitude}
                effectiveClientLocation={allCoordinatesValidClient(activity)} 
                name={activity.client_name}
                createdDate={activity.created_date}
                activityName={activity.activity_name}
                effectiveLocation={activity.effective_location}
                monto={activity.total}
                isReport={false}
                color="white"
              />
            )
          })}
      </GoogleMapReact>
      {!mapApiLoaded && (
        <div className="map-modal-error">
          <div className="map-error-label">
            <p>El usuario ha desactivado el envío de ubicación para todas las actividades.</p>
            <p>No es posible mostrar el mapa de geocercas.</p>
          </div>
        </div>
      )}
    </>
  )
}

export default ShowUserReportMapModalContent
