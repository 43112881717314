import _ from 'lodash'

export const ScalePromoReducer = (state, action) => {
  const scaleDiscountProducts = state
  const product = action.payload

  switch (action.type) {
    case 'ADD_TO_SCALE_DISCOUNT_PRODUCT': {
      const orderPackages = _.sortBy(product?.packages, ['quantity']).reverse()
      product.product_ids = [product.id]
      product.base_package = null
      product.base_package_uid = null
      product.base_quantity = 0
      product.basePackageQuantity = null
      product.packages = orderPackages
      return [product, ...scaleDiscountProducts]
    }

    case 'CHANGE_BASE_PACKAGE_PRODUCTS': {
      const findBasePackageProduct = scaleDiscountProducts.find((item) => item.id === product.id)

      if (findBasePackageProduct) {
        const productIndex = scaleDiscountProducts.findIndex((item) => item.id === product.id)
        scaleDiscountProducts[productIndex].base_package = product.base_package
        scaleDiscountProducts[productIndex].base_package_uid = product.basePackage_uid
        scaleDiscountProducts[productIndex].base_package_quantity = product.basePackageQuantity
        scaleDiscountProducts[productIndex].basePackageQuantity = product.basePackageQuantity
        return [...scaleDiscountProducts]
      }
      return state
    }

    case 'REMOVE_SCALE_DISCOUNT_PRODUCT': {
      return scaleDiscountProducts.filter((item) => item.id !== product.id)
    }

    case 'CLEAR_SCALE_DISCOUNT_PRODUCT': {
      return []
    }

    default: {
      return state
    }
      
  }
}