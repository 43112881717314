import cookie from 'react-cookies'
import * as apiStatusCodes from '../../constants/apiStatusCodes'


const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
const BASE_BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const BACKEND_URL = USE_BUSINESS_URL
  ? `${BASE_BACKEND_URL}/businesses/${cookie.load('business_id')}`
  : BASE_BACKEND_URL

const API_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + cookie.load('token')
}

////// Stops fetchs when page is changed
const abortController = new AbortController()
const abortCurrentFetchs = () => {
  abortController.abort();
};
window.addEventListener("beforeunload", abortCurrentFetchs);
//////

const sendApiRequest = (method, url, params) =>
  fetch(url, {
    method: method,
    signal: abortController.signal,
    headers: API_HEADERS,
    body: JSON.stringify(params)
  })

export const handleErrors = (response) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    if (response.status !== apiStatusCodes.OK && response.status !== apiStatusCodes.CREATED) {
      reject(response)
    } else {
      resolve(response)
    }
  })

export const getAllUsers = (query = '', page = 1, per_page = 25, filters = [],active = []) => {
  // filters 'role.salesman-role.charges'
    // active 'true,false'


  const url = `${BASE_BACKEND_URL}/businesses/${cookie.load('business_id')}/ubiqua_app_users?business_tenant_uid=${cookie.load('business_tenant_uid')}&q=${query}&filters=${filters}&active=${active}&page=${page}&per_page=${per_page}`;

  return sendApiRequest('GET', url)

}

export const getFeatureRoles = (query = '', page = 1, per_page = 25) => {
  const url =`${BACKEND_URL}/ubiqua_app_feature_roles?business_tenant_uid=${cookie.load('business_tenant_uid')}&q=${query}&page=${page}&per_page=${per_page}`;
  return sendApiRequest('GET', url);
}

export const getAuditUser = (id, query = '', page = 1, per_page = 25) => {
  const url = `${BACKEND_URL}/admin/user_config_audits/?business_tenant_uid=${cookie.load('business_tenant_uid')}&id=${id}&q=${query}&page=${page}&per_page=${per_page}`;
  return sendApiRequest('GET', url)

}

export const getUserConfig = (id) => {
  const url = `${BACKEND_URL}/ubiqua_app_users/${id}?business_tenant_uid=${cookie.load('business_tenant_uid')}`;

  return sendApiRequest('GET', url);
}

export const getBusiness = () => {
  const url = `${BACKEND_URL}/admin/businesses?business_tenant_uid=${cookie.load('business_tenant_uid')}`;
  return sendApiRequest('GET', url);
}

export const updateUserInfo = (id, value) => {
  const url = `${BACKEND_URL}/ubiqua_app_users/${id}?business_tenant_uid=${cookie.load('business_tenant_uid')}`;

  const params = {
    active: value.active,
    business_uid: cookie.load('business_tenant_uid'),
    email: value.email,
    full_name: value.name,
    route_db_ref: value.code,
    mobile: value.mobile,
    warehouse_uid: value.warehouse,
    roles: value.role
  }
  return sendApiRequest('PUT', url, params)
}

export const createUserInfo = (user, config) => {
  const url =
    `${BACKEND_URL}/ubiqua_app_users?business_tenant_uid=${cookie.load('business_tenant_uid')}`;
  const configFlags = Object.entries(config).map((e) => ({ [e[0]]: e[1].value }))
  const params = {
    config: configFlags,
    // can_access_refunds_app: config.can_access_refunds_app.value,
    // can_change_prices: config.can_change_prices.value,
    // can_set_prices_on_quote_items: config.can_set_prices_on_quote_items.value,
    // has_fixed_route: config.has_fixed_route.value,
    // can_consolidate_orders: config.can_consolidate_orders.value,
    // can_see_customer_segment: config.can_see_customer_segment.value,
    // enable_fiscal_printer: config.enable_fiscal_printer.value,
    // can_select_discount: config.can_select_discount.value,
    active: user.active,
    business_uid: cookie.load('business_tenant_uid'),
    email: user.email,
    full_name: user.name,
    password: user.password,
    route_db_ref: user.code,
    warehouse_uid: user.warehouse,
    roles: user.role,
    mobile: user.mobile
  }
  return sendApiRequest('POST', url, params)
}

export const sendEmail = (user, action) => {
  const url = `${BASE_BACKEND_URL}/admin/send_email_to_user?business_tenant_uid=${cookie.load('business_tenant_uid')}`;
  const params = {
    email: user.email,
    key: user.password,
    email_type: action
  }
  return sendApiRequest('POST', url, params)
}

export const resetPassword = (user) => {
  const url = `${BACKEND_URL}/ubiqua_app_users/${user.id}/update_password?business_tenant_uid=${cookie.load('business_tenant_uid')}`;
  const params = {
    password: user.password,
    key_confirmation: user.password_confirmation
  }
  return sendApiRequest('POST', url, params)
}

export const updateSalesmanConfig = (id, value) => {
  const url = `${BASE_BACKEND_URL}/admin/salesmen/${id}?business_tenant_uid=${cookie.load('business_tenant_uid')}`;
  const params = {
    can_access_refunds_app: value.can_access_refunds_app.value,
    can_change_prices: value.can_change_prices.value,
    can_set_prices_on_quote_items: value.can_set_prices_on_quote_items.value,
    has_fixed_route: value.has_fixed_route.value,
    can_consolidate_orders: value.can_consolidate_orders.value,
    can_see_customer_segment: value.can_see_customer_segment.value,
    enable_fiscal_printer: value.enable_fiscal_printer.value,
    can_select_discount: value.can_select_discount.value,
    route: value.route.value,
    email: value.email.value,
    salesman_email: value.email.value
  }
  return sendApiRequest('PUT', url, params)
}

export const getQueryState = () => {
  if (window.location.href.indexOf('query') >= 0) {
    return decodeURI(
      window.location.href.split('query=')[window.location.href.split('query=').length - 1]
    )
  } else {
    return ''
  }
}

export const getFormattedDate = (date) => {
  return new Date(date).toLocaleString();
}