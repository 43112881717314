import React from 'react'
import cookie from 'react-cookies'

export default class InvoiceDetailsTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      invoiceDetails: this.props.invoiceDetails
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    this.setState({ invoiceDetails: nextProps.invoiceDetails })
  }

  getFormattedDate = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  render() {
    return (
      <div>
        <div className="show-product-header show-header">
          <h2>Detalles de factura:</h2>
        </div>
        <hr />
        <div>
          {this.state.invoiceDetails['status'] == 'cancelled' && (
            <div className="show-product-header show-header mb-110">
              <div className="invoice-cancelled-status-label">ANULADA</div>
              <div className="order-client-name">{this.state.invoiceDetails['client_name']}</div>
              <div className="order-client-db_ref">
                Código: {this.state.invoiceDetails['client_db_ref']}
              </div>
            </div>
          )}
          {this.state.invoiceDetails['status'] == 'created' && (
            <div className="show-product-header show-header">
              <div className="order-client-name">{this.state.invoiceDetails['client_name']}</div>
              <div className="order-client-db_ref">
                Código: {this.state.invoiceDetails['client_db_ref']}
              </div>
            </div>
          )}
          <hr />
          <div>
            <div className="show-data">
              <div>
                <b>Número de factura:</b>
              </div>
              <div>{this.state.invoiceDetails['invoice_db_ref']}</div>
            </div>
            <div className="show-data">
              <div>
                <b>Fecha de creación:</b>
              </div>
              <div>{this.getFormattedDate(this.state.invoiceDetails['created_date'])}</div>
            </div>
            <div className="show-data">
              <div>
                <b>Entregador/Repartidor/Conductor</b>
              </div>
              <div>{this.state.invoiceDetails['invoicer_name']}</div>
            </div>
            <div className="show-data">
              <div>
                <b>Productos Facturados:</b>
              </div>
            </div>
            <div className="index-table">
              <table className="table table-hover order-table">
                <thead>
                  <tr>
                    <th>CÓDIGO</th>
                    <th>NOMBRE DEL PRODUCTO</th>
                    <th>CANTIDAD</th>
                    <th>UNIDAD DE PRECIO</th>
                    <th>PESO</th>
                    <th>PRECIO DE EMPAQUE</th>
                    <th>TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.invoiceDetails['details'].map((model, index) => (
                    <tr key={index}>
                      <td>{model['product_db_ref']}</td>
                      <td>{model['product_name']}</td>
                      <td>{model['quantity']}</td>
                      <td>{model['price_unit']}</td>
                      <td>{model['weight']}</td>
                      <td>${Number(model['unit_price']).toFixed(2)}</td>
                      <td>${Number(model['extended_price']).toFixed(2)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan="5"></td>
                    <td>
                      <b>PESO TOTAL</b>
                    </td>
                    <td>{Number(this.state.invoiceDetails['total_weight']).toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td colSpan="5"></td>
                    <td>
                      <b>SUB-TOTAL</b>
                    </td>
                    <td>
                      <b>${Number(this.state.invoiceDetails['sub_total']).toFixed(2)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="5"></td>
                    <td>
                      <b>Total ITBMS</b>
                    </td>
                    <td>
                      <b>${Number(this.state.invoiceDetails['tax_total']).toFixed(2)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="5"></td>
                    <td className="fs-20">
                      <b>TOTAL</b>
                    </td>
                    <td className="fs-20">
                      <b>
                        $
                        {(
                          parseFloat(this.state.invoiceDetails.tax_total) +
                          parseFloat(this.state.invoiceDetails.sub_total)
                        ).toFixed(2)}
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
