import React from 'react'
import cookie from 'react-cookies'
import {
  capitalizeFirstLetterFrom,
  effectiveLocationStr,
  effectiveLocationWithAllowedLocation,
  formatMoney,
  calculateTaxRate
} from '../../helpers/utils'
import { v5 as uuidv5 } from 'uuid'
import { Table } from 'react-bootstrap'

export default class DeliveryDetailsTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activity: this.props.activity || [],
      taxSettings: cookie.load('tax_settings')
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ activity: nextProps.activity })
  }

  getFormattedDate = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  getDiscountPercent = (_price = 0, _discountedPrice = 0) => {
    let price = parseFloat(_price)
    let discountedPrice = parseFloat(_discountedPrice)
    if (discountedPrice === 0) return '-'
    return `${((discountedPrice / price) * 100).toFixed(2)}%`
  }

  getRowSubtotal = (_extendedPrice = 0, _discountedPrice = 0) => {
    let subtotal = 0
    let extendedPrice = parseFloat(_extendedPrice)
    let discountedPrice = parseFloat(_discountedPrice)
    if (!discountedPrice || discountedPrice == 0) {
      subtotal = extendedPrice
    } else {
      subtotal = extendedPrice - discountedPrice
    }
    return subtotal
  }

  calculateSubTotal = () => {
    let total = 0
    this.state.activity.details.map((obj) => {
      const calculatedTaxPrice = obj.calculated_tax_price
        ? obj.calculated_tax_price
        : obj.extended_price
      return (total += parseFloat(calculatedTaxPrice))
    })
    return total
  }

  getWeight = (_weight = 0) => {
    let weight = Number(_weight).toFixed(2)
    if (weight == 0) return 'N/A'
    return weight
  }

  getTotalWeight = () => {
    let tw = Number(this.state.activity['total_weight']).toFixed(2)
    if (tw == 0) return 'N/A'
    return tw
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          {this.props.transactionUuid && (
            <>
              <div className="row show-product-header">
                <div className="col-sm-6">
                  <h3 className="margin-text-subtitle">Detalles de entrega:</h3>
                </div>
              </div>
              <hr />
            </>
          )}
          <div>
            <div className="show-product-header-ubiqua-app show-header">
              <div>
                <div className="order-client-name">{this.state.activity['client_name']}</div>
                <div className="order-client-db_ref">
                  Código: {this.state.activity['client_db_ref']}
                </div>
              </div>
              {/* {this.state.activity?.origin == 'UbiquaApp' && (
                <button className="float-right download-order-button" onClick={this.download}>
                  Descargar devolución
                  <FontAwesomeIcon icon={faArrowCircleDown} />
                </button>
              )} */}
            </div>
            <hr />
            <div>
              <table cellSpacing="10">
                <tbody>
                  <tr>
                    <td>
                      <div className="mr-85 mb-10">
                        <b>Fecha de creación:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">
                        {this.getFormattedDate(this.state.activity['created_date'])}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-10">
                        <b>Número de entrega:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">{this.state.activity['uid']}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-10">
                        <b>Vendedor:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">{this.state.activity['salesman_name']}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-10">
                        <b>Tipo de cliente:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">
                        {capitalizeFirstLetterFrom(this.state.activity['client_payment_type'])}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-10">
                        <b>Número de factura:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">
                        {this.state.activity['delivery_reference'] || '-'}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-10">
                        <b>Referencia del cobro:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">
                        <a
                          className="ubiqua-link"
                          target="_blank"
                          href={
                            '/cobros/' +
                            uuidv5(
                              `${cookie.load('business_tenant_uid')}~${
                                this.state.activity['payment_reference']
                              }`,
                              'd90e460b-8136-48a0-8d50-760d2fec9a39'
                            )
                          }
                        >
                          {this.state.activity['payment_reference']}
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-10">
                        <b>Dentro de geocerca:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">
                        {effectiveLocationWithAllowedLocation(
                          this.state.activity['effective_location'],
                          this.state.activity['has_allowed_location']
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <div className="show-data-details">
                <div>
                  <b>Productos:</b>
                </div>
              </div>

              <Table hover responsive>
                <thead className="table-header-gray">
                  <tr>
                    <th>CÓDIGO</th>
                    <th>NOMBRE DEL PRODUCTO</th>
                    <th>UNIDAD DE VENTA</th>
                    <th>CANTIDAD</th>
                    <th>PRECIO</th>
                    <th className="text-center">SUB-TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.activity['details'].map((model, index) => (
                    <tr key={index}>
                      <td>{model['product_db_ref']}</td>
                      <td>{model['product_name']}</td>
                      <td>{model['unit']}</td>
                      <td>{parseInt(model['quantity'])}</td>
                      <td>{formatMoney(Number(model['price']))}</td>
                      <td className="text-center">
                        {formatMoney(Number(model['extended_price']))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <hr className="mt-0" />
              <div className="totals-container-delivery">
                <div className="totals-card">
                  <div className="totals-line">
                    <div>
                      <b>Recibido por:</b>
                    </div>
                    <div>{this.state.activity.signature_name}</div>
                  </div>
                  <img
                    width={400}
                    src={`data:image/bmp;base64,${this.state.activity.signature_b64}`}
                    alt="Firma"
                  />
                </div>
                <div className="totals-card">
                  <div className="totals-line">
                    <div>
                      <b>SUB-TOTAL:</b>
                    </div>
                    <div>
                      {this.state.activity.subtotal !== null
                        ? formatMoney(this.state.activity.subtotal)
                        : formatMoney(this.calculateSubTotal())}
                    </div>
                  </div>
                  {this.state.taxSettings['tax_rate_2'] && (
                    <div className="totals-line">
                      <div>
                        <b>{this.state.taxSettings['tax_rate_2']}:</b>
                      </div>
                      <div>
                        {this.state.activity.taxes_2 !== null
                          ? formatMoney(this.state.activity.taxes_2)
                          : formatMoney(0)}
                      </div>
                    </div>
                  )}
                  <div className="totals-line">
                    <div>
                      <b>{this.state.taxSettings['tax_rate']}:</b>
                    </div>
                    <div>
                      {this.state.activity.taxes !== null
                        ? formatMoney(this.state.activity.taxes)
                        : formatMoney(this.calculateTaxRate())}
                    </div>
                  </div>
                  <div className="totals-line">
                    <div>
                      <h4>
                        <b>TOTAL:</b>
                      </h4>
                    </div>
                    <div>{formatMoney(this.state.activity.total)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
