import React, { Component } from 'react'

import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import SalesmanCreateNewView from './SalesmanCreateNewView'
import * as keyboardCodes from '../constants/keyboardCodes'
import { DISCARD_CHANGES_CONFIRMATION } from '../constants/appMessages'

export default class SalesmanIndexApp extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isFetching: true,
      searchPlaceholder: 'Buscar vendedores por código o nombre',
      title: 'Vendedor nuevo'
    }
  }

  componentDidMount() {
    this.setState({ isFetching: false })
  }

  passEditState = (editStateVal) => {
    this.setState({ isEditing: editStateVal })
  }

  handleSearch = (event) => {
    if (event.keyCode === keyboardCodes.ENTER_KEY && window.confirm(DISCARD_CHANGES_CONFIRMATION)) {
      event.preventDefault()
      const query = event.target.value
      if (query !== '') {
        window.location = `/vendedores?query=${query}`;
      }
    }
  }

  render() {
    return (
      <div>
        <div>
          <div onKeyDown={this.handleSearch}>
            <TopBar
              searchPlaceholder={this.state.searchPlaceholder}
              userName={this.state.userName}
            />
          </div>
          <div onClick={this.handlePaginationClick}>
            <SalesmanCreateNewView
              data={this.state.data}
              isFetching={this.state.isFetching}
              title={this.state.title}
            />
          </div>
        </div>
        <div>
          <NavBar activeItem="Equipo de campo" isEditing={true} />
        </div>
      </div>
    )
  }
}
