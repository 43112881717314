import React, { useState, useEffect } from 'react'
import TabWithDropdown from '../../layout/TabWithDropdown'
import PosDetailsTab from './PosDetailsTab'

const PosDetailsView = (props) => {
  const [showSuccessfulMessage, setShowSuccessfulMessage] = useState(false)
  const [alertState, setAlertState] = useState('alert alert-success')
  const [createText, setCreateText] = useState('Guardar')
  const [isPosting, setIsPosting] = useState(false)
  const [activity, setActivity] = useState(props.defaultDetails)

  useEffect(() => {
    setActivity(props.defaultDetails)
  }, [props.defaultDetails])

  useEffect(() => {
    setQueryParams()
  }, [])

  useEffect(() => {
    showSuccessfulMessageCreation()
  }, [])

  const showSuccessfulMessageCreation = () => {
    setShowSuccessfulMessage(document.referrer.includes('nuevo_pedido'))
  }

  const hiddenAlert = () => {
    setShowSuccessfulMessage(false)
  }

  const disableButton = () => {
    setCreateText('Guardando...')
    setIsPosting(true)
  }

  const enableButton = () => {
    setCreateText('Guardar')
    setIsPosting(false)
  }

  const setQueryParams = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const success = urlParams.get('success') || ''
    if (success) {
      setShowSuccessfulMessage(true)
    }
  }

  return (
    <div>
      <div className={`main-view ${props.collapsed ? 'collapsed' : ''}`}>
        {showSuccessfulMessage && (
          <div className="alert alert-success d-flex justify-content-between align-items-center">
            <strong className="success-message">
              La tarea en punto de venta fue creada exitosamente
            </strong>
            <button
              type="button"
              className="btn-close"
              onClick={hiddenAlert}
              aria-label="Close"
            ></button>
          </div>
        )}
        <div className="main-show-header">
          <h2>Tareas en punto de venta: {activity?.short_uuid}</h2>
        </div>
        <div className="index-table">
          <div className="show-area">
            <div>
              <div>
                <div>
                  {(activity?.status === 'received' || activity?.status === 'sent') && (
                    <ul className="nav nav-tabs">
                      <TabWithDropdown
                        name="Ejecución en punto de venta"
                        activeTab={'Ejecución en punto de venta'}
                        items={[]}
                      />
                    </ul>
                  )}
                </div>
                <div>
                  {activity && (
                    <PosDetailsTab activity={activity} transactionUuid={props.details.uuid} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PosDetailsView
