import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import TopBar from './layout/TopBar'
import NavBar from './layout/NavBar'
import cookie from 'react-cookies'

const ProtectedRoute = ({
  component: Component,
  visible,
  redirect,
  ...rest
}) => {
  const token = cookie.load('token');

  if (!token) {
    window.location.href = '/logout';
    return null;
  }

  if (visible == null || redirect === false) {
    return (
      <div>
        <TopBar />
        <div className="main-view">
          <div className="show-title">
            <p>Obteniendo datos ...</p>
          </div>
        </div>
        <NavBar />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        visible ? <Component {...props} /> : <Redirect to={redirect} />
      )}
    />
  );
};

export default ProtectedRoute;
