import React from 'react';
import SurveyImageTask from './../forms/surveys/SurveyImageTask';
import SurveyTextTask from './../forms/surveys/SurveyTextTask';
import SurveyNumberTask from './../forms/surveys/SurveyNumberTask'; 
import SurveyComponentMultiple from './../forms/surveys/SurveyComponentMultiple'; 
import SurveyComponentUnique from './../forms/surveys/SurveyComponentUnique'
export default function PosDetailsSurvey({ data }) {
  return (
    <div>
      <div className=" ">
        <div>
        <p className="details-title">Detalles de la encuesta:</p>
        </div>
        <hr className="" />

        {data.map((survey, index) => (
          <div key={index}>
            {renderSurveyComponent(survey.type, survey,
              { //CSS Details Surveys
                container: 'survey-text-task-container-details',
                headers: 'headers-details',
                formsgroup:'form-group-details blue-border-input-details',
                response:'response-details response-details-border response-surveys-details',
                responseplace: 'response-surveys-details form-control-details',
                fontsquestion: 'question-input-details form-control-details',
                fontsdescriptions :'description-input-details form-control-details',
                checkbox:'chkrd-and-input-details blue-border-input-details',
                checkradtext:'form-control form-control-details',
                surveysdetails:'details-surveys',
                optionContainer: 'options-surveys',
              },
              index,
              )}
          </div>
        ))}
      </div>
    </div>
  );
}

function renderSurveyComponent(type, data, classNames, index) {
  switch (type) {
    case 'text':
      return <div>
        <SurveyTextTask 
          data={data} 
          classNames={classNames}
          showOptionsButton={false} 
          disableQuestion= {true} 
          isDetailsSurvey= {true} 
          showDescriptionsDetails= {true}
          questionNumber={index + 1} 
          showresponseDetails= {true}
        />
          </div>
    case 'number':
      return <div>
        <SurveyNumberTask 
          data={data} 
          classNames={classNames}
          showOptionsButton={false} 
          disableQuestion= {true} 
          isDetailsSurvey= {true} 
          showDescriptionsDetails= {true}
          questionNumber={index + 1}
          showresponseDetails= {true}
          />
        </div>
    case 'image':
      return <div>
      <SurveyImageTask 
        data={data} 
        classNames={classNames}
        showOptionsButton={false} 
        disableQuestion= {true} 
        isDetailsSurvey= {true} 
        showDescriptionsDetails= {true}
        questionNumber={index + 1} 
        showresponseDetails= {true}
        />
        </div>
    case 'multiplechk':
      return <div>
      <SurveyComponentMultiple 
        data={data}
        classNames={classNames}
        showOptionsButton={false} 
        disableQuestion= {true} 
        isDetailsSurvey= {true} 
        showDescriptionsDetails= {true}
        questionNumber={index + 1} 
        showresponseDetails= {true}
        />
        </div>
    case 'multiplerb':
      return <div>
      <SurveyComponentUnique 
        data={data}
        classNames={classNames}
        showOptionsButton={false} 
        disableQuestion= {true} 
        isDetailsSurvey= {true} 
        showDescriptionsDetails= {true}
        questionNumber={index + 1} 
        showresponseDetails= {true}
        />
        </div>
    default:
      return null; 
  }
}