import React, { Component, Fragment } from 'react'
import cookie from 'react-cookies'

import * as apiStatusCodes from '../constants/apiStatusCodes'
import * as apiService from '../services/apiService'
import { SPACE_KEY } from '../constants/keyboardCodes'
import { DISCARD_CHANGES_CONFIRMATION } from '../constants/appMessages'

export default class SalesmanDetailsTab extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEditing: false,
      originalData: props.userData,
      userData: {},
      showError: false,
      isApiErrorVisible: false,
      apiErrorText: ''
    }
  }

  componentDidMount() {
    this.setState({
      userData: { ...this.props.userData }
    })
  }

  getFormattedDate = (date) => {
    const d = new Date(date)
    return d.toLocaleString()
  }

  handleStartEditMode = (event) => {
    this.setState({
      isEditing: true
    })
    this.props.changeEditMode(true)
  }

  handleCancelEdit = (event) => {
    if (window.confirm(DISCARD_CHANGES_CONFIRMATION)) {
      this.setState({
        userData: this.state.originalData,
        isEditing: false
      })

      this.props.changeEditMode(false)
    } else {
      event.preventDefault()
    }
  }

  handleKeydown = (e) => {
    // Prevent delivery_user entering spaces in input.
    // Used in email field only.
    if (e.keyCode === SPACE_KEY) e.preventDefault()
  }

  handleChange = (event) => {
    const property = event.target.getAttribute('name')
    let propertyValue

    if (event.target.type === 'select-one') {
      propertyValue = event.target.value === 'true' ? true : false
    } else {
      propertyValue = event.target.value
    }

    const newUserData = { ...this.state.userData }
    newUserData[property] = propertyValue

    this.setState({ userData: newUserData })
  }

  handleEditSalesmanSubmit = (event) => {
    event.preventDefault()
    this.setState({ isFetching: true })
    this.props.hideApiError()

    const params = {
      ...this.state.userData,
      business_id: '1'
    }

    apiService
      .updateSalesmanDetails(this.state.userData.id, params)
      .then((response) => apiService.handleErrors(response))
      .then(() => {
        this.setState({ isFetching: false, isEditing: false })
        this.props.changeEditMode(false)
        this.props.updateSalesmanDetails()
      })
      .catch((error) => {
        console.log(error)
        if (error.status === apiStatusCodes.CONFLICT) {
          error.json().then((error) => {
            this.props.showApiError(error.message)
            this.setState({
              isFetching: false
            })
          })
        } else if (error.status === apiStatusCodes.UNAUTHORIZED) {
          cookie.remove('token', { path: '/' })
          window.location = '/'
        }
      })
  }

  render() {
    const { userData } = this.state
    const { id } = this.props

    return (
      <div className="salesman-details">
        <form data-userid={id} onSubmit={this.handleEditSalesmanSubmit} className="edit-salesman">
          <div className="show-header salesman">
            <h2>Detalles del vendedor:</h2>
            <div className="show-buttons">
              {!this.state.isEditing && (
                <button type="submit" className="save-button" onClick={this.handleStartEditMode}>
                  Actualizar detalles
                </button>
              )}
              {this.state.isEditing && (
                <div>
                  <button className="cancel-button" onClick={this.handleCancelEdit}>
                    Cancelar
                  </button>
                  <input className="save-button" value="Guardar" type="submit" />
                </div>
              )}
            </div>
          </div>
          <hr></hr>
          <div className="show-data">
            <div>Activo</div>
            {!this.state.isEditing && <div>{userData.active ? 'Si' : 'No'}</div>}
            {this.state.isEditing && (
              <div>
                <label>
                  <select
                    value={userData.active}
                    name="active"
                    onChange={this.handleChange}
                    className="edit-new-salesman__input"
                  >
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </select>
                </label>
              </div>
            )}
          </div>
          <div className="show-data">
            <div>Nombre de Usuario</div>
            {!this.state.isEditing && <div>{userData.name}</div>}
            {this.state.isEditing && (
              <div>
                <label>
                  <input
                    value={userData.name}
                    type="text"
                    name="name"
                    onChange={this.handleChange}
                    className="edit-new-salesman__input"
                    required
                  ></input>
                </label>
              </div>
            )}
          </div>
          <div className="show-data">
            <div>Número de rutero/Código de vendedor</div>
            <div>{userData.db_ref}</div>
          </div>
          <div className="show-data">
            <div>Correo electrónico</div>
            {!this.state.isEditing && <div>{userData.email}</div>}
            {this.state.isEditing && (
              <div>
                <label>
                  <input
                    value={userData.email}
                    type="email"
                    pattern="^([0-9]{9})|([A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3})$"
                    name="email"
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeydown}
                    className="edit-new-salesman__input"
                    required
                  ></input>
                </label>
              </div>
            )}
          </div>
          <div className="show-data">
            <div>Celular (optional)</div>
            {!this.state.isEditing && (
              <div>{userData.mobile ? userData.mobile : 'No disponible'}</div>
            )}
            {this.state.isEditing && (
              <div>
                <label>
                  <input
                    value={userData.mobile ? userData.mobile : ''}
                    type="number"
                    name="mobile"
                    onChange={this.handleChange}
                    className="edit-new-salesman__input"
                  ></input>
                </label>
              </div>
            )}
          </div>
          {!this.state.isEditing && (
            <div className="show-data">
              <div>Creado</div>
              <div>{this.getFormattedDate(userData.created_at)}</div>
            </div>
          )}
          {!this.state.isEditing && (
            <div className="show-data">
              <div>Actualizado</div>
              <div>{this.getFormattedDate(userData.updated_at)}</div>
            </div>
          )}
        </form>
        {this.state.showError && (
          <div className="red">
            Por favor llene los campos obligatorios con * para poder proceder
          </div>
        )}
      </div>
    )
  }
}
