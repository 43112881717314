import React from 'react'
import _ from 'lodash'
import { PaginationConsumer } from '../global_context/PaginationContext'

export default class RealPagination extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pager: {},
      page: 1
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    // set page if items array isn't empty
    if (this.props.totalCount) {
      this.setPage(this.state.page)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // reset page if items array has changed
    if (this.props.totalCount !== prevProps.totalCount) {
      this.setPage(this.state.page)
    }
  }

  setPage(page) {
    let pager = this.state.pager
    const totalCount = this.props.totalCount

    if (page < 1 || page > pager.totalPages) {
      return
    }
    // get new pager object for specified page
    pager = RealPagination.getPager(totalCount, page)

    // get new page of items from items array
    //let pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

    // update state
    this.setState({ pager: pager })

    // call change page function in parent component
    //this.props.onChangePage(pager);
  }

  static getPager(totalItems, currentPage, pageSize) {
    // default to first page
    // eslint-disable-next-line no-param-reassign
    currentPage = currentPage || 1

    // default page size is 10
    // eslint-disable-next-line no-param-reassign
    pageSize = pageSize || 25

    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize)

    let startPage, endPage
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1
      endPage = totalPages
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1
        endPage = 10
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9
        endPage = totalPages
      } else {
        startPage = currentPage - 5
        endPage = currentPage + 4
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1)

    // create an array of pages to ng-repeat in the pager control
    const pages = _.range(startPage, endPage + 1)

    // return object with all pager properties required by the view
    return {
      initPage: 1,
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    }
  }

  render() {
    const pager = this.state.pager
    if (!pager.pages || pager.pages.length <= 1) {
      // don't display pager if there is only 1 page
      return null
    }
    return (
      <PaginationConsumer>
        {({ getCurrentPage }) => (
          <div className="navbar fixed-bottom">
            <ul className="pagination">
              <li className={pager.currentPage === 1 ? 'disabled' : 'page-item'}>
                <a
                  className="cursor-pointer"
                  aria-label="Previous"
                  onClick={() => {
                    if (pager.currentPage != 1) {
                      this.setPage(pager.initPage)
                      getCurrentPage(pager.initPage)
                    }
                  }}
                >
                  <span aria-hidden="true">«</span>
                  <span className="sr-only">Previous</span>
                </a>
              </li>

              <li className={pager.currentPage === 1 ? 'disabled' : ''}>
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    pager.currentPage = pager.currentPage - 1
                    this.setPage(pager.currentPage)
                    if (pager.currentPage > 0) {
                      getCurrentPage(pager.currentPage)
                    }
                  }}
                >
                  Anterior
                </a>
              </li>

              {pager.pages.map((page, index) => (
                <li key={index} className={pager.currentPage === page ? 'active' : ''}>
                  <a
                    className="cursor-pointer"
                    onClick={() => {
                      this.setPage(page)
                      pager.currentPage = page
                      getCurrentPage(pager.currentPage)
                    }}
                  >
                    {page}
                  </a>
                </li>
              ))}
              <li className={pager.currentPage === pager.totalPages ? 'disabled' : ''}>
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    pager.currentPage = pager.currentPage + 1
                    this.setPage(pager.currentPage)
                    if (pager.currentPage <= pager.totalPages) {
                      getCurrentPage(pager.currentPage)
                    }
                  }}
                >
                  Siguiente
                </a>
              </li>

              <li className={pager.currentPage === pager.totalPages ? 'disabled' : 'page-item'}>
                <a
                  className="cursor-pointer"
                  aria-label="Next"
                  onClick={() => {
                    pager.currentPage = pager.currentPage + 1
                    this.setPage(pager.totalPages)
                    if (pager.currentPage <= pager.totalPages) {
                      getCurrentPage(pager.totalPages)
                    }
                  }}
                >
                  <span aria-hidden="true">»</span>
                  <span className="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </div>
        )}
      </PaginationConsumer>
    )
  }
}
