import React, { useState, useEffect } from 'react'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import ShowOrderView from './ShowOrderView'
import cookie from 'react-cookies'
import { DispatchConsumer, DispatchProvider } from './context/OrderContext'
import { useParams } from 'react-router-dom'

const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
const BACKEND_URL = USE_BUSINESS_URL
  ? `${process.env.REACT_APP_BACKEND_URL}/businesses/${cookie.load('business_id')}`
  : process.env.REACT_APP_BACKEND_URL

const ShowOrderApp = () => {
  const { id } = useParams()
  const [searchPlaceholder, setSearchPlaceholder] = useState(
    'Buscar pedidos por código o nombre de cliente'
  )
  const [isFetching, setIsFetching] = useState(true)
  const [query, setQuery] = useState('')
  const [details, setDetails] = useState(null)
  const [defaultOrderUuid, setDefaultOrderUuid] = useState(id)
  const [defaultOrderDetails, setDefaultOrderDetails] = useState(id)
  const [collapsed, setCollapsed] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetchDefaultOrderDetails()
    const collapsedState = localStorage.getItem('collapsedState') === 'true'
    setCollapsed(collapsedState)
  }, [defaultOrderUuid])

  const fetchDefaultOrderDetails = () => {
    fetch(`${BACKEND_URL}/orders/${defaultOrderUuid}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        if (response.status === 404) {
          return {
            error: true,
            message: 'No se encontró el pedido'
          }
        }
        return response.json()
      })
      .then((data) => {
        if (data.error) {
          setError(data.message)
          setDetails({ ...details })
          setIsFetching(false)
        } else {
          setDetails({ ...details, status: data?.status })
          setDefaultOrderDetails(data)
          setIsFetching(false)
        }
      })
      .catch((e) => {
        console.log(e)
        Sentry.captureException(e)
      })
  }

  const handleSearch = (event, isEditing) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      const query = event.target.value
      if (query !== '') {
        if (isEditing) {
          const isConfirmation = window.confirm(
            'Esta seguro que quiere continuar? Los cambios editados no seran guardados'
          )
          if (isConfirmation === true) {
            window.location = `/pedidos?query=${query}`
          }
        } else {
          window.location = `/pedidos?query=${query}`
        }
        event.target.value = ''
      }
    }
  }

  const handleToggleCollapse = (collapsed) => {
    setCollapsed(collapsed)
    localStorage.setItem('collapsedState', collapsed)
  }

  return (
    <div>
      {isFetching === false ? (
        <div>
          <DispatchProvider
            componentState={{ id }}
            details={details}
            orderDetails={details?.orderDetails}
            defaultOrderDetails={defaultOrderDetails}
          >
            <DispatchConsumer>
              {({ isEditing }) => (
                <div>
                  <div>
                    <div onKeyDown={(e) => handleSearch(e, isEditing)}>
                      <TopBar
                        searchPlaceholder={searchPlaceholder}
                        userName={details?.userName}
                        onToggleCollapse={handleToggleCollapse}
                        collapsed={collapsed}
                      />
                    </div>
                    <div>
                      {error ? (
                        <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
                          <div className="main-show-header">
                            <p>{error}</p>
                          </div>
                        </div>
                      ) : (
                        <ShowOrderView
                          details={details}
                          position={id}
                          defaultOrderDetails={defaultOrderDetails}
                          collapsed={collapsed}
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <NavBar activeItem="Pedidos" isEditing={isEditing} collapsed={collapsed} />
                  </div>
                </div>
              )}
            </DispatchConsumer>
          </DispatchProvider>
        </div>
      ) : (
        <div>
          <TopBar
            searchPlaceholder={searchPlaceholder}
            userName={details?.userName}
            onToggleCollapse={handleToggleCollapse}
            collapsed={collapsed}
          />
          <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
            <div className="show-title">
              <p>Obteniendo datos ....</p>
            </div>
          </div>
          <div>
            <NavBar activeItem="Pedidos" isEditing={false} collapsed={collapsed} />
          </div>
        </div>
      )}
    </div>
  )
}

export default ShowOrderApp
