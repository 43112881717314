import React from 'react'
import cookie from 'react-cookies'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      wrongCredentialsMessage: false,
      successfulReset: false,
      failedRequest: false
    }
  }

  handleChange(event) {
    const obj = {}
    obj[event.target.name] = event.target.value
    this.setState(obj)
  }

  hideMessage = () => {
    this.setState({ wrongCredentialsMessage: false, successfulReset: false, failedRequest: false })
  }

  sendResetPasswordInstructions = (event) => {
    event.preventDefault()
    fetch(`${BACKEND_URL}/users/password`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user: {
          email: this.state.email
        }
      })
    })
      .then((response) => {
        if (response.status === 422) {
          this.setState({
            failedRequest: false,
            successfulReset: false,
            wrongCredentialsMessage: true
          })
          setTimeout(this.hideMessage, 10000)
        } else if (response.status === 200 || response.status === 201) {
          this.setState({
            failedRequest: false,
            successfulReset: true,
            wrongCredentialsMessage: false
          })
          setTimeout(this.hideMessage, 10000)
        } else {
          this.setState({
            failedRequest: true,
            successfulReset: false,
            wrongCredentialsMessage: false
          })
          setTimeout(this.hideMessage, 10000)
        }
        return response.json()
      })
      .then((json) => this.evaluateResponse(json))
      .catch((e) => console.log(e))
  }

  evaluateResponse = (json) => {
    console.log(json)
  }

  render() {
    return (
      <div>
        {this.state.failedRequest === true && (
          <div className="alert alert-danger" role="alert">
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <strong>Error: Hubo un error en la solicitud. Vuelva a intentarlo.</strong>
          </div>
        )}
        {(this.state.successfulReset === true || this.state.wrongCredentialsMessage === true) && (
          <div className="alert alert-success" role="alert">
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <strong>
              Se enviará un correo con las instrucciones para restablecer su contraseña si la cuenta
              asociada a este correo electrónico existe.
            </strong>
          </div>
        )}
        <div className="login-container">
          <div className="login-section">
            <img alt="UBIQUA" src="/ubiqua-logo-top.png" />
            <h2 className="login-header">
              Ingresar correo para enviar detalles de cambio de contraseña:
            </h2>
            <form onSubmit={this.sendResetPasswordInstructions.bind(this)}>
              <div>
                <div className="login-input">
                  <input
                    onChange={this.handleChange.bind(this)}
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="Correo Electronico"
                    required
                  />
                </div>
                <div className="form-check">
                  <input className="login-button" value="Enviar" type="submit" />
                </div>
                <br />
                <br />
                <a className="change-password-link" href="/">
                  Iniciar Sesión
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
