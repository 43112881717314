import React, { createContext, useReducer, useState } from 'react'
import { AssortedBonusPromoReducer } from './AssortedBonusPromoReducer'

export const AssortedBonusPromoContext = createContext()

const AssortedBonusPromoContextProvider = ({ children }) => {
  const [assortedBonusProducts, dispatch] = useReducer(AssortedBonusPromoReducer, [], () => [])

  const [assortedGiftBonusProductQuantity, setAssortedGiftBonusProductQuantity] = useState(0)

  const addAssortedBonusProduct = (payload) => {
    dispatch({ type: 'ADD_ASSORTED_BONUS_PRODUCT', payload })
  }

  const addAssortedBonusProductQuantity = (payload) => {
    setAssortedGiftBonusProductQuantity(payload.bonusQuantity)
  }

  const removeAssortedBonusProduct = (payload) => {
    dispatch({ type: 'REMOVE_ASSORTED_BONUS_PRODUCT', payload })
  }

  const clearAssortedBonusProducts = () => {
    dispatch({ type: 'CLEAR_ASSORTED_BONUS_PRODUCTS' })
  }

  const changeBonusPackageAssortedBonusProducts = (payload) => {
    dispatch({ type: 'CHANGE_BONUS_PACKAGE_ASSORTED_BONUS_PRODUCTS', payload })
  }

  const changeLimitToAssortedBonus = (payload) => {
    dispatch({ type: 'CHANGE_LIMIT_TO_ASSORTED_BONUS', payload})
  }

  const contextValues = {
    addAssortedBonusProduct,
    removeAssortedBonusProduct,
    addAssortedBonusProductQuantity,
    clearAssortedBonusProducts,
    changeBonusPackageAssortedBonusProducts,
    changeLimitToAssortedBonus,
    assortedGiftBonusProductQuantity,
    assortedBonusProducts
  }

  return (
    <AssortedBonusPromoContext.Provider value={contextValues}>{children}</AssortedBonusPromoContext.Provider>
  )
}

export default AssortedBonusPromoContextProvider
