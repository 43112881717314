import React, { useState, useEffect, createContext } from 'react'
import cookie from 'react-cookies'
import { useHistory } from 'react-router-dom'

const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
const BACKEND_URL = USE_BUSINESS_URL
  ? `${process.env.REACT_APP_BACKEND_URL}/businesses/${cookie.load('business_id')}`
  : process.env.REACT_APP_BACKEND_URL

const ClientContext = createContext()

const ClientProvider = ({ children }) => {
  const [isFetching, setIsFetching] = useState(true)
  const [isEditClient, setIsEditClient] = useState(false)
  const [clientDetailsData, setClientDetailsData] = useState(null)
  const [clientEditDetailsData, setClientEditDetailsData] = useState('')
  const [editingClient, setEditingClient] = useState(false)
  const [addingClient, setAddingClient] = useState(false)
  const [failedCreation, setFailedCreation] = useState(false)
  const [failedCreationInfo, setFailedCreationInfo] = useState('')
  const [activeTab, setActiveTab] = useState('Detalles')
  const [clientForm, setClientForm] = useState({
    client_group_id: '',
    sales_channel_id: '',
    payment_type_id: '',
    credit_term_id: '',
    province_id: '',
    district_id: '',
    town_id: '',
    price_list_id: '',
    db_ref: '',
    active: '',
    taxpayer_id: '',
    taxpayer_verificator_digit: '',
    business_name: '',
    commercial_name: '',
    customer_name_db_ref: '',
    credit_limit: '',
    legal_representative: '',
    billing_contact_name: '',
    billing_contact_email: '',
    billing_contact_phone: '',
    contact_name: '',
    contact_email: '',
    contact_phone: '',
    city: '',
    street: '',
    location_description: '',
    geographical_latitude: '',
    geographical_longitud: ''
  })

  const history = useHistory()
  const urlParts = window.location.href.split('/')
  const id = urlParts[urlParts.length - 2]

  useEffect(() => {
    fetchDetailsFromServer()
  }, [])

  const fetchDetailsFromServer = () => {
    setIsFetching(true)

    fetch(`${BACKEND_URL}/clients/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookie.load('token')}`
      }
    })
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => {
        setClientDetailsData(data)
        setIsFetching(false)
        setDefaultParams(data)
      })
      .catch((e) => console.log(e))

    fetchDataForEdit()
  }

  const setDefaultParams = (data) => {
    setClientForm({
      client_group_id: data['client_group'] ? data['client_group']['id'] : '',
      sales_channel_id: data['sales_channel'] ? data['sales_channel']['id'] : '',
      payment_type_id: data['payment_type'] ? data['payment_type']['id'] : '',
      credit_term_id: data['credit_term'] ? data['credit_term']['id'] : '',
      province_id: data['province'] ? data['province']['id'] : '',
      district_id: data['district'] ? data['district']['id'] : '',
      town_id: data['town'] ? data['town']['id'] : '',
      price_list_id: data['price_list'] ? data['price_list']['id'] : '',
      db_ref: data['db_ref'],
      active: data['active'],
      taxpayer_id: data['taxpayer_id'],
      taxpayer_verificator_digit: data['taxpayer_verificator_digit'],
      business_name: data['business_name'],
      commercial_name: data['commercial_name'],
      customer_name_db_ref: data['customer_name_db_ref'],
      credit_limit: data['credit_limit'],
      legal_representative: data['legal_representative'],
      billing_contact_name: data['billing_contact_name'],
      billing_contact_email: data['billing_contact_email'],
      billing_contact_phone: data['billing_contact_phone'],
      contact_name: data['contact_name'],
      contact_email: data['contact_email'],
      contact_phone: data['contact_phone'],
      city: data['city'],
      street: data['street'],
      location_description: data['location_description'],
      geographical_latitude: data['geographical_latitude'],
      geographical_longitud: data['geographical_longitud']
    })
  }

  const fetchDataForEdit = () => {
    fetch(`${BACKEND_URL}/businesses/${cookie.load('business_id')}/clients/new`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => setClientEditDetailsData(data))
      .catch((e) => console.log(e))
  }

  const createOrUpdateClient = (event) => {
    if (event.target.value === 'Actualizar cliente') {
      setIsEditClient(true)
      setEditingClient(true)
      passEditState(true)
    } else if (event.target.value === 'Cancelar') {
      setIsEditClient(false)
      setEditingClient(false)
      passEditState(false)
    }
  }

  const passEditState = (val) => {
    setIsEditClient(val)
  }

  const handleSubmitEditClient = (event) => {
    event.preventDefault()
    const url = USE_BUSINESS_URL
      ? `${BACKEND_URL}/businesses/${cookie.load('business_id')}/clients/${id}`
      : `${BACKEND_URL}/clients/${id}`

    fetch(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      },
      body: JSON.stringify(clientForm)
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => evaluateResponse(obj))
      .catch((e) => console.log(e))
  }

  const evaluateResponse = (obj) => {
    switch (obj.status) {
      case 422:
        setFailedCreation(true)
        break
      case 409:
        setFailedCreationInfo(
          'No se puede crear cliente. Código ya esta en uso. Verifique los datos del cliente'
        )
        setFailedCreation(true)
        break
      case 404:
        setFailedCreationInfo('Servidor fuera de servicio')
        setFailedCreation(true)
        break
      case 500:
        setFailedCreationInfo('Error interno en el sistema')
        setFailedCreation(true)
        break
      default:
        const data = obj.body
        window.location = `/clientes/${data['id']}/detalles`
        break
    }
  }

  const handleChange = (event) => {
    setClientForm({
      ...clientForm,
      [event.target.name]: event.target.value
    })
  }

  return (
    <ClientContext.Provider
      value={{
        clientData: clientDetailsData,
        clientEditDetailsData: clientEditDetailsData,
        isEditClient: isEditClient,
        isFetching: isFetching,
        failedCreation: failedCreation,
        failedCreationInfo: failedCreationInfo,
        handleChange: handleChange,
        editingClient: editingClient,
        handleSubmitEditClient: handleSubmitEditClient,
        activeTab: activeTab,
        passEditState: passEditState,
        handleClickButton: createOrUpdateClient
      }}
    >
      {children}
    </ClientContext.Provider>
  )
}

const ClientConsumer = ClientContext.Consumer

export { ClientProvider, ClientConsumer, ClientContext }
