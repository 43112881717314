import React, { useState, useEffect } from 'react'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import ShowClientTab from './tabs/ShowClientTab'
import ShowEcommerceClientTab from './tabs/ShowEcommerceClientTab'
import { ClientConsumer } from './context/ClientContext'
import { GlobalConsumer, GlobalProvider } from './context/GlobalContext'
import UserHeader from './components/UserHeader'
import { Link } from 'react-router-dom'
import { Route, useHistory } from 'react-router-dom'
import { ClientEcommerceProvider } from './context/ClientEcommerceContext'

const ShowClientApp = ({ match, client, editForm, successCreate, successCreateForNewClient }) => {
  const [state, setState] = useState({
    dbRef: '',
    clientName: '',
    alertState: 'alert alert-success',
    id: client,
    activeTab: 'Detalles',
    searchPlaceholder: 'Buscar clientes por código o nombre',
    isEditing: false,
    editingClient: false,
    addingClient: false,
    editGlobal: false,
    collapsed: false
  })

  const history = useHistory()

  useEffect(() => {
    const collapsed = localStorage.getItem('collapsedState') === 'true'
    setState((prevState) => ({ ...prevState, collapsed }))
  }, [])

  useEffect(() => {
    setState((prevState) => ({ ...prevState, editGlobal: editForm }))
  }, [editForm])

  const handleSearch = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      const query = event.target.value
      if (query !== '') {
        if (state.isEditClient) {
          const val = window.confirm(
            'Esta seguro que quiere continuar? Los cambios editados no seran guardados'
          )
          if (val === true) {
            window.location = `/clientes?query=${query}`
          } else {
            window.location = `/clientes?query=${query}`
          }
        }
        event.target.value = ''
      }
    }
  }

  const hiddenAlert = () => {
    setState((prevState) => ({ ...prevState, alertState: 'alert alert-success hidden' }))
  }

  const handleToggleCollapse = (collapsed) => {
    setState(
      (prevState) => ({ ...prevState, collapsed }),
      () => {
        localStorage.setItem('collapsedState', collapsed)
      }
    )
  }

  const location = window.location.href.split('/')[window.location.href.split('/').length - 1]

  return (
    <ClientConsumer>
      {({ clientData, isFetching, isEditClient }) => (
        <GlobalProvider stateEdit={state.editGlobal} globalEdit={isEditClient} location={location}>
          <GlobalConsumer>
            {({ handleTabClick, isEditing, globalEdit, activeTab }) => (
              <>
                <div onKeyDown={handleSearch}>
                  <UserHeader isFormEditing={globalEdit} />
                </div>
                <TopBar onToggleCollapse={handleToggleCollapse} collapsed={state.collapsed} />
                <div className={`main-view ${state.collapsed ? 'collapsed' : ''}`}>
                  {successCreateForNewClient && (
                    <div className={state.alertState} role="alert">
                      <button
                        type="button"
                        className="btn-close"
                        onClick={hiddenAlert}
                        aria-label="Close"
                      ></button>
                      <strong className="success-message">
                        El cliente nuevo fue creado exitosamente.
                      </strong>
                    </div>
                  )}
                  <div className="main-show-header">
                    <h2>
                      {isFetching
                        ? ''
                        : `Cliente: ${clientData.db_ref} - ${clientData.customer_name_db_ref} `}
                    </h2>
                  </div>
                  {!isFetching && (
                    <div className="index-table">
                      <div className="show-area">
                        <div>
                          <ul className="nav nav-tabs">
                            <li className="nav-item">
                              <a
                                className={`nav-link ${activeTab === 'Detalles' ? 'active' : 'inactive-tab'} cursor-pointer`}
                                onClick={(event) => {
                                  if (activeTab === 'Detalles') {
                                    event.preventDefault()
                                    return
                                  }
                                  handleTabClick(event, clientData)
                                  window.location.href = `${match}${client}/detalles`
                                  event.preventDefault()
                                }}
                              >
                                Detalles
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className={`nav-link ${activeTab === 'E-Commerce' ? 'active' : 'inactive-tab'} cursor-pointer`}
                                onClick={(event) => {
                                  if (activeTab === 'E-Commerce') {
                                    event.preventDefault()
                                    return
                                  }
                                  handleTabClick(event, clientData)
                                  window.location.href = `${match}${client}/ecommerce`
                                  event.preventDefault()
                                }}
                              >
                                E-Commerce
                              </a>
                            </li>
                          </ul>
                          <div>
                            <Route
                              exact
                              path={`${match}${client}/detalles`}
                              component={ShowClientTab}
                            />
                            <Route
                              exact
                              path={`${match}${client}/ecommerce`}
                              render={() => (
                                <ClientEcommerceProvider
                                  id={clientData.id}
                                  clientName={clientData.customer_name_db_ref}
                                  dbRef={clientData.db_ref}
                                >
                                  <ShowEcommerceClientTab />
                                </ClientEcommerceProvider>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {isFetching && (
                    <div className="index-table">
                      <div className="mt-70">
                        <div className="text-center">
                          <br />
                          <p>Obteniendo datos de clientes ...</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <NavBar
                    activeItem="Clientes"
                    isEditing={state.editGlobal}
                    collapsed={state.collapsed}
                  />
                </div>
              </>
            )}
          </GlobalConsumer>
        </GlobalProvider>
      )}
    </ClientConsumer>
  )
}

export default ShowClientApp
