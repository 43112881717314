import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import Pagination from '../layout/Pagination'
import CreateOrderInput from './component/protected_component/CreateOrderInput'
import SearchInput from '../layout/SearchInput'
import { formatMoney } from '../helpers/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faMagnifyingGlass, faCalendar } from '@fortawesome/free-solid-svg-icons'
import ReactFilter from '../components/inputs/ReactFilterList'
import Toolbar from 'react-multi-date-picker/plugins/toolbar'
import DatePicker from 'react-multi-date-picker'
import { Table } from 'react-bootstrap'
import moment from 'moment'

const OrderIndexView = ({
  data,
  filters,
  fetchDataStatus,
  isFetching,
  collapsed,
  title,
  onSearch,
  onSearchInput,
  updateAllSelectedOptions,
  filterVersion,
  handleDateFilter,
  selectedDates,
  searchInput,
  handleSearch,
  handleEnterSearch,
  resetReactFilters,
  isAnyFilterSelected,
  pagination,
  handlePaginationClick
}) => {
  const showModel = (event) => {
    event.preventDefault()
    const id = event.target.id
    window.open(`/pedidos/${id}`, '_blank')
  }

  const getFormattedDate = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  const filtersArray = Array.isArray(filters) ? filters : Array.from(filters || [])
  const anyFilterEmpty = filtersArray.some((filter) => filter.value.length === 0)

  if (isFetching) {
    return (
      <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
        <div className="index-header">
          <br />
          <p>Obteniendo datos ....</p>
        </div>
      </div>
    )
  } else {
    return (
      <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
        <div className="index-header detailed-container">
          <h2 className="mb-20">{title}</h2>
        </div>
        <div className="index-table">
          <div className="show-area">
            <h4 className="text-sub-title">Filtrar pedidos por:</h4>
            <div className="filters-search-container mb-10">
              <FontAwesomeIcon className="filter-icon mr-10" icon={faFilter} />
              <div className="filters-container">
                {filters.map((filter, indx) => (
                  <div key={`flr-${indx}`} className="mr-10">
                    <ReactFilter
                      className="mr-10"
                      title={filter.key}
                      identifier={filter.key}
                      options={filter.value}
                      resetWatcher={filterVersion}
                      onSelectionChange={updateAllSelectedOptions}
                      disabled={anyFilterEmpty}
                    />
                  </div>
                ))}
                <DatePicker
                  disabled={anyFilterEmpty}
                  range
                  rangeHover
                  dateSeparator=" - "
                  months={[
                    'Enero',
                    'Febrero',
                    'Marzo',
                    'Abril',
                    'Mayo',
                    'Junio',
                    'Julio',
                    'Agosto',
                    'Septiembre',
                    'Octubre',
                    'Noviembre',
                    'Diciembre'
                  ]}
                  weekDays={['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']}
                  format="DD MMM"
                  weekStartDayIndex={1}
                  highlightToday={false}
                  numberOfMonths={2}
                  maxDate={moment().local().endOf('day').toDate()}
                  onChange={(dateObjects) => handleDateFilter(dateObjects)}
                  value={selectedDates}
                  plugins={[
                    <Toolbar
                      position="bottom"
                      names={{
                        deselect: 'Deseleccionar'
                      }}
                      sort={['deselect']}
                    />
                  ]}
                  render={(value, openCalendar) => {
                    return (
                      <div
                        key={`flr-date`}
                        className={`mr-10 ${anyFilterEmpty ? 'react-filter-disabled' : ''}`}
                      >
                        <div className="react-filter-box filter-input-width-datepicker">
                          <div
                            title="Rango de fechas"
                            onClick={!anyFilterEmpty ? openCalendar : undefined}
                            className="react-filter-box-clickable-zone"
                          >
                            <div className="react-filter-titles">
                              {value?.length > 0 && (
                                <>
                                  {Array.isArray(value) ? (
                                    <div className="filter-title-overflow">{value.join(' - ')}</div>
                                  ) : (
                                    <div className="filter-title-overflow">{value}</div>
                                  )}
                                  <FontAwesomeIcon style={{ color: 'ef823a' }} icon={faCalendar} />
                                </>
                              )}
                              {value?.length == 0 && (
                                <>
                                  <div className="filter-title-overflow">RANGO DE FECHAS</div>
                                  <FontAwesomeIcon
                                    style={{ color: anyFilterEmpty ? '#D5D5D5' : '#597fa9' }}
                                    icon={faCalendar}
                                  />{' '}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }}
                />
              </div>
              <div className="search-input-containter">
                <input
                  value={searchInput}
                  onChange={handleSearch}
                  onKeyDown={handleEnterSearch}
                  className="round-search-input"
                  style={{ fontSize: 12 }}
                  placeholder="Buscar por código o nombre"
                />
                {searchInput === '' && (
                  <FontAwesomeIcon icon={faMagnifyingGlass} className="search-icon" />
                )}
                {searchInput && (
                  <div
                    title="Limpiar texto"
                    onClick={resetReactFilters}
                    className="round-search-input-x"
                  >
                    X
                  </div>
                )}
              </div>
            </div>
            <div className="mb-20">
              {isAnyFilterSelected && (
                <a onClick={resetReactFilters} className="reset-filter cursor-pointer mb-20">
                  Restablecer filtros aplicados
                </a>
              )}
            </div>
            <Table hover responsive>
              <thead className="table-header-gray">
                <tr>
                  <th style={{ verticalAlign: 'middle', padding: '10px 25px', width: '10%' }}>
                    FECHA Y HORA
                  </th>
                  <th style={{ verticalAlign: 'middle', width: '10%' }}># DE PEDIDO</th>
                  <th style={{ verticalAlign: 'middle', width: '15%' }}>CÓDIGO DE CLIENTE</th>
                  <th style={{ verticalAlign: 'middle', width: '20%' }}>NOMBRE DE CLIENTE</th>
                  <th style={{ verticalAlign: 'middle', width: '15%' }}>CREADO POR</th>
                  <th style={{ verticalAlign: 'middle', width: '10%' }}>TOTAL PEDIDO</th>
                  <th style={{ verticalAlign: 'middle', width: '10%' }}>TOTAL FACTURA</th>
                  <th style={{ verticalAlign: 'middle' }}>ESTADO DEL PEDIDO</th>
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  data.map((model, index) => (
                    <tr className="cursor-pointer" key={index}>
                      <td
                        className="td-text-limited td-text-limited-space"
                        id={model['uuid']}
                        onClick={showModel}
                      >
                        {getFormattedDate(model['created_date'])}
                      </td>
                      <td className="td-text-limited" id={model['uuid']} onClick={showModel}>
                        {model['order_db_ref']}
                      </td>
                      <td className="td-text-limited" id={model['uuid']} onClick={showModel}>
                        {model['client_db_ref']}
                      </td>
                      <td className="td-text-limited" id={model['uuid']} onClick={showModel}>
                        {model['client_name']}
                      </td>
                      <td className="td-text-limited" id={model['uuid']} onClick={showModel}>
                        {model['salesman_db_ref']} - {model['salesman_name']}
                      </td>
                      <td className="td-text-limited" id={model['uuid']} onClick={showModel}>
                        {formatMoney(Number(model['order_total']))}
                      </td>
                      <td className="td-text-limited" id={model['uuid']} onClick={showModel}>
                        {model['invoice_total'] > 0
                          ? formatMoney(Number(model['invoice_total']))
                          : '-'}
                      </td>
                      <td id={model['uuid']} onClick={showModel}>
                        <div className="td-order-status">
                          {model['order_status'] === 'sent' && (
                            <div className="order-status sent-style">ENVIADO</div>
                          )}
                          {model['order_status'] === 'received' && (
                            <div className="order-status received-style">RECIBIDO</div>
                          )}
                          {model['order_status'] === 'dispatched' && (
                            <div className="order-status dispatched-style">DESPACHADO</div>
                          )}
                          {model['order_status'] === 'billed' && (
                            <div className="order-status billed-style">FACTURADO</div>
                          )}
                          {model['order_status'] === 'cancelled' && (
                            <div className="order-status cancelled-style">CANCELADO</div>
                          )}
                          {model['order_status'] === 'tobebilled' && (
                            <div className="order-status pending-bill-style">POR FACTURAR</div>
                          )}
                          {model['order_status'] === 'ontheway' && (
                            <div className="order-status dispatched-style">EN CAMINO</div>
                          )}
                          {model['order_status'] === 'partial' && (
                            <div className="order-status partial-style">PARCIAL</div>
                          )}
                          {model['order_status'] === 'annulled' && (
                            <div className="order-status annulled-style">ANULADO</div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="td-text-limited td-text-limited-space">
                      {' '}
                      No hay resultados
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <nav className="index-table">
          {pagination.total_pages > 1 && (
            <div onClick={handlePaginationClick}>
              <Pagination
                current_page={pagination.current_page}
                total_pages={pagination.total_pages}
              />
            </div>
          )}
        </nav>
      </div>
    )
  }
}

export default OrderIndexView
