import React from 'react'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import EssentialIndexView from './EssentialIndexView'


export default class EssentialIndexApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userName: 'Soporte',
      searchPlaceholder: ''
    }
  }
  componentDidMount() {
    const collapsed = localStorage.getItem('collapsedState') === 'true';
    this.setState({ collapsed });
  }

  handleToggleCollapse = (collapsed) => {
    this.setState({ collapsed }, () => {
      localStorage.setItem('collapsedState', collapsed);
    });
  };

  render() {
    return (
      <div>
        <div>
          <div>
            <TopBar
              searchPlaceholder={this.state.searchPlaceholder}
              userName={this.state.userName}
              onToggleCollapse={this.handleToggleCollapse} 
              collapsed={this.state.collapsed}
            />
          </div>
          <div>
            <EssentialIndexView
            collapsed={this.state.collapsed}  
            />
          </div>
        </div>
        <div>
          <NavBar activeItem="Infaltables" isEditing={false} collapsed={this.state.collapsed}/>
        </div>
      </div>
    )
  }
}
