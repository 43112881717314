/* eslint-disable no-case-declarations */
import { formatDiscount, getDiscountPrice } from '../../helpers/utils'

export const DiscountPromoReducer = (state, action) => {
  const discountProducts = state
  const product = action.payload

  switch (action.type) {
    case 'ADD_TO_DISCOUNT_PRODUCT':
      const productInCart = discountProducts.find((item) => item.id === product.id)
      if (productInCart) {
        const productIndex = discountProducts.findIndex((item) => item.id === product.id)
        discountProducts[productIndex].discount = 0
        discountProducts[productIndex].discountPrice = 0
        discountProducts[productIndex].originalPrice = discountProducts[productIndex].price
        discountProducts[productIndex].product_id = discountProducts[productIndex].id
        return [...discountProducts]
      }
      product.discount = 0
      product.discountPrice = 0
      product.originalPrice = product.price
      product.product_id = product.id
      return [product, ...discountProducts]

    case 'REMOVE_DISCOUNT_PRODUCT':
      return discountProducts.filter((item) => item.id !== product.id)

    case 'CHANGE_DISCOUNT_PRODUCT':
      const incrementProduct = discountProducts.find((item) => item.id === product.id)

      if (incrementProduct) {
        const incrementProductIndex = discountProducts.findIndex((item) => item.id === product.id)
        parseFloat(product.discount)

        const discount = formatDiscount(product.discount)
        discountProducts[incrementProductIndex].discount = discount
        const parseDiscount = getDiscountPrice(parseFloat(product.price), discount)
        discountProducts[incrementProductIndex].price = parseDiscount
        discountProducts[incrementProductIndex].discountPrice = parseDiscount
        return [...discountProducts]
      }
      return state

    case 'CLEAR_DISCOUNT_PRODUCT':
      return []

    default:
      return state
  }
}
