import React from 'react'
import BaseCard from './BaseCard'

const TimestampControlOverviewCard = ({ data }) => {
  return (
    <BaseCard addClass="base-card-container-overview base-card-container-timestamp">
      <p className="p-card-title">Control de horario</p>
      <div className="numbers-container">
        {data?.map((item, index) => (
          <div key={index} className="card-flex-item">
            <p className="p-card-timestamp-subtitle">{item.text}</p>
            <p className="p-card-number">{item.value}</p>
          </div>
        ))}
      </div>
    </BaseCard>
  )
}

export default TimestampControlOverviewCard
