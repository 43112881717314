import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react'
import { sendEmail } from '../../services/apiUserService'

const SuccessResetPasswordModal = (props) => {
  const [showText, setShowText] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const handleClose = () => {
    props.handleCloseResetModal();
    setShowModal(false);
  };

  const sendUserEmail = (event) => {
    event.preventDefault();
    const user = {
      email: props.email,
      password: props.password,
    };
    sendEmail(user, 'reset_password')
      .then((r) =>
        r.json().then((data) => {
          setShowModal(false);
          props.handleCloseResetModal();
        })
      )
      .then((obj) => console.log(obj))
      .catch((e) => {
        setShowModal(false);
        props.handleCloseResetModal();
        console.log(e);
      });
  };

  return (
    <div>
      <Modal
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        show={showModal}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="title-green-modal-icon">
              <b className="subtitle-user-success">¡La contraseña ha sido cambiada exitosamente!</b>
              <FontAwesomeIcon className="icon-success" icon={faCheckCircle} />
            </p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: '30px' }}>
          <div>
            <div className="container-fluid modal-container">
              <div className="row">
                <p>
                  <b>Aviso:</b> Para notificar al usuario sobre el cambio de contraseña, haga clic en el botón "Enviar correo al usuario".
                </p>
                <br />
              </div>

              <div className="row">
                <b className=" ft-17">Nueva contraseña:</b>
                <div>
                  {showText && <span className="bold ">{props.password}</span>}
                  {!showText && <span className="bold">**************</span>}

                  <div>
                    {!showText && (
                      <button
                        onClick={() => setShowText(!showText)}
                        name="remove"
                        value="1"
                        type="submit"
                        className="font-tiny btn-link link-bold show-text-password ft-12"
                      >
                        <FontAwesomeIcon className='icon-eye-success' icon={faEyeSlash} /> VER CONTRASEÑA
                      </button>
                    )}

                    {showText && (
                      <button
                        onClick={() => setShowText(!showText)}
                        name="remove"
                        value="1"
                        type="submit"
                        className="font-tiny btn-link link-bold show-text-password ft-12"
                      >
                        <FontAwesomeIcon className='icon-eye-success' icon={faEye} /> OCULTAR CONTRASEÑA
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <input
                  onClick={sendUserEmail}
                  type="button"
                  className="btn float-right ubq-modal-button"
                  value="Enviar correo a usuario"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SuccessResetPasswordModal;