import React, { useState } from 'react'
import BaseCardPaymen from './BaseCardPayment'
import { VictoryPie } from 'victory'
import { useEffect } from 'react'
import { formatMoney } from '../../helpers/utils'

const colors = {
  Efectivo: '#27AD60',
  'Transferencia Bancaria': '#E9C471',
  'Pago Digital': '#65BBE7',
  'Depósito Bancario': '#6175E5',
  Cheque: '#CE402C',
  'Tarjeta de Crédito': '#F4833B',
  'Vale Digital': '#C7C7C7'
}

const ColorDash = ({ color, selected }) => {
  return (
    <div
      className="motive-dash"
      style={{
        backgroundColor: color,
        height: selected ? '8px' : '4px'
      }}
    ></div>
  )
}

const formatNumber = (number) => {
  if (number < 1000) {
    return number.toFixed(2)
  }

  if (number < 10000) {
    return `${Math.floor(number / 100) / 10}k`
  }
  if (number < 1000000) {
    return `${Math.floor(number / 1000)}k`
  }
  if (number >= 10000000 && number < 100000000) {
    return `${Math.floor(number / 1000000)}M`
  }
  return `${Math.floor(number / 100000) / 10}M`
}

const CircleLabelNumber = ({ number, subtitle }) => {
  return (
    <div className="circle-label-number">
      <p className="p-card-circle-label-number">{formatNumber(number)}</p>
    </div>
  )
}

const getModifiedText = (text) => {
  const textMappings = {
    'Transferencia Bancaria': 'Transferencia',
    'Depósito Bancario': 'Depósito',
    'Tarjeta de Crédito': 'Tarj. De Crédito',
    Efectivo: 'Efectivo',
    'Pago Digital': 'Pago Digital',
    Cheque: 'Cheque',
    'Vale Digital': 'Vale Digital'
  }

  return textMappings[text] || text
}

const convertSubtitleToNumber = (moneyString) => {
  const numericString = moneyString.replace(/[$,]/g, '')
  return parseFloat(numericString)
}

const ReportPayment = ({
  reportPayment,
  text,
  value,
  selectedTab,
  displayTab,
  businessTotal,
  subtitle
}) => {
  const subtitleNumber = convertSubtitleToNumber(subtitle)

  let maxValue = reportPayment.reduce((max, motive) => {
    return motive.value > max ? motive.value : max
  }, 0)
  let totalValue = reportPayment.reduce((total, motive) => {
    return total + motive.value
  }, 0)

  const [numberValue, setNumberValue] = useState(totalValue)
  const [hoverIndex, setHoverIndex] = useState(-1)

  let maxColors = colors.length

  const calculateValue = (value) => {
    return (value * 5) / maxValue
  }

  const buildreportPayment = () => {
    return reportPayment
      .filter((motive) => motive.value > 0)
      .map((motive) => ({
        x: motive.text,
        y: calculateValue(motive.value),
        color: colors[motive.text] || '#000000'
      }))
  }

  const sortreportPayment = () => {
    return [...reportPayment]
      .filter((motive) => motive.value > 0)
      .map((motive, index) => ({
        ...motive,
        originalIndex: index
      }))
      .sort((a, b) => b.value - a.value)
  }

  const formatCurrency = (value) => {
    return `$${parseFloat(value)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
  }

  const findOriginalIndex = (motiveTxt) => {
    let idx = reportPayment.findIndex((motive) => {
      return motive.text === motiveTxt
    })
    return idx
  }

  useEffect(() => {
    setNumberValue(totalValue)
  }, [selectedTab])

  if (selectedTab == 1 || displayTab.includes(selectedTab)) {
    return (
      <BaseCardPaymen>
        <p className="p-card-title">Métodos de pago usados</p>
        <div className="report-container">
          <div className="victory-pie">
            <VictoryPie
              style={{ labels: { fill: 'black' } }}
              padAngle={({ datum }) => 2}
              colorScale={buildreportPayment().map((motive) => motive.color)}
              innerRadius={85}
              radius={({ datum }) => 85 + datum.y * 15}
              labels={({ datum }) => ''}
              data={buildreportPayment()}
            />
            <CircleLabelNumber number={numberValue} subtitle={subtitleNumber} />
          </div>
          <div className="motives-container-payment">
            {sortreportPayment().map((motive, index) => {
              const color = colors[motive.text] || '#000000'
              const selected = hoverIndex === motive.originalIndex
              return (
                <div
                  key={index}
                  className="motive"
                  onMouseOver={() => {
                    setNumberValue(motive.value)
                    setHoverIndex(motive.originalIndex)
                  }}
                  onMouseLeave={() => {
                    setNumberValue(totalValue)
                    setHoverIndex(-1)
                  }}
                >
                  <ColorDash color={color} selected={selected} />
                  <div className="motive-flex-container">
                    <div className="motive-value">
                      <b className="motive-value-text-size">{formatMoney(motive.value)}</b>
                    </div>
                    <div className="motive-text">{getModifiedText(motive.text)}</div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </BaseCardPaymen>
    )
  }
  return null
}

export default ReportPayment
