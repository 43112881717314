import React, { Component } from 'react'
import TextInput from '../../components/inputs/TextInput'
import SelectInput from '../../components/inputs/SelectInput'
import Email from '../../components/inputs/EmailInput'
import { DeliveriesConsumer } from '../context/DeliveriesCreateContext'

class CreateDeliveryUserForm extends Component {
  cancelAction(path) {
    this.props.history.push(path)
  }

  render() {
    return (
      <DeliveriesConsumer>
        {({ headerOrder, formControl, formSubmitHandler, changeHandler, failedCreation }) => (
          <div className="main-view">
            {failedCreation !== null && (
              <div className="alert alert-danger" role="alert">
                <strong>{failedCreation}</strong>
              </div>
            )}
            <div>
              <div className="show-title">
                <h2>{headerOrder.title}</h2>
              </div>
              <div className="index-table">
                <div className="show-area">
                  <div className="create-promo-wrapper">
                    <form onSubmit={formSubmitHandler}>
                      <div className="inline-block full-width">
                        <div className="float-left">
                          <h4>{headerOrder.detail}</h4>
                          <p style={{ color: 'red' }}>{headerOrder.optional}</p>
                        </div>
                        <div className="float-right">
                          <button
                            className="cancel-button"
                            onClick={() => this.cancelAction('/entregadores')}
                          >
                            {' '}
                            Cancelar
                          </button>
                          <input className="save-button" value="Guardar" type="submit" />
                        </div>
                      </div>
                      <hr />

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Activo:</label>
                        <div className="col-sm-8">
                          <SelectInput
                            name="active"
                            value={formControl.active.value}
                            onChange={changeHandler}
                            options={formControl.active.options}
                            touched={formControl.active.touched}
                            valid={formControl.active.valid}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="inputName" className="col-sm-3 col-form-label">
                          Nombre de entregador<span className="red">*</span>:
                        </label>
                        <div className="col-sm-8">
                          <TextInput
                            name="name"
                            inputstyle="form-control"
                            placeholder={formControl.name.placeholder}
                            value={formControl.name.value}
                            touched={formControl.name.touched.toString()}
                            valid={formControl.name.valid.toString()}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
                          Número de rutero/Código
                          <span className="red">*</span>:<div>(maximo 6 digitos)</div>
                        </label>
                        <div className="col-sm-8">
                          <TextInput
                            name="code"
                            inputstyle="form-control"
                            placeholder={formControl.code.placeholder}
                            value={formControl.code.value}
                            touched={formControl.code.touched.toString()}
                            valid={formControl.code.valid.toString()}
                            onChange={changeHandler}
                            maxLength={6}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
                          Correo electrónico<span className="red">*</span>:
                        </label>
                        <div className="col-sm-8">
                          <Email
                            name="email"
                            inputstyle="form-control"
                            placeholder={formControl.email.placeholder}
                            value={formControl.email.value}
                            touched={formControl.email.touched.toString()}
                            valid={formControl.email.valid.toString()}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
                          Celular (opcional)
                        </label>
                        <div className="col-sm-8">
                          <TextInput
                            name="phone"
                            type="number"
                            inputstyle="form-control"
                            placeholder={formControl.phone.placeholder}
                            value={formControl.phone.value}
                            touched={formControl.phone.touched.toString()}
                            valid={formControl.phone.valid.toString()}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </DeliveriesConsumer>
    )
  }
}

export default (CreateDeliveryUserForm)
