export const mapDayOfTheWeekFromEnglishToSpanish = (dayInEnglish) => {
  let dayInSpanish = ''
  const dayInEnglishLowerCase = dayInEnglish.toLowerCase()
  switch (dayInEnglishLowerCase) {
    case 'monday':
      dayInSpanish = 'Lunes'
      break
    case 'tuesday':
      dayInSpanish = 'Martes'
      break
    case 'wednesday':
      dayInSpanish = 'Miercoles'
      break
    case 'thursday':
      dayInSpanish = 'Jueves'
      break
    case 'friday':
      dayInSpanish = 'Viernes'
      break
    case 'saturday':
      dayInSpanish = 'Sabado'
      break
    case 'sunday':
      dayInSpanish = 'Domingo'
      break
    case 'everyday':
      dayInSpanish = 'Todos los dias'
      break
    default:
      break
  }

  return dayInSpanish
}
