import React from 'react'
import NavBar from '../../layout/NavBar'
import TopBar from '../../layout/TopBar'
import DeliveryEffectivityIndexView from './DeliveryEffectivityIndexView'

export default class DeliveryEffectivityIndexApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userName: 'Ana Sofia Lau Hou',
      searchPlaceholder: '',
      title: 'Reporte: Efectividad de entregas'
    }
  }

  render() {
    return (
      <div>
        <div>
          <div>
            <TopBar
              searchPlaceholder={this.state.searchPlaceholder}
              userName={this.state.userName}
            />
          </div>
          <div>
            <DeliveryEffectivityIndexView title={this.state.title} />
          </div>
        </div>
        <div>
          <NavBar activeItem="Reportes" isEditing={false} />
        </div>
      </div>
    )
  }
}
