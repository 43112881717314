import React, { useContext, useEffect, useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { ActionButtonContext } from './ActionButtonContext'

export default function DropdownActionButton({ id, list }) {
  const ref = useRef(null)
  const [toggle, setToggle] = useState(false)
  const { activeDropdown, setActiveDropdown } = useContext(ActionButtonContext)

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setToggle(false)
    }
  }

  const handleDropdown = (e) => {
    e.stopPropagation()
    setToggle(!toggle)
  }

  const handleAction = (handler) => {
    handler()
    setToggle(false)
  }

  useEffect(() => {
    if (toggle) {
      setActiveDropdown(id)
    }
  }, [toggle])

  useEffect(() => {
    if (activeDropdown != id) {
      setToggle(false)
    }
  }, [activeDropdown])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return (
    <div ref={ref} className="dropdown-btn-container">
      <button className="dropdown-btn" type="button" onClick={(e) => handleDropdown(e)}>
        <FontAwesomeIcon icon={faEllipsisH} style={{ margin: 0 }} />
      </button>
      <div className={`dropdown-btn-ul ${toggle ? 'active' : ''}`}>
        {list.map(
          (item, index) =>
            !item.disabled && (
              <div
                key={index}
                className="dropdown-btn-li"
                onClick={(e) => {
                  e.stopPropagation()
                  item.handler
                    ? handleAction(item.handler)
                    : console.log(item.text + ' button executed (missing handler)')
                }}
              >
                {item.text}
              </div>
            )
        )}
      </div>
    </div>
  )
}
