import React, { useContext, useEffect, useState, useRef } from 'react'
import { set } from 'react-hook-form'

export default function SeeMoreButton({ id, products, uuid }) {
  const [showMore, setShowMore] = useState(false)
  const handleButtonChange = (e) => {
    e.stopPropagation()
    window.location = `/promociones/${uuid}`
  }

  const handleProductStringCut = () => {
    var product_string = ''
    products.map(
      (product, index) =>
        (product_string = `${product_string}${products[index]['db_ref']} - ${products[index]['name']}\n`)
    )
    return <span style={{ whiteSpace: 'pre-wrap' }}>{product_string.substring(0, 80)}</span>
  }

  return (
    <div>
      {showMore && (
        <>
          {products.map((product, index) => (
            <div key={index}>
              `${products[index]['db_ref']} - ${products[index]['name']}`
            </div>
          ))}
          <a style={{ color: 'blue' }} onClick={(e) => setShowMore(false)}>
            Ver menos
          </a>
        </>
      )}
      {!showMore && (
        <>
          {handleProductStringCut()}
          <a style={{ color: 'blue' }} onClick={(e) => handleButtonChange(e)}>
            Ver más...
          </a>
        </>
      )}
    </div>
  )
}
