import React, { useState, useEffect, useContext } from 'react'
import cookie from 'react-cookies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { Table } from 'react-bootstrap'
import AsyncSelect from 'react-select/async'
import fetchWithAuth from '../../helpers/fetchWithAuth'
import { UserInfoContext } from '../context/UserInfoProvider'
import { DAY_DIC } from '../../pos/constants/constants'
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'

const UserRoutesDetailTab = () => {
  const { userInfoData } = useContext(UserInfoContext)

  const [isFetching, setIsFetching] = useState(true)
  const [userRouteData, setUserRouteData] = useState([])
  const [addNewRoute, setAddNewRoute] = useState(false)

  const [client, setClient] = useState(null)
  const [clientValue, setClientValue] = useState(null)
  const [dayOfWeek, setDayOfWeek] = useState(null)

  const BUSINESS_ID = cookie.load('business_id')
  const BUSINESS_UID = cookie.load('business_tenant_uid')

  const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
  const BACKEND_URL = USE_BUSINESS_URL
    ? `${process.env.REACT_APP_BACKEND_URL}/businesses/${cookie.load('business_id')}`
    : process.env.REACT_APP_BACKEND_URL

  ////// Stops fetchs when page is changed
  const abortController = new AbortController()
  var abortClientController = new AbortController()
  const abortCurrentFetchs = () => {
    abortClientController.abort()
    abortController.abort()
  }
  window.addEventListener('beforeunload', abortCurrentFetchs)
  //////

  // Stops client fetch when is unfocus
  const abortClientFetchs = () => {
    abortClientController.abort()
  }
  //

  useEffect(() => {
    async function fetchAll() {
      await fetchUserRoutesFromServer()
    }
    fetchAll()
  }, [])

  const startAddingNewRoute = () => {
    setAddNewRoute(true)
  }

  const cancelAddNewRoute = () => {
    setAddNewRoute(false)
  }

  const handleClientOption = (event) => {
    setClientValue(event)
    setClient(event ? event.client : null)
  }

  const handleDayOfWeek = (event) => {
    if (event != null) {
      setDayOfWeek(event.target.value)
    }
  }

  const getClient = async (inputValue) => {
    if (inputValue.length >= 3) {
      abortClientController = new AbortController()
      return fetch(`${BACKEND_URL}/clients?q=${inputValue}&active=true`, {
        method: 'GET',
        signal: abortClientController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      })
        .then((response) => response.json())
        .then((data) =>
          data.table.map((cl) => ({
            value: cl.name,
            label: `${cl.db_ref} - ${cl.name}`,
            client: cl
          }))
        )
        .catch((error) => {
          if (error.name != 'AbortError') {
            console.error('Error fetching clients:', error)
            return []
          }
        })
    }
  }

  const fetchUserRoutesFromServer = async () => {
    setIsFetching(true)
    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_BACKEND_URL}/salesmen/${userInfoData.id}/salesman_client`,
        {
          method: 'GET',
          signal: abortController.signal
        }
      )
      if (response) {
        setUserRouteData(response.result)
      }
    } catch (error) {
      console.error('Error fetching user routes:', error)
    } finally {
      setIsFetching(false)
    }
  }

  const sendAddNewRoute = (event) => {
    event.preventDefault()
    fetch(`${process.env.REACT_APP_BACKEND_URL}/salesman_client`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      },
      body: JSON.stringify({
        business_id: BUSINESS_ID,
        business_uid: BUSINESS_UID,
        client_id: client.id,
        client_uid: client.db_ref,
        client_name: client.name,
        user_id: userInfoData.id,
        user_uid: userInfoData.uid,
        day_of_week: Number(dayOfWeek)
      })
    })
      .then((r) => evaluateResponse(r))
      .catch((e) => console.log(e))
  }

  const handleDeleteRoute = (model, event) => {
    event.preventDefault()
    fetch(`${process.env.REACT_APP_BACKEND_URL}/salesman_client/${model.id}`, {
      method: 'DELETE',
      signal: abortController.signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((r) => evaluateResponse(r))
      .catch((e) => console.log(e))
  }

  const evaluateResponse = (response) => {
    if (response.ok) {
      setClientValue(null)
      setClient(null)
      fetchUserRoutesFromServer()
      setAddNewRoute(false)
    } else {
      console.error('error at user route: ', response)
      Sentry.captureException(response)
    }
  }

  return (
    <div className="show-area-tab">
      {addNewRoute === true && (
        <form onSubmit={sendAddNewRoute.bind(this)}>
          <div className="show-header show-routes-header">
            <h2 className="show-routes-subtitle">Clientes asignados a usuario:</h2>
            <div className="cancel-save-block-with-margin">
              <input
                id="cancel-add"
                onClick={cancelAddNewRoute.bind(this)}
                className="cancel-edit-button"
                value="Cancelar"
                type="submit"
              />
              <input value="Guardar" className="save-button" type="submit" />
            </div>
          </div>
          <hr className="mt-50" />
          <div className="add-price-list-block">
            <div className="title-promos-form">
              Escoja el cliente que desea asignarle al usuario:
            </div>
            <div className="add-price-list-inputs">
              <div>
                <div>Cliente</div>
                <div className="client-select-no-margin">
                  <AsyncSelect
                    placeholder={'Buscar cliente por nombre o código'}
                    noOptionsMessage={() => 'Buscar Clientes'}
                    loadingMessage={() => 'Cargando clientes...'}
                    name="selectOptionClients"
                    loadOptions={getClient}
                    isClearable={true}
                    searchParamName={'q'}
                    className="clients select-async-select"
                    isSearchable={!client}
                    value={clientValue}
                    onChange={(e) => handleClientOption(e)}
                    onBlur={abortClientFetchs}
                    required
                  />
                </div>
              </div>
              <div>
                <div>Día de visita</div>
                <select
                  className="select-visit-day form-control custom-select"
                  name="visitDay"
                  required
                  onChange={(e) => handleDayOfWeek(e)}
                >
                  <option value="" hidden>
                    Escoger día de visita
                  </option>
                  {Object.entries(DAY_DIC).map((day) => (
                    <option value={day[0]}>{day[1]}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </form>
      )}
      {addNewRoute === false && (
        <div className="show-header show-routes-header">
          <h2 className="show-routes-subtitle">Clientes asignados a usuario:</h2>
          <div className="show-route-buttons">
            <input type="submit" onClick={startAddingNewRoute.bind(this)} value="Asignar cliente" />
          </div>
        </div>
      )}
      <div className="mt-50">
        <Table hover responsive>
          <thead className="table-header-gray">
            <tr>
              <th style={{ verticalAlign: 'middle', padding: '10px 25px', width: '10%' }}>
                <span className="th-header-text">CÓDIGO</span>
              </th>
              <th style={{ verticalAlign: 'middle' }}>
                <span className="th-header-text">NOMBRE</span>
              </th>
              <th style={{ verticalAlign: 'middle' }}>
                <span className="th-header-text">DÍA DE VISITA</span>
              </th>
              <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                <span className="th-header-text">ACCIÓN</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {!isFetching &&
              userRouteData?.map((route, indx) => (
                <tr key={indx}>
                  <td className="td-text-limited td-text-limited-space">{route.client_uid}</td>
                  <td className="td-text-limited">{route.client_name}</td>
                  <td className="td-text-limited">{DAY_DIC[String(route.day_of_week)]}</td>
                  <td className="td-delete-route-btn">
                    <button
                      type="button"
                      className={`delete-report-btn`}
                      style={{ marginLeft: 0 }}
                      onClick={(e) => {
                        handleDeleteRoute(route, e)
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} style={{ margin: 0 }} />
                    </button>
                  </td>
                </tr>
              ))}
            {!isFetching && userRouteData?.length === 0 && (
              <tr>
                <td colSpan="9" className="td-text-no-border">
                  No hay clientes asignados a la ruta de este vendedor.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default UserRoutesDetailTab
