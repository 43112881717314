import React, { useState, useEffect } from 'react'
import cookie from 'react-cookies'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import OrderIndexView from './OrderIndexView'
import fetchWithAuth from '../helpers/fetchWithAuth'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const OrderIndexApp = () => {
  const initPaginationData = () => {
    const searchParams = new URLSearchParams(window.location.search)
    let page = searchParams.get('page')
    let currentPage = 1
    if (page) {
      currentPage = Number(page)
    } else {
      searchParams.set('page', '1')
      let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
      window.history.pushState(null, '', newRelativePathQuery)
    }
    return {
      per_page: 25,
      current_page: currentPage,
      total_pages: 1,
      searchInput: ''
    }
  }

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [isFetching, setIsFetching] = useState(true)
  const [data, setData] = useState(null)
  const [title, setTitle] = useState('Pedidos')
  const [collapsed, setCollapsed] = useState(false)
  const [clientsUid, setClientsUid] = useState([])
  const [status, setStatus] = useState([
    { name: 'ENVIADO', value: 'sent' },
    { name: 'RECIBIDO', value: 'received' },
    { name: 'DESPACHADO', value: 'dispatched' },
    { name: 'FACTURADO', value: 'billed' },
    { name: 'CANCELADO', value: 'cancelled' },
    { name: 'POR FACTURAR', value: 'tobebilled' },
    { name: 'EN CAMINO', value: 'ontheway' },
    { name: 'PARCIAL', value: 'partial' },
    { name: 'ANULADO', value: 'annulled' }
  ])
  const [filters, setFilters] = useState([])
  const [fetchDataStatus, setFetchDataStatus] = useState({ filters: false })
  const [allSelectedOptions, setAllSelectedOptions] = useState(new Map())
  const [filterQuery, setFilterQuery] = useState('')
  const [dateQuery, setDateQuery] = useState(null)
  const [selectedDates, setSelectedDates] = useState(null)
  const [pagination, setPagination] = useState(initPaginationData())
  const [labelToIdMapping, setLabelToIdMapping] = useState({})
  const [filterVersion, setFilterVersion] = useState(0)
  const [searchInput, setSearchInput] = useState('')
  const [triggerSearch, setTriggerSearch] = useState(false)
  const [filterSelectionMade, setFilterSelectionMade] = useState(false)
  const [resetTriggeredByDeselection, setResetTriggeredByDeselection] = useState(false)

  ////// Stops fetchs when page is changed
  const abortController = new AbortController()
  const abortCurrentFetchs = () => {
    abortController.abort()
  }
  window.addEventListener('beforeunload', abortCurrentFetchs)
  //////

  const updateAllSelectedOptions = (identifier, selectedLabels) => {
    setAllSelectedOptions((prevOptions) => {
      const updatedOptions = new Map(prevOptions)
      const labels = Array.isArray(selectedLabels)
        ? selectedLabels
        : Array.from(selectedLabels || [])

      const selectedIds = labels.map((label) => labelToIdMapping[label])
      updatedOptions.set(identifier, selectedIds)
      return updatedOptions
    })
    buildFiltersQuery()
  }

  const buildFiltersQuery = () => {
    let queryParam = ''

    allSelectedOptions.forEach((value, key) => {
      if (value.length !== 0) {
        if (key === 'CÓDIGO DE CLIENTE') {
          queryParam += `&client_uid=${value.join(',')}`
        } else if (key === 'CREADO POR') {
          queryParam += `&salesman_uid=${value.join(',')}`
        } else if (key === 'Estado') {
          queryParam += `&status=${value.join(',')}`
        }
      }
    })
    pagination.current_page = 1

    setFilterQuery(queryParam)
  }

  const prepareFilterData = () => {
    const localLabelToIdMapping = {}
    if (!clientsUid) {
      return
    }
    const clientOptions = clientsUid
      .filter((client) => (client.client_db_ref || '').trim() !== '')
      .map((client) => {
        const displayText = `${client.client_db_ref} - ${client.client_description}`
        localLabelToIdMapping[displayText] = client.client_db_ref
        return displayText
      })

    const salesmanOptions = Array.from(
      new Set(
        clientsUid
          .filter((client) => (client.client_db_ref || '').trim() !== '' && client.salesman_name)
          .map((client) => {
            const displayText = `${client.salesman_db_ref} - ${client.salesman_name}`
            localLabelToIdMapping[displayText] = client.salesman_db_ref
            return displayText
          })
      )
    )

    const statusOptions = status.map((option) => {
      localLabelToIdMapping[option.name] = option.value
      return option.name
    })

    const newFilters = [
      { key: 'CÓDIGO DE CLIENTE', value: clientOptions },
      { key: 'CREADO POR', value: salesmanOptions },
      { key: 'Estado', value: statusOptions }
    ]

    setFilters(newFilters)
    setLabelToIdMapping(localLabelToIdMapping)
    setFetchDataStatus({ filters: true })
  }

  const handleDateFilter = (datesObj) => {
    let formatedDates = []
    let queryParam = ''
    let startDate = new Date()
    let endDate = new Date()

    if (!datesObj || datesObj.length === 0) {
      const otherFiltersSelected = Array.from(allSelectedOptions.values()).some(
        (value) => value.length > 0
      )
      setDateQuery(null)
      setSelectedDates(null)
      setFilterSelectionMade(otherFiltersSelected)
      setResetTriggeredByDeselection(!otherFiltersSelected)
    } else {
      datesObj.map((date) => {
        formatedDates.push(new Date(date.unix * 1000))
      })
      setSelectedDates(datesObj)
      if (formatedDates.length > 1) {
        startDate = formatedDates[0] < formatedDates[1] ? formatedDates[0] : formatedDates[1]
        endDate = formatedDates[0] < formatedDates[1] ? formatedDates[1] : formatedDates[0]
      } else if (formatedDates.length === 1) {
        startDate = formatedDates[0]
        endDate = formatedDates[0]
      }
      queryParam = `&start_date=${startDate.toLocaleDateString()}&end_date=${endDate.toLocaleDateString()}`
      setDateQuery(queryParam)
      setFilterSelectionMade(true)
      setResetTriggeredByDeselection(false)
    }
    pagination.current_page = 1
  }

  const resetReactFilters = () => {
    setAllSelectedOptions(new Map())
    setFilterQuery('')
    setDateQuery(null)
    setSelectedDates(null)
    setSearchInput('')
    setFilterVersion((prevVersion) => prevVersion + 1)
    setTriggerSearch((prev) => !prev)
  }

  const handleToggleCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed)
    localStorage.setItem('collapsedState', newCollapsed)
  }

  useEffect(() => {
    const collapsedFromStorage = localStorage.getItem('collapsedState') === 'true'
    setCollapsed(collapsedFromStorage)
  }, [])

  useEffect(() => {
    prepareFilterData()
  }, [clientsUid])

  useEffect(() => {
    initPaginationData()
    async function fetchAll() {
      await fetchClientsFromServer()
    }
    fetchAll()
  }, [])

  useEffect(() => {
    if (allSelectedOptions.size > 0) {
      buildFiltersQuery()
    }
  }, [allSelectedOptions])

  const handlePaginationClick = (event) => {
    // <a> = when pagination elements were clicked
    if (event.target.tagName === 'A') {
      handlePagination(event.target)
    } else if (event.target.tagName === 'svg') {
      handlePagination(event.target.parentNode)
    } else if (event.target.tagName === 'path') {
      handlePagination(event.target.parentNode.parentNode)
    }
  }

  const handlePagination = (target) => {
    let targetId = target.id
    switch (targetId) {
      case 'first-page':
        if (pagination.current_page !== 1) {
          setPagination({ ...pagination, current_page: 1 })
        }
        break
      case 'last-page':
        if (pagination.current_page !== pagination.total_pages) {
          setPagination({ ...pagination, current_page: pagination.total_pages })
        }
        break
      case 'previous-page':
        if (pagination.current_page !== 1) {
          setPagination({ ...pagination, current_page: pagination.current_page - 1 })
        }
        break
      case 'next-page':
        if (pagination.current_page !== pagination.total_pages) {
          setPagination({ ...pagination, current_page: pagination.current_page + 1 })
        }
        break
    }
  }

  const isAnyFilterSelected = () => {
    return (
      Array.from(allSelectedOptions.values()).some((options) => options.length > 0) ||
      dateQuery != null
    )
  }

  const updateURLPaginationData = () => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('page', pagination.current_page)
    let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
    window.history.pushState(null, '', newRelativePathQuery)
  }

  const fetchClientsFromServer = async (loadingState = true) => {
    if (loadingState) {
      setFetchDataStatus({ ...fetchDataStatus, filters: true })
    }
    try {
      const response = await fetch(
        `${BACKEND_URL}/businesses/${cookie.load('business_id')}/transactions?client_names_only=true`,
        {
          method: 'GET',
          signal: abortController.signal,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        }
      )
      if (response.status === 401) {
        console.error('Authorization error', response)
        return
      }

      const data = await response.json()
      setClientsUid(data['result'])
    } catch (error) {
      console.error('Fetch error:', error)
    } finally {
      setFetchDataStatus({ ...fetchDataStatus, filters: false })
    }
  }

  const fetchDataFromServer = async () => {
    const per_page = pagination.per_page
    const page = pagination.current_page

    const response = await fetchWithAuth(
      `${BACKEND_URL}/businesses/${cookie.load('business_id')}/transactions?page=${page}&per_page=${per_page}${filterQuery || dateQuery || searchInput ? '&filter_search=true' : ''}${filterQuery ? filterQuery : ''}${dateQuery ? dateQuery : ''}${searchInput ? `&q=${searchInput}` : ''}`,
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
    if (response.status === 401) {
      return console.error(response)
    }
    const data = response

    setPagination({ ...pagination, total_pages: Math.ceil(data['count'] / pagination.per_page) })
    setData(data['table'])
    setIsFetching(false)
  }

  useEffect(() => {
    updateURLPaginationData()
    async function fetchAll() {
      await fetchDataFromServer()
    }
    fetchAll()
  }, [pagination.current_page, filterQuery, dateQuery, triggerSearch])

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      setTriggerSearch((prev) => !prev)
      pagination.current_page = 1
    }
  }

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
    pagination.current_page = 1
  }

  const handleEnterSearch = (e) => {
    if (e.key === 'Enter') {
      pagination.current_page = 1
      fetchDataFromServer()
    }
  }

  return (
    <div>
      <div>
        <div>
          <TopBar
            searchPlaceholder={''}
            userName={''}
            onToggleCollapse={handleToggleCollapse}
            collapsed={collapsed}
          />
        </div>
        <div>
          <OrderIndexView
            collapsed={collapsed}
            fetchDataStatus={fetchDataStatus}
            data={data}
            total_pages={totalPages}
            current_page={currentPage}
            setFilterQuery={setFilterQuery}
            filterQuery={filterQuery}
            searchQuery={searchInput}
            pagination={pagination}
            handlePaginationClick={handlePaginationClick}
            filters={filters}
            updateAllSelectedOptions={updateAllSelectedOptions}
            resetReactFilters={resetReactFilters}
            filterVersion={filterVersion}
            onSearch={handleSearch}
            handleSearchKeyPress={handleSearchKeyPress}
            isAnyFilterSelected={isAnyFilterSelected()}
            isFetching={isFetching}
            title={title}
            searchInput={searchInput}
            handleSearch={handleSearch}
            handleEnterSearch={handleEnterSearch}
            handleDateFilter={handleDateFilter}
            selectedDates={selectedDates}
          />
        </div>
      </div>
      <div>
        <NavBar activeItem="Pedidos" isEditing={false} collapsed={collapsed} />
      </div>
    </div>
  )
}
export default OrderIndexApp
