import React, { createContext, useReducer, useState } from 'react'
import { AssortedPromoReducer } from './AssortedPromoReducer'

export const AssortedPromoContext = createContext()

const AssortedPromoContextProvider = ({ children }) => {
  const [assortedProducts, dispatch] = useReducer(AssortedPromoReducer, [], () => [])

  const [assortedBonusProductQuantity, setAssortedBonusProductQuantity] = useState(0)

  const addAssortedProduct = (payload) => {
    dispatch({ type: 'ADD_ASSORTED_PRODUCT', payload })
  }

  const addAssortedProductQuantity = (payload) => {
    setAssortedBonusProductQuantity(payload.baseQuantity)
  }

  const removeAssortedProduct = (payload) => {
    dispatch({ type: 'REMOVE_ASSORTED_PRODUCT', payload })
  }

  const clearAssortedProducts = () => {
    dispatch({ type: 'CLEAR_ASSORTED_PRODUCTS' })
  }

  const changeBasePackageAssortedProducts = (payload) => {
    dispatch({ type: 'CHANGE_BASE_PACKAGE_ASSORTED_PRODUCTS', payload })
  }

  const contextValues = {
    addAssortedProduct,
    removeAssortedProduct,
    addAssortedProductQuantity,
    clearAssortedProducts,
    changeBasePackageAssortedProducts,
    assortedBonusProductQuantity,
    assortedProducts
  }

  return (
    <AssortedPromoContext.Provider value={contextValues}>{children}</AssortedPromoContext.Provider>
  )
}

export default AssortedPromoContextProvider
