import React, { createContext, useReducer } from 'react'
import { BonusPromoReducer } from './BonusPromoReducer'

export const BonusPromoContext = createContext()

const BonusPromoContextProvider = ({ children }) => {
  const [bonusProducts, dispatch] = useReducer(BonusPromoReducer, [], () => [])

  const addBonusProduct = (payload) => {
    dispatch({ type: 'ADD_TO_BONUS_PRODUCT', payload })
  }

  const changeBonusQuantityProduct = (payload) => {
    dispatch({ type: 'CHANGE_BONUS_QUANTITY_PRODUCT', payload })
  }

  const changeBonusPackageProduct = (payload) => {
    dispatch({ type: 'CHANGE_BONUS_PACKAGE_PRODUCT', payload })
  }

  const removeBonusProduct = (payload) => {
    dispatch({ type: 'REMOVE_BONUS_PRODUCT', payload })
  }

  const clearBonusProduct = () => {
    dispatch({ type: 'CLEAR_BONUS_PRODUCT' })
  }

  const changeLimitToBonus = (payload) => {
    dispatch({ type: 'CHANGE_LIMIT_TO_BONUS', payload})
  }

  const contextValues = {
    addBonusProduct,
    removeBonusProduct,
    changeBonusQuantityProduct,
    changeBonusPackageProduct,
    clearBonusProduct,
    changeLimitToBonus,
    bonusProducts
  }

  return <BonusPromoContext.Provider value={contextValues}>{children}</BonusPromoContext.Provider>
}

export default BonusPromoContextProvider
