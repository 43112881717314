import React, { createContext, useReducer } from 'react'
import { DiscountPromoReducer } from './DiscountPromoReducer'

export const DiscountPromoContext = createContext()

const DiscountPromoContextProvider = ({ children }) => {
  const [discountProducts, dispatch] = useReducer(DiscountPromoReducer, [], () => [])

  const addDiscountProduct = (payload) => {
    dispatch({ type: 'ADD_TO_DISCOUNT_PRODUCT', payload })
  }

  const changeDiscountProduct = (payload) => {
    dispatch({ type: 'CHANGE_DISCOUNT_PRODUCT', payload })
  }

  const removeDiscountProduct = (payload) => {
    dispatch({ type: 'REMOVE_DISCOUNT_PRODUCT', payload })
  }

  const clearDiscountProduct = () => {
    dispatch({ type: 'CLEAR_DISCOUNT_PRODUCT' })
  }
  const contextValues = {
    addDiscountProduct,
    removeDiscountProduct,
    changeDiscountProduct,
    clearDiscountProduct,
    discountProducts
  }

  return (
    <DiscountPromoContext.Provider value={contextValues}>{children}</DiscountPromoContext.Provider>
  )
}

export default DiscountPromoContextProvider
