import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'
import { Button, Modal } from 'react-bootstrap'
import ShowMapModalContent from '../map/ShowMapModalContent'
import { MapModalProvider } from '../../hook/MapModalProvider'
import {
  CHARGES,
  CANCELLED_CHARGES,
  STATUS_CANCELLED,
  STATUS_SEND
} from '../../../constants/chargeConstants'
import moment from 'moment'

export default function ShowUserChargeReportVisitsView(props) {
  const getTimestampFromDateTime = (date) => {
    const d = new Date(date)
    return d.toLocaleString()
  }

  const formatActivityName = (activity_name, motive_text, status) => {
    if (activity_name === CHARGES && status === STATUS_CANCELLED) {
      return CANCELLED_CHARGES
    } else if (activity_name === CHARGES && status === STATUS_SEND) {
      return 'COBRO'
    } else if (activity_name === CHARGES && status === null) {
      return activity_name
    } else {
      return activity_name + ': ' + motive_text
    }
  }

  const [show, setShow] = useState(false)
  const [dataSelected, setDataSelected] = useState(false)

  const [latitude, setLatitude] = useState(false)
  const [longitude, setLongitude] = useState(false)

  const handleClose = () => setShow(false)

  const handleShow = (model, event) => {
    setDataSelected(model)
    setLatitude(model.latitude)
    setLongitude(model.longitude)
    setShow(true)
  }

  if (props.isFetching === false) {
    return (
      <MapModalProvider>
        <div>
          <hr className="mt-90" />
          <div>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>FECHA Y HORA</th>
                  <th>CÓDIGO</th>
                  <th>CLIENTE</th>
                  <th>ACTIVIDAD</th>
                  <th>MONTO</th>
                  <th>UBICACIÓN</th>
                </tr>
              </thead>
              <tbody>
                {props.data.length > 0 &&
                  props.data.map((model, index) => (
                    <tr key={index}>
                      <td>{getTimestampFromDateTime(model['created_date'])}</td>
                      <td>{model['client_db_ref']}</td>
                      <td>{model['client_name']}</td>
                      {formatActivityName(
                        model['activity_name'],
                        model['motive_text'],
                        model['status']
                      ) == 'COBRO ANULADO' ? (
                        <td style={{ color: 'red' }}>
                          {formatActivityName(
                            model['activity_name'],
                            model['motive_text'],
                            model['status']
                          )}
                        </td>
                      ) : (
                        <td>
                          {formatActivityName(
                            model['activity_name'],
                            model['motive_text'],
                            model['status']
                          )}
                        </td>
                      )}
                      {model['total'] != null && model['status'] != 'cancelled' && (
                        <td>${parseFloat(model['total']).toFixed(2)}</td>
                      )}
                      {model['total'] != null && model['status'] == 'cancelled' && (
                        <td>-${parseFloat(model['total']).toFixed(2)}</td>
                      )}
                      {model['total'] == null && <td />}
                      <td>
                        <button
                          onClick={(e) => {
                            handleShow(model, e)
                          }}
                          className="unassign-price-list-button"
                          type="button"
                        >
                          <FontAwesomeIcon icon={faMapMarkedAlt} />
                        </button>
                      </td>
                    </tr>
                  ))}
                {props.data.length === 0 && (
                  <tr>
                    <td colSpan="7" className="td-text-limited td-text-limited-space">No hay resultados</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <>
            <Modal
              show={show}
              dialogClassName="modal-90w"
              onHide={handleClose}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  <p>Reporte: Efectividad de cobro - {props.salesman} </p>
                  <p>Detalles de captura de actividad</p>
                  <p style={{ marginLeft: 10, fontSize: 14 }}>
                    Cliente: {dataSelected.client_name}
                  </p>
                  <p style={{ marginLeft: 10, fontSize: 14 }}>
                    Fecha y hora: {moment(dataSelected.created_date).format('L')} -{' '}
                    {moment(dataSelected.created_date).format('LT')}
                  </p>
                  <p style={{ marginLeft: 10, fontSize: 14 }}>
                    Actividad: {dataSelected.activity_name}
                  </p>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div id="map-canvas">
                  <ShowMapModalContent
                    longitude={longitude}
                    clientLatitude={dataSelected.client_latitude}
                    clientLongitude={dataSelected.client_longitude}
                    latitude={latitude}
                    hasAllowedLocation={dataSelected.has_allowed_location}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        </div>
      </MapModalProvider>
    )
  } else {
    return (
      <div>
        <hr className="mt-90" />
        <div>
          <div className="table table-hover">
            <p className="text-align-center">Obteniendo datos ....</p>
          </div>
        </div>
      </div>
    )
  }
}
