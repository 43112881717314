import React from 'react'
import Pagination from '../layout/Pagination'
import SearchInput from '../layout/SearchInput'
import { Table } from 'react-bootstrap'

const PriceListIndexView = (props) => {
  const showModel = (event) => {
    event.preventDefault()
    const id = event.target.id
    window.open(`/listas_de_precios/${id}`)
  }

  const { data, isFetching, collapsed, title, query, onSearchUpdate, total_pages, current_page } =
    props

  if (isFetching) {
    return (
      <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
        <div className="index-header">
          <br />
          <p>Obteniendo datos ....</p>
        </div>
      </div>
    )
  } else {
    return (
      <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
        <div className="index-header detailed-container">
          <h2 className="mb-20">{title}</h2>
        </div>
        <div className="index-table">
          <div className="show-area">
            <div className="title-and-search-containter">
              <h4 className="text-sub-title">Listas de Precios:</h4>
              <div className="search-input-containter mb-20">
                <SearchInput
                  query={query}
                  onSearch={onSearchUpdate}
                  placeholder="Buscar  por código o nombre"
                />
              </div>
            </div>
            <Table hover responsive>
              <thead className="table-header-gray">
                <tr>
                  <th style={{ verticalAlign: 'middle', padding: '10px 25px', width: '40%' }}>
                    <span className="th-header-text">CÓDIGO</span>
                  </th>
                  <th style={{ verticalAlign: 'middle', width: '50%', textAlign: 'left' }}>
                    <span className="th-header-text">NOMBRE DE LISTA DE PRECIO</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  data.map((model, index) => (
                    <tr className="cursor-pointer" key={index}>
                      <td
                        className="td-text-limited"
                        style={{
                          verticalAlign: 'middle',
                          textAlign: 'left',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          padding: '10px 25px'
                        }}
                        id={model['id']}
                        onClick={showModel}
                      >
                        {model['db_ref']}
                      </td>
                      <td
                        className="td-text-limited"
                        id={model['id']}
                        onClick={showModel}
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {model['name']}
                      </td>
                    </tr>
                  ))}
                {data.length === 0 && (
                  <tr>
                    <td colSpan="3" className="td-text-limited td-text-limited-space">
                      No hay resultados
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <nav className="index-table">
          {total_pages > 1 && <Pagination current_page={current_page} total_pages={total_pages} />}
        </nav>
      </div>
    )
  }
}

export default PriceListIndexView
