import React, { Component } from 'react'
import NavBar from '../layout/NavBar'
import UserHeader from './component/UserHeader'
import DeliveryUserDetailView from './component/DeliveryUserDetailView'
import { DeliveryDetailConsumer, DeliveryDetailProvider } from './context/DeliveryDetailContext'
import DelivererRoutesTab from './DelivererRoutesTab'

class DeliveryUserDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      alertState: 'alert alert-success'
    }
  }

  hiddenAlert = () => {
    this.setState({
      alertState: 'alert alert-success hidden'
    })
  }

  render() {
    return (
      <DeliveryDetailProvider isEditing={this.isEditing} id={this.props.deliver}>
        <DeliveryDetailConsumer>
          {({
            isEditing,
            failedCreation,
            deliverer,
            handleTabClick,
            activeTab,
            changeEditMode
          }) => (
            <div>
              <UserHeader isFormEditing={isEditing} />
              <div className="main-view">
                {this.props.successCreateForNewDeliverer && (
                  <div className={this.state.alertState} role="alert">
                    <button
                      type="button"
                      className="close"
                      onClick={this.hiddenAlert}
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <strong>El entregador nuevo fue creado exitosamente.</strong>
                  </div>
                )}
                {isEditing === true && (
                  <div className="main-show-header">
                    <h2>
                      Actualizar detalles de entregador: {deliverer.db_ref + ' - ' + deliverer.name}
                    </h2>
                  </div>
                )}
                {failedCreation !== null && (
                  <div className="alert alert-danger" role="alert">
                    <strong>{failedCreation}</strong>
                  </div>
                )}
                {isEditing === false && (
                  <div className="main-show-header">
                    {deliverer !== '' ? (
                      <h2>{`Entregador: ${deliverer.db_ref} - ${deliverer.name}`}</h2>
                    ) : (
                      <h2>{`Entregador`}</h2>
                    )}
                  </div>
                )}
                <div>
                  <div className="index-table">
                    <div className="show-area-tab">
                      <div>
                        <div onClick={handleTabClick}>
                          <ul className="nav nav-tabs">
                            <li
                              id="details_tab"
                              className={`${activeTab === 'Detalles' ? 'active' : ''}`}
                            >
                              <a
                                className={
                                  activeTab !== 'Detalles'
                                    ? 'inactive-tab cursor-pointer'
                                    : 'cursor-pointer'
                                }
                              >
                                Detalles
                              </a>
                            </li>
                            <li
                              id="price_lists_tab"
                              className={activeTab === 'Ruta' ? 'active' : ''}
                            >
                              <a
                                className={
                                  activeTab !== 'Ruta'
                                    ? 'inactive-tab cursor-pointer'
                                    : 'cursor-pointer'
                                }
                              >
                                Ruta
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div>
                          {activeTab === 'Detalles' && (
                            <div>
                              <DeliveryUserDetailView
                                id={this.props.deliver}
                                successCreateForNewDeliverer={
                                  this.props.successCreateForNewDeliverer
                                }
                              />
                            </div>
                          )}
                          {activeTab === 'Ruta' && (
                            <div>
                              <DelivererRoutesTab
                                deliverer={deliverer}
                                changeEditMode={changeEditMode}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <NavBar activeItem="Equipo de campo" isEditing={isEditing} />
              </div>
              )}
            </div>
          )}
        </DeliveryDetailConsumer>
      </DeliveryDetailProvider>
    )
  }
}

export default DeliveryUserDetail
