import React, { useEffect, useState } from 'react'
import cookie from 'react-cookies'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import _, { chain } from 'lodash'
import { validateAccessRole } from '../helpers/userRole'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'

const ProductDetailsTab = (props) => {
  const [editing, setEditing] = useState(false)
  const [newProductInfo, setNewProductInfo] = useState([])
  const [categoryValue, setCategoryValue] = useState(0)
  const handleDropdownChange = (e) => {
    setCategoryValue(e.target.value)
  }

  const categoryFiltered = newProductInfo.category_subcategory_relation?.filter(
    (c) => c.category_id == categoryValue
  )

  useEffect(() => {
    fetchDataForEdit()
    getCurrentProduct()
  }, [])

  const setEditMode = (event) => {
    event.preventDefault()
    setEditing(true)
  }

  const validationSchema = Yup.object().shape({
    active: Yup.bool().default(true),
    code: Yup.string()
      .required('Este es un campo obligatorio.')
      .min(3, 'Asegúrese de cumplir con el mínimo de los 3 caracteres'),
    barcode: Yup.string()
      .required('Este es un campo obligatorio.')
      .min(3, 'Asegúrese de cumplir con el mínimo de los 3 caracteres'),
    name: Yup.string()
      .required('Este es un campo obligatorio.')
      .min(3, 'Asegúrese de cumplir con el mínimo de los 3 caracteres'),
    description: Yup.string().notRequired().nullable(),
    itbms: Yup.string().required('Este es un campo obligatorio.'),
    brand: Yup.string().required('Este es un campo obligatorio.'),
    unit: Yup.string().required('Este es un campo obligatorio.'),
    unitQuantity: Yup.number()
      .typeError('Este es un campo obligatorio.')
      .min(1, 'Debe ser mayor a 0'),
    splitUnit: Yup.boolean().when('unitQuantity', {
      is: (val) => parseInt(val) <= 1 || isNaN(parseInt(val)),
      then: Yup.boolean().oneOf([false], 'La unidad de venta debe ser mayor a 1')
    }),
    unitWeight: Yup.string()
      .transform((value) => (isNaN(value) ? '' : value))
      .notRequired()
      .nullable(),
    weight: Yup.number()
      .transform((value) => (isNaN(value) ? 0 : value))
      .notRequired()
      .nullable(),
    category: Yup.string().required('Este es un campo obligatorio.'),
    subCategory: Yup.string().notRequired().nullable(),
    image: Yup.string().notRequired().nullable()
  })

  const formOptions = { resolver: yupResolver(validationSchema) }
  const { watch, register, handleSubmit, reset, setValue, formState } = useForm(formOptions)
  const { errors } = formState

  const [description, setDescription] = useState('')
  const [nameproduct, setNameProduct] = useState('')

  const nameValue = watch('name')
  const descriptionValue = watch('description')
  useEffect(() => {
    setNameProduct(nameValue)
    setDescription(descriptionValue)
  }, [nameValue, descriptionValue])

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value)
  }

  const handleNameProductsChange = (event) => {
    setNameProduct(event.target.value)
  }

  const getCurrentProduct = () => {
    setValue('active', props.data.active)
    setValue('code', props.data.db_ref)
    setValue('barcode', props.data.barcode)
    setValue('description', props.data.description)
    setValue('name', props.data.name)
    setValue('itbms', props.data.tax ? props.data.tax.id : '')
    setValue('brand', props.data.brand ? props.data.brand.id : '')
    setValue('unit', props.data.package ? props.data.package.unit : '')
    setValue('package_id', props.data.package ? props.data.package.id : '')
    setValue('unitQuantity', props.data.quantity_per_package)
    setValue('splitUnit', props.data.has_package_per_unit)
    setValue('unitWeight', _.isEmpty(props.data.price_unit) ? '' : props.data.price_unit.id)
    setValue('weight', props.data.max_weight)
    setValue('category', props.data.categories.length > 0 ? props.data.categories[0].id : '')
    setCategoryValue(props.data.categories.length > 0 ? props.data.categories[0].id : '')
    setValue(
      'subCategory',
      props.data.subcategories.length > 0 ? parseInt(props.data.subcategories[0].id) : ''
    )
  }

  const unsetEditMode = (event) => {
    event.preventDefault()
    setEditing(false)
  }

  const fetchDataForEdit = () => {
    fetch(`${BACKEND_URL}/businesses/${cookie.load('business_id')}/products/new`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => setNewProductInfo(data))
      .catch((e) => console.log(e))
  }

  const handleCreateProduct = (data) => {
    const taxObject = newProductInfo.taxes.find((tax) => tax.id === parseInt(data.itbms))
    const tax_rate = taxObject ? taxObject.rate : null

    const url = USE_BUSINESS_URL
      ? `${BACKEND_URL}/businesses/${cookie.load('business_id')}/products/${props.id}`
      : `${BACKEND_URL}/products/${props.id}`

    fetch(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      },
      body: JSON.stringify({
        business_id: cookie.load('business_id'),
        active: data.active,
        price_unit_id: data.unitWeight ?? 0,
        db_ref: data.code,
        barcode: data.barcode,
        name: data.name,
        description: data.description,
        tax_id: parseInt(data.itbms),
        tax_rate: parseInt(tax_rate),
        brand_id: data.brand,
        package_id: data.package_id,
        unit: data.unit,
        quantity_per_package: data.unitQuantity,
        max_weight: data.weight,
        min_weight: data.weight,
        average_weight: data.weight,
        has_package_per_unit: data.splitUnit,
        goal_category_id: data.category ?? null,
        product_type_id: data.category ?? null,
        category_ids: data.category ? [data.category] : [],
        subcategory_ids: data.subCategory ? [data.subCategory] : []
    })
    })
      .then((response) => response.json())
      .then(() => {
        window.location = `/productos/${props.id}/detalles`
      })
      .catch((e) => console.log(e))
  }

  const getFormattedDate = (date) => {
    const d = new Date(date)
    return d.toLocaleString()
  }

  const data = props.data
  const checkdata = (value) => (value ? value : '-')

  let active = 'No'
  if (data['active'] === true) {
    active = 'Si'
  }

  let activePackage = 'No'
  if (data.has_package_per_unit === true) {
    activePackage = 'Si'
  }

  return (
    <div>
      {editing === false && (
        <div>
          <div className="show-product-header show-header">
            <h2>Detalles de producto:</h2>
            <div>
              {validateAccessRole(cookie.load('user_role')?.product_module)?.can_write && (
                <div className="show-buttons-responsive">
                  <input onClick={setEditMode} value="Actualizar detalles" type="submit" />
                </div>
              )}
            </div>
          </div>
          <hr className="mb-30" />
          <div>
            <div>
              <table cellSpacing="10">
                <tbody>
                  <tr>
                    <td>
                      <div className="mr-85 mb-20">
                        <b>Activo:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">{active}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-20">
                        <b>Código interno:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">{checkdata(data.db_ref)}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-20">
                        <b>Código de barra:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">{checkdata(data.barcode)}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-20">
                        <b>Nombre de producto:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">{checkdata(data.name)}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-20">
                        <b>Descripción:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20 initial-height">{checkdata(data.description)}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-20">
                        <b>ITBMS:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20 initial-height">{checkdata(data.tax.name)}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-20">
                        <b>Marca:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">{checkdata(data.brand.description)}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-20">
                        <b>Unidad de venta 1:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">
                        {checkdata(data.package.unit)} de{' '}
                        {checkdata(data.quantity_per_package)}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-20">
                        <b>Venta de empaque en unidad:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">{activePackage}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-20">
                        <b>Peso por unidad:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">
                        {checkdata(data.max_weight)}{' '}
                        {checkdata(data.price_unit.description)}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-20">
                        <b>Categoría:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">
                        {data.categories.length > 0
                          ? data.categories.map((obj) => obj.description).join(', ')
                          : '-'}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-20">
                        <b>Subcategoría:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">
                        {data.subcategories.length > 0
                          ? data.subcategories.map((obj) => obj.description).join(', ')
                          : '-'}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-20">
                        <b>Fecha de creación:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">{getFormattedDate(data.created_at)}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-20">
                        <b>Fecha de actualización:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">{getFormattedDate(data.updated_at)}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {editing === true && (
        <>
          <form onSubmit={handleSubmit(handleCreateProduct)}>
            <div className="inline-block full-width">
              <div className="show-product-header show-header">
                <h2>Detalles de producto:</h2>
                <div className="show-buttons-responsive">
                  <input
                    onClick={unsetEditMode}
                    className="cancel-edit-button"
                    value="Cancelar"
                    type="submit"
                  />
                  <input value="Guardar" type="submit" />
                </div>
              </div>
            </div>
            <hr className="margin-product-details" />

            <div className="form-group row">
              <label className="col-sm-3 col-form-label">Activo:</label>
              <div className="col-sm-8">
                <select
                  name="active"
                  {...register('active')}
                  className={`form-control custom-select ${errors.active ? 'is-invalid' : ''}`}
                >
                  <option value="true">Si</option>
                  <option value="false">No</option>
                </select>
                <div className="invalid-feedback">{errors.active?.message}</div>
              </div>
            </div>

            <div className="form-group row show-data-form">
              <label htmlFor="internalCode" className="col-sm-3 col-form-label">
                Código interno: <span className="red">*</span>
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  id="internalCode"
                  className="form-control"
                  disabled
                  value={data.db_ref}
                  style={{ backgroundColor: '#f0f0f0' }}
                />
              </div>
            </div>

            <div className="form-group row show-data-form">
              <label htmlFor="inputName" className="col-sm-3 col-form-label">
                Código de barra: <span className="red">*</span>
              </label>
              <div className="col-sm-8">
                <input
                  name="barcode"
                  type="text"
                  {...register('barcode')}
                  className={`form-control ${errors.barcode ? 'is-invalid' : ''}`}
                  placeholder="0123-4567"
                />
                <div className="invalid-feedback">{errors.barcode?.message}</div>
              </div>
            </div>

            <div className="form-group row show-data-form">
              <label htmlFor="inputCode" className="col-sm-3 col-form-label">
                Nombre de producto: <span className="red">*</span>
              </label>
              <div className="col-sm-8">
                <input
                  name="name"
                  type="text"
                  {...register('name')}
                  maxLength="50"
                  className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                  placeholder="Nombre"
                  value={nameproduct}
                  onChange={handleNameProductsChange}
                />
                <span className="float-right" style={{ color: '#808080' }}>
                  Cantidad máxima de caracteres {Math.abs(nameproduct.length - 50)}/50
                </span>
                <div className="invalid-feedback">{errors.name?.message}</div>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputCode" className="col-sm-3 col-form-label">
                Descripción:
              </label>
              <div className="col-sm-8">
                <textarea
                  name="description"
                  autoComplete="off"
                  maxLength="400"
                  {...register('description')}
                  className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                  placeholder="Descripción del producto"
                  value={description}
                  onChange={handleDescriptionChange}
                />
                <span className="float-right" style={{ color: '#808080' }}>
                  Cantidad máxima de caracteres {Math.abs(description.length - 400)}/400
                </span>
                <div className="invalid-feedback">{errors.description?.message}</div>
              </div>
            </div>

            <div className="form-group row show-data-form">
              <label htmlFor="inputCode" className="col-sm-3 col-form-label">
                ITBMS: <span className="red">*</span>
              </label>
              <div className="col-sm-8">
                <select
                  name="itbms"
                  {...register('itbms')}
                  className={`form-control custom-select ${errors.itbms ? 'is-invalid' : ''}`}
                >
                  <option value="">Escoja una opción</option>

                  {newProductInfo.taxes != null
                    ? newProductInfo.taxes
                        .filter((v) => v.name)
                        .map((obj) => (
                          <option key={obj.id} value={obj.id}>{`ITBMS - ${obj.name}`}</option>
                        ))
                    : []}
                </select>
                <div className="invalid-feedback">{errors.itbms?.message}</div>
              </div>
            </div>

            <div className="form-group row show-data-form">
              <label htmlFor="inputCode" className="col-sm-3 col-form-label">
                Marca: <span className="red">*</span>
              </label>
              <div className="col-sm-8">
                <select
                  name="brand"
                  {...register('brand')}
                  className={`form-control custom-select ${errors.brand ? 'is-invalid' : ''}`}
                >
                  <option value="">Escoja una opción</option>

                  {newProductInfo.brand != null
                    ? newProductInfo.brand
                        .sort((a, b) => a.description.localeCompare(b.description))
                        .map((obj) => (
                          <option key={obj.id} value={obj.id}>
                            {obj.description}
                          </option>
                        ))
                    : []}
                </select>
                <div className="invalid-feedback">{errors.brand?.message}</div>
              </div>
            </div>

            <div className="form-group row show-data-form">
              <label htmlFor="inputCode" className="col-sm-3 col-form-label">
                Unidad de venta 1: <span className="red">*</span>
              </label>

              <div className="col-sm-3">
                <select
                  name="unit"
                  {...register('unit')}
                  className={`form-control custom-select ${errors.unit ? 'is-invalid' : ''}`}
                >
                  <option value="">Escoja una opción</option>
                  {newProductInfo.package_units != null
                    ? newProductInfo.package_units
                        .sort((a, b) => a.description.localeCompare(b.description))
                        .map((obj) => (
                          <option key={obj.id} value={obj.description}>
                            {obj.description}
                          </option>
                        ))
                    : []}
                </select>
                <div className="invalid-feedback">{errors.unit?.message}</div>
              </div>

              <div className="col-sm-1 text-center" style={{ paddingTop: 5 }}>
                de
              </div>

              <div className="col-sm-3">
                <input
                  name="unitQuantity"
                  type="number"
                  {...register('unitQuantity')}
                  className={`form-control ${errors.unitQuantity ? 'is-invalid' : ''}`}
                  placeholder="00"
                />
                <div className="invalid-feedback">{errors.unitQuantity?.message}</div>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputCode" className="col-sm-3 col-form-label" />
              <div className="col-sm-8">
                <input
                  name="splitUnit"
                  {...register('splitUnit')}
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                />
                <label
                  className="form-check-label"
                  htmlFor="exampleCheck1"
                  style={{ paddingLeft: 10, fontWeight: 500, color: '#27588D' }}
                >
                  Activar venta de empaque en unidad
                </label>
                <div className="invalid-feedback">{errors.splitUnit?.message}</div>
              </div>
            </div>

            <div className="form-group row show-data-form">
              <label htmlFor="inputCode" className="col-sm-3 col-form-label">
                Peso por unidad: <span className="red">*</span>
              </label>
              <div className="col-sm-3">
                <input
                  name="weight"
                  type="number"
                  step="0.01"
                  {...register('weight')}
                  className={`form-control ${errors.weight ? 'is-invalid' : ''}`}
                  placeholder="00"
                />
                <div className="invalid-feedback">{errors.weight?.message}</div>
              </div>
              <div className="col-sm-1 text-center" style={{ paddingTop: 5 }}>
                Unidad
              </div>
              <div className="col-sm-3">
                <select
                  name="unitWeight"
                  {...register('unitWeight')}
                  className={`form-control custom-select select-unit-weight ${errors.unitWeight ? 'is-invalid' : ''}`}
                >
                  <option value="">Escoja una opción</option>

                  {newProductInfo.price_unit != null
                    ? newProductInfo.price_unit
                        .sort((a, b) => a.description.localeCompare(b.description))
                        .map((obj) => (
                          <option key={obj.id} value={obj.id}>
                            {obj.description}
                          </option>
                        ))
                    : []}
                  <option value={null} />
                </select>
                <div className="invalid-feedback">{errors.unitWeight?.message}</div>
              </div>
            </div>

            <div className="form-group row show-data-form">
              <label htmlFor="inputCode" className="col-sm-3 col-form-label">
                Categoría: <span className="red">*</span>
              </label>
              <div className="col-sm-8">
                <select
                  name="category"
                  {...register('category')}
                  className={`form-control custom-select ${errors.category ? 'is-invalid' : ''}`}
                  onChange={handleDropdownChange}
                >
                  <option value="">Escoja una opción</option>

                  {newProductInfo.categories != null
                    ? newProductInfo.categories
                        .sort((a, b) => a.description.localeCompare(b.description))
                        .map((obj) => (
                          <option key={obj.id} value={obj.id}>
                            {obj.description}
                          </option>
                        ))
                    : []}
                </select>
                <div className="invalid-feedback">{errors.category?.message}</div>
              </div>
            </div>
            <div className="form-group row show-data-form">
              <label htmlFor="inputCode" className="col-sm-3 col-form-label">
                Subcategoría:
              </label>
              <div className="col-sm-8">
                <select
                  name="subCategory"
                  {...register('subCategory')}
                  className={`form-control custom-select ${errors.subCategory ? 'is-invalid' : ''}`}
                >
                  <option value="">Escoja una opción</option>
                  {categoryFiltered != null
                    ? categoryFiltered
                        .sort((a, b) =>
                          a.subcategory_description.localeCompare(b.subcategory_description)
                        )
                        .map((obj) => (
                          <option key={obj.id} value={obj.subcategory_id}>
                            {obj.subcategory_description}
                          </option>
                        ))
                    : []}
                </select>
                <div className="invalid-feedback">{errors.subCategory?.message}</div>
              </div>
            </div>
            <input type="hidden" name="package_id" {...register('package_id')} />
          </form>
        </>
      )}
    </div>
  )
}

export default ProductDetailsTab
