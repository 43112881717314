import React from 'react'
import BaseCard from './BaseCard'

const GeoCard = ({ geoData }) => {
  return (
    <BaseCard background={'/assets/blur-map.svg'}>
      <p className="p-card-title">Dentro de Geocerca</p>
      <div className="geomap">
        <div className="geo-numbers-container">
          <div>
            <img draggable={false} className="geo-icon" src="assets/location.svg" />
          </div>
          <div>
            <p className="p-card-number">{geoData}</p>
          </div>
        </div>
      </div>
    </BaseCard>
  )
}

export default GeoCard
