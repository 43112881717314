import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import cookie from 'react-cookies'
import { DISCARD_CHANGES_CONFIRMATION } from '../constants/appMessages'
import history from '../helpers/history'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const CreateNewClientView = ({ collapsed, title }) => {
  const [isFetching, setIsFetching] = useState(true)
  const [failedCreation, setFailedCreation] = useState(false)
  const [data, setData] = useState(null)
  const [failedCreationInfo, setFailedCreationInfo] = useState('')
  const [formState, setFormState] = useState({
    client_group_id: '',
    sales_channel_id: '',
    payment_type_id: '',
    credit_term_id: '',
    country_id: '',
    province_id: '',
    district_id: '',
    town_id: '',
    price_list_id: '',
    db_ref: '',
    active: true,
    taxpayer_id: '',
    taxpayer_verificator_digit: '',
    business_id: cookie.load('business_id'),
    business_name: '',
    commercial_name: '',
    customer_name_db_ref: '',
    credit_limit: '',
    legal_representative: '',
    billing_contact_name: '',
    billing_contact_email: '',
    billing_contact_phone: '',
    contact_name: '',
    contact_email: '',
    contact_phone: '',
    city: '',
    street: '',
    location_description: '',
    geographical_latitude: '',
    geographical_longitud: ''
  })

  const [countries, setCountries] = useState([])
  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [towns, setTowns] = useState([])

  const history = useHistory()

  useEffect(() => {
    fetchDataToCreate()
  }, [])

  const fetchDataToCreate = () => {
    Promise.all([
      fetch(`${BACKEND_URL}/businesses/${cookie.load('business_id')}/clients/new`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }).then((response) => response.json()),
      fetch(`${BACKEND_URL}/countries`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }).then((response) => response.json())
    ])
      .then(([clientData, countriesData]) => {
        setData(clientData)
        setCountries(countriesData.result)
        setIsFetching(false)
      })
      .catch((e) => console.log(e))
  }

  const fetchProvinces = (country_id) => {
    fetch(`${BACKEND_URL}/provinces/${country_id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        const provincesArray = Array.isArray(data) ? data : [data]
        setProvinces(provincesArray)
        setDistricts([])
        setTowns([])
        setFormState((prevState) => ({
          ...prevState,
          province_id: '',
          district_id: '',
          town_id: ''
        }))
      })
      .catch((e) => console.log(e))
  }

  const fetchDistricts = (province_id) => {
    fetch(`${BACKEND_URL}/districts/${province_id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        const districtsArray = Array.isArray(data) ? data : [data]
        setDistricts(districtsArray)
        setTowns([])
        setFormState((prevState) => ({
          ...prevState,
          district_id: '',
          town_id: ''
        }))
      })
      .catch((e) => console.log(e))
  }

  const fetchTowns = (district_id) => {
    fetch(`${BACKEND_URL}/towns/${district_id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        const townsArray = Array.isArray(data) ? data : [data]
        setTowns(townsArray)
        setFormState((prevState) => ({ ...prevState, town_id: '' }))
      })
      .catch((e) => console.log(e))
  }

  const handleCreateClientSubmit = (event) => {
    event.preventDefault()
    if (!isFetching) {
      fetch(`${BACKEND_URL}/businesses/${cookie.load('business_id')}/clients`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify(formState)
      })
        .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
        .then((obj) => evaluateResponse(obj))
        .catch((e) => console.log(e))
    }
  }

  const evaluateResponse = (obj) => {
    switch (obj.status) {
      case 422:
        setFailedCreationInfo('Verifique la información')
        setFailedCreation(true)
        break
      case 409:
        setFailedCreationInfo(
          'No se puede crear cliente. Código ya está en uso. Verifique los datos del cliente'
        )
        setFailedCreation(true)
        break
      case 404:
        setFailedCreationInfo('Servidor fuera de servicio')
        setFailedCreation(true)
        break
      case 500:
        setFailedCreationInfo('Error interno en el sistema')
        setFailedCreation(true)
        break
      default:
        const data = obj.body
        setFailedCreation(false)
        setFailedCreationInfo('')
        history.push({
          pathname: `/clientes/${data['id']}/detalles`,
          params: { successCreateForNewClient: true }
        })
        break
    }
  }

  const handleCancelCreate = (event) => {
    event.preventDefault()
    if (window.confirm(DISCARD_CHANGES_CONFIRMATION)) {
      window.location.href = '/clientes'
    }
  }
  const handleChange = (event) => {
    const value = event.target.value
    const name = event.target.name
    setFormState((prevState) => ({ ...prevState, [name]: value }))

    // Load dependent data based on selection
    if (name === 'country_id') {
      fetchProvinces(value)
    } else if (name === 'province_id') {
      fetchDistricts(value)
    } else if (name === 'district_id') {
      fetchTowns(value)
    }
  }

  return (
    <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
      {failedCreation === true && (
        <div className="alert alert-danger" role="alert">
          <strong>{failedCreationInfo}</strong>
        </div>
      )}
      <div>
        <div className="show-title">
          <h2>{title}</h2>
        </div>
      </div>
      <div className="index-table">
        {!isFetching && (
          <div className="show-area">
            <form onSubmit={handleCreateClientSubmit} className="create-new-salesman">
              <div className="inline-block full-width">
                <div className="float-left create-new-salesman__form-description">
                  <div>Ingrese los siguientes datos para crear un cliente nuevo</div>
                </div>
                <div className="float-right">
                  <button onClick={handleCancelCreate} className="cancel-button">
                    Cancelar
                  </button>
                  <input className="save-button" value="Guardar" type="submit" />
                </div>
              </div>
              <hr />
              <div className="red mb-10">
                Por favor llene los campos obligatorios con * para poder proceder
              </div>
              {data && (
                <>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Grupo de Cliente:
                    </label>
                    <select
                      name="client_group_id"
                      onChange={handleChange}
                      className={`form-control custom-select`}
                    >
                      <option value="" hidden disabled selected>
                        Escoja una opción
                      </option>
                      {data['client_group']?.map((obj) => (
                        <option key={obj.id} value={obj.id}>
                          {obj['description']}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Canal de Ventas:
                    </label>

                    <select
                      name="sales_channel_id"
                      onChange={handleChange}
                      className={`form-control custom-select`}
                    >
                      <option value="" hidden disabled selected>
                        Escoja una opción
                      </option>
                      {data['sales_channel']?.map((obj) => (
                        <option key={obj.id} value={obj.id}>
                          {obj['description']}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Tipo de Pago:
                    </label>
                    <select
                      name="payment_type_id"
                      onChange={handleChange}
                      className={`form-control custom-select`}
                    >
                      <option value="" hidden disabled selected>
                        Escoja una opción
                      </option>
                      {data['payment_type']?.map((obj) => (
                        <option key={obj.id} value={obj.id}>
                          {obj['description']}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Plazo de Crédito:
                    </label>
                    <select
                      name="credit_term_id"
                      onChange={handleChange}
                      className={`form-control custom-select`}
                    >
                      <option value="" hidden disabled selected>
                        Escoja una opción
                      </option>
                      {data['credit_term']?.map((obj) => (
                        <option key={obj.id} value={obj.id}>
                          {obj['description']}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="data-row">
                    <label htmlFor="country_id" className="col-sm-3 col-form-label">
                      País:
                    </label>
                    <select
                      name="country_id"
                      value={formState.country_id}
                      onChange={handleChange}
                      className={`form-control custom-select`}
                    >
                      <option value="" hidden disabled>
                        Escoja una opción
                      </option>
                      {countries.map((obj) => (
                        <option key={obj.id} value={obj.id}>
                          {obj.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="data-row">
                    <label htmlFor="province_id" className="col-sm-3 col-form-label">
                      Provincia:
                    </label>
                    <select
                      name="province_id"
                      value={formState.province_id}
                      onChange={handleChange}
                      className={`form-control custom-select`}
                    >
                      <option value="" hidden disabled>
                        Escoja una opción
                      </option>
                      {provinces.map((obj) => (
                        <option key={obj.id} value={obj.id}>
                          {obj.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="data-row">
                    <label htmlFor="district_id" className="col-sm-3 col-form-label">
                      Distrito:
                    </label>
                    <select
                      name="district_id"
                      value={formState.district_id}
                      onChange={handleChange}
                      className={`form-control custom-select`}
                    >
                      <option value="" hidden disabled>
                        Escoja una opción
                      </option>
                      {districts.map((obj) => (
                        <option key={obj.id} value={obj.id}>
                          {obj.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="data-row">
                    <label htmlFor="town_id" className="col-sm-3 col-form-label">
                      Corregimiento:
                    </label>
                    <select
                      name="town_id"
                      value={formState.town_id}
                      onChange={handleChange}
                      className={`form-control custom-select`}
                    >
                      <option value="" hidden disabled>
                        Escoja una opción
                      </option>
                      {towns.map((obj) => (
                        <option key={obj.id} value={obj.id}>
                          {obj.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Lista de Precio que le aplica: <span className="red">*</span>
                    </label>

                    <select
                      required
                      name="price_list_id"
                      onChange={handleChange}
                      className={`form-control custom-select`}
                    >
                      <option value="" hidden disabled selected>
                        Escoja una opción
                      </option>
                      {data['price_list']?.map((obj) => (
                        <option key={obj.id} value={obj.id}>
                          {obj['description']}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Código cliente: <span className="red">*</span>
                    </label>

                    <input
                      type="text"
                      name="db_ref"
                      onChange={handleChange}
                      value={formState.db_ref}
                      className="form-control"
                      required
                      placeholder="1234"
                    />
                  </div>

                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Activo:
                    </label>
                    <select
                      name="active"
                      onChange={handleChange}
                      className={`form-control custom-select`}
                    >
                      <option value="true">Si</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Ruc:
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="taxpayer_id"
                      value={formState.taxpayer_id}
                      className="form-control"
                      placeholder="0000000-0-000000"
                    />
                  </div>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      DV:
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="taxpayer_verificator_digit"
                      value={formState.taxpayer_verificator_digit}
                      className="form-control"
                      placeholder="00"
                    />
                  </div>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Razón Social:
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="business_name"
                      value={formState.business_name}
                      className="form-control"
                      placeholder="Razón Social"
                    />
                  </div>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Nombre Comercial:
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="commercial_name"
                      value={formState.commercial_name}
                      className="form-control"
                      placeholder="Nombre comercial"
                    />
                  </div>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Nombre de cliente en sistema: <span className="red">*</span>
                    </label>

                    <input
                      type="text"
                      onChange={handleChange}
                      name="customer_name_db_ref"
                      value={formState.customer_name_db_ref}
                      className="form-control"
                      required
                      placeholder="Nombre cliente en sistema"
                    />
                  </div>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Monto de Crédito:
                    </label>
                    <input
                      min="0"
                      max="999999"
                      type="number"
                      onChange={handleChange}
                      name="credit_limit"
                      value={formState.credit_limit}
                      className="form-control"
                      placeholder="0.00"
                    />
                  </div>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Representante Legal:
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="legal_representative"
                      value={formState.legal_representative}
                      className="form-control"
                      placeholder="Nombre representante legal"
                    />
                  </div>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Persona de contacto para cobro:
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="billing_contact_name"
                      value={formState.billing_contact_name}
                      className="form-control"
                      placeholder="Persona de contacto para cobro"
                    />
                  </div>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Correo electrónico de pago:
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="billing_contact_email"
                      value={formState.billing_contact_email}
                      className="form-control"
                      placeholder="Correo electrónico de pago"
                    />
                  </div>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Pago Teléfono:
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="billing_contact_phone"
                      value={formState.billing_contact_phone}
                      className="form-control"
                      placeholder="0000-0000"
                    />
                  </div>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Persona de contacto:
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="contact_name"
                      value={formState.contact_name}
                      className="form-control"
                      placeholder="Nombre persona de contacto"
                    />
                  </div>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Correo electronico:
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="contact_email"
                      value={formState.contact_email}
                      className="form-control"
                      placeholder="Correo electrónico persona de contacto"
                    />
                  </div>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Teléfono:
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="contact_phone"
                      value={formState.contact_phone}
                      className="form-control"
                      placeholder="0000-0000"
                    />
                  </div>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Ciudad:
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="city"
                      value={formState.city}
                      className="form-control"
                      placeholder="Ciudad"
                    />
                  </div>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Calle:
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="street"
                      value={formState.street}
                      className="form-control"
                      placeholder="Calle"
                    />
                  </div>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Guía de Ubicación:
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="location_description"
                      value={formState.location_description}
                      className="form-control"
                      placeholder="Guía de Ubicación"
                    />
                  </div>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Coordenadas en grados decimales (latitud):
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="geographical_latitude"
                      value={formState.geographical_latitude}
                      className="form-control"
                      placeholder="00.000000"
                    />
                  </div>
                  <div className="data-row">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Coordenadas en grados decimales (longitud):
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="geographical_longitud"
                      value={formState.geographical_longitud}
                      className="form-control"
                      placeholder="00.000000"
                    />
                  </div>
                  <div className="data-row hidden">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Ruta asignada:
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="route_id"
                      value={formState.route_id}
                    />
                  </div>
                </>
              )}
            </form>
          </div>
        )}
        {isFetching && (
          <div className="scroll-section">
            <p>Obteniendo datos</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default CreateNewClientView
