import React, { createContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { handleTabClick } from '../helpers/UserConfigHandlers'

const initialState = {}

const UserStepTabsContext = createContext(initialState)

const UserStepTabsProvider = (props) => {
  const [activeTab, setActiveTab] = useState('Detalles')
  const [isEditing, setEditing] = useState(false)

  const history = useHistory()

  const handleCreateNewDeliverUser = () => {
    history.push('/crear_usuario_configuracion')
  }

  const handleTabClickDetails = (event) => {
    const value = handleTabClick(event, isEditing)
    setActiveTab(value.activeTab)
    setEditing(value.editing)
  }

  return (
    <UserStepTabsContext.Provider
      value={{
        activeTab,
        isEditing,
        handleTabClickDetails
      }}
    >
      {props.children}
    </UserStepTabsContext.Provider>
  )
}
export { UserStepTabsContext, UserStepTabsProvider }
