import React, { useState, useEffect, useRef } from 'react'
import cookie from 'react-cookies'
import ReactDOM from 'react-dom'
import Pagination from '../layout/Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import SearchInput from '../layout/SearchInput'
import { Table } from 'react-bootstrap'

const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
const BACKEND_URL = USE_BUSINESS_URL
  ? `${process.env.REACT_APP_BACKEND_URL}/businesses/${cookie.load('business_id')}`
  : process.env.REACT_APP_BACKEND_URL

const ProductsPriceListTab = ({ id }) => {
  const searchInput = useRef(null)
  const [data, setData] = useState([])
  const [isFetching, setIsFetching] = useState(true)
  const [perPage] = useState(25)
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState('')

  useEffect(() => {
    fetchProducts()
  }, [query, currentPage])

  const fetchProducts = () => {
    setIsFetching(true)

    fetch(
      `${BACKEND_URL}/price_lists/${id}/product_pricelist?page=${currentPage}&per_page=${perPage}${query ? `&q=${query}` : ''}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookie.load('token')}`
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData(data.table)
        setIsFetching(false)
        setTotalPages(Math.ceil(data.count / perPage))
      })
      .catch((e) => console.log(e))
  }

  const handlePaginationClick = (event) => {
    if (event.target.tagName === 'A') {
      handlePagination(event.target)
    } else if (event.target.tagName === 'svg') {
      handlePagination(event.target.parentNode)
    } else if (event.target.tagName === 'path') {
      handlePagination(event.target.parentNode.parentNode)
    }
  }

  const handlePagination = (target) => {
    if (target.id === 'first-page' && currentPage !== 1) {
      setCurrentPage(1)
    } else if (target.id === 'last-page' && currentPage !== totalPages) {
      setCurrentPage(totalPages)
    } else if (target.id === 'previous-page' && currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    } else if (target.id === 'next-page' && currentPage !== totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handleSearch = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      setQuery(event.target.value)
      setCurrentPage(1)
    }
  }

  const deleteSearch = () => {
    setQuery('')
    setCurrentPage(1)
    searchInput.current.value = ''
  }

  const showModel = (event) => {
    event.preventDefault()
    const id = event.target.id
    window.location = `/productos/${id}/detalles`
  }

  return (
    <div>
      <div className="title-and-search-containter mt-30">
        <h4>Productos asignados a esta lista de precio:</h4>
        <div onKeyDown={handleSearch} className="search-input-containter mb-20">
          <SearchInput
            ref={searchInput}
            query={query}
            onSearch={setQuery}
            placeholder="Buscar clientes por código, nombre"
          />
        </div>
      </div>
      <div>
        <Table hover responsive>
          <thead className="table-header-gray">
            <tr>
              <th
                style={{
                  verticalAlign: 'middle',
                  padding: '10px 25px',
                  width: '100px',
                  textAlign: 'center'
                }}
              >
                <span className="th-header-text">CÓDIGO</span>
              </th>
              <th style={{ verticalAlign: 'middle', padding: '10px 25px', textAlign: 'center' }}>
                <span className="th-header-text">NOMBRE</span>
              </th>
              <th style={{ verticalAlign: 'middle', padding: '10px 25px', textAlign: 'center' }}>
                <span className="th-header-text">UNIDAD DE VENTA</span>
              </th>
              <th style={{ verticalAlign: 'middle', padding: '10px 25px', textAlign: 'center' }}>
                <span className="th-header-text-end-price-details">PRECIO</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {isFetching && (
              <tr>
                <td colSpan="4" className="td-text-limited td-text-limited-space">
                  Obteniendo datos...
                </td>
              </tr>
            )}
            {!isFetching &&
              data?.length > 0 &&
              data?.map((model, index) => (
                <tr className="cursor-pointer" key={index}>
                  <td className="td-text-limited" id={model.id} onClick={showModel}>
                    {model.db_ref}
                  </td>
                  <td className="td-text-limited" id={model.id} onClick={showModel}>
                    {model.name}
                  </td>
                  <td className="td-text-limited" id={model.id} onClick={showModel}>
                    {model.package ? model.package.description : ''}
                  </td>
                  <td
                    style={{ display: 'flex', justifyContent: 'center' }}
                    id={model.id}
                    onClick={showModel}
                  >
                    {model.price}
                  </td>
                </tr>
              ))}
            {!isFetching && data?.length === 0 && (
              <tr>
                <td colSpan="4" className="td-text-limited td-text-limited-space">
                  No hay resultados
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {totalPages > 1 && (
          <div onClick={handlePaginationClick}>
            <Pagination current_page={currentPage} total_pages={totalPages} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductsPriceListTab
