import React from 'react'

const BaseCard = ({ children, background, addClass }) => {
  return (
    <div
      className={'shadow base-card-container ' + addClass || ''}
      style={{ backgroundImage: background ? `url(${background})` : null }}
    >
      {children}
    </div>
  )
}

export default BaseCard
