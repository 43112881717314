import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import ShowPromoView from './ShowPromoView'

const ShowPromoApp = () => {
  const [userName] = useState('Ana Sofia Lau Hou');
  const [searchPlaceholder] = useState('Buscar promociones por código, descripción, o productos base');
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const collapsedState = localStorage.getItem('collapsedState') === 'true';
    setCollapsed(collapsedState);
  }, []);

  const handleSearch = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      const query = event.target.value;
      if (query !== '') {
        window.location = `/promociones?query=${query}`;
        event.target.value = '';
      }
    }
  };

  const handleToggleCollapse = (collapsed) => {
    setCollapsed(collapsed);
    localStorage.setItem('collapsedState', collapsed);
  };

  return (
    <div>
      <div onKeyDown={handleSearch}>
        <TopBar
          searchPlaceholder={searchPlaceholder}
          userName={userName}
          onToggleCollapse={handleToggleCollapse}
          collapsed={collapsed}
        />
      </div>
      <div>
        <ShowPromoView collapsed={collapsed} />
      </div>
      <div>
        <NavBar activeItem="Promociones" isEditing={false} collapsed={collapsed} />
      </div>
    </div>
  );
};

export default ShowPromoApp;
