import React, { useState, useEffect } from 'react'
import cookie from 'react-cookies'
import ProductPriceListRow from './ProductPriceListRow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faDollarSign, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { validateAccessRole } from '../helpers/userRole'
import { Table } from 'react-bootstrap'

const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
const BASE_BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const BACKEND_URL = USE_BUSINESS_URL
  ? `${BASE_BACKEND_URL}/businesses/${cookie.load('business_id')}`
  : BASE_BACKEND_URL


const ProductPriceListsTab = ({ id }) => {
  const [priceListsData, setPriceListsData] = useState({ table: [] })
  const [isFetching, setIsFetching] = useState(true)
  const [addNewPriceList, setAddNewPriceList] = useState(false)
  const [missingPriceLists, setMissingPriceLists] = useState([])
  const [price, setPrice] = useState(null)
  const [priceListId, setPriceListId] = useState(null)
  const [invalidPriceListId, setInvalidPriceListId] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [pricelistCode, setPricelistCode] = useState(null)

  useEffect(() => {
    fetchDataFromServer()
  }, [])

  const handleSearch = (event) => {
    setSearchInput(event.target.value)
  }

  const handleEnterSearch = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      fetchDataFromServer()
    }
  }

  const fetchDataFromServer = () => {
    setIsFetching(true)

    fetch(`${BACKEND_URL}/products/${id}/product_pricelist/missing_pricelists`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => setMissingPriceLists(data || []))
      .catch((e) => {
        console.log(e)
        setMissingPriceLists([])
      })

    fetch(`${BASE_BACKEND_URL}/products/${id}/product_pricelist`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        const filteredData = filterDataBySearchTerm(data, searchInput)
        setPriceListsData(filteredData)
        setIsFetching(false)
      })
      .catch((e) => {
        console.log(e)
        setPriceListsData({ table: [] })
        setIsFetching(false)
      })
  }

  const filterDataBySearchTerm = (data, searchTerm) => {
    if (!searchTerm) {
      return data
    }

    const filteredData = data['table'].filter((obj) =>
      Object.values(obj).some((value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    )

    return { ...data, table: filteredData }
  }

  const setOptionsForMissingPriceLists = () => {
    if (!Array.isArray(missingPriceLists)) return null

    return missingPriceLists.map((obj) => (
      <option
        key={obj.id}
        value={obj.description + ' - ' + obj.db_ref}
        name={obj.description + ' - ' + obj.db_ref}
      >
        {obj.id}
      </option>
    ))
  }

  const startAddingNewPriceList = () => {
    setAddNewPriceList(true)
  }

  const cancelAddNewPriceList = () => {
    setAddNewPriceList(false)
  }

  const sendAddNewPriceList = (event) => {
    event.preventDefault()
    if (priceListId == null) {
      setInvalidPriceListId(true)
    } else {
      setInvalidPriceListId(false)
      fetch(`${BASE_BACKEND_URL}/product_pricelist/`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          price,
          product_id: id,
          price_list_id: priceListId,
          pricelist_code: pricelistCode
        })
      })
        .then((response) => response.json())
        .then(() => (window.location = `/productos/${id}/listas_de_precios`))
        .catch((e) => console.log(e))
    }
  }
  

  const handleInputPriceChange = (event) => {
    setPrice(event.target.value)
  }

  const handleSearchPriceList = (event) => {
    const element = document.getElementsByName(event.target.value)[0]
    if (element) {
      const price_list_id = element.innerText
      const selectedPriceList = missingPriceLists.find(
        (obj) => `${obj.description} - ${obj.db_ref}` === event.target.value
      )
  
      if (price_list_id && selectedPriceList) {
        setPriceListId(price_list_id)
        setPricelistCode(selectedPriceList.description)
      }
    }
  }

  return (
    <div>
      {addNewPriceList && (
        <form onSubmit={sendAddNewPriceList}>
          <div className="show-header show-product-header">
            <div className="cancel-save-block-list">
              <input
                id="cancel-add"
                onClick={cancelAddNewPriceList}
                className="cancel-edit-button"
                value="Cancelar"
                type="submit"
              />
              <input value="Guardar" className="save-button" type="submit" />
            </div>
          </div>

          <div className="add-price-list-block mb-20">
            <h2>Escoja la lista de precio y coloque el precio para asignarla a su producto:</h2>
            <div className="add-price-list-inputs">
              <div className="price-list-select">
                <div>
                  <b>Lista de precio:</b>
                </div>
                <input
                  onChange={handleSearchPriceList}
                  list="all-price-lists"
                  placeholder="Escribir nombre o código"
                  type="text"
                  required
                  className=""
                />
                <span className="icon-search">
                  <FontAwesomeIcon className="icon-search" icon={faSearch} />
                </span>
                <datalist id="all-price-lists">
                  {!isFetching && setOptionsForMissingPriceLists()}
                </datalist>
                {invalidPriceListId && (
                  <div className="red">
                    Por favor seleccione una lista de precio dentro de las opciones.
                  </div>
                )}
              </div>
              <div className="price-select">
                <div>
                  <b>Precio:</b>
                </div>
                <span className="icon-dollar"></span>
                <input
                  required
                  name="price"
                  placeholder="0.00"
                  step="0.000001"
                  onChange={handleInputPriceChange}
                  type="number"
                />
              </div>
            </div>
          </div>
        </form>
      )}
      {!addNewPriceList &&
        validateAccessRole(cookie.load('user_role')?.product_module)?.can_write && (
          <div className="title-and-search-container ">
            <h2></h2>
            <div className="search-input-container mb-20">
              <input
                onClick={startAddingNewPriceList}
                className="add-price-list-button"
                value="+ Agregar lista de precio"
                type="button"
              />
            </div>
          </div>
        )}

      {!addNewPriceList && (
        <div className="title-and-search-container ">
          <h2>Listas de precios asignados a producto:</h2>
          <div className="search-input-container mb-20">
            <input
              value={searchInput}
              onChange={handleSearch}
              onKeyDown={handleEnterSearch}
              className="round-search-input"
              style={{ fontSize: 12 }}
              placeholder="Buscar por código o nombre"
            />
            {searchInput === '' ? (
              <FontAwesomeIcon icon={faMagnifyingGlass} className="search-icon" />
            ) : (
              <div
                title="Limpiar texto"
                onClick={() => setSearchInput('')}
                className="round-search-input-x"
              >
                X
              </div>
            )}
          </div>
        </div>
      )}
      <div>
        <Table hover responsive>
          <thead className="table-header-gray">
            <tr>
              <th style={{ verticalAlign: 'middle', padding: '10px 25px', width: '100px' }}>
                <span className="th-header-text">CÓDIGO</span>
              </th>
              <th style={{ verticalAlign: 'middle', padding: '10px', width: '300px' }}>
                <span className="th-header-text">NOMBRE</span>
              </th>
              <th style={{ verticalAlign: 'middle', padding: '10px', width: '200px' }}>
                <span className="th-header-text">PRECIO</span>
              </th>
              <th
                style={{
                  textAlign: 'center',
                  verticalAlign: 'middle',
                  padding: '10px',
                  width: '100px'
                }}
              >
                <span className="th-header-text">ACCIÓN</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {!isFetching && priceListsData && (
              <>
                {priceListsData['table'] && priceListsData['table'].length === 0 && (
                  <tr>
                    <td colSpan="4" style={{ verticalAlign: 'middle', padding: '10px 25px' }}>
                      No hay resultados
                    </td>
                  </tr>
                )}
                {priceListsData['table'] &&
                  priceListsData['table'].length > 0 &&
                  priceListsData['table'].map((obj) => (
                    <ProductPriceListRow key={obj.id} obj={obj} id={id} />
                  ))}
              </>
            )}
            {isFetching && (
              <tr>
                <td style={{ verticalAlign: 'middle', padding: '10px 25px' }} colSpan="4">
                  Obteniendo datos...
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default ProductPriceListsTab
