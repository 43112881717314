import React from 'react'
import { ClientEcommerceConsumer } from '../context/ClientEcommerceContext'
import { Table } from 'react-bootstrap'
import ProductPriceListRow from '../../product/ProductPriceListRow'
import EcommerceUserRow from './EcommerceUserRow'

function IndexEcommerceView() {
  return (
    <ClientEcommerceConsumer>
      {({ eCommerceUserData }) => (
        <div>
          <Table hover responsive>
            <thead className="table-header-gray">
              <tr>
                <th style={{ verticalAlign: 'middle', padding: '10px 25px' }}>
                  <span className="th-header-text">FECHA DE ACTUALIZACIÓN</span>
                </th>
                <th style={{ verticalAlign: 'middle' }}>
                  <span className="th-header-text">NOMBRE</span>
                </th>
                <th style={{ verticalAlign: 'middle' }}>
                  <span className="th-header-text">CORREO</span>
                </th>
                <th style={{ verticalAlign: 'middle' }}>
                  <span className="th-header-text">NÚMERO DE CONTACTO</span>
                </th>
                <th style={{ verticalAlign: 'middle' }}>
                  <span className="th-header-text">ESTADO</span>
                </th>
                <th className="th-end-clients-ecomerce" style={{ verticalAlign: 'middle' }}>
                  <span className="th-header-text-end-clients ">ACCIÓN</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {eCommerceUserData.length > 0 &&
                eCommerceUserData.map((obj) => <EcommerceUserRow key={obj.id} obj={obj} />)}
            </tbody>
          </Table>
          {eCommerceUserData.length === 0 && (
            <p className="mt-16 ml-16">No existen clientes ecommerce para este cliente</p>
          )}
        </div>
      )}
    </ClientEcommerceConsumer>
  )
}

export default IndexEcommerceView
