import React, { createContext, useReducer, useState } from 'react'
import { AssortedScalePromoReducer } from './AssortedScalePromoReducer'

export const AssortedScalePromoContext = createContext()

const AssortedScalePromoContextProvider = ({ children }) => {
  const [assortedScaleProducts, dispatch] = useReducer(AssortedScalePromoReducer, [], () => [])

  const [assortedBonusProductQuantity, setAssortedBonusProductQuantity] = useState(0)

  const addAssortedScaleProduct = (payload) => {
    dispatch({ type: 'ADD_ASSORTED_SCALE_PRODUCT', payload })
  }

  const changeDiscountAssortedScaleProduct = (payload) => {
    dispatch({ type: 'CHANGE_DISCOUNT_ASSORTED_SCALE_PRODUCT', payload })
  }

  const changeBasePackageAssortedProducts = (payload) => {
    dispatch({ type: 'CHANGE_BASE_PACKAGE_ASSORTED_PRODUCTS', payload })
  }

  const removeAssortedScaleProduct = (payload) => {
    dispatch({ type: 'REMOVE_ASSORTED_SCALE_PRODUCT', payload })
  }

  const clearAssortedScaleProducts = () => {
    dispatch({ type: 'CLEAR_ASSORTED_SCALE_PRODUCTS' })
  }

  const contextValues = {
    addAssortedScaleProduct,
    removeAssortedScaleProduct,
    changeDiscountAssortedScaleProduct,
    changeBasePackageAssortedProducts,
    clearAssortedScaleProducts,
    assortedBonusProductQuantity,
    assortedScaleProducts
  }

  return (
    <AssortedScalePromoContext.Provider value={contextValues}>
      {children}
    </AssortedScalePromoContext.Provider>
  )
}

export default AssortedScalePromoContextProvider
