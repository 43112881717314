import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './css/index.css';
import './css/styles.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from './serviceWorker';
import FlagProvider from './global_context/FlagProvider';

const container = document.getElementById('app');
const root = createRoot(container); 

root.render(
  <FlagProvider>
    <App />
  </FlagProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
