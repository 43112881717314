import React, { createContext, useState } from 'react'

import { createUserInfo } from '../services/apiUserService'
import { useHistory } from 'react-router-dom'

const initialState = {}

const SubmitCreateUserContext = createContext(initialState)

const SubmitCreateUserProvider = (props) => {
  const [failedCreationInfo, setFailedCreationInfo] = useState(null)

  const history = useHistory()

  const formSubmitUser = (user, flags) => {
    createUserInfo(user, flags)
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => evaluateResponse(obj, user, false))
      .catch((e) => console.log(e))
  }

  const hideFailedCreationMessage = () => {
    setFailedCreationInfo(null)
  }

  const evaluateResponse = (obj, user) => {
    switch (obj.status) {
      case 422:
        setFailedCreationInfo(obj.body.message.replace('Db ref', 'Código'))
        setTimeout(hideFailedCreationMessage, 5000)
        break
      case 409:
        setFailedCreationInfo(
          'Error al guardar cambios. El código ingresado ya existe. Por favor escoger otro código.'
        )
        setTimeout(hideFailedCreationMessage, 9000)
        break
      case 404:
        setFailedCreationInfo('Servidor fuera de servicio')
        setTimeout(hideFailedCreationMessage, 9000)
        break
      case 500:
        setFailedCreationInfo('Error interno en el sistema')
        setTimeout(hideFailedCreationMessage, 9000)
        break
      default:
        // eslint-disable-next-line no-case-declarations
        const data = obj.body

        const url = new URL('/usuarios/', window.location.origin)
        url.searchParams.append('success', true)
        url.searchParams.append('email', user.email)
        url.searchParams.append('password', user.password)

        window.location.href = url.toString()
        break
    }
  }

  return (
    <SubmitCreateUserContext.Provider
      value={{
        formSubmitUser,
        failedCreationInfo
      }}
    >
      {props.children}
    </SubmitCreateUserContext.Provider>
  )
}
export { SubmitCreateUserContext, SubmitCreateUserProvider }
