import React, { createContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  getBusiness,
  getFeatureRoles,
  getQueryState,
  handleErrors
} from '../services/apiUserService'
import { handleTabClick } from '../helpers/UserConfigHandlers'
import * as apiStatusCodes from '../../constants/apiStatusCodes'
import cookie from 'react-cookies'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

const initialState = {}

const UserConfigCreateContext = createContext(initialState)

//Provider donde se validan los campos de creacion de cliente, paso 1 detalles.

const UserConfigCreateProvider = (props) => {
  const [failedCreationInfo, setFailedCreationInfo] = useState(null)
  const [formIsValid, setFormIsValid] = useState(false)

  const [isFetching, setFetching] = useState(true)
  const [businessData, setBusinessData] = useState({})
  const [formMessage, setFormMessage] = useState('')

  const [featureRoles, setFeatureRoles] = useState([])

  // form validation rules
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Este es un campo obligatorio.')
      .min(3, 'Asegúrese de cumplir con el mínimo de los 3 caracteres'),
    role: Yup.array()
      .required('Mínimo debe asignar 1 rol disponible.')
      .min(1, 'Mínimo debe asignar 1 rol disponible.'),
    active: Yup.bool(),
    code: Yup.string()
      .required('Este es un campo obligatorio.')
      .min(3, 'Asegúrese de cumplir con el mínimo de los 3 caracteres'),
    warehouse: Yup.string()
      .required('Este es un campo obligatorio.')
      .min(1, 'Asegúrese de cumplir con el mínimo de 1 caracter'),
    email: Yup.string().required('Este es un campo obligatorio.').email('El formato no es válido.'),
    password: Yup.string()
      .min(8, 'Asegúrese de cumplir con el mínimo de los 8 caracteres')
      .required('Este es un campo obligatorio.'),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('password'), null],
        'El valor en el campo de contraseña y reescribir contraseña deben de ser iguales.'
      )
      .required('Este es un campo obligatorio.'),
    mobile: Yup.string().notRequired().nullable()
  })

  const formOptions = { resolver: yupResolver(validationSchema) }
  const { register, handleSubmit, formState, control, getValues } = useForm(formOptions)
  const { errors, isValid } = formState

  const history = useHistory()

  useEffect(() => {
    getBusinessConfig()
    getFeatureRolesList()
  }, [])

  const getBusinessConfig = () => {
    setFetching(true)
    const queryParams = getQueryState()
    getBusiness()
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((data) => {
        setFetching(false)
        setBusinessData(data)
      })
      .catch((error) => {
        if (error.status === apiStatusCodes.UNAUTHORIZED) {
          cookie.remove('token', { path: '/' })
          window.location = '/'
        }
      })
  }

  const getFeatureRolesList = () => {
    setFetching(true)
    const queryParams = getQueryState()
    getFeatureRoles()
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((data) => {
        setFetching(false)
        setFeatureRoles(data?.table?.map((e) => ({ value: e.key, role: e.name, label: e.name })))
      })
      .catch((error) => {
        if (error.status === apiStatusCodes.UNAUTHORIZED) {
          cookie.remove('token', { path: '/' })
          window.location = '/'
        }
      })
  }

  const handleCancelStep = (event) => {
    const value = handleTabClick(event, true);
    if (value?.confirm) {
      window.location.href = '/usuarios/';
    }
  };
  

  return (
    <UserConfigCreateContext.Provider
      value={{
        failedCreationInfo,
        formIsValid,
        businessData,
        formMessage,
        errors,
        register,
        handleSubmit,
        isValid,
        handleCancelStep,

        Controller,
        control,
        getValues,
        featureRoles
      }}
    >
      {props.children}
    </UserConfigCreateContext.Provider>
  )
}
export { UserConfigCreateContext, UserConfigCreateProvider }
