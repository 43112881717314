import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Dropdown = ({ headerTitle, icon, list, resetThenSet, activeCursor }) => {
  const [listOpen, setListOpen] = useState(false);
  const [title, setTitle] = useState(headerTitle);
  const [iconType] = useState(icon);

  const close = useCallback(() => {
    setListOpen(false);
  }, []);

  useEffect(() => {
    if (listOpen) {
      window.addEventListener('click', close);
    } else {
      window.removeEventListener('click', close);
    }
    return () => {
      window.removeEventListener('click', close);
    };
  }, [listOpen, close]);

  const selectItem = (event, title, id, stateKey) => {
    setTitle(title);
    setListOpen(false);
    resetThenSet(event, id, stateKey);
  };

  const toggleList = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setListOpen((prevState) => !prevState);
  };

  return (
    <div>
      <a
        className={`${
          activeCursor === title ? 'active-nav-bar-item cursor-pointer' : 'cursor-pointer'
        }`}
        onClick={toggleList}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>
            <FontAwesomeIcon className="title-header" icon={iconType} />
            {headerTitle}
          </span>
          {listOpen ? (
            <FontAwesomeIcon className="vertical-dropdown-arrow" icon={faCaretUp} />
          ) : (
            <FontAwesomeIcon className="down-dropdown-arrow" icon={faCaretDown} />
          )}
        </div>
      </a>

      <div className="dropdown-container">
        {listOpen && (
          <span onClick={(e) => e.stopPropagation()}>
            {list.map((item) => (
              <Link
                to={'/' + item.key}
                className="sub-nav-bar-item cursor-pointer"
                key={item.id}
                onClick={(e) => {
                  selectItem(e, item.title, item.id, item.key);
                }}
              >
                {item.title} {item.selected && <FontAwesomeIcon icon="check" />}
              </Link>
            ))}
          </span>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
