export const handleUpdate = (event) => {
  if (
    event.target.value === 'Actualizar detalles' ||
    event.target.value === 'Actualizar configuraciones'
  ) {
    return true
  } else if (event.target.value === 'Cancelar') {
    return false
  }
}
export const handleTabClick = (event, isEditing) => {
  // Si se hizo clic en una pestaña (la pestaña es un elemento <a>)
  if (event.target.tagName === 'A') {
    if (!isEditing) {
      return {
        activeTab: event.target.innerText,
        editing: false
      }
    } else {
      const confirmation = window.confirm(
        '¿Está seguro que quiere continuar? Los cambios editados no serán guardados'
      )
      if (confirmation) {
        return {
          activeTab: event.target.innerText,
          editing: false,
          confirm: confirmation
        }
      }
    }
  }
}
