import _ from 'lodash'

export const AssortedBonusPromoReducer = (state, action) => {
  const assortedBonusProducts = state
  const product = action.payload

  switch (action.type) {
    case 'ADD_ASSORTED_BONUS_PRODUCT': {
      const orderPackages = _.sortBy(product?.packages, ['quantity']).reverse()
      product.bonus_product_ids = [product.id]
      product.bonus_product_ids = [product.id]
      product.bonus_product_uid = product.db_ref
      product.bonus_product_db_ref = product.db_ref
      product.bonus_product_name = product.name
      product.bonus_package_uids = orderPackages.map((pk) => pk.uid)
      product.bonus_package_uid = null
      product.bonus_quantity = 0
      product.bonus_limit = 0
      product.bonus_packages = orderPackages.map((pk) => pk.unit)
      product.bonus_package = null
      product.bonusPackageQuantity = 1
      product.bonus_package_hash = {}
      product.packages = orderPackages
      return [product, ...assortedBonusProducts]
    }
      
    case 'REMOVE_ASSORTED_BONUS_PRODUCT': {
      return assortedBonusProducts.filter((item) => item.id !== product.id)
    }
      
    case 'ADD_ASSORTED_BONUS_PRODUCT_QUANTITY': {
      assortedBonusProducts.forEach((pr, index) => {
        assortedBonusProducts[index].bonus_quantity = product.bonusQuantity
      })
      return [...assortedBonusProducts]
    }
      

    case 'CHANGE_BONUS_PACKAGE_ASSORTED_BONUS_PRODUCTS': {
      const hash = {}
      assortedBonusProducts.forEach((pr, index) => {
        assortedBonusProducts[index].bonus_package_uid = assortedBonusProducts[index].bonus_package_uids[product.bonusPackage]
        assortedBonusProducts[index].bonus_package = assortedBonusProducts[index].bonus_packages[product.bonusPackage]
        hash[assortedBonusProducts[index].bonus_product_db_ref] = assortedBonusProducts[index].bonus_package_uid
        assortedBonusProducts[index].bonus_package_hash = hash
      })
      return [...assortedBonusProducts]
    }

    case 'CLEAR_ASSORTED_BONUS_PRODUCTS': {
      return []
    }

    case 'CHANGE_LIMIT_TO_ASSORTED_BONUS': {
      assortedBonusProducts.forEach((pr, index) => {
        assortedBonusProducts[index].bonus_limit = product.maxLimit
      })
      return [...assortedBonusProducts]
    }

    default: {
      return state
    }
  }
}