import React, { useState, useContext, useEffect } from 'react'
import _ from 'lodash'
import { AssortedPromoContext } from '../../context/AssortedPromoContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'

const DiscountPromoRuleSection = ({ cloningPromo, scaleArray, setScaleArray }) => {
  const [failedAddScale, setFailedAddScale] = useState(false)
  const [failedAddScaleMessage, setFailedAddScaleMessage] = useState('')
  const [isAddScaleDisabled, setIsAddScaleDisabled] = useState(false)

  const { assortedProducts } = useContext(AssortedPromoContext)

  const handleExceededInputValues = (value) => {
    setIsAddScaleDisabled(value >= 999998)

    if (value >= 999999) {
      setFailedAddScaleMessage(
        'La cantidad excede el máximo permitido de 999999. Ingresa una cantidad igual o menor para continuar.'
      )
      setFailedAddScale(true)
    } else {
      setFailedAddScale(false)
    }
  }

  const handleScaleMinChange = (event, index) => {
    event.preventDefault()
    const minValue = event.target.valueAsNumber
    handleExceededInputValues(minValue)

    const arrays = [...scaleArray]
    const array = { ...arrays[index] }
    array.min = minValue
    arrays[index] = array
    setScaleArray([...arrays])
  }

  const handleScaleMaxChange = (event, index) => {
    event.preventDefault()
    const maxValue = event.target.valueAsNumber
    handleExceededInputValues(maxValue)

    if (scaleArray.length === index + 1) {
      const arrays = [...scaleArray]
      const array = { ...arrays[index] }
      array.max = maxValue
      arrays[index] = array
      setScaleArray([...arrays])
    } else {
      const arrays = [...scaleArray]
      const currentArray = { ...arrays[index] }
      const nextArray = { ...arrays[index + 1] }
      currentArray.max = maxValue
      nextArray.min = maxValue + 1
      arrays[index] = currentArray
      arrays[index + 1] = nextArray
      setScaleArray([...arrays])
    }
  }

  const handleScalePercentageChange = (event, index) => {
    event.preventDefault()
    if (event.target.value > 100) {
      return false
    }
    const arrays = [...scaleArray]
    const array = { ...arrays[index] }
    array.discount = parseFloat(event.target.value)
    arrays[index] = array
    setScaleArray([...arrays])
  }

  const changeScaleMinValue = (filteredArray) => {
    const arrays = [...filteredArray]
    arrays.map((obj, index) => {
      if (index !== 0) {
        if (arrays[index - 1].max !== arrays[index].min + 1) {
          obj.min = arrays[index - 1].max + 1
          arrays[index] = obj
        }
      }
    })
    setScaleArray([...arrays])
  }

  const deleteScale = (event, elementToDelete) => {
    event.preventDefault()
    changeScaleMinValue(scaleArray.filter((item) => item !== elementToDelete))
  }

  const addScale = (event, lastScaleMax) => {
    event.preventDefault()
    if (scaleArray[scaleArray.length - 1].min >= scaleArray[scaleArray.length - 1].max) {
      setFailedAddScaleMessage(
        'Por favor agregue un máximo mayor al mínimo de la escala ' + scaleArray.length + '.'
      )
      setFailedAddScale(true)
    } else {
      setFailedAddScale(false)
      setScaleArray((scaleArray) => [...scaleArray, { min: lastScaleMax + 1, max: 0, discount: 0 }])
    }
  }

  useEffect(() => {
    const lastScale = scaleArray[scaleArray.length - 1]

    if (lastScale) {
      const isDisabled = lastScale.max >= 999998 || lastScale.min >= 999999
      setIsAddScaleDisabled(isDisabled)
    }
  }, [scaleArray])

  if (cloningPromo) {
    return null
  }
  return (
    <div className="promo-card">
      <div className="title-promos-form">
        <b>4. Regla de la promoción</b>
      </div>
      <div className="promo-card-heading mt-10">
        <b>Nota: </b>Los descuentos se activan unicamente por la venta de el o los productos en el
        empaque de base establecido.
      </div>
      <hr className="hr-promo-card" />
      <p className="promo-card-subtitle">4.1: Establezca la regla de esta promoción</p>
      <>
        {assortedProducts.length === 0 &&
          scaleArray.map((element, index) => (
            <div className="row" style={{ paddingLeft: 16, paddingBottom: 20 }} key={index}>
              <div className="col-xs-1" style={{ marginTop: 33, width: 80 }}>
                <span className="scale-number-circle">{index + 1}</span>
              </div>
              <div className="scale-text-separator col-sm-1" style={{ marginLeft: -35 }}>
                Desde:
              </div>
              <div className="col-sm-1" style={{ padding: 0 }}>
                <input
                  min="0"
                  max="999999"
                  type="number"
                  step="1"
                  autoComplete="off"
                  name="min-quantity"
                  onChange={(e) => handleScaleMinChange(e, index)}
                  className="scale-input inactive"
                  placeholder={element.min}
                  value={element.min}
                  required
                  disabled
                />
                <div className="scale-input-subtitle">Mínimo de venta</div>
              </div>

              <div className="scale-text-separator col-sm-1" style={{ marginLeft: -25 }}>
                hasta:
              </div>
              <div className="col-sm-1" style={{ padding: 0 }}>
                <input
                  min="0"
                  max="999999"
                  type="number"
                  step="1"
                  autoComplete="off"
                  name="max-quantity"
                  onChange={(e) => handleScaleMaxChange(e, index)}
                  className="scale-input inactive"
                  placeholder="0"
                  value={element.max}
                  required
                  disabled
                />
                <div className="scale-input-subtitle">Máximo de venta</div>
              </div>
              <div className="scale-text-separator col-sm-1" style={{ paddingLeft: 0 }}>
                se aplica el:
              </div>
              <div className="col-sm-1" style={{ padding: 0 }}>
                <input
                  min="0"
                  max="100"
                  step="any"
                  type="number"
                  autoComplete="off"
                  name="percentage-quantity"
                  onChange={(e) => handleScalePercentageChange(e, index)}
                  className="scale-input inactive"
                  placeholder="0"
                  value={element.discount}
                  required
                  disabled
                />
                <div className="scale-input-subtitle">Porcentaje de Descuento</div>
              </div>
              <div className="percentage-icon col-xs-1">%</div>
            </div>
          ))}
        {assortedProducts.length > 0 &&
          scaleArray.map((element, index) => (
            <div className="row" style={{ paddingLeft: 16, paddingBottom: 20 }} key={index}>
              <div className="col-xs-1" style={{ marginTop: 33, width: 80 }}>
                <span className="scale-number-circle">{index + 1}</span>
              </div>
              <div className="scale-text-separator col-sm-1" style={{ marginLeft: -35 }}>
                Desde:
              </div>
              {index > 0 ? (
                <div className="col-sm-1" style={{ padding: 0 }}>
                  <input
                    min="0"
                    max="999999"
                    type="number"
                    step="1"
                    autoComplete="off"
                    name="min-quantity"
                    className="scale-input inactive"
                    placeholder={element.min}
                    value={element.min}
                    disabled
                  />
                  <div className="scale-input-subtitle">Mínimo de venta</div>
                </div>
              ) : (
                <div className="col-sm-1" style={{ padding: 0 }}>
                  <input
                    min="0"
                    max="999999"
                    type="number"
                    step="1"
                    autoComplete="off"
                    name="min-quantity"
                    onChange={(e) => handleScaleMinChange(e, index)}
                    className="scale-input"
                    placeholder={element.min}
                    value={element.min}
                    required
                  />
                  <div className="scale-input-subtitle">Mínimo de venta</div>
                </div>
              )}
              <div className="scale-text-separator col-sm-1" style={{ marginLeft: -25 }}>
                hasta:
              </div>
              <div className="col-sm-1" style={{ padding: 0 }}>
                <input
                  min="0"
                  max="999999"
                  type="number"
                  step="1"
                  autoComplete="off"
                  name="max-quantity"
                  onChange={(e) => handleScaleMaxChange(e, index)}
                  className="scale-input"
                  placeholder="0"
                  value={element.max}
                  required
                />
                <div className="scale-input-subtitle">Máximo de venta</div>
              </div>
              <div className="scale-text-separator col-sm-1" style={{ paddingLeft: 0 }}>
                se aplica el:
              </div>
              <div className="col-sm-1" style={{ padding: 0 }}>
                <input
                  min="0"
                  max="100"
                  step="any"
                  type="number"
                  autoComplete="off"
                  name="percentage-quantity"
                  onChange={(e) => handleScalePercentageChange(e, index)}
                  className="scale-input"
                  placeholder="0"
                  value={element.discount}
                  required
                />
                <div className="scale-input-subtitle">Porcentaje de Descuento</div>
              </div>
              <div className="percentage-icon col-xs-1">%</div>
              {scaleArray.length > 1 && (
                <div className="scale-column col-sm-1" style={{ padding: 0 }}>
                  <button
                    className="delete-scale-button d-flex align-items-center"
                    onClick={(e) => deleteScale(e, element)}
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                    <b className="delete-scale-title">Borrar escala</b>
                  </button>
                </div>
              )}
            </div>
          ))}
        {failedAddScale === true && <p className="alert-text">{failedAddScaleMessage}</p>}
        <div className="row" style={{ paddingLeft: 16 }}>
          <button
            className="add-scale-button"
            onClick={(e) => addScale(e, scaleArray[scaleArray.length - 1].max)}
            disabled={isAddScaleDisabled}
          >
            <p className="text-add-scale-colors note-essentials">Agregar escala</p>
          </button>
        </div>
      </>
    </div>
  )
}
export default DiscountPromoRuleSection
