import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faEllipsisH, faGripHorizontal } from '@fortawesome/free-solid-svg-icons'
import '../../css/survey.scss'
import autosize from 'autosize'
const ClientFormListQuestion = ({
  id,
  onDuplicate,
  onDelete,
  onUpdateQuestion,
  data,
  questionNumber,
  disableQuestion,
  isCreatingForm,
  isShowingFormDetails,
  showresponsePostask,
  provided,
  questionType
}) => {
  const optionsContainerRef = useRef(null)
  const [isVisible, setIsVisible] = useState(true)
  const [formData, setFormData] = useState({
    question: data.question || '',
    options: [
      {
        id: 1,
        text: `Opción ${questionType == 'multiplechk' ? `múltiple 1` : ''}`,
        selected: false,
        editable: true
      },
      {
        id: 2,
        text: `Opción ${questionType == 'multiplechk' ? `múltiple 2` : ''}`,
        selected: false,
        editable: true
      }
    ]
  })
  const [isDuplicating, setIsDuplicating] = useState(false)
  const [showOptions, setShowOptions] = useState(false)
  const [editingIndex, setEditingIndex] = useState(null)
  const newOptionInputRef = useRef(null)
  const [isRequiredAlertActive, setIsRequiredAlertActive] = useState(false)
  const [rows, setRows] = useState(1)
  const textareaRefQuestion = useRef(null)

  const [optionIdCounter, setOptionIdCounter] = useState(
    data.options && data.options.length > 0
      ? Math.max(...data.options.map((option) => option.id)) + 1
      : 1
  )

  useEffect(() => {
    return () => {
      autosize(textareaRefQuestion.current)
    }
  }, [])

  useEffect(() => {
    if (editingIndex !== null && newOptionInputRef.current) {
      newOptionInputRef.current.focus()
    }
  }, [editingIndex, formData.options])

  useEffect(() => {
    if (!isDuplicating) {
      setFormData({
        question: data.question || '',
        options: data.options || '',
        response: data.response || ''
      })
    }
  }, [id, isDuplicating])

  useEffect(() => {
    setFormData({
      question: data.question || '',
      options: data.options || '',
      response: data.response || ''
    })
  }, [data])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsContainerRef.current && !optionsContainerRef.current.contains(event.target)) {
        setShowOptions(false)
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleSelectOption = (index) => {
    setFormData((prevFormData) => {
      const updatedOptions = prevFormData.options.map((option, i) => ({
        ...option,
        selected: i === index
      }))
      return { ...prevFormData, options: updatedOptions }
    })
  }

  const handleOptionChange = (index, newText) => {
    const isFieldEmpty = newText.trim().length == 0
    setIsRequiredAlertActive(isFieldEmpty)
    setFormData((prevFormData) => {
      const updatedOptions = [...prevFormData.options]
      updatedOptions[index] = { ...updatedOptions[index], text: newText }
      onUpdateQuestion(id, 'options', updatedOptions)
      return { ...prevFormData, options: updatedOptions }
    })
    setTimeout(() => {
      setEditingIndex(index)
      if (newOptionInputRef.current) {
        newOptionInputRef.current.focus()
      }
    }, 0)
  }

  const handleKeyDown = (e, index) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleAddOption()
    } else if (e.key === 'Tab') {
      e.preventDefault()
      const nextIndex = index + 1
      if (nextIndex < formData.options.length) {
        setEditingIndex(nextIndex)
      } else {
        handleAddOption()
      }
    }
  }
  const handleAddOption = () => {
    setFormData((prevFormData) => {
      const newOption = {
        id: optionIdCounter,
        text: `Opción ${questionType == 'multiplechk' ? `múltiple ${optionIdCounter}` : ''}`,
        selected: false,
        editable: true
      }
      setOptionIdCounter((prevCounter) => prevCounter + 1)
      const updatedOptions = [...prevFormData.options, newOption]
      onUpdateQuestion(id, 'options', updatedOptions)
      setEditingIndex(updatedOptions.length - 1)
      return { ...prevFormData, options: updatedOptions }
    })
  }

  const handleRemoveOption = (index) => {
    event.preventDefault()
    setFormData((prevFormData) => {
      const updatedOptions = prevFormData.options
        .filter((_, i) => i !== index)
        .map((option, i) => {
          if (option.text.includes('Opción')) {
            return {
              ...option,
              id: i + 1,
              text: `Opción ${questionType == 'multiplechk' ? `múltiple ${i + 1}` : ''}`
            }
          }
          return option
        })
      const newEditingIndex = editingIndex === index ? null : editingIndex
      onUpdateQuestion(id, 'options', updatedOptions)
      setEditingIndex(newEditingIndex)
      setOptionIdCounter(updatedOptions.length + 1)
      return { ...prevFormData, options: updatedOptions }
    })
  }

  const handleToggleOptions = () => {
    setShowOptions(!showOptions)
  }

  const handleDuplicate = () => {
    const duplicatedData = { ...formData }
    onDuplicate(duplicatedData)
    setShowOptions(false)
    setIsDuplicating(true)
  }

  const handleDelete = () => {
    setIsVisible(false)
    setShowOptions(false)
    onDelete(id)
  }

  const handleInputChange = (name, value, scrollHeight) => {
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
    onUpdateQuestion(id, name, value)
    const calculatedRows = Math.floor(scrollHeight / 20)
    setRows(calculatedRows)
  }
  const handleTextareaClick = () => {
    autosize.update(textareaRefQuestion.current)
  }

  return isCreatingForm ? (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      className={'survey-text-task-container'}
    >
      <div className={'headers'}>
        <div className="question-text">
          <p>Pregunta&nbsp;{questionNumber}:</p>
        </div>
        <div
          className="drag-handle drag-handle-move"
          {...provided.dragHandleProps}
          title="Arrastra para reubicar la pregunta"
        >
          <FontAwesomeIcon icon={faGripHorizontal} />
        </div>
        <div className="options-container" ref={optionsContainerRef}>
          <FontAwesomeIcon
            icon={faEllipsisH}
            className="ellipsis-icon"
            onClick={handleToggleOptions}
          />
          {showOptions && (
                  <div className="options-card-surveys">
                  <button onClick={handleDuplicate}>Duplicar</button>
                  <div className="divider-options-card"></div>
                  <button onClick={handleDelete}>Eliminar</button>
                </div>
          )}
        </div>
      </div>
      <div className={'form-group blue-border-input'}>
        <div className="question-container">
          {disableQuestion && (
            <div className="">
              <b>{questionNumber}.</b>
            </div>
          )}
          <textarea
            ref={textareaRefQuestion}
            className={`form-control`}
            id={`question-${id}`}
            name="question"
            maxLength="100"
            placeholder="Escriba su pregunta"
            value={formData.question}
            onChange={(e) => handleInputChange('question', e.target.value)}
            onClick={handleTextareaClick}
            required
            disabled={disableQuestion}
            style={{ resize: 'none', minHeight: '30px', height: 'auto' }}
            rows={1}
          />
        </div>
      </div>
      <>
        {formData.options.map((option, index) => (
          <div className={'answer-container'} key={index}>
            <div className={`editable-option`}>
              <div className={'radio-and-input blue-border-input'}>
                {questionType == 'multiplechk' && (
                  <input
                    type="checkbox"
                    checked={option.selected}
                    onChange={() => handleSelectOption(index)}
                    disabled
                  />
                )}
                {questionType == 'multiplerb' && (
                  <div className="option-list-id">{formData.options[index].id}.</div>
                )}
                <input
                  ref={(element) => {
                    if (index === editingIndex) {
                      newOptionInputRef.current = element
                    }
                  }}
                  className={`inputline`}
                  type="text"
                  maxLength="50"
                  value={formData.options[index].text}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onClick={() => setEditingIndex(index)}
                  disabled={disableQuestion}
                  required
                />
              </div>
            </div>
            <div className="delete-button-container">
              {formData.options.length > 1 && (
                <button className="delete-button" onClick={() => handleRemoveOption(index)}>
                  <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                </button>
              )}
            </div>
          </div>
        ))}
        <div>
          <input
            className="btnsurveyoption"
            type="button"
            value="+ Agregar opción"
            onClick={handleAddOption}
          />
        </div>
      </>
    </div>
  ) : (
    <div className={`survey-text-task-container-details`}>
      <div className={`headers-details`}></div>

      {isShowingFormDetails && formData.question && (
        <>
          <div className={`form-group-details blue-border-input-details`}>
            <div className="question-container">
              <div className="question-number-chk-radio">
                <b>{questionNumber}.</b>
              </div>
              <div className={`form-group-details blue-border-input-details`}>
                <textarea
                  className={`question-input-details form-control-details`}
                  id={`question-${id}`}
                  name="question"
                  value={formData.question}
                  disabled
                  style={{
                    height: 'auto',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    resize: 'none'
                  }}
                  rows={1}
                />
              </div>
            </div>
          </div>
          {formData.options.map((option, index) => (
            <>
              {questionType == 'multiplerb' && (
                <div className={`details-surveys`} key={index}>
                  <div className={`editable-option`}>
                    <div className={`chkrd-and-input-details blue-border-input-details`}>
                      <input
                        ref={(element) => {
                          if (index === editingIndex) {
                            newOptionInputRef.current = element
                          }
                        }}
                        className={`form-control form-control-details`}
                        type="text"
                        value={`${index + 1}.  ${formData.options[index].text}`}
                        disabled
                        required
                      />
                    </div>
                  </div>
                </div>
              )}
              {questionType == 'multiplechk' && (
                <div className={`details-surveys`} key={index}>
                  <div className={`editable-option`}>
                    <div className={`chkrd-and-input-details blue-border-input-details`}>
                      <input type="checkbox" disabled />
                      <input
                        ref={(element) => {
                          if (index === editingIndex) {
                            newOptionInputRef.current = element
                          }
                        }}
                        className={`form-control form-control-details`}
                        type="text"
                        value={formData.options[index].text}
                        disabled
                        required
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
        </>
      )}

      {showresponsePostask && (
        <>
          {formData.options.map((option, index) => (
            <div className={`details-surveys`} key={index}>
              <div className={`editable-option`}>
                <div className={`chkrd-and-input-details blue-border-input-details`}>
                  <input
                    type="radio"
                    checked={option.selected}
                    onChange={() => handleSelectOption(index)}
                    className="radio-b"
                    disabled
                  />
                  <input
                    ref={(element) => {
                      if (index === editingIndex) {
                        newOptionInputRef.current = element
                      }
                    }}
                    className={`form-control form-control-details`}
                    type="text"
                    maxLength="50"
                    value={formData.options[index].text}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onClick={() => setEditingIndex(index)}
                    disabled={!option.selected}
                    required
                  />
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  )
}
export default ClientFormListQuestion
