import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

export default function ImageModalProducts({ imgSrc, showImage, setShowImage }) {
  const [zoom, setZoom] = useState(false); 

  const toggleZoom = () => setZoom(!zoom); 

  const handleCloseImage = () => {
    setShowImage(false);
    setZoom(false); 
  };

  return (
    <div style={{ position: 'relative', textAlign: 'center' }}>
      {imgSrc ? (
        <>
          <Modal
            open={showImage}
            onClose={handleCloseImage}
            center
            classNames={{
              overlay: 'customOverlay',
              modal: 'customModal',
            }}
            styles={{
              overlay: {
                background: 'rgba(0, 0, 0, 0.7)', 
              },
              modal: {
                background: 'transparent', 
                maxWidth: '100%',
                width: 'auto',
                padding: '0', 
              }
            }}
          >
            <div style={{ position: 'relative' }}>
              <img
                src={imgSrc}
                alt="Imagen agregada"
                style={{
                  width: '100%',
                  transform: zoom ? 'scale(2)' : 'scale(1)', 
                  transition: 'transform 0.3s ease'
                }}
                onDoubleClick={toggleZoom} 
              />
              <button
                className='img-modals-close'
                onClick={handleCloseImage}
              >

              </button>
              <div
                className="img-overlay-tooltip img-modals-div"
              >
                Haga doble clic en la imagen para aumentar/disminuir el zoom
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <div>Imagen no disponible</div>
      )}
    </div>
  );
}
