import React, { useState, useEffect } from 'react'
import cookie from 'react-cookies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { setUpTaxSettings } from '../helpers/utils'
import { LoginState } from '../login/constants/loginStates'
import { nonWritePermissionsAlert, validateAccessRole } from '../helpers/userRole'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default function LoginPage() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginState, setLoginState] = useState(LoginState.NONE)
  const [showPassword, setShowPassword] = useState(false)
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    isUserLoggedIn();
    setSuccessfulMessage();

    const urlParams = new URLSearchParams(window.location.search);
    const isDeactivated = urlParams.get('deactivated');
    if (isDeactivated) {
      setLoginState(LoginState.USER_DEACTIVATED);
      setTimeout(hideMessages, 7000);
    }
  }, [userData]);

  const isUserLoggedIn = () => {
    const token = cookie.load('token');
    
    if (!token) {
      return; 
    }
  
    fetch(`${BACKEND_URL}/users/current`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch user data');
          }
          return response.json();
      })
      .then((data) => {
        if (data) {
          setUserData(data); 
          const redirectPath = getRedirectPath(data.user_role);
          window.location = `/${redirectPath}`;
        }
      })
      .catch((e) => console.error(e));
  };
  

  const setSuccessfulMessage = () => {
    if (window.location.href.includes('logout')) {
      setLoginState(LoginState.LOGGED_OUT)
      setTimeout(hideMessages, 7000)
    } else if (window.location.href.includes('reset_done')) {
      setLoginState(LoginState.RESET_PASSWORD)
      setTimeout(hideMessages, 7000)
    }
  }

  const hideMessages = () => {
    setLoginState(LoginState.NONE)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'email') setEmail(value)
    if (name === 'password') setPassword(value)
  }

  const login = (e) => {
    e.preventDefault()
    fetch(`${BACKEND_URL}/users/sign_in`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user: {
          email,
          password
        }
      })
    })
      .then((response) => {
        if (response.status === 403) {
          setLoginState(LoginState.USER_DEACTIVATED)
        } else if (response.status === 401) {
          setLoginState(LoginState.FAILED_AUTHENTICATION)
        } else {
          setLoginState(LoginState.NONE)
          return response.json().then((data) => evaluateResponse(data))
        }
        setTimeout(hideMessages, 7000)
      })
      .catch((e) => console.log(e))
  }

  const evaluateResponse = (json) => {
    if (json.token) {
      cookie.save('business_id', json.business_id, { path: '/' })
      cookie.save('business_tenant_uid', json.business_tenant_uid, { path: '/' })
      cookie.save('token', json.token, { path: '/' })
      cookie.save('business_name', json.business_name, { path: '/' })
      cookie.save('business_url', json.business_url, { path: '/' })
      cookie.save('user_name', json.user_name, { path: '/' })
      cookie.save('user_email', email, { path: '/' })
      cookie.save('user_role', json.user_role, { path: '/' })
      cookie.save('intl_locale', json.intl_locale, { path: '/' })
      cookie.save('intl_currency', json.intl_currency, { path: '/' })
      setUpTaxSettings(json.intl_locale)
      const redirectPath = getRedirectPath(json.user_role);
      window.location = `/${redirectPath}`;
   }
  }
  const getRedirectPath = (userRole) => {
    if (validateAccessRole(userRole?.product_module)?.can_read) return 'productos';
    if (validateAccessRole(userRole?.clients_module)?.can_read) return 'clientes';
    if (validateAccessRole(userRole?.segments_module)?.can_read) return 'segmentos';
    if (validateAccessRole(userRole?.essentials_module)?.can_read) return 'infaltables';
    if (validateAccessRole(userRole?.pos_module)?.can_read) return 'ejecucion_punto_venta';
    if (validateAccessRole(userRole?.price_list_module)?.can_read) return 'listas_de_precios';
    if (validateAccessRole(userRole?.can_make_promo)?.can_read) return 'promociones';
    if (validateAccessRole(userRole?.orders_modules)?.can_read) return 'pedidos';
    if (validateAccessRole(userRole?.can_config_user)?.can_read) return 'usuarios';
    if (validateAccessRole(userRole?.collections_module)?.can_read) return 'liquidaciones';
    if (validateAccessRole(userRole?.can_view_report)?.can_read) return 'reportes';
    if (validateAccessRole(userRole?.tableau_dashboard_module)?.can_read) return 'dashboard';
    return 'inicio';
  };

  const toggleEye = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div>
      {loginState === LoginState.FAILED_AUTHENTICATION && (
        <div className="alert alert-message alert-danger" role="alert">
          <strong>Correo electrónico o contraseña inválidos.</strong>
        </div>
      )}
      {loginState === LoginState.USER_DEACTIVATED && (
        <div className="alert alert-message alert-danger" role="alert">
          <strong>Esta cuenta ha sido desactivada.</strong>
        </div>
      )}
      {loginState === LoginState.LOGGED_OUT && (
        <div
          className="alert alert-success d-flex justify-content-between align-items-center"
          role="alert"
        >
          <strong className="ml-20">Cerraste sesión correctamente</strong>

          <button
            type="button"
            className="btn-close"
            onClick={hideMessages}
            aria-label="Close"
          ></button>
        </div>
      )}
      {loginState === LoginState.RESET_PASSWORD && (
        <div className="alert alert-success" role="alert">
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <strong>Su contraseña ha sido cambiada exitosamente. Ya puede iniciar sesión.</strong>
        </div>
      )}
      <div className="login-container">
        <div className="login-section">
          <img alt="UBIQUA" src="/ubiqua-logo-top.png" />
          <h2 className="login-header">¡Bienvenidos! Por favor ingresar a su perfil</h2>
          <form onSubmit={login}>
            <div className="">
              <div className="login-input">
                <input
                  onChange={handleChange}
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Correo Electronico"
                  required
                />
              </div>
              <div className="login-input">
                <input
                  onChange={handleChange}
                  type={showPassword ? 'text' : 'password'}
                  className="form-control"
                  name="password"
                  placeholder="Contraseña"
                  autoComplete="off"
                  required
                />
                <span onClick={toggleEye} className="icon-eye">
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </span>
              </div>
              <div className="">
                <input className="login-button" value="Iniciar sesión" type="submit" />
              </div>
              <br />
              <br />
              <a className="change-password-link" href="/cambiar-contrasena">
                Cambiar contraseña
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
