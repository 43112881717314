import React, { useState, useEffect } from 'react'
import cookie from 'react-cookies'
import Pagination from '../layout/Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { Table } from 'react-bootstrap'

const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
const BACKEND_URL = USE_BUSINESS_URL
  ? `${process.env.REACT_APP_BACKEND_URL}/businesses/${cookie.load('business_id')}`
  : process.env.REACT_APP_BACKEND_URL

const ProductHistoryTab = ({ id }) => {
  const [isFetching, setIsFetching] = useState(true)
  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [totalPages, setTotalPages] = useState(1)
  const [query, setQuery] = useState('')
  const [searchInput, setSearchInput] = useState('')

  useEffect(() => {
    fetchDataFromServer()
  }, [currentPage, query])

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
  }

  const handleEnterSearch = (e) => {
    if (e.key === 'Enter') {
      buildSearchQuery()
    }
  }

  const buildSearchQuery = () => {
    const queryParam = searchInput ? `&q=${searchInput}` : ''
    setQuery(queryParam)
  }

  const resetFilters = () => {
    setSearchInput('')
    setQuery('')
  }

  const fetchDataFromServer = () => {
    setIsFetching(true)
    fetch(
      `${BACKEND_URL}/products/${id}/product_price_audits?page=${currentPage}&per_page=${perPage}${query ? `&q=${query}` : ''}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookie.load('token')}`
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData(data['table'])
        setIsFetching(false)
        setTotalPages(Math.ceil(data['count'] / perPage))
      })
      .catch((e) => console.log(e))
  }

  const handlePagination = (event) => {
    const { id } = event.target
    if (id === 'first-page' && currentPage !== 1) {
      setCurrentPage(1)
    } else if (id === 'last-page' && currentPage !== totalPages) {
      setCurrentPage(totalPages)
    } else if (id === 'previous-page' && currentPage !== 1) {
      setCurrentPage((prevPage) => prevPage - 1)
    } else if (id === 'next-page' && currentPage !== totalPages) {
      setCurrentPage((prevPage) => prevPage + 1)
    }
  }

  const getFormattedDate = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  return (
    <div>
      <div className="title-and-search-container">
        <h2>Listas de precios asignados a producto:</h2>

        <div className="search-input-container mb-20">
          <input
            value={searchInput}
            onChange={handleSearch}
            onKeyDown={handleEnterSearch}
            className="round-search-input"
            placeholder="Buscar por código o nombre"
            style={{ fontSize: 12, marginTop: -1 }}
          />
          {searchInput === '' ? (
            <FontAwesomeIcon icon={faMagnifyingGlass} className="search-icon" />
          ) : (
            <div
              title="Limpiar texto"
              onClick={() => setSearchInput('')}
              className="round-search-input-x"
            >
              X
            </div>
          )}
        </div>
      </div>

      <div>
        <Table hover responsive>
          <thead className="table-header-gray">
            <tr>
              <th style={{ verticalAlign: 'middle', padding: '10px 25px', width: '20%' }}>
                <span className="th-header-text">FECHA Y HORA</span>
              </th>
              <th style={{ verticalAlign: 'middle', width: '20%' }}>
                <span className="th-header-text">NOMBRE</span>
              </th>
              <th style={{ verticalAlign: 'middle', width: '20%' }}>
                <span className="th-header-text">DESCRIPCIÓN</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {!isFetching && data.length > 0 ? (
              data.map((obj, index) => (
                <tr key={index}>
                  <td
                    className="td-text-limited"
                    style={{ verticalAlign: 'middle', padding: '10px 25px' }}
                  >
                    {getFormattedDate(obj['timestamp'])}
                  </td>
                  <td className="td-text-limited">{obj['user_email']}</td>
                  <td className="td-text-limited">{obj['description']}</td>
                </tr>
              ))
            ) : !isFetching && data.length === 0 ? (
              <tr>
                <td
                  className="td-text-limited"
                  style={{ verticalAlign: 'middle', padding: '10px 25px' }}
                  colSpan="3"
                >
                  No hay resultados
                </td>
              </tr>
            ) : (
              <tr>
                <td className="td-text-limited" colSpan="3">
                  Obteniendo datos...
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <nav onClick={handlePagination}>
          {totalPages > 1 && <Pagination current_page={currentPage} total_pages={totalPages} />}
        </nav>
      </div>
    </div>
  )
}

export default ProductHistoryTab
