import React, { createContext, useState } from 'react'

import { useHistory } from 'react-router-dom'
import cookie from 'react-cookies'

const initialState = {}

const SubmitCreateProductContext = createContext(initialState)

const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
const BACKEND_URL = USE_BUSINESS_URL
  ? `${process.env.REACT_APP_BACKEND_URL}/businesses/${cookie.load('business_id')}`
  : process.env.REACT_APP_BACKEND_URL

const SubmitCreateProductProvider = (props) => {
  const [failedCreationInfo, setFailedCreationInfo] = useState(null)
  const [showWarningPage2, setShowWarningPage2] = useState(false)

  const history = useHistory()

  const validatePriceListsInputs = (priceListSate) => {
    const incomplete = priceListSate.filter((obj) => obj.price_list_id === '' || obj.price === '')
    return incomplete.length <= 0
  }

  const handleCreateProduct = (product, images, priceListSate, newProductInfo) => {
    setShowWarningPage2(false)
    const canCreate = validatePriceListsInputs(priceListSate)
    const taxObject = newProductInfo.taxes.find((tax) => tax.id === parseInt(product.itbms));
    const tax_rate = taxObject ? taxObject.rate : null;

    if (canCreate === true) {
      fetch(`${BACKEND_URL}/products`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          business_id: cookie.load('business_id'),
          active: product.active,
          price_unit_id: product.unitWeight,
          db_ref: product.code,
          barcode: product.barcode,
          name: product.name,
          description: product.description,
          tax_id: parseInt(product.itbms), 
          tax_rate: parseInt(tax_rate),        
          brand_id: product.brand,
          unit: product.unit,
          quantity_per_package: product.unitQuantity,
          max_weight: product.weight,
          min_weight: product.weight,
          average_weight: product.weight,
          has_package_per_unit: product.splitUnit,
          goal_category_id: product.category ?? null,
          product_type_id: product.category ?? null,          
          category_ids: product.category ? [product.category] : [],
          subcategory_ids: product.subCategory ? [product.subCategory] : [],
          price_lists: priceListSate
        })
      })
        .then((r) =>
          r.json().then((data) => {
            if (images.length > 0) {
              handleSubmitImage(data, images)
            }
            return { status: r.status, body: data }
          })
        )
        .then((obj) => evaluateResponse(obj))
        .catch((e) => console.log(e))
    } else {
      setShowWarningPage2(true)
    }
  }

  const handleSubmitImage = (data, images) => {
    console.log(images)
    fetch(`${BACKEND_URL}/products/${data.id}/product_photos`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      },
      body: JSON.stringify({
        business_id: cookie.load('business_id'),
        product_id: data.id,
        photo_name: images[0].file.name,
        url: images[0].data_url
      })
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .catch((e) => console.log(e))
  }

  const hideFailedCreationMessage = () => {
    setFailedCreationInfo(null)
  }

  const evaluateResponse = (obj) => {
    switch (obj.status) {
      case 422:
        setFailedCreationInfo(
          'Error al guardar cambios. El producto con el código ingresado ya existe.'
        )
        setTimeout(hideFailedCreationMessage, 5000)
        break
      case 409:
        setFailedCreationInfo(
          'Error al guardar cambios. El código ingresado ya existe. Por favor escoger otro código.'
        )
        setTimeout(hideFailedCreationMessage, 9000)
        break
      case 404:
        setFailedCreationInfo('Servidor fuera de servicio')
        setTimeout(hideFailedCreationMessage, 9000)
        break
      case 500:
        setFailedCreationInfo('Error interno en el sistema')
        setTimeout(hideFailedCreationMessage, 9000)
        break
      default:
        const data = obj.body
        const productId = data['id']
        const successCreateForNewProduct = true
        const url = `/productos/${productId}/detalles?successCreateForNewProduct=${successCreateForNewProduct}`

        window.location.href = url
        break
    }
  }

  return (
    <SubmitCreateProductContext.Provider
      value={{
        handleCreateProduct,
        failedCreationInfo,
        showWarningPage2
      }}
    >
      {props.children}
    </SubmitCreateProductContext.Provider>
  )
}
export { SubmitCreateProductContext, SubmitCreateProductProvider }
