import React from 'react'
import cookie from 'react-cookies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
import ProductWeightInput from './component/protected_component/ProductWeightInput'
import ProductQuantityInput from './component/protected_component/ProductQuantityInput'
import IconOption from './component/IconOption'
import AsyncSelect from 'react-select/async'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

class CreateOrderView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      business_id: 1,
      price_list_name: '',
      price_list_id: null,
      client_id: null,
      salesman_id: null,
      comments: '',
      selected_products: [],
      failedCreation: false,
      failedCreationMessage: '',
      total_weight: 0,
      subtotal: 0,
      // currentClientValue: '',
      total: 0,
      isPosting: false,
      createText: 'Guardar'
    }
  }

  handleCommentsChange = (event) => {
    event.preventDefault()
    const obj = {}
    obj[event.target.name] = event.target.value
    this.setState(obj)
  }

  loadSalesmanOptions = (inputValue) =>
    // eslint-disable-next-line no-undef
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.searchForSalesman(inputValue))
      }, 1000)
    })

  loadProductOptions = (inputValue) =>
    // eslint-disable-next-line no-undef
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.searchForProduct(inputValue))
      }, 1000)
    })

  loadClientOptions = (inputValue) =>
    // eslint-disable-next-line no-undef
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.searchForClient(inputValue))
      }, 1000)
    })

  changeFormHandler = (event) => {
    const obj = {}
    obj[event.target.name] = event.target.value
    this.setState(obj)
    if (event.target.name === 'client_id') {
      this.setPriceListData(event.target.value)
    }
    if (event.target.name === 'product_id') {
      const product_id = event.target.value
      const selectedObj = event.delivererObject
      selectedObj['subtotal'] = 0
      selectedObj['quantity'] = 0
      selectedObj['weight'] = 0
      selectedObj['product_id'] = product_id
      const new_obj = this.state.selected_products
      new_obj.push(selectedObj)
      this.setState({ selected_products: new_obj })
      event.target.value = ''
    }
  }

  fetchClient = (inputValue) => {
    // will search after typing 1 or more characters
    if (inputValue.length >= 1) {
      return fetch(
      `${BACKEND_URL}/businesses/${this.state.business_id}/clients?q=${inputValue}`,{
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        }
      )
        .then((response) => response.json())
        .then((data) =>
          data['table'].map((i) => ({
            value: i.name,
            target: {
              name: 'client_id',
              value: i.id,
              id: i.id
            },
            label: i.db_ref.concat(' ' + i.name),
            delivererObject: i
          }))
        )
        .catch((e) => console.log(e))
    }
  }

  searchForClient = (inputValue) => {
    if (this.state.selected_products.length > 0) {
      const val = window.confirm(
        'Al cambiar el cliente todos los productos de su pedido serán eliminados. Desea continuar con esta acción?'
      )
      if (val) {
        this.setState({ selected_products: [], price_list_id: null, price_list_name: null })
        return this.fetchClient(inputValue)
      }
    } else {
      return this.fetchClient(inputValue)
    }
  }

  setPriceListData = (client_id) => {
    fetch(`${BACKEND_URL}/clients/${client_id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          price_list_name: data['price_list']['description'],
          price_list_id: data['price_list']['id']
        })
      )
      .catch((e) => console.log(e))
  }

  searchForSalesman = (inputValue) => {
    // will search after typing 1 or more characters
    if (inputValue.length >= 1) {
      return fetch(
        `${BACKEND_URL}/businesses/${this.state.business_id}/salesmen?q=${inputValue}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        }
      )
        .then((response) => response.json())
        .then((data) =>
          data['table'].map((i) => ({
            value: i.name,
            target: {
              name: 'salesman_id',
              value: i.id,
              id: i.id
            },
            label: i.db_ref.concat(' ' + i.name),
            delivererObject: i
          }))
        )
        .catch((e) => console.log(e))
    }
  }

  searchForProduct = (inputValue) => {
    // will search after typing 1 or more characters
    if (inputValue.length >= 1) {
      return fetch(`${BACKEND_URL}/price_lists/${this.state.price_list_id}/product_pricelist?q=${inputValue}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        }
      )
        .then((response) => response.json())
        .then((data) => {
          // index products by "product_db_ref"
          const lookup = _.keyBy(this.state.selected_products, (o) => o.db_ref)

          /* find all products where "db_ref" exists in index, one loop, quick lookup. no nested loops
            return
           */
          const result = _.filter(data['table'], (u) => !lookup[u.db_ref])

          return result.map((i) => ({
            value: i.name,
            target: {
              name: 'product_id',
              value: i.id,
              id: i.id
            },
            label: i.db_ref.concat(' ' + i.name),
            delivererObject: i
          }))
        })
        .catch((e) => console.log(e))
    }
  }

  cancelCreate = (event) => {
    event.preventDefault()
    const val = window.confirm(
      '¿Esta seguro que quiere continuar? Los cambios editados no seran guardados'
    )
    if (val) {
      window.location = '/pedidos'
    }
  }

  getNewProduct = (obj) => {
    const hash = {}
    hash['extended_price'] = obj['subtotal'].toFixed(2)
    hash['calculated_tax_price'] = this.calculateTaxPrice(obj['subtotal'].toFixed(2), obj.tax_rate)
    hash['unit_price'] = obj['price']
    hash['sales_unit_id'] = obj['price_unit']['id']
    hash['product_id'] = obj['product_id']
    hash['quantity'] = obj['quantity']
    hash['weight'] = obj['weight']
    hash['tax_rate'] = obj.tax.rate
    return hash
  }

  calculateTaxPrice = (priceWithoutTax, tax) => {
    const taxRate = tax ? tax : 0.0
    const taxToSum = parseFloat(priceWithoutTax * taxRate)
    return priceWithoutTax + taxToSum
  }

  sendOrderParams = (event) => {
    event.preventDefault()

    const new_obj = this.state.selected_products.map((obj) => this.getNewProduct(obj))

    if (this.state.client_id === null || this.state.salesman_id === null || new_obj.length === 0) {
      this.setState({
        failedCreation: true,
        failedCreationMessage: 'Por favor introduzca los campos obligatorios'
      })
    } else {
      this.setState({ isPosting: true, createText: 'Guardando...' })
      fetch(`${BACKEND_URL}/orders`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          business_id: this.state.business_id,
          created_date: new Date(),
          client_id: this.state.client_id,
          salesman_id: this.state.salesman_id,
          comments: this.state.comments,
          total: this.state.total,
          details: new_obj
        })
      })
        .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
        .then((obj) => this.evaluateResponse(obj))
        .catch((e) => console.log(e))
    }
  }

  evaluateResponse = (obj) => {
    // evaluate responses for status 422 or 500
    if (obj.status === 500) {
      this.setState({
        isPosting: false,
        createText: 'Guardar',
        failedCreation: true,
        failedCreationMessage: 'Error en el sistema'
      })
    } else if (obj.status === 409) {
      this.setState({
        isPosting: false,
        createText: 'Guardar',
        failedCreation: true,
        failedCreationMessage: 'Orden duplicada'
      })
    } else if (obj.status === 422) {
      this.setState({
        isPosting: false,
        createText: 'Guardar',
        failedCreation: true,
        failedCreationMessage: 'Error en la creacion por fallos en validaciones'
      })
    } else if (obj.status >= 201 && obj.status <= 299) {
      window.location = `/pedidos/${obj.body.uuid}`;
    }
  }

  deleteProductRow = (event, obj) => {
    event.preventDefault()
    const selectedProducts = this.state.selected_products
    const filterProducts = selectedProducts.filter((t) => t.db_ref !== obj.db_ref)
    this.setState({ selected_products: filterProducts }, this.calculateOrderSubtotal)
  }

  handleProductQuantity = (event) => {
    const new_products = this.state.selected_products
    const row = event.target.id
    let new_quantity = 0
    if (event.target.value !== '') {
      new_quantity = event.target.value
    }
    const obj = new_products[row]

    obj[event.target.name] = parseInt(new_quantity)

    if (['KILO', 'LIBRA'].includes(obj.price_unit.description)) {
      const row_subtotal = parseFloat(obj.weight) * parseFloat(obj['price'])
      obj['subtotal'] = row_subtotal
    } else {
      const row_subtotal = parseInt(new_quantity) * parseFloat(obj['price'])
      obj['subtotal'] = row_subtotal
    }

    new_products[row] = obj
    this.setState({ selected_products: new_products }, this.calculateOrderSubtotal)
  }

  calculateOrderSubtotal = () => {
    let order_subtotal = 0
    let total_weight = 0
    this.state.selected_products.map((obj) => (order_subtotal += obj.subtotal))
    this.state.selected_products.map((obj) => (total_weight += obj.weight))
    this.setState({
      subtotal: order_subtotal,
      total: order_subtotal.toFixed(2),
      total_weight: total_weight
    })
  }

  handleProductWeight = (event) => {
    const new_products = this.state.selected_products
    const row = event.target.id
    let new_weight = 0
    if (event.target.value !== '') {
      new_weight = event.target.value
    }
    const obj = new_products[row]
    obj[event.target.name] = parseFloat(new_weight)

    if (['KILO', 'LIBRA'].includes(obj.price_unit.description)) {
      const row_subtotal = parseFloat(new_weight) * parseFloat(obj['price'])
      obj['subtotal'] = row_subtotal
    } else {
      const row_subtotal = parseInt(obj.quantity) * parseFloat(obj['price'])
      obj['subtotal'] = row_subtotal
    }

    new_products[row] = obj
    this.setState({ selected_products: new_products }, this.calculateOrderSubtotal)
  }

  render() {
    return (
      <div className={`main-view ${this.props.collapsed ? 'collapsed' : ''}`}>
      {this.state.failedCreation === true && (
          <div className="alert alert-danger" role="alert">
            <strong>{this.state.failedCreationMessage}</strong>
          </div>
        )}
        <div>
          <div className="show-title">
            <h2>Crear Pedido Nuevo</h2>
          </div>
          <div className="index-table">
            <div className="show-area">
              <div className="create-promo-wrapper">
                <form onSubmit={this.sendOrderParams.bind(this)}>
                  <div className="inline-block full-width">
                    <div className="float-left">
                      <h4>Ingrese los siguientes datos para crear un pedido nuevo:</h4>
                      <h4 className="red">Campos obigatorios *</h4>
                    </div>
                    {this.state.isPosting === false && (
                      <div className="float-right">
                        <button onClick={this.cancelCreate} className="cancel-button">
                          Cancelar
                        </button>
                        <input
                          className="save-button"
                          value={this.state.createText}
                          type="submit"
                        />
                      </div>
                    )}
                    {this.state.isPosting === true && (
                      <div className="float-right">
                        <input
                          disabled
                          className="save-button disabled-button"
                          value={this.state.createText}
                          type="submit"
                        />
                      </div>
                    )}
                  </div>
                  <hr />
                  <div className="form-group row mt-33">
                    <label className="pl-form col-sm-4 col-form-label">
                      Nombre y Código del Cliente
                      <span className="red"> *</span>:
                    </label>
                    <div className="col-sm-7">
                      <AsyncSelect
                        placeholder={'Buscar cliente por nombre o código'}
                        noOptionsMessage={() => 'Buscar'}
                        loadingMessage={() => 'Cargando clientes...'}
                        cacheOptions={false}
                        defaultOptions
                        name="selectOption"
                        onChange={this.changeFormHandler}
                        loadOptions={this.loadClientOptions}
                      />
                    </div>
                  </div>

                  <div className="form-group row mt-33">
                    <label className="pl-form col-sm-4 col-form-label">Lista de Precio:</label>
                    <div className="col-sm-7">{this.state.price_list_name}</div>
                  </div>

                  <div className="form-group row mt-33">
                    <label className="pl-form col-sm-4 col-form-label">
                      Vendedor
                      <span className="red"> *</span>:
                    </label>
                    <div className="col-sm-7">
                      <AsyncSelect
                        placeholder={'Buscar vendedor por nombre o código'}
                        noOptionsMessage={() => 'Buscar'}
                        loadingMessage={() => 'Cargando vendedores...'}
                        cacheOptions={false}
                        defaultOptions
                        name="selectOption"
                        onChange={this.changeFormHandler}
                        loadOptions={this.loadSalesmanOptions}
                      />
                    </div>
                  </div>

                  <div className="form-group row mt-33">
                    <label className="pl-form col-sm-4 col-form-label">
                      Comentarios Generales:
                    </label>
                    <textarea
                      autoComplete="off"
                      onChange={this.handleCommentsChange.bind(this)}
                      className="comments-textArea"
                      name="comments"
                      maxLength="500"
                    />
                  </div>

                  <div className="form-group row mt-33">
                    <label className="pl-form col-sm-4 col-form-label">
                      Productos en Pedido
                      <span className="red"> *</span>:
                    </label>
                    {this.state.client_id && !this.state.price_list_id && (
                      <div className="col-sm-7">
                        <AsyncSelect
                          placeholder={'Buscar productos por nombre o código'}
                          noOptionsMessage={() => 'Buscar'}
                          loadingMessage={() => 'Cargando productos...'}
                          cacheOptions={false}
                          defaultOptions={false}
                          value={''}
                          name="selectOption"
                          onChange={this.changeFormHandler}
                          loadOptions={this.loadProductOptions}
                          isDisabled={true}
                        />
                      </div>
                    )}
                    {this.state.client_id &&
                      this.state.price_list_id &&
                      this.state.selected_products.length >= 0 && (
                        <div className="col-sm-7">
                          <AsyncSelect
                            placeholder={'Buscar productos por nombre o código'}
                            noOptionsMessage={() => 'Buscar'}
                            loadingMessage={() => 'Cargando productos...'}
                            cacheOptions={false}
                            defaultOptions={false}
                            value={''}
                            name="selectOption"
                            onChange={this.changeFormHandler}
                            components={{ Option: IconOption }}
                            loadOptions={this.loadProductOptions}
                          />
                        </div>
                      )}
                    {this.state.client_id === null && (
                      <div className="col-sm-7">
                        <AsyncSelect
                          placeholder={'Buscar productos por nombre o código'}
                          noOptionsMessage={() => 'Buscar'}
                          loadingMessage={() => 'Cargando productos...'}
                          cacheOptions={false}
                          defaultOptions={false}
                          value={''}
                          name="selectOption"
                          onChange={this.changeFormHandler}
                          loadOptions={this.loadProductOptions}
                          isDisabled={true}
                        />
                      </div>
                    )}
                  </div>

                  <div className="mt-33">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th className="pl-40">CÓDIGO</th>
                          <th>NOMBRE DEL PRODUCTO</th>
                          <th>CANTIDAD</th>
                          <th>PESO NETO</th>
                          <th>UNIDAD DE VENTA</th>
                          <th>PRECIO</th>
                          <th>SUBTOTAL</th>
                          <th>ELIMINAR</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.selected_products.map((obj, index) => (
                          <tr key={index}>
                            <td className="pl-40">{obj.db_ref}</td>
                            <td>{obj.name}</td>

                            <td>
                              <ProductQuantityInput
                                id={index}
                                quantity={obj.quantity}
                                handleProductQuantity={(e) => this.handleProductQuantity(e)}
                              />
                            </td>
                            <td>
                              <ProductWeightInput
                                id={index}
                                isUseWeight={false}
                                weight={obj.weight}
                                handleProductWeight={(e) => this.handleProductWeight(e)}
                              />
                            </td>

                            <td>{obj.price_unit?.description || 'N/A'}</td>
                            <td>${obj.price}</td>
                            <td>${obj.subtotal.toFixed(2)}</td>
                            <td>
                              <button
                                className="unassign-price-list-button"
                                id={index}
                                onClick={(e) => this.deleteProductRow(e, obj)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan="5" />
                          <td>
                            <b>PESO TOTAL</b>
                          </td>
                          <td>{this.state.total_weight.toFixed(2)}</td>
                          <td />
                        </tr>
                        <tr>
                          <td colSpan="5" />
                          <td>
                            <b>SUB-TOTAL</b>
                          </td>
                          <td>${this.state.subtotal.toFixed(2)}</td>
                          <td />
                        </tr>
                        <tr>
                          <td colSpan="5" />
                          <td className="fs-20">
                            <b>TOTAL</b>
                          </td>
                          <td className="fs-20">
                            <b>${this.state.total}</b>
                          </td>
                          <td />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CreateOrderView
