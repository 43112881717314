import React, { useRef, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

export default function ReactRadioFilter({
  title,
  optionsKeys,
  onChange,
  resetWatcher,
  isRequired,
  disabled
}) {
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [selectedFilterType, setSelectedFilterType] = useState(null)
  const filterContainer = useRef(null)

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (filterContainer.current && !filterContainer.current.contains(e.target)) {
        setDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => document.removeEventListener('mousedown', handleOutsideClick)
  }, [])

  useEffect(() => {
    selectFilterType(null)
  }, [resetWatcher])

  useEffect(() => {
    if (disabled) {
      setDropdownOpen(false)
    }
  }, [disabled])

  const selectFilterType = (option) => {
    if (selectedFilterType !== option) {
      setSelectedFilterType(option)
      onChange(option)
    }
  }

  return (
    <div className={`react-filter-box ${disabled ? 'react-filter-disabled' : ''}`}>
      <div
        title={title}
        onClick={() => !disabled && setDropdownOpen(!isDropdownOpen)}
        className="react-filter-box-clickable-zone"
      >
        <div className="react-filter-titles">
          <div className="filter-title-overflow">{title.toUpperCase()}</div>
          {isRequired && selectedFilterType == null && <span className="text-red">*</span>}
          {selectedFilterType != null && <span className="text-orange">(1)</span>}
          <FontAwesomeIcon icon={isDropdownOpen ? faChevronUp : faChevronDown} />
        </div>
      </div>
      {isDropdownOpen && (
        <div className="react-radio-filter-dropdown-box box-transition" ref={filterContainer}>
          <div className="react-filter-option-nohover">
            <div className="filter-subtitle">Filtrar por:</div>
          </div>
          {optionsKeys.map((option, idx) => (
            <div className="react-filter-option-nohover" key={idx}>
              <input
                type="radio"
                className="filter-radio"
                id={`filterType-${idx}`}
                name="filterType"
                value={option}
                checked={selectedFilterType === option}
                onChange={() => selectFilterType(option)}
              />
              <div className="filter-radio-label">{option}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
