import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { formatDateNoHours } from '../../../helpers/utils'

const InvalidCoordinatesModal = ({ show, handleClose, startDate, username, lastActivityTime }) => (
  <Modal
    show={show}
    dialogClassName="modal-90w close-button-modal-maps"
    onHide={handleClose}
    aria-labelledby="example-modal-sizes-title-lg"
  >
    <Modal.Header closeButton className="no-border-bottom">    
    <Modal.Title id="example-modal-sizes-title-lg">
    <b className='mb-10'>Mapa de Geocercas</b>
        </Modal.Title>
    </Modal.Header>
    <Modal.Header className="d-flex flex-column align-items-start">
        <p className="text-title-modal-map">Usuario: {username}</p>
        <p className="text-title-modal-map">Fecha: {formatDateNoHours(startDate)}</p>
        <p className="text-title-modal-map">Hora de última actividad: {lastActivityTime}</p>
    </Modal.Header>
    <Modal.Body>
      <div className="map-modal-error">
      <div className="map-error-label">
          <p>
            El usuario ha desactivado el envío de ubicación para todas las actividades. 
          </p>
          <p>No es posible mostrar el mapa de geocercas.</p>
        </div>
         </div>
    </Modal.Body>
    <Modal.Footer>
    <Button variant="light" onClick={handleClose} className="custom-close-button">
    Cerrar
      </Button>
    </Modal.Footer>
  </Modal>
)

export default InvalidCoordinatesModal
