import React from 'react'
import NavBar from '../../layout/NavBar'
import TopBar from '../../layout/TopBar'
import EffectivenessIndexView from './EffectivenessIndexView'

export default class EffectivenessIndexApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userName: 'Ana Sofia Lau Hou',
      searchPlaceholder: '',
      title: 'Reporte de usuarios:',
      collapsed: false, 
    }
  }

  componentDidMount() {
    const collapsed = localStorage.getItem('collapsedState') === 'true';
    this.setState({ collapsed });
  }

  handleToggleCollapse = (collapsed) => {
    this.setState({ collapsed }, () => {
      localStorage.setItem('collapsedState', collapsed);
    });
  };

  render() {
    return (
      <div>
        <div>
          <div>
            <TopBar
              searchPlaceholder={this.state.searchPlaceholder}
              userName={this.state.userName}
              onToggleCollapse={this.handleToggleCollapse} 
              collapsed={this.state.collapsed} 
            />
          </div>
          <div>
            <EffectivenessIndexView 
            title={this.state.title}
            collapsed={this.state.collapsed} 
            />
          </div>
        </div>
        <div>
          <NavBar activeItem="Reportes" isEditing={false} collapsed={this.state.collapsed} />
        </div>
      </div>
    )
  }
}
