import React, { useState, useEffect } from 'react'
import cookie from 'react-cookies'
import ProductDetailsTab from './ProductDetailsTab'
import ProductPriceListsTab from './ProductPriceListsTab'
import ProductHistoryTab from './ProductHistoryTab'
import ProductPhotoTab from './ProductPhotoTab'
import { useHistory, useLocation } from 'react-router-dom'

const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
const BACKEND_URL = USE_BUSINESS_URL
  ? `${process.env.REACT_APP_BACKEND_URL}/businesses/${cookie.load('business_id')}`
  : process.env.REACT_APP_BACKEND_URL

const ShowProductView = ({
  collapsed,
  successCreateForNewProduct,
  isEditing,
  editStateChanged
}) => {
  const getIdFromUrl = () =>
    window.location.href.split('/')[window.location.href.split('/').length - 2]

  const getActiveTab = () => {
    if (window.location.href.indexOf('listas_de_precios') >= 0) {
      return 'Listas de Precios'
    } else if (window.location.href.indexOf('imagenes') >= 0) {
      return 'Imágenes'
    } else {
      return 'Detalles'
    }
  }

  const [activeTab, setActiveTab] = useState(getActiveTab())
  const [isFetching, setIsFetching] = useState(true)
  const [productDetailsData, setProductDetailsData] = useState(null)
  const [title, setTitle] = useState('')
  const [id] = useState(getIdFromUrl())
  const [editingPrices, setEditingPrices] = useState(false)
  const [addingPrices, setAddingPrices] = useState(false)
  const [alertState, setAlertState] = useState('hidden')

  useEffect(() => {
    fetchDataFromServer()
  }, [])

  const fetchDataFromServer = () => {
    setIsFetching(true)

    fetch(`${BACKEND_URL}/products/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookie.load('token')}`
      }
    })
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => {
        setProductDetailsData(data)
        setIsFetching(false)
        setTitle(`Producto: ${data['db_ref']}-${data['name']}`)
      })
      .catch((e) => console.log(e))
  }

  const handleTabClick = (event) => {
    if (event.target.tagName === 'A') {
      if (!editingPrices && !addingPrices && !isEditing) {
        setActiveTab(event.target.innerText)
      } else {
        const confirmation = window.confirm(
          '¿Está seguro que quiere continuar? Los cambios editados no serán guardados'
        )
        if (confirmation) {
          setActiveTab(event.target.innerText)
          setEditingPrices(false)
          setAddingPrices(false)
          passEditState(false)
        }
      }
    }
  }

  const passEditState = (val) => {
    editStateChanged(val)
  }

  const editInDetailsTab = (event) => {
    if (event.target.value === 'Actualizar detalles') {
      let newTitle = `Actualizar detalles de producto: ${productDetailsData['db_ref']}-${productDetailsData['name']}`
      newTitle = newTitle.length > 80 ? `${newTitle.substring(0, 77)}...` : newTitle
      setTitle(newTitle)
      passEditState(true)
    } else if (event.target.value === 'Cancelar') {
      setTitle(`Producto: ${productDetailsData['db_ref']}-${productDetailsData['name']}`)
      passEditState(false)
    } else if (event.target.value === 'Guardar') {
      setTitle(`Producto: ${productDetailsData['db_ref']}-${productDetailsData['name']}`)
    }
  }

  const editInPriceListTab = (event) => {
    if (event.target.value === 'Actualizar') {
      setEditingPrices(true)
      passEditState(true)
    } else if (event.target.value === '+  Agregar lista de precio') {
      setAddingPrices(true)
      passEditState(true)
    } else if (event.target.value === 'Cancelar') {
      if (event.target.id === 'cancel-add') {
        setAddingPrices(false)
        checkForEditMode()
      } else if (event.target.id === 'cancel-edit') {
        setEditingPrices(false)
        checkForEditMode()
      }
    }
  }

  const checkForEditMode = () => {
    if (!editingPrices && !addingPrices) {
      passEditState(false)
    }
  }

  const location = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.get('successCreateForNewProduct') === 'true') {
      setAlertState('alert alert-success')
    }
  }, [location.search])

  const hiddenAlert = () => {
    setAlertState('hidden')
  }

  if (!isFetching) {
    return (
      <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
        {alertState !== 'hidden' && (
          <div className="alert alert-success alert-success-product" role="alert">
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={hiddenAlert}
            ></button>
            <strong className="success-message">El producto nuevo fue creado exitosamente.</strong>
          </div>
        )}
        <div className="main-show-header">
          <h2>{title}</h2>
        </div>
        <div className="index-table">
          <div className="show-area">
            <div onClick={handleTabClick}>
              <ul className="nav nav-tabs">
                <li id="details_tab" className="nav-item">
                  <a
                    className={`nav-link ${activeTab === 'Detalles' ? 'active' : 'inactive-tab'} cursor-pointer`}
                  >
                    Detalles
                  </a>
                </li>
                <li id="price_lists_tab" className="nav-item">
                  <a
                    className={`nav-link ${activeTab === 'Listas de Precios' ? 'active' : 'inactive-tab'} cursor-pointer`}
                  >
                    Listas de Precios
                  </a>
                </li>
                <li id="history_tab" className="nav-item">
                  <a
                    className={`nav-link ${activeTab === 'Historial' ? 'active' : 'inactive-tab'} cursor-pointer`}
                  >
                    Historial
                  </a>
                </li>
                <li id="photo_tab" className="nav-item">
                  <a
                    className={`nav-link ${activeTab === 'Imágenes' ? 'active' : 'inactive-tab'} cursor-pointer`}
                  >
                    Imágenes
                  </a>
                </li>
              </ul>
            </div>
            <div>
              {activeTab === 'Detalles' && (
                <div onClick={editInDetailsTab}>
                  <ProductDetailsTab data={productDetailsData} id={id} />
                </div>
              )}
              {activeTab === 'Listas de Precios' && (
                <div onClick={editInPriceListTab}>
                  <ProductPriceListsTab data={productDetailsData} id={id} />
                </div>
              )}
              {activeTab === 'Historial' && <ProductHistoryTab data={productDetailsData} id={id} />}
              {activeTab === 'Imágenes' && <ProductPhotoTab data={productDetailsData} id={id} />}
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
        <div className="main-show-header">
          <p>Obteniendo datos ....</p>
        </div>
      </div>
    )
  }
}
export default ShowProductView
