import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import AsyncSelect from 'react-select/async'
import cookie from 'react-cookies'
import { CreateDispatchContext } from '../context/CreateDispatchContext'
import { v4 as uuid } from 'uuid';
import { TYPES } from '../state/Types'
import IconOption from './IconOption'
import moment from 'moment'
import { DispatchConsumer } from '../context/OrderContext'
import _ from 'lodash'
import { GlobalContext } from '../../global_context/GlobalContext'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const DispatchUpdateDetails = () => {
  const {
    client,
    onUpdateDispatch,
    priceList,
    formControls,
    failedCreationInfo,
    changeFormHandler,
    state,
    dispatch
  } = useContext(CreateDispatchContext)

  const { variants } = useContext(GlobalContext)

  const [business_id] = useState(1)
  const [total_weight, setTotalWeight] = useState(0)
  const [subTotal, setSubTotal] = useState(0)
  const [total, setTotal] = useState(0)

  const [deliverer, setDeliverer] = useState({
    value: formControls.deliverer.value,
    label: formControls.deliverer.value
  })
  const { products } = state

  useEffect(() => {
    calculateOrderSubtotal()
    return () => {
      calculateOrderSubtotal()
    }
  }, [products])

  const calculateOrderSubtotal = () => {
    let order_subtotal = 0
    let total_weight = 0
    products.map((obj) => (order_subtotal += obj.productSubTotal))
    products.map((obj) => {
      const weight = obj.weight ? obj.weight : 0.0
      return (total_weight += parseFloat(weight))
    })
    setSubTotal(order_subtotal)
    setTotal(order_subtotal)
    setTotalWeight(total_weight)
  }

  const searchForDeliverer = (inputValue) => {
    if (inputValue.length >= 1) {
      const uri = `${BACKEND_URL}/businesses/${cookie.load('business_id')}/deliveries_users?q=${inputValue}`;
      return fetch(uri, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      })
        .then((response) => response.json())
        .then((data) =>
          data['table'].map((i) => ({
            value: i.name,
            target: {
              name: 'deliverer',
              value: i.id,
              id: i.id
            },
            label: i.db_ref.concat(' ' + i.name),
            delivererObject: i
          }))
        )
        .catch((e) => console.log(e))
    }
  }

  const searchForProduct = (inputValue) => {
    const query = inputValue
    if (query.length > 1) {
      return fetch(`${BACKEND_URL}/businesses/${cookie.load('business_id')}/price_lists/${priceList.id}/product_pricelist?q=${query}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      })
        .then((response) => response.json())
        .then((data) => {
          // index products by "product_db_ref"
          const lookup = _.keyBy(products, (pr) => pr.product_db_ref)
          /* find all products where "db_ref" exists in index, one loop, quick lookup. no nested loops
            return !lookup[u.db_ref]
           */
          const result = _.filter(data['table'], (data) => !lookup[data.db_ref])
          return result.map((i) => ({
            value: i.name,
            label: i.db_ref.concat(' ' + i.name),
            productObject: i
          }))
        })
        .catch((e) => console.log(e))
    }
  }

  const handleProductQuantity = (event, task) => {
    const quantityValue = event.target.value
    dispatch({ type: TYPES.CHANGE_QUANTITY, task, quantityValue })
  }

  const handleProductWeight = (event, task) => {
    const weightValue = event.target.value
    dispatch({ type: TYPES.CHANGE_WEIGHT, task, weightValue })
  }

  const addTask = (product) => {
    dispatch({
      type: TYPES.ADD_TASK,
      task: {
        id: uuid(),
        price_unit: product.price_unit.description,
        product_db_ref: product.db_ref,
        product_name: product.name,
        weight: null,
        quantity: null,
        tax_rate: product.tax.rate,
        useWeight: ['KILO', 'LIBRA'].includes(product.price_unit.description),
        unit_price: product.price,
        productSubTotal: 0.0,
        validQuantity: false,
        validWeight: false,
        price_unit_id: product.price_unit.id,
        product_id: product.id
      }
    })
  }

  const deleteTask = (task) => () => {
    dispatch({ type: TYPES.DELETE_TASK, task })
  }

  const changeProductHandler = (event) => {
    if (event !== null) {
      addTask(event.productObject)
    }
  }

  const promiseOptions = (inputValue) =>
    // eslint-disable-next-line no-undef
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(searchForDeliverer(inputValue))
      }, 1000)
    })

  const promiseProductOptions = (inputValue) =>
    // eslint-disable-next-line no-undef
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(searchForProduct(inputValue))
      }, 1000)
    })

  const getMonth = () => {
    const m = new Date().getMonth() + 1
    if (m < 10) {
      return '0' + m
    } else {
      return m
    }
  }

  const getDay = () => {
    const d = new Date().getDate()
    if (d < 10) {
      return '0' + d
    } else {
      return d
    }
  }

  return (
    <div>
      <div className="create-promo-wrapper">
        <form>
          <div className="row show-product-header">
            <div className="col-sm-6">
              <h4>Detalles de despacho</h4>
              <p className="red">Campos Obligatorios *</p>
            </div>
            <DispatchConsumer>
              {({ cancelUpdate }) => (
                <div className="col-sm-6">
                  <div className="show-buttons">
                    <button className="cancel-button" onClick={cancelUpdate} value="Cancelar">
                      Cancelar
                    </button>
                    <input
                      onClick={(e) => onUpdateDispatch(e, variants.isUseWeight)}
                      className="save-button"
                      value="Guardar"
                      type="submit"
                    />
                  </div>
                </div>
              )}
            </DispatchConsumer>
          </div>
          <hr />
        </form>

        <div>
          {failedCreationInfo.length !== 0 && (
            <div className="container-fluid">
              <div className="form-group row">
                <div className="col-sm-12">
                  <div className="alert alert-danger" role="alert">
                    <p>{failedCreationInfo}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="container-fluid">
            <div className="form-group row">
              <label className="pl-form col-sm-4 col-form-label">
                Nombre y Código del Cliente:
              </label>
              <div className="col-sm-7">
                <div>{`${client.db_ref} ${client.customer_name_db_ref}`}</div>
              </div>
            </div>

            <div className="form-group row mt-33">
              <label className="pl-form col-sm-4 col-form-label">Lista de Precio:</label>
              <div className="col-sm-7">{`${priceList.db_ref} ${priceList.description}`}</div>
            </div>

            <div className="form-group row mt-33">
              <label className="pl-form col-sm-4 col-form-label">
                Entregador/Repartidor/Conductor<span className="red"> *</span>:
              </label>
              <div className="col-sm-7">
                <AsyncSelect
                  placeholder={'Buscar entregador por nombre o código'}
                  noOptionsMessage={() => 'Buscar'}
                  loadingMessage={() => 'Cargando entregadores...'}
                  cacheOptions
                  defaultOptions
                  name="selectOption"
                  defaultValue={deliverer}
                  onChange={changeFormHandler}
                  loadOptions={promiseOptions}
                />
              </div>
            </div>

            <div className="form-group row mt-33">
              <label className="pl-form col-sm-4 col-form-label">
                Fecha de Entrega<span className="red"> *</span>:
              </label>
              <div className="col-sm-7">
                <input
                  min={new Date().getFullYear() + '-' + getMonth() + '-' + getDay()}
                  type="date"
                  onChange={changeFormHandler}
                  className="form-control"
                  value={moment(formControls.date.value).format('YYYY-MM-DD')}
                  name="date"
                  required
                />
              </div>
            </div>

            <div className="form-group row mt-33">
              <label className="pl-form col-sm-4 col-form-label">
                Productos por Entregar<span className="red"> *</span>:
              </label>
              <div className="col-sm-7">
                <AsyncSelect
                  placeholder={'Buscar producto por nombre o código'}
                  noOptionsMessage={() => 'Escriba para poder buscar y agregar un producto'}
                  loadingMessage={() => 'Cargando productos...'}
                  cacheOptions
                  defaultOptions={false}
                  value={''}
                  name="selectOption"
                  onChange={changeProductHandler}
                  components={{ Option: IconOption }}
                  loadOptions={promiseProductOptions}
                />
              </div>
            </div>

            <div className="mt-33">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>CÓDIGO</th>
                    <th>NOMBRE DEL PRODUCTO</th>
                    <th>CANTIDAD</th>
                    <th>PESO NETO</th>
                    <th>UNIDAD DE VENTA</th>
                    <th>PRECIO</th>
                    <th>SUBTOTAL</th>
                    <th>ELIMINAR</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((obj, index) => (
                    <tr key={obj.product_db_ref}>
                      <td>{obj.product_db_ref}</td>
                      <td>{obj.product_name}</td>

                      <td>
                        <div>
                          <div>
                            <input
                              name="quantity"
                              type="number"
                              className={
                                obj.validQuantity
                                  ? 'form-control'
                                  : 'form-control valid-control-error'
                              }
                              placeholder={0}
                              min="1"
                              defaultValue={obj.quantity}
                              id={index}
                              onChange={(e) => handleProductQuantity(e, obj)}
                            />
                          </div>
                        </div>
                      </td>

                      <td>
                        {variants.isUseWeight ? (
                          <input
                            name="weight"
                            type="number"
                            className={
                              obj.validWeight ? 'form-control' : 'form-control valid-control-error'
                            }
                            placeholder={0}
                            min="1"
                            defaultValue={obj.weight}
                            id={index}
                            onChange={(e) => handleProductWeight(e, obj)}
                          />
                        ) : (
                          <input
                            name="weight"
                            type="number"
                            disabled={true}
                            className="goal-quantity-input-disable"
                            placeholder={0}
                            step="0.01"
                            min="0"
                            value="0"
                            id={index}
                          />
                        )}
                      </td>

                      <td>{obj.price_unit}</td>

                      <td>${parseFloat(obj.unit_price).toFixed(2)}</td>
                      <td>${parseFloat(obj.productSubTotal).toFixed(2)}</td>
                      <td>
                        <button
                          className="unassign-price-list-button"
                          id={index}
                          onClick={deleteTask(obj)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan="5" />
                    <td>
                      <b>PESO TOTAL</b>
                    </td>
                    <td>{total_weight.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td colSpan="5" />
                    <td>
                      <b>SUB-TOTAL</b>
                    </td>
                    <td>${subTotal.toFixed(2)}</td>
                    <td />
                  </tr>
                  <tr>
                    <td colSpan="5" />
                    <td className="fs-20">
                      <b>TOTAL</b>
                    </td>
                    <td className="fs-20">
                      <b>${total.toFixed(2)}</b>
                    </td>
                    <td />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DispatchUpdateDetails
