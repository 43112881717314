import cookie from 'react-cookies'

const validateAccessRole = (role) => {
  let isAdmin = cookie.load('user_role')?.is_admin
  if (isAdmin) {
    return {
      can_read: true,
      can_write: true
    }
  }
  let access_type
  switch (role) {
    case 'READ_WRITE':
      access_type = {
        can_read: true,
        can_write: true
      }
      break

    case 'READ':
      access_type = {
        can_read: true,
        can_write: false
      }
      break

    case 'OFF':
      access_type = {
        can_read: false,
        can_write: false
      }
      break
    default:
      access_type = {
        can_read: false,
        can_write: false
      }
      break
  }
  return access_type
}

const nonWritePermissionsAlert = () => {
  return alert(
    `Lo sentimos, el rol asignado al usuario ${cookie.load('user_email')} no tiene permisos para realizar esta acción\n\nContacte a soporte@ubiqua.me si cree que esto es un error`
  )
}
export { validateAccessRole, nonWritePermissionsAlert }
