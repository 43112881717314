import cookie from 'react-cookies'
import * as apiStatusCodes from '../constants/apiStatusCodes'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const API_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + cookie.load('token')
}

const routes = {
  salesmen: {
    list: '/businesses/'+ cookie.load('business_id')+'/salesmen',
    individual: '/salesmen',
    salesmanClients: '/salesman_client' // Note, NOT a standalone route.
  }
}

/**
 * @function sendApiRequest
 * A dynamic fetch method that can be re-used for multiple routes
 * and request methods.
 * @param {String} method One of 'GET', 'POST', 'PUT', or 'DELETE'.
 * @param {String} url Route to send request to.
 * @param {Object} params Optional parameters if request requires them.
 *
 * @return {Request} A fetch request with all relevant info attached.
 */
const sendApiRequest = (method, url, params) =>
  fetch(url, {
    method: method,
    headers: API_HEADERS,
    body: JSON.stringify(params)
  })

/**
 * @function handleErrors
 * Processes API responses and returns a rejection for responses that are NOT
 * 200 OK, or 201 CREATED.
 * @param {Response} response the HTTP response to be processed.
 *
 * @returns {Promise} Returns the same response that came in either as a resolved
 * or rejected promise based on the response status code.
 */
export const handleErrors = (response) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    if (response.status !== apiStatusCodes.OK && response.status !== apiStatusCodes.CREATED) {
      reject(response)
    } else {
      resolve(response)
    }
  })

/**
 * @function getAllSalesmen
 * Fetches a list of all salesmen for the business.
 * @param {String} query Search query to run against the entire list
 * @param {Number} page Current page of results.
 * @param {Number} per_page Number of results to return per page.
 *
 * @returns {Promise} GET request with the relevant route and params.
 */
export const getAllSalesmen = (query = '', page = 1, per_page = 25) => {
  const url = `${BACKEND_URL}${routes.salesmen.list}?q=${query}&page=${page}&per_page=${per_page}`
  return sendApiRequest('GET', url)
}

/**
 * @function getSalesmanDetails
 * Fetches details for an individual salesman based on their ID.
 * @param {Number} salesmanId Id of the salesman to GET.
 *
 * @returns {Promise} GET request with the relevant route and params.
 */
export const getSalesmanDetails = (salesmanId) => {
  const url = `${BACKEND_URL}${routes.salesmen.individual}/${salesmanId}`
  return sendApiRequest('GET', url)
}

/**
 * @function createNewSalesman
 * Creates a new salesman based on params provided.
 * @param {Object} params JS object (NOT raw JSON) with all relevant request params.
 *
 * @returns {Promise} POST request to create a new salesman with relevant params.
 */
export const createNewSalesman = (params) => {
  const url = `${BACKEND_URL}${routes.salesmen.individual}`
  return sendApiRequest('POST', url, params)
}

/**
 * @function updateSalesmanDetails
 * Patches a salesman record with updated information.
 * @param {Number} salesmanId ID of salesman to be updated.
 * @param {Object} params JS Object (NOT raw JSON) with salesman details to be updated.
 *
 * @returns {Promise} PUT request to updated salesman with relevant params.
 */
export const updateSalesmanDetails = (salesmanId, params) => {
  const url = `${BACKEND_URL}${routes.salesmen.individual}/${salesmanId}`
  return sendApiRequest('PUT', url, params)
}

/**
 * @function getSalesmanClientList
 * Fetches a list of all clients currently assigned to a salesman.
 * @param {String} query Search query to run against the entire list
 * @param {Number} page Current page of results.
 * @param {Number} per_page Number of results to return per page.
 *
 * @returns {Promise} GET request with the relevant route and params.
 */
export const getSalesmanClientList = (salesmanId, query = '', page = 1, per_page = 25) => {
  const { individual, salesmanClients } = routes.salesmen
  const url = `${BACKEND_URL}${individual}/${salesmanId}${salesmanClients}?q=${query}&page=${page}&per_page=${per_page}`
  return sendApiRequest('GET', url)
}

/**
 * @function getUnassignedClientList
 * Fetches a list of all clients that are available for assignment to a salesman.
 * @param {String} query Search query to run against the entire list
 * @param {Number} page Current page of results.
 * @param {Number} per_page Number of results to return per page.
 *
 * @returns {Promise} GET request with the relevant route and params.
 */
export const getUnassignedClientList = (salesmanId, query = '', page = 1, per_page = 25) => {
  const { list, salesmanClients } = routes.salesmen
  const url = `${BACKEND_URL}${list}/${salesmanId}${salesmanClients}/missing_clients/?q=${query}&page=${page}&per_page=${per_page}`
  return sendApiRequest('GET', url)
}

/**
 * @function addClientToSalesmanRoute
 * Adds a client to a salesman's route with a specific visit day.
 * @param {Object} params Object with key values for request
 *
 * @returns {Promise} POST request with the relevant route and params.
 */
export const addClientToSalesmanRoute = (params) => {
  const { salesmanClients } = routes.salesmen
  const url = `${BACKEND_URL}/${salesmanClients}`
  return sendApiRequest('POST', url, params)
}

/**
 * @function unassignClientFromSalesman
 * Removes a client from a salesman's route
 * @param {Object} params Object with key values for request
 *
 * @returns {Promise} POST request with the relevant route and params.
 */
export const unassignClientFromSalesman = (clientId, params) => {
  const { salesmanClients } = routes.salesmen
  const url = `${BACKEND_URL}/${salesmanClients}/${clientId}`
  return sendApiRequest('PUT', url, params)
}

/**
 * @function getAllDeliverersUsers
 * Fetches a list of all deliverers users for the business.
 * @param {String} query Search query to run against the entire list
 * @param {Number} page Current page of results.
 * @param {Number} per_page Number of results to return per page.
 *
 * @returns {Request} GET request with the relevant route and params.
 */
export const getAllDeliverersUsers = (query = '', page = 1, per_page = 25) => {
  const endPoint = '/businesses/'+ cookie.load('business_id')+'/deliveries_users'
  const url = `${BACKEND_URL}${endPoint}?q=${query}&page=${page}&per_page=${per_page}`
  return sendApiRequest('GET', url)
}

/**
 * @function getDeliveryDetails
 * Fetches details for an individual delivery based on their ID.
 * @param {Number} deliveryId Id of the salesman to GET.
 *
 * @returns {Request} GET request with the relevant route and params.
 */
export const getDeliveryDetails = (deliveryId) => {
  const endPoint = '/deliveries_users'
  const url = `${BACKEND_URL}${endPoint}/${deliveryId}`
  return sendApiRequest('GET', url)
}

/**
 * @function getEcommerceUserDetails
 * Fetches details for an individual delivery based on their ID.
 * @param {Number} clientId Id of the salesman to GET.
 *
 * @returns {Request} GET request with the relevant route and params.
 */
export const getEcommerceUserDetails = (clientId) => {
  const endPoint = `/businesses/${cookie.load('business_id')}/clients/${clientId}/ecommerce_users`
  const url = `${BACKEND_URL}${endPoint}`
  return sendApiRequest('GET', url)
}

/**
 * @function getEcommerceUser
 * Fetches details for an individual delivery based on their ID.
 * @param {Number} clientId Id of the salesman to GET.
 *
 * @returns {Request} GET request with the relevant route and params.
 */
export const getEcommerceUser = (id) => {
  const endPoint = `/ecommerce_users/`
  const url = `${BACKEND_URL}${endPoint}/${id}`
  return sendApiRequest('GET', url)
}
