import React, { useState, useContext, useEffect } from 'react'
import ProductStepProgressBar from '../component/ProductStepProgressBar'
import CreateProductDetailsStep from '../steps/CreateProductDetailsStep'
import CreateProductPriceListStep from '../steps/CreateProductPriceListStep'
import { SubmitCreateProductContext } from '../context/SubmitCreateProductProvider'
import NavBar from '../../layout/NavBar'
import TopBar from '../../layout/TopBar'


const CreateProductStep = (props) => {
  const { failedCreationInfo } = useContext(SubmitCreateProductContext)
  const [collapsed, setCollapsed] = useState(false);

   const handleToggleCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed);
    localStorage.setItem('collapsedState', newCollapsed);
  };

  useEffect(() => {
    const collapsedFromStorage = localStorage.getItem('collapsedState') === 'true';
    setCollapsed(collapsedFromStorage);
  }, []);



  const step1Content = <CreateProductDetailsStep />
  const step2Content = <CreateProductPriceListStep />

  function onFormSubmit() {}

  return (
    <>
    <div> 
        <TopBar
          onToggleCollapse={handleToggleCollapse}
          collapsed={collapsed}
          searchPlaceholder={''}
        />
      </div>
      <div>
      <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
          {failedCreationInfo !== null && (
            <div className="alert alert-danger ml-10" role="alert">
              <strong>{failedCreationInfo}</strong>
            </div>
          )}
          <div className="main-show-header mt-40">
            <h2>Crear Producto</h2>
          </div>
          <div>
            <div className="index-table">
              <div className="show-area-tab">
                <ProductStepProgressBar
                  startingStep={0}
                  onSubmit={onFormSubmit}
                  steps={[
                    {
                      label: 'Información',
                      name: 'Información',
                      content: step1Content
                    },
                    {
                      label: 'Listas de precio',
                      name: 'Listas de precio',
                      content: step2Content
                    }
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
        <NavBar activeItem="Productos" isEditing={true} collapsed={collapsed} />
        </div>
        
      </div>
    </>
  )
}
export default CreateProductStep
