import React, { useContext, useState } from 'react'
import { ProductCreateContext } from '../context/ProductCreateProvider'
import ImageUploading from 'react-images-uploading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowUp, faImage, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import ImageModal from '../../components/image_modal/ImageModalProducts'
import { convertByteToMb } from '../../helpers/utils'

const CreateProductDetailsStep = () => {
  const { errors, register, newProductInfo, onChangeImage, maxNumber, images } =
    useContext(ProductCreateContext)

  const [categoryValue, setCategoryValue] = useState(0)
  const [categorySelected, setCategorySelected] = useState(false)

  const handleDropdownChange = (e) => {
    setCategoryValue(e.target.value)
    setCategorySelected(e.target.value != '' && e.target.value != null)
  }

  const categoryFiltered = newProductInfo.category_subcategory_relation?.filter(
    (c) => c.category_id == categoryValue
  )

  const maxMbFileSize = 5242880

  const [description, setDescription] = useState('')
  const [nameproduct, setNameProduct] = useState('')
  const [showImage, setShowImage] = useState(false)

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value)
  }

  const handleNameProductsChange = (event) => {
    setNameProduct(event.target.value)
  }

  const handleShowImage = (event) => {
    setShowImage(true)
  }

  return (
    <>
      <hr className="mb-30" />
      <div className="flex-inline-message full-width mb-40">
        <span>
          <b>Paso 1 de 2:</b>
        </span>
        <span></span>
        Por favor, complete los campos obligatorios marcados con
        <span className="required">*</span> para proceder.
      </div>

      <div className="form-group row">
        <label className="col-sm-3 col-form-label">Activo:</label>
        <div className="col-sm-8">
          <select
            name="active"
            {...register('active')}
            className={`form-control custom-select ${errors.active ? 'is-invalid' : ''}`}
          >
            <option value="true">Si</option>
            <option value="false">No</option>
          </select>
          <div className="invalid-feedback">{errors.active?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputName" className="col-sm-3 col-form-label">
          Código interno: <span className="red">*</span>
        </label>
        <div className="col-sm-8">
          <input
            name="code"
            type="text"
            {...register('code')}
            className={`form-control ${errors.code ? 'is-invalid' : ''}`}
            placeholder="00000"
          />
          <div className="invalid-feedback">{errors.code?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputName" className="col-sm-3 col-form-label">
          Código de barra: <span className="red">*</span>
        </label>
        <div className="col-sm-8">
          <input
            name="barcode"
            type="text"
            {...register('barcode')}
            className={`form-control ${errors.barcode ? 'is-invalid' : ''}`}
            placeholder="0123-4567"
          />
          <div className="invalid-feedback">{errors.barcode?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Nombre de producto: <span className="red">*</span>
        </label>
        <div className="col-sm-8">
          <input
            name="name"
            type="text"
            {...register('name')}
            maxLength="50"
            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
            placeholder="Nombre"
            value={nameproduct}
            onChange={handleNameProductsChange}
          />
          <span className="float-right" style={{ color: '#808080' }}>
            Cantidad máxima de caracteres {Math.abs(nameproduct.length - 50)}/50
          </span>
          <div className="invalid-feedback">{errors.name?.message}</div>
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Descripción:
        </label>
        <div className="col-sm-8">
          <textarea
            name="description"
            autoComplete="off"
            maxLength="400"
            {...register('description')}
            className={`form-control ${errors.description ? 'is-invalid' : ''}`}
            placeholder="Descripción del producto"
            value={description}
            onChange={handleDescriptionChange}
          />
          <span className="float-right" style={{ color: '#808080' }}>
            Cantidad máxima de caracteres {Math.abs(description.length - 400)}/400
          </span>
          <div className="invalid-feedback">{errors.description?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          ITBMS: <span className="red">*</span>
        </label>
        <div className="col-sm-8">
          <select
            name="itbms"
            {...register('itbms')}
            className={`form-control custom-select ${errors.itbms ? 'is-invalid' : ''}`}
          >
            <option value="" hidden disabled selected>
              Escoja una opción
            </option>
            {newProductInfo.taxes != null
              ? newProductInfo.taxes
                  .filter((v) => v.name)
                  .map((obj) => (
                    <option key={obj.id} value={obj.id}>{`ITBMS - ${obj.name}`}</option>
                  ))
              : []}
          </select>
          <div className="invalid-feedback">{errors.itbms?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Marca: <span className="red">*</span>
        </label>
        <div className="col-sm-8">
          <select
            name="brand"
            {...register('brand')}
            className={`form-control custom-select ${errors.brand ? 'is-invalid' : ''}`}
          >
            <option value="" hidden disabled selected>
              Escoja una opción
            </option>
            {newProductInfo.brand != null
              ? newProductInfo.brand
                  .sort((a, b) => a.description.localeCompare(b.description))
                  .map((obj) => (
                    <option key={obj.id} value={obj.id}>
                      {obj.description}
                    </option>
                  ))
              : []}
          </select>
          <div className="invalid-feedback">{errors.brand?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Unidad de venta 1: <span className="red">*</span>
        </label>

        <div className="col-sm-3">
          <select
            name="unit"
            {...register('unit')}
            className={`form-control custom-select ${errors.unit ? 'is-invalid' : ''}`}
          >
            <option value="" hidden disabled selected>
              Escoja una opción
            </option>
            {newProductInfo.package_units != null
              ? newProductInfo.package_units
                  .sort((a, b) => a.description.localeCompare(b.description))
                  .map((obj) => (
                    <option key={obj.id} value={obj.description}>
                      {obj.description}
                    </option>
                  ))
              : []}
          </select>
          <div className="invalid-feedback">{errors.unit?.message}</div>
        </div>

        <div className="col-sm-1 text-center" style={{ paddingTop: 5 }}>
          de
        </div>

        <div className="col-sm-3">
          <input
            name="unitQuantity"
            type="number"
            {...register('unitQuantity')}
            className={`form-control ${errors.unitQuantity ? 'is-invalid' : ''}`}
            placeholder="00"
          />
          <div className="invalid-feedback">{errors.unitQuantity?.message}</div>
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label" />
        <div className="col-sm-8">
          <input
            name="splitUnit"
            {...register('splitUnit')}
            type="checkbox"
            className="form-check-input"
            id="exampleCheck1"
          />
          <label
            className="form-check-label"
            htmlFor="exampleCheck1"
            style={{ paddingLeft: 10, fontWeight: 500, color: '#27588D' }}
          >
            Activar venta de empaque en unidad
          </label>
          <div className="invalid-feedback">{errors.splitUnit?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Peso por unidad:
        </label>
        <div className="col-sm-3">
          <input
            name="weight"
            type="number"
            step="0.01"
            {...register('weight')}
            className={`form-control ${errors.weight ? 'is-invalid' : ''}`}
            placeholder="00"
          />
          <div className="invalid-feedback">{errors.weight?.message}</div>
        </div>
        <div className="col-sm-1 text-center" style={{ paddingTop: 5 }}>
          Unidad
        </div>
        <div className="col-sm-3">
          <select
            name="unitWeight"
            {...register('unitWeight')}
            className={`form-control custom-select ${errors.unitWeight ? 'is-invalid' : ''}`}
          >
            <option value="" hidden disabled selected>
              Escoja una opción
            </option>
            {newProductInfo.price_unit != null
              ? newProductInfo.price_unit
                  .sort((a, b) => a.description.localeCompare(b.description))
                  .map((obj) => (
                    <option key={obj.id} value={obj.id}>
                      {obj.description}
                    </option>
                  ))
              : []}
          </select>
          <div className="invalid-feedback">{errors.unitWeight?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Categoría: <span className="red">*</span>
        </label>
        <div className="col-sm-8">
          <select
            name="category"
            {...register('category')}
            className={`form-control custom-select ${errors.category ? 'is-invalid' : ''}`}
            onChange={handleDropdownChange}
          >
            <option value="" hidden disabled selected>
              Escoja una opción
            </option>
            {newProductInfo.categories != null
              ? newProductInfo.categories
                  .sort((a, b) => a.description.localeCompare(b.description))
                  .map((obj) => (
                    <option key={obj.id} value={obj.id}>
                      {obj.description}
                    </option>
                  ))
              : []}
          </select>
          <div className="invalid-feedback">{errors.category?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Subcategoría:
        </label>
        <div className="col-sm-8">
          {categorySelected ? (
            <select
              name="subCategory"
              {...register('subCategory')}
              className={`form-control custom-select ${errors.subCategory ? 'is-invalid' : ''}`}
            >
              <option value="" disabled selected>
                Escoja una opción
              </option>
              {categoryFiltered != null
                ? categoryFiltered
                    .sort((a, b) =>
                      a.subcategory_description.localeCompare(b.subcategory_description)
                    )
                    .map((obj) => (
                      <option key={obj.id} value={obj.subcategory_id}>
                        {obj.subcategory_description}
                      </option>
                    ))
                : []}
            </select>
          ) : (
            <select
              name="subCategory"
              {...register('subCategory')}
              className={`form-control custom-select ${errors.subCategory ? 'is-invalid' : ''}`}
              disabled
              style={{ backgroundColor: '#f0f0f0' }}
            >
              <option value="" disabled selected>
                Escoja una opción
              </option>
            </select>
          )}
          <div className="invalid-feedback">{errors.subCategory?.message}</div>
        </div>
      </div>
      <div className="form-group row show-data-form expand-imagen">
        <div className="col-md-3">
          <label htmlFor="inputCode" className="col-form-label">
            Imagen:
          </label>
          <p style={{ fontSize: 13, color: '#AAAAAA' }}></p>
        </div>
        <div className="col-sm-8">
          <ImageUploading
            multiple
            value={images}
            onChange={onChangeImage}
            maxNumber={maxNumber}
            maxFileSize={maxMbFileSize}
            resolutionWidth={800}
            resolutionHeight={800}
            resolutionType={'less'}
            acceptType={['jpg', 'png']}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
              errors
            }) => (
              <div className="upload__image-wrapper">
                {imageList.length < 1 && (
                  <div className="form-group">
                    <div className="">
                      <div
                        className="product-upload-drop-zone"
                        id="drop-zone"
                        style={isDragging ? { background: '#F0F4F7' } : undefined}
                        {...dragProps}
                      >
                        <div className="drag-drop-product-icon">
                          <FontAwesomeIcon icon={faCloudArrowUp} />
                        </div>
                        <div className="planimetry-drop-text">
                          <b>Seleccione una sola imagen o arrástrela y suéltela aquí</b>
                        </div>
                        <div className="planimetry-drop-subtext">
                          JPG o PNG, tamaño del archivo no mayor a 5MB
                        </div>
                        <div className="planimetry-drop-subtext">
                          Tamaño máximo de 800px x 800 px
                        </div>
                        <div className="planimetry-drop-subtext">ó</div>
                        <button
                          onClick={onImageUpload}
                          className="select-photo-button"
                          type="button"
                        >
                          Seleccionar imagen
                        </button>
                      </div>
                    </div>
                    {errors && (
                      <div className="img-error-message">
                        {errors.maxNumber && <span>Máximo de 1 imagen por tarea</span>}
                        {errors.maxFileSize && (
                          <span>
                            Asegúrate de que la imagen sea en formato .png o .jpg y tenga un tamaño
                            no mayor a 5MB.
                          </span>
                        )}
                        {errors.acceptType && (
                          <span>
                            Asegúrate de que la imagen sea en formato .png o .jpg y tenga un tamaño
                            no mayor a 5MB.
                          </span>
                        )}
                        {errors.resolution && (
                          <span>
                            Verifique que la imagen sea formato .png .jpg y que las dimensiones sean
                            por debajo de 800px x 800px.
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {imageList.map((image, index) => (
                  <div className="promo-form-section" key={index}>
                    <div
                      className="title-promos-form"
                      style={{ marginTop: -65, fontSize: 13, marginBottom: 10 }}
                    ></div>
                    {image['data_url'] !== undefined && (
                      <div className="img-load-wrapper-product">
                        <div className="img-load-div">
                          <div className="img-load-icon">
                            <FontAwesomeIcon icon={faImage} />
                          </div>
                          <span className="img-load-text">{image['file'].name}</span>
                          <button
                            type="button"
                            className="img-view-buttom"
                            onClick={() => {
                              handleShowImage()
                            }}
                          >
                            Ver imagen
                          </button>
                        </div>
                        <span className="img-load-text" style={{ marginRight: 40 }}>
                          {convertByteToMb(image['file'].size)}
                        </span>
                        <button
                          type="button"
                          className="delete-image-button"
                          onClick={() => onImageRemove(index)}
                        >
                          <FontAwesomeIcon icon={faCircleXmark} />
                        </button>
                      </div>
                    )}
                    {image['image_url'] !== undefined && (
                      <div className="img-load-wrapper">
                        <div className="img-load-div">
                          <div className="img-load-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21"
                              height="22"
                              viewBox="0 0 21 22"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14.3451 7.63889C15.5702 7.63889 16.5634 6.61287 16.5634 5.34722C16.5634 4.08157 15.5702 3.05556 14.3451 3.05556C13.1199 3.05556 12.1268 4.08157 12.1268 5.34722C12.1268 6.61287 13.1199 7.63889 14.3451 7.63889ZM14.3451 6.11111C14.7534 6.11111 15.0845 5.76911 15.0845 5.34722C15.0845 4.92534 14.7534 4.58333 14.3451 4.58333C13.9367 4.58333 13.6056 4.92534 13.6056 5.34722C13.6056 5.76911 13.9367 6.11111 14.3451 6.11111Z"
                                fill="#597FA9"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M3.5493 0C1.58907 0 0 1.64162 0 3.66667V18.3333C0 20.3584 1.58907 22 3.5493 22H17.4507C19.4109 22 21 20.3584 21 18.3333V3.66667C21 1.64162 19.4109 0 17.4507 0H3.5493ZM17.4507 1.83333H3.5493C2.56919 1.83333 1.77465 2.65414 1.77465 3.66667V13.1023L6.17425 8.0316L11.3607 13.8334L14.6209 10.5455L19.2254 14.4085V3.66667C19.2254 2.65415 18.4308 1.83333 17.4507 1.83333ZM19.2254 16.3767L14.7113 12.5894L11.3058 16.0238L6.19058 10.3017L1.77465 15.3913V18.3333C1.77465 19.3459 2.56919 20.1667 3.5493 20.1667H17.4507C18.4308 20.1667 19.2254 19.3459 19.2254 18.3333V16.3767Z"
                                fill="#597FA9"
                              />
                            </svg>
                          </div>
                          <span className="img-load-text">{image['image_reference']}</span>
                          <button
                            type="button"
                            className="img-view-buttom"
                            onClick={() => {
                              handleShowImage()
                            }}
                          >
                            Ver imagen
                          </button>
                        </div>
                        <span className="img-load-text" style={{ marginRight: 40 }}></span>
                        <button
                          type="button"
                          className="delete-image-button"
                          onClick={() => onImageRemove(index)}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ margin: '0 !important' }}
                          >
                            <circle cx="12" cy="12" r="12" fill="#202020" />
                            <path
                              d="M12.6266 12L15.8666 8.75995C15.9395 8.67493 15.9775 8.56556 15.9732 8.45371C15.9689 8.34185 15.9225 8.23575 15.8433 8.15659C15.7642 8.07744 15.6581 8.03107 15.5462 8.02675C15.4344 8.02243 15.325 8.06048 15.24 8.13329L12 11.3733L8.75997 8.12884C8.67495 8.05603 8.56558 8.01798 8.45373 8.0223C8.34187 8.02662 8.23577 8.07299 8.15661 8.15215C8.07746 8.2313 8.03109 8.33741 8.02677 8.44926C8.02245 8.56112 8.0605 8.67049 8.13331 8.75551L11.3733 12L8.12886 15.24C8.08234 15.2798 8.04455 15.3288 8.01787 15.384C7.9912 15.4391 7.97621 15.4992 7.97384 15.5604C7.97148 15.6216 7.98179 15.6826 8.00414 15.7397C8.02648 15.7967 8.06037 15.8485 8.10369 15.8918C8.147 15.9351 8.1988 15.969 8.25583 15.9913C8.31286 16.0137 8.3739 16.024 8.43511 16.0216C8.49632 16.0193 8.55638 16.0043 8.61152 15.9776C8.66666 15.9509 8.71569 15.9131 8.75553 15.8666L12 12.6266L15.24 15.8666C15.325 15.9394 15.4344 15.9775 15.5462 15.9732C15.6581 15.9688 15.7642 15.9225 15.8433 15.8433C15.9225 15.7642 15.9689 15.6581 15.9732 15.5462C15.9775 15.4343 15.9395 15.325 15.8666 15.24L12.6266 12Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </div>
      </div>
      {images.map(
        (image, index) =>
          (image['data_url'] || image['image_url']) && (
            <div key={index}>
              <ImageModal
                imgSrc={image['data_url'] || image['image_url']}
                showImage={showImage}
                setShowImage={setShowImage}
              />
            </div>
          )
      )}
    </>
  )
}

export default CreateProductDetailsStep
