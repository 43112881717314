import React, { useState, useEffect } from 'react'
import TopBar from '../../layout/TopBar'

const UserHeader = ({ isFormEditing }) => {
  const [searchPlaceholder, setSearchPlaceholder] = useState('Buscar clientes por código o nombre')
  const [isEditing, setIsEditing] = useState(isFormEditing)

  useEffect(() => {
    setIsEditing(isFormEditing)
  }, [isFormEditing])

  const handleSearch = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      const query = event.target.value
      if (isEditing) {
        const isConfirmation = window.confirm(
          '¿Está seguro que quiere continuar? Los cambios editados no serán guardados'
        )
        if (isConfirmation) {
          window.location = `/clientes?query=${query}`
        }
      } else {
        window.location = `/clientes?query=${query}`
      }
      event.target.value = ''
    }
  }

  return <div onKeyDown={handleSearch}></div>
}

export default UserHeader
