import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { formatDateNoHours } from '../../../helpers/utils'

const NoActivityRecordsModal = ({ show, handleClose, startDate, username }) => (
  <Modal
    show={show}
    dialogClassName="modal-90w close-button-modal-maps"
    onHide={handleClose}
    aria-labelledby="example-modal-sizes-title-lg"
  >
    <Modal.Header closeButton className="no-border-bottom">    
    <Modal.Title id="example-modal-sizes-title-lg">
    <b className='mb-10'>Mapa de Geocercas</b>
      </Modal.Title>
    </Modal.Header>
    <Modal.Header className="d-flex flex-column align-items-start">
      <p className="text-title-modal-map">Usuario: {username}</p>
      <p className="text-title-modal-map">Fecha: {formatDateNoHours(startDate)}</p>
      <p className="text-title-modal-map">Hora de última actividad: -</p>
    </Modal.Header>
    <Modal.Body>
      <div className="map-modal-error">
        <div className="map-error-label">
          <p>
            No se han encontrado registros de actividades para este usuario en la fecha indicada.
          </p>
          <p>Por favor, elija otro día o intente más tarde.</p>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="light" onClick={handleClose} className="custom-close-button">
        Cerrar
      </Button>
    </Modal.Footer>
  </Modal>
)

export default NoActivityRecordsModal
