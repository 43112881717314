import React from 'react'
import cookie from 'react-cookies'

import SalesmanDetailsTab from './SalesmanDetailsTab'
import SalesmanGoalsTab from './SalesmanGoalsTab'
import SalesmanRoutesTab from './SalesmanRoutesTab'
import * as apiService from '../services/apiService'
import * as apiStatusCodes from '../constants/apiStatusCodes'

export default class ShowSalesmanView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: true,
      activeTab: this.getActiveTab(),
      title: '',
      id: window.location.href.split('/vendedores/')[1].split('/')[0],
      data: {},
      isApiErrorVisible: false,
      apiErrorText: '',
      alertState: 'alert alert-success'
    }
  }

  componentDidMount() {
    this.fetchDataFromServer()
  }

  getActiveTab = () => {
    if (window.location.href.indexOf('detalles') >= 0) {
      return 'Detalles'
    } else if (window.location.href.indexOf('cuotas') >= 0) {
      return 'Cuotas'
    } else if (window.location.href.indexOf('rutas') >= 0) {
      return 'Rutas'
    }
  }

  showApiError = (errorText) => {
    this.setState({
      isApiErrorVisible: true,
      apiErrorText: errorText
    })
  }

  hideApiError = () => {
    this.setState({
      isApiErrorVisible: false,
      apiErrorText: ''
    })
  }

  // in order to change active state for tabs
  handleTabClick = (event) => {
    // Si se hizo clic en una pestaña (la pestaña es un elemento <a>)
    if (event.target.tagName === 'A') {
      if (!this.props.isEditing) {
        this.setState({ activeTab: event.target.innerText }, this.render);
      } else {
        const confirmation = window.confirm(
          '¿Está seguro que quiere continuar? Los cambios editados no serán guardados'
        );
        if (confirmation) {
          this.setState({ activeTab: event.target.innerText }, this.render);
          this.changeEditMode(false);
        }
      }
    }
  };
  

  changeEditMode = (val) => {
    if (val) {
      this.setState({
        title:
          'Actualizar detalles de vendedor: ' +
          this.state.data['db_ref'] +
          ' - ' +
          this.state.data['name']
      })
    } else {
      this.setState({
        title: 'Vendedor: ' + this.state.data['db_ref'] + ' - ' + this.state.data['name']
      })
    }
    this.props.changeEditMode(val)
  }

  arrayToObject = (array) =>
    array.reduce((obj, item) => {
      obj[item.id] = item
      return obj
    }, {})

  fetchDataFromServer = () => {
    this.setState({ isFetching: true })

    apiService
      .getSalesmanDetails(this.state.id)
      .then((response) => apiService.handleErrors(response))
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          data: data,
          isFetching: false,
          title: `Vendedor: ${data.db_ref} - ${data.name}`
        })
      })
      .catch((e) => {
        if (e.status === apiStatusCodes.UNAUTHORIZED) {
          cookie.remove('token', { path: '/' })
          window.location = '/'
        }
      })
  }

  hiddenAlert = () => {
    this.setState({
      alertState: 'alert alert-success hidden'
    })
  }

  render() {
    if (this.state.isFetching === false) {
      return (
        <div className="main-view">
          {this.props.successCreateForNewSalesman && (
            <div className={this.state.alertState} role="alert">
              <button type="button" className="close" onClick={this.hiddenAlert} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <strong className="success-message">El vendedor nuevo fue creado exitosamente.</strong>
            </div>
          )}
          {this.state.isApiErrorVisible && (
            <div className="alert alert-danger" role="alert">
              <strong>{this.state.apiErrorText}</strong>
            </div>
          )}
          <div className="main-show-header">
            <h2>{this.state.title}</h2>
          </div>
          <div className="index-table">
            <div className="show-area">
              <div>
                <div onClick={this.handleTabClick}>
                  <ul className="nav nav-tabs">
                    <li
                      id="details_tab"
                      className={`${this.state.activeTab === 'Detalles' ? 'active' : ''}`}
                    >
                      <a
                        className={
                          this.state.activeTab !== 'Detalles'
                            ? 'inactive-tab cursor-pointer'
                            : 'cursor-pointer'
                        }
                      >
                        Detalles
                      </a>
                    </li>
                    <li
                      id="price_lists_tab"
                      className={this.state.activeTab === 'Cuotas' ? 'active' : ''}
                    >
                      <a
                        className={
                          this.state.activeTab !== 'Cuotas'
                            ? 'inactive-tab cursor-pointer'
                            : 'cursor-pointer'
                        }
                      >
                        Cuotas
                      </a>
                    </li>
                    <li
                      id="price_lists_tab"
                      className={this.state.activeTab === 'Rutas' ? 'active' : ''}
                    >
                      <a
                        className={
                          this.state.activeTab !== 'Rutas'
                            ? 'inactive-tab cursor-pointer'
                            : 'cursor-pointer'
                        }
                      >
                        Rutas
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  {this.state.activeTab === 'Detalles' && (
                    <div>
                      <SalesmanDetailsTab
                        showApiError={this.showApiError}
                        hideApiError={this.hideApiError}
                        changeEditMode={this.changeEditMode}
                        id={this.state.id}
                        userData={this.state.data}
                        updateSalesmanDetails={this.fetchDataFromServer}
                      />
                    </div>
                  )}
                  {this.state.activeTab === 'Cuotas' && (
                    <div>
                      {this.props.useAve ? (
                        <SalesmanGoalsTab
                          changeEditMode={this.changeEditMode}
                          id={this.state.id}
                          isEditing={this.props.isEditing}
                        />
                      ) : (
                        <div>
                          <div className="row">
                            <div className="col">
                              <h3 className="text-align-center">No cuenta con la aplicacion AVE</h3>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {this.state.activeTab === 'Rutas' && (
                    <div>
                      <SalesmanRoutesTab
                        showApiError={this.showApiError}
                        hideApiError={this.hideApiError}
                        changeEditMode={this.changeEditMode}
                        id={this.state.id}
                        salesmanData={this.state.data}
                        routeData={{}}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="main-view">
          <div className="main-show-header">
            <p>Obteniendo datos ....</p>
          </div>
        </div>
      )
    }
  }
}
