import React, { createContext, useEffect, useState } from 'react'

const initialState = {}
const MapModalStore = createContext(initialState)
const { Provider } = MapModalStore

const MapModalProvider = (props) => {
  const modalMapStyles = [
    {
      featureType: 'landscape.natural',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'on'
        },
        {
          color: '#e0efef'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'on'
        },
        {
          hue: '#1900ff'
        },
        {
          color: '#c0e8e8'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          lightness: 900
        },
        {
          visibility: 'simplified'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'on'
        },
        {
          lightness: 700
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          color: '#7dcdcd'
        }
      ]
    }
  ]

  return (
    <Provider
      value={{
        modalMapStyles: modalMapStyles
      }}
    >
      {props.children}{' '}
    </Provider>
  )
}

export { MapModalStore, MapModalProvider }
