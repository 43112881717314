import React, { useState } from 'react'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'

export default function ImageModal({ imgSrc, showImage, setShowImage }) {
  const [zoom, setZoom] = useState(false)

  const toggleZoom = () => setZoom(!zoom)

  const handleCloseImage = () => {
    setShowImage(false)
    setZoom(false)
  }

  return (
    <div>
      {imgSrc ? (
        <Modal
          open={showImage}
          onClose={handleCloseImage}
          center
          classNames={{
            overlay: 'customOverlay',
            modal: 'customModal'
          }}
          styles={{
            modal: {
              maxWidth: '90%',
              width: 'auto',
              padding: '20px'
            }
          }}
        >
          <img
            src={imgSrc}
            alt="Imagen agregada"
            style={{
              width: '100%',
              transform: zoom ? 'scale(2)' : 'scale(1)',
              transition: 'transform 0.3s ease'
            }}
            onDoubleClick={toggleZoom}
          />
          <div className="img-overlay-tooltip">
            Haga doble clic en la imagen para aumentar/disminuir el zoom
          </div>
        </Modal>
      ) : (
        <div>Imagen no disponible</div>
      )}
    </div>
  )
}
