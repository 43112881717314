import React from 'react'
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'
import cookie from 'react-cookies'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class DeliveryEffectivityIndexView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: false,
      data: [],
      report_total: 0,
      report_sub_total: 0,
      report_tax_total: 0,
      report_weight: 0,
      selected_date: this.getSelectedDate(),
      supervisor_id: null
    }
  }

  getSelectedDate = () => {
    if (window.location.href.indexOf('fecha') >= 0) {
      return window.location.href.split('fecha=')[window.location.href.split('fecha=').length - 1]
    } else {
      return this.getCurrentDate()
    }
  }

  getCurrentDate = () => new Date().getFullYear() + '-' + this.getMonth() + '-' + this.getDay()

  getMonth = () => {
    const m = new Date().getMonth() + 1
    if (m < 10) {
      return '0' + m
    } else {
      return m
    }
  }

  getDay = () => {
    const d = new Date().getDate()
    if (d < 10) {
      return '0' + d
    } else {
      return d
    }
  }

  componentDidMount = () => {
    this.fetchDataFromServer()
  }

  fetchDataFromServer = () => {
    this.setState({ isFetching: true })
    fetch(
      `${BACKEND_URL}/businesses/${cookie.load('business_id')}/reports/delivery_effectiveness?date=${this.state.selected_date}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => {
        if (response.status === 401) {
          cookie.remove('token', { path: '/' })
          window.location = '/'
        }
        return response.json()
      })
      .then((data) =>
        this.setState({
          data: data['result'],
          report_total: data['report_total'],
          report_weight: data['report_weight'],
          report_sub_total: data['report_sub_total'],
          report_tax_total: data['report_tax_total'],
          isFetching: false
        })
      )
      .catch((e) => console.log(e))
  }

  showModel = (event) => {
    event.preventDefault()
    const id = event.target.id
    window.location = `/facturas_por_entregador?id=${id}&date=${this.state.selected_date}`;
  }

  changeSelectedDate = (event) => {
    window.location = `efectividad_de_entregas?fecha=${event.target.value}`;
  }

  convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60)
    let m = Math.floor(mins % 60)
    h = h < 10 ? '0' + h : h
    m = m < 10 ? '0' + m : m
    return `${h}:${m}`
  }

  render() {
    if (this.state.isFetching) {
      return (
        <div className="main-view">
          <div className="index-header">
            <br />
            <p>Obteniendo datos ....</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className="main-view">
          <div className="index-header">
            <h2 className="mb-20 float-unset">{this.props.title}</h2>
          </div>
          <div className="index-header">
            <input
              max={this.getCurrentDate()}
              onChange={this.changeSelectedDate}
              defaultValue={this.state.selected_date}
              className="select-date-report"
              type="date"
              name="selected_date"
              required
            />
            {/*<button className="float-right" onClick={this.download}>
              Descargar reporte<FontAwesomeIcon icon={faArrowCircleDown}/>
            </button> */}
          </div>

          <div className="index-table">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>ENTREGADOR</th>
                  <th>TOTAL SALIDAS</th>
                  <th>TOTAL FACTURAS</th>
                  <th>EFECTIVIDAD</th>
                  <th>PESO</th>
                  <th>VENTA</th>
                  <th>TOTAL DURACION</th>
                </tr>
              </thead>
              <tbody>
                {this.state.data.length > 0 &&
                  this.state.data.map((model, index) => (
                    <tr className="cursor-pointer" key={index}>
                      <td id={model['deliverer_id']} onClick={this.showModel}>
                        {model['deliverer_name']}
                      </td>
                      <td id={model['deliverer_id']} onClick={this.showModel}>
                        {model['delivery_count']}
                      </td>
                      <td id={model['deliverer_id']} onClick={this.showModel}>
                        {model['invoice_count']}
                      </td>
                      <td id={model['deliverer_id']} onClick={this.showModel}>
                        {Number(model['effectiveness']).toFixed(0)}%
                      </td>
                      <td id={model['deliverer_id']} onClick={this.showModel}>
                        {Number(model['total_weight']).toFixed(1)} KG
                      </td>
                      <td id={model['deliverer_id']} onClick={this.showModel}>
                        $
                        {(parseFloat(model['sub_total']) + parseFloat(model['tax_total'])).toFixed(
                          2
                        )}
                      </td>
                      <td id={model['deliverer_id']} onClick={this.showModel}>
                        {this.convertMinsToHrsMins(model['duration_in_mins'])}
                      </td>
                    </tr>
                  ))}
                {this.state.data.length > 0 && (
                  <tr>
                    <td colSpan="5" />
                    <td>
                      <b>PESO TOTAL</b>
                    </td>
                    <td>{Number(this.state.report_weight).toFixed(2)} KG</td>
                  </tr>
                )}
                {this.state.data.length > 0 && (
                  <tr>
                    <td colSpan="5" />
                    <td>
                      <b>SUB-TOTAL</b>
                    </td>
                    <td>${Number(this.state.report_sub_total).toFixed(2)}</td>
                  </tr>
                )}
                {this.state.data.length > 0 && (
                  <tr>
                    <td colSpan="5" />
                    <td>
                      <b>Total ITBMS</b>
                    </td>
                    <td>${Number(this.state.report_tax_total).toFixed(2)}</td>
                  </tr>
                )}
                {this.state.data.length > 0 && (
                  <tr>
                    <td colSpan="5"></td>
                    <td className="fs-20">
                      <b>TOTAL</b>
                    </td>
                    <td className="fs-20">
                      <b>${Number(this.state.report_total).toFixed(2)}</b>
                    </td>
                  </tr>
                )}
                {this.state.data.length == 0 && (
                  <tr>
                     <td colSpan="7" className="td-text-limited td-text-limited-space">No hay resultados</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )
    }
  }
}
