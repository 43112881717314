import React, { useState, useEffect } from 'react';
import cookie from 'react-cookies'
import { DispatchConsumer } from '../context/OrderContext'
import ConvertOrderInput from '../component/protected_component/ConvertOrderInput'
import { formatMoney } from '../../helpers/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'
import { Table } from 'react-bootstrap'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const OrderDetailsTab = ({ orderDetails: initialOrderDetails, transactionUuid }) => {
  const [orderDetails, setOrderDetails] = useState(initialOrderDetails || []);
  const [taxSettings, setTaxSettings] = useState(cookie.load('tax_settings'));

  useEffect(() => {
    setOrderDetails(initialOrderDetails);
  }, [initialOrderDetails]);

  const getFormattedDate = (createdDate) => {
    const d = new Date(createdDate);
    return d.toLocaleString();
  };

  const cancelOrder = () => {
    const response = window.confirm('¿Esta seguro que quiere anular el pedido?');
    if (response) {
      fetch(
        `${BACKEND_URL}/businesses/${cookie.load('business_id')}/orders/${orderDetails.uuid}`,
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookie.load('token')}`,
          },
        }
      )
        .then(() => (window.location = `/pedidos/${transactionUuid}`))
        .catch((e) => console.log(e));
    }
  };

  const calculateTaxRate = () => {
    let total = 0;
    if (orderDetails.origin_of_order === 'UbiquaApp') {
      orderDetails.details.forEach(
        (obj) => (total += (parseFloat(obj.extended_price) * parseFloat(obj.tax_rate)) / 100)
      );
    } else {
      orderDetails.details.forEach(
        (obj) => (total += parseFloat(obj.extended_price) * parseFloat(obj.tax_rate))
      );
    }
    return total;
  };

  const calculateTaxRate2 = () => {
    let total = 0;
    orderDetails.details.forEach(
      (obj) => (total += (parseFloat(obj.extended_price) * parseFloat(obj.tax_rate_2)) / 100)
    );
    return total;
  };

  const calculateTotal = () => {
    if (orderDetails.origin_of_order === 'UbiquaApp') {
      return Number(orderDetails.total);
    }
    let total = 0;
    orderDetails.details.forEach((obj) => {
      const calculatedTaxPrice = obj.calculated_tax_price
        ? obj.calculated_tax_price
        : obj.extended_price;
      total += parseFloat(calculatedTaxPrice);
    });
    return total;
  };

  const getDiscountPercent = (_discount = 0) => {
    const discount = Number(_discount);
    if (discount === 0) return '-';
    return `${discount.toFixed(2)}%`;
  };

  const getRowSubtotal = (_extendedPrice = 0, _discount = 0) => {
    const extendedPrice = parseFloat(_extendedPrice);
    const discountPercent = parseFloat(_discount);
    return !discountPercent || discountPercent === 0
      ? extendedPrice
      : extendedPrice * discountPercent;
  };

  const calculateSubTotal = () => {
    if (orderDetails.origin_of_order === 'UbiquaApp') {
      return Number(orderDetails.subtotal) + getTotalDiscount();
    }
    return Number(orderDetails.total);
  };

  const getWeight = (_weight = 0) => {
    const weight = Number(_weight).toFixed(2);
    return weight === 0 ? 'N/A' : weight;
  };

  const getTotalDiscount = () => {
    let discountTotal = 0;
    orderDetails.details.forEach((obj) => {
      if (obj.discount && obj.discount > 0) {
        const totalPrice = obj.unit_price * obj.quantity;
        discountTotal += obj.discount * totalPrice;
      }
      const discountPrice = obj.discount_price || 0;
      discountTotal += parseFloat(discountPrice);
    });
    return discountTotal;
  };

  const getTotalWeight = () => {
    const tw = Number(orderDetails.total_weight).toFixed(2);
    return tw === 0 ? 'N/A' : tw;
  };

  return (
    <div className="container-fluid">
      <div className="">
        <DispatchConsumer>
          {({ handleDispatchState, isDispatch }) => (
            <div>
              <div className=" show-product-header">
                  <h3 className="margin-text-subtitle">Detalles del pedido:</h3>
                  
                {!isDispatch && orderDetails.origin_of_order !== 'UbiquaApp' && (
                  <div className="col-sm-6">
                    {orderDetails.status === 'created' && (
                      <div className="show-buttons">
                        <ConvertOrderInput
                          handleDispatchState={handleDispatchState}
                          cancelOrder={cancelOrder}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <hr />
            </div>
          )}
        </DispatchConsumer>
        <div>
          <div className="show-product-header-ubiqua-app show-header">
            <div>
              <div className="order-client-name">{orderDetails.client_name}</div>
              <div className="order-client-db_ref">Código: {orderDetails.client_db_ref}</div>
            </div>
          </div>
          <hr />
          <div>
            <table cellSpacing="10">
              <tbody>
                <tr>
                  <td>
                    <div className="mr-85 mb-10">
                      <b>Fecha de creación:</b>
                    </div>
                  </td>
                  <td>
                    <div className="mb-10">
                      {getFormattedDate(orderDetails.created_date)}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="mr-85 mb-10">
                      <b>Número de pedido:</b>
                    </div>
                  </td>
                  <td>
                    <div className="mb-10">{orderDetails.order_db_ref}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="mr-85 mb-10">
                      <b>Vendedor:</b>
                    </div>
                  </td>
                  <td>
                    <div className="mb-10">{orderDetails.salesman_name}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="mr-85 mb-10">
                      <b>Fecha de entrega:</b>
                    </div>
                  </td>
                  <td>
                    <div className="mb-10">{orderDetails.delivery_date || '-'}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="mr-85 mb-10">
                      <b>Dirección de entrega:</b>
                    </div>
                  </td>
                  <td>
                    <div className="mb-10">{orderDetails.delivery_address || 'N/A'}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="mr-85 mb-10">
                      <b>Comentarios generales:</b>
                    </div>
                  </td>
                  <td>
                    <div className="mb-10">{orderDetails.comments || '-'}</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />
            <div className="mr-85 mb-10">
              <div>
                <b>Productos en Pedido:</b>
              </div>
            </div>
            <Table hover responsive>
            <thead className="table-header-gray">
                <tr>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">CÓDIGO</span>
                  </th>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">NOMBRE DEL PRODUCTO</span>
                  </th>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">PESO NETO</span>
                  </th>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">UNIDAD DE VENTA</span>
                  </th>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">CANTIDAD</span>
                  </th>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">PRECIO</span>
                  </th>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">% DESCUENTO</span>
                  </th>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">SUB-TOTAL</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {orderDetails.details.map((model, index) => (
                  <tr key={index}>
                    <td className="td-text-limited">{model.product_db_ref}</td>
                    <td className="td-text-limited">{model.product_name}</td>
                    <td className="td-text-limited">{getWeight(model.weight)}</td>
                    <td className="td-text-limited">{model.price_unit}</td>
                    <td className="td-text-limited">{Number(model.quantity).toFixed(2)}</td>
                    <td className="td-text-limited">{formatMoney(Number(model.unit_price))}</td>
                    <td className="td-text-limited">{getDiscountPercent(model.discount)}</td>
                    <td className="td-text-limited">{formatMoney(model.extended_price)}</td>
                  </tr>
                ))}
              </tbody>
              </Table>
            <div className="totals-container">
              <div className="totals-card">
                <div className="totals-line">
                  <div>
                    <b>SUB-TOTAL:</b>
                  </div>
                  <div>{formatMoney(orderDetails.subtotal)}</div>
                </div>
                <div className="totals-line">
                  <div>
                    <b>DESCUENTOS:</b>
                  </div>
                  <div>-{formatMoney(orderDetails.discount || 0)}</div>
                </div>
                {taxSettings.tax_rate_2 && (
                  <div className="totals-line">
                    <div>
                      <b>{taxSettings.tax_rate_2}:</b>
                    </div>
                    <div>{formatMoney(calculateTaxRate2())}</div>
                  </div>
                )}
                <div className="totals-line">
                  <div>
                    <b>{taxSettings.tax_rate}:</b>
                  </div>
                  <div>{formatMoney(calculateTaxRate())}</div>
                </div>
                <div className="totals-line">
                  <div>
                    <h4>
                      <b>TOTAL:</b>
                    </h4>
                  </div>
                  <div>{formatMoney(calculateTotal())}</div>
                </div>
                <hr />
                <div className="totals-line">
                  <div>
                    <b>PESO TOTAL:</b>
                  </div>
                  <div>{getTotalWeight()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsTab;