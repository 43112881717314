import React, { useContext, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import cookie from 'react-cookies'
import Select from 'react-select'
import { validateAccessRole } from '../../helpers/userRole'
import SegmentContext from '../context/SegmentContext'
import { MetroSpinner } from 'react-spinners-kit'

const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
const BACKEND_URL = USE_BUSINESS_URL
  ? `${process.env.REACT_APP_BACKEND_URL}/businesses/${cookie.load('business_id')}`
  : process.env.REACT_APP_BACKEND_URL

export default function AttributeView({ setIsUpdating }) {
  const [isFetching, setFetching] = useState(false)
  const [editionMode, setEditionMode] = useState(null)
  const [latestSelectedAttribute, setLatestSelectedAttribute] = useState(null)
  const [attributeValues, setAttributeValues] = useState([])
  const [jobStatus, setJobStatus] = useState(null)
  const [attributes, setAttributes] = useState({
    0: {
      attributeTitle: 'Atributo A',
      savedLabel: null,
      savedValue: null
    },
    1: {
      attributeTitle: 'Atributo B',
      savedLabel: null,
      savedValue: null
    },
    2: {
      attributeTitle: 'Atributo C',
      savedLabel: null,
      savedValue: null
    },
    3: {
      attributeTitle: 'Atributo D',
      savedLabel: null,
      savedValue: null
    }
  })

  const { qualifiersData, fetchDataStatus, fetchQualifiersFromServer } = useContext(SegmentContext)

  useEffect(() => {
    handleQualifierData()
  }, [qualifiersData])

  useEffect(() => {
    if (jobStatus != null) {
      const interval = setInterval(() => {
        checkJobStatus(jobStatus.job_id)
      }, 10000)

      return () => clearInterval(interval)
    }
  }, [jobStatus])

  const handleQualifierData = async () => {
    let data = { ...qualifiersData }
    let availableQualifiers = data['result']?.available_qualifiers
    let savedQualifiers = data['result']?.saved_qualifiers
    
    setAttributeValues(availableQualifiers)
    
    if (savedQualifiers && savedQualifiers?.qualifier_2) {
      let attr = { ...attributes }
      
      // qualifier 2 - Atributo A
      let qualifier2 = availableQualifiers.find(
        (item) => item.value === savedQualifiers?.qualifier_2
      )
      attr[0].savedLabel = qualifier2?.label
      attr[0].savedValue = qualifier2?.value

      // qualifier 3 - Atributo B
      let qualifier3 = availableQualifiers.find(
        (item) => item.value === savedQualifiers?.qualifier_3
      )
      attr[1].savedLabel = qualifier3?.label
      attr[1].savedValue = qualifier3?.value

      // qualifier 4 - Atributo C
      let qualifier4 = availableQualifiers.find(
        (item) => item.value === savedQualifiers?.qualifier_4
      )
      attr[2].savedLabel = qualifier4?.label
      attr[2].savedValue = qualifier4?.value

      // qualifier 5 - Atributo D
      let qualifier5 = availableQualifiers.find(
        (item) => item.value === savedQualifiers?.qualifier_5
      )
      attr[3].savedLabel = qualifier5?.label
      attr[3].savedValue = qualifier5?.value

      setAttributes(attr)
    }
  }

  const checkJobStatus = async (jobId) => {
    try {
      const response = await fetch(`${BACKEND_URL}/job_status/${jobId}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      })

      if (response.ok) {
        const data = await response.json()
        
        if (data.status === 'in_progress') {
          setJobStatus(data);
        }
        else
        {
          if (data.status === 'completed') {
            setIsUpdating(false)
            await fetchQualifiersFromServer(false) 
          }
          else if (data.status === 'failed') {
            setIsUpdating(false)
            alert('Lo sentimos, algo salió mal. Intente nuevamente.')
          }
          
          setJobStatus(null);
        }
      } else {
        setIsUpdating(false)
        console.error('Error fetching job status:', error)
      }
    } catch (error) {
      console.error('Error fetching job status:', error)
      setIsUpdating(false)
    }
  }

  const saveQualifiers = async () => {
    setIsUpdating(true)

    const qualifierKey = `qualifier_${Number(editionMode) + 2}`
    
    try {
      const response = await fetch(
        `${BACKEND_URL}/qualifiers?business_tenant_uid=${cookie.load('business_tenant_uid')}&qualifier_key=${qualifierKey}`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          },
          body: JSON.stringify({
            business_tenant_uid: cookie.load('business_tenant_uid'),
            qualifier_2: attributes[0].savedValue,
            qualifier_3: attributes[1].savedValue,
            qualifier_4: attributes[2].savedValue,
            qualifier_5: attributes[3].savedValue,
            essential_qualifier_value: null
          })
        }
      )
  
      if (response.status === 401) {
        setIsUpdating(false)
        console.error(response)
        return
      }
  
      const data = await response.json()
  
      if (response.status === 428) {
        let baseMessage =
          'No es posible modificar este atributo mientras haya actividades activas que lo utilicen. Actualmente, hay'
  
        const issues = []
        if (data.task) {
          issues.push(`${data.task} ejecuciones en punto de venta`)
        }
        if (data.promotion) {
          issues.push(`${data.promotion} promociones`)
        }
        if (data.qualifier_in_use) {
          issues.push(`${data.qualifier_in_use} infaltables en uso`)
        }
  
        if (issues.length > 0) {
          baseMessage += ` ${issues.join(', ')}.`
        }
  
        baseMessage +=
          ' Por favor, vaya a la sección correspondiente y suspenda las actividades que estén en uso antes de intentar modificar el atributo.'
  
        window.confirm(baseMessage)
        window.location.reload()
      } else if (data.job_id) {
        setJobStatus({ job_id: data.job_id, status: 'enqueued' })
      } else {
        setIsUpdating(false)
        alert('Lo sentimos, algo salió mal. Intente nuevamente.')
      }
    } catch (error) {
      console.error("Error en la solicitud:", error)
      setIsUpdating(false)
      alert('Hubo un problema con la solicitud. Intente nuevamente.')
    }
  }
  
  const listAvailableOptions = (key) => {
    let options = []
    let usedValues = Object.values(attributes).map((e) => e.savedValue)
    let lastSelectedIndex = Object.values(attributes).reduce((acc, curr, index) => {
      return curr.savedValue ? index : acc
    }, 0)

    for (const atr of attributeValues) {
      if (!usedValues.includes(atr.value)) options.push({ value: atr.value, label: atr.label })
    }

    if (Number(key) === lastSelectedIndex) {
      options.unshift({ label: 'Sin Asignar', value: null })
    }

    return options
  }

  const updateAttributeValue = (key) => {
    let attr = { ...attributes }
    attr[key].savedValue = latestSelectedAttribute?.value
    attr[key].savedLabel = latestSelectedAttribute?.label
    setAttributes(attr)
  }

  const shouldBeDisabled = (key) => {
    if (jobStatus != null) return true
    if (editionMode !== null) return true
    let num = Number(key)
    if (num === 0) return false
    let previousValue = attributes[num - 1]?.savedValue
    if (!previousValue) return true
  }

  return (
    <>
      <h4 className="mt-50">Listado de Atributos:</h4>
      <p>Nota: Solo es posible actualizar un atributo a la vez, en orden descendente.</p>
      {fetchDataStatus?.qualifiers ? (
        <div className="mt-10">
          <p>Obteniendo datos...</p>
        </div>
      ) : (
        <Table hover responsive>
          <thead className="table-header-gray">
            <tr>
              <th style={{ verticalAlign: 'middle', width: '20%' }}>
                <span className="th-header-text">ATRIBUTO</span>
              </th>
              <th style={{ verticalAlign: 'middle', width: '60%' }}>
                <span className="th-header-text">CAMPO DE CLIENTE</span>
              </th>
              {validateAccessRole(cookie.load('user_role')?.segments_module)?.can_write && (
                <th style={{ textAlign: 'center', verticalAlign: 'middle', width: '20%' }}>
                  <span className="th-header-text">ACCIÓN</span>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {jobStatus != null ? (
              <tr>
                <td colSpan="3" className="td-centered">
                  <div className="loading-box">
                    <MetroSpinner size={25} color="#747474" />
                    <span style={{ marginLeft: '10px', color: '#747474', fontSize: '14px' }}>
                      Actualizando
                    </span>
                  </div>
                </td>
              </tr>
            ) : (
              Object.entries(attributes).map((e, index) => (
                <tr key={index}>
                  <td className="td-text-limited" style={{ whiteSpace: 'nowrap', width: '20%' }}>
                    {e[1].attributeTitle}
                  </td>
                  <td className="td-text-limited" style={{ width: '60%' }}>
                    {editionMode === e[0] ? (
                      <Select
                        placeholder={'Seleccione un atributo'}
                        noOptionsMessage={() => 'Buscar'}
                        loadingMessage={() => 'Cargando...'}
                        isDisabled={false}
                        isSearchable={true}
                        options={listAvailableOptions(e[0])}
                        menuPortalTarget={document.body}
                        onChange={(selectEvent) => {
                          setLatestSelectedAttribute(selectEvent)
                        }}
                      />
                    ) : e[1].savedLabel ? (
                      e[1].savedLabel
                    ) : (
                      'Sin Asignar'
                    )}
                  </td>
                  {validateAccessRole(cookie.load('user_role')?.segments_module)?.can_write && (
                    <td
                      className="td-text-limited"
                      style={{ textAlign: 'center', whiteSpace: 'nowrap', width: '20%' }}
                    >
                      {editionMode === e[0] ? (
                        <>
                          <button
                            className="ubiqua-cancel-btn mr-10"
                            onClick={() => {
                              setEditionMode(null)
                            }}
                          >
                            Cancelar
                          </button>
                          <button
                            className="ubiqua-primary-btn"
                            onClick={() => {
                              updateAttributeValue(e[0])
                              setEditionMode(null)
                              saveQualifiers()
                            }}
                          >
                            Guardar
                          </button>
                        </>
                      ) : (
                        <button
                          disabled={shouldBeDisabled(index)}
                          className={`ubiqua-primary-btn ${shouldBeDisabled(index) ? 'disabled' : ''}`}
                          onClick={() => {
                            setEditionMode(e[0])
                          }}
                        >
                          Actualizar
                        </button>
                      )}
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </Table>
      )}
    </>
  )
}
