import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import ShowUserReportMapModalContent from '../../charges/map/ShowUserReportMapModalContent'
import { formatDateNoHours } from '../../../helpers/utils'

const GeoCardMapModal = ({
  show,
  handleClose,
  username,
  startDate,
  lastActivityTime,
  processedData
}) => (
  <Modal
    show={show}
    dialogClassName="modal-90w close-button-modal-maps"
    onHide={handleClose}
    aria-labelledby="example-modal-sizes-title-lg"
  >
    <Modal.Header closeButton className="no-border-bottom">    
      <Modal.Title id="example-modal-sizes-title-lg">
      <b className='mb-10'>Mapa de Geocercas</b>
      </Modal.Title>
    </Modal.Header>
    <Modal.Header className="d-flex justify-content-between align-items-start w-100">
      <div>
        <p className="text-title-modal-map">Usuario: {username}</p>
        <p className="text-title-modal-map">Fecha: {formatDateNoHours(startDate)}</p>
        <p className="text-title-modal-map">Hora de última actividad: {lastActivityTime}</p>
      </div>
      <div className="legend-container d-flex flex-column align-items-end">
        <p className="text-title-modal-map legend-item-firts-title">
          <b>Leyenda:</b>
        </p>
        <div className="d-flex justify-content-end">
          <div className="d-flex align-items-center me-3">
            <div className="legend-color-cube" style={{ backgroundColor: '#50739A' }}></div>
            <div className="text-legend-modal-map">Dentro de Geocerca</div>
          </div>
          <div className="d-flex align-items-center me-3">
            <div className="legend-color-cube" style={{ backgroundColor: '#D5422D' }}></div>
            <div className="text-legend-modal-map">Fuera de Geocerca</div>
          </div>
          <div className="d-flex align-items-center">
            <div className="legend-color-cube" style={{ backgroundColor: '#202020' }}></div>
            <div className="text-legend-modal-map">Cliente sin ubicación registrada</div>
          </div>
        </div>
      </div>
    </Modal.Header>
    <Modal.Body>
      <div id="map-canvas">
        <ShowUserReportMapModalContent data={processedData} />
      </div>
    </Modal.Body>
    <Modal.Footer className="d-flex justify-content-between align-items-center">
  <div>
  <p className="text-footer-modal-map">
  <b className="note-geocerca-maps">Nota:</b> Los puntos numerados en el mapa representan las ubicaciones registradas durante el día actual.
</p>
    <p className="text-footer-modal-map">
      Los saltos en la numeración indican períodos en los que el GPS del dispositivo se encontraba desactivado.
    </p>
  </div>
  <Button variant="light" onClick={handleClose} className="custom-close-button">
    Cerrar
  </Button>
</Modal.Footer>
  </Modal>
)

export default GeoCardMapModal
