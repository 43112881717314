import React from 'react'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import TableauIndexView from './TableauIndexView'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class TableauIndexApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userName: 'Soporte',
      searchPlaceholder: '',
      collapsed: false, 
    }
  }

  componentDidMount() {
    const collapsed = localStorage.getItem('collapsedState') === 'true';
    this.setState({ collapsed });
  }

  handleToggleCollapse = (collapsed) => {
    this.setState({ collapsed }, () => {
      localStorage.setItem('collapsedState', collapsed);
    });
  };

  render() {
    return (
      <div>
        <div>
          <div>
            <TopBar
              searchPlaceholder={this.state.searchPlaceholder}
              userName={this.state.userName}
              onToggleCollapse={this.handleToggleCollapse} 
              collapsed={this.state.collapsed} 
            />
          </div>
          <div>
            <TableauIndexView
              collapsed={this.state.collapsed} 
            />
          </div>
        </div>
        <div>
          <NavBar activeItem="Dashboard" isEditing={false} collapsed={this.state.collapsed} />
        </div>
      </div>
    )
  }
}
