import React from 'react'
import cookie from 'react-cookies'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default function HomeIndexView(props) {
  return (
    <div className="main-view">
      <div className="index-header">
        <h2 className="mb-20 float-unset">Inicio</h2>
      </div>
      <div className="index-table">
        <div className="show-area">
          <h4>Bienvenido, {cookie.load('user_email')}</h4>
        </div>
      </div>
    </div>
  )
}
