import React from 'react'
import Pagination from '../layout/Pagination'
import { validateAccessRole } from '../helpers/userRole'
import cookie from 'react-cookies'
import DropdownActionButton from '../components/action_button/DropdownActionButton'
import ActionButtonProvider from '../components/action_button/ActionButtonContext'
import { STATUS_DIC, TASK_TYPE_DIC } from './constants/constants'
import { capitalizeFirstLetterFrom } from '../helpers/utils'
import SearchInput from '../layout/SearchInput'
import { Table } from 'react-bootstrap'

const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
const BACKEND_URL = USE_BUSINESS_URL
  ? `${process.env.REACT_APP_BACKEND_URL}/businesses/${cookie.load('business_id')}`
  : process.env.REACT_APP_BACKEND_URL

class PointOfSaleView extends React.Component {
  redirectToCreateTask() {
    window.location = '/crear_tarea_punto_venta'
  }

  showModel = (uuid) => {
    window.open(`/ejecucion_punto_venta/detalles/${uuid}`, '_blank')
  }

  checkValue = (value) => {
    return value === null || value === undefined ? '-' : value
  }

  getFormattedDateOnly = (createdDate) => {
    if (!createdDate) return 'Indefinida'
    const d = new Date(createdDate)
    return d.toLocaleDateString(undefined, { timeZone: 'utc' })
  }

  handleClone = (uuid) => {
    let letsGo = confirm('¿Está seguro de crear una copia a partir de esta tarea?')
    if (letsGo) window.location = `/crear_tarea_punto_venta?clone=${uuid}`
  }

  getQualifiers = (qualifiers) => {
    let text = ''
    if (qualifiers?.length > 0) text = qualifiers.join(', ') + ' '
    return text
  }

  getClientOrAttribute = (model) => {
    let text = ''
    text += this.getQualifiers(model['client_names'])
    text +=
      model['qualifier_2']?.length > 0
        ? `Atributo A - ${this.getQualifiers(model['qualifier_2'])}`
        : ''
    text +=
      model['qualifier_3']?.length > 0
        ? `Atributo B - ${this.getQualifiers(model['qualifier_3'])}`
        : ''
    text +=
      model['qualifier_4']?.length > 0
        ? `Atributo C - ${this.getQualifiers(model['qualifier_4'])}`
        : ''
    text +=
      model['qualifier_5']?.length > 0
        ? `Atributo D - ${this.getQualifiers(model['qualifier_5'])}`
        : ''
    return text
  }

  suspendTask = (uuid, businessUid) => {
    this.setState({ suspending: true })
    let letsGo = confirm('¿Está seguro de que desea continuar con la suspensión de esta tarea?')
    if (letsGo) {
      fetch(`${BACKEND_URL}/pos_tasks/${uuid}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          business_uid: businessUid,
          suspended: true,
          uuid: uuid
        })
      })
        .then((response) => {
          if (response.status === 401) {
            window.location = '/'
          }
          if (response.status === 404) {
            return {
              error: true,
              message: 'No se encontró la tarea'
            }
          }
          return response.json()
        })
        .then((data) => {
          if (data['suspended']) {
            window.location.reload()
          }
        })
        .catch((e) => console.log(e))
    }
    this.setState({ suspending: false })
  }

  render() {
    const data = this.props.data

    if (this.props.isFetching) {
      return (
        <div className={`main-view ${this.props.collapsed ? 'collapsed' : ''}`}>
          <div className="index-header">
            <br />
            <p>Obteniendo datos ...</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className={`main-view ${this.props.collapsed ? 'collapsed' : ''}`}>
          <div className="index-header detailed-container">
            <h2>{this.props.title}</h2>
            <div className="index-buttons">
              {validateAccessRole(cookie.load('user_role')?.pos_module)?.can_write && (
                <button onClick={() => this.redirectToCreateTask()}>Crear nueva tarea</button>
              )}
            </div>
          </div>
          <div className="index-table">
            <div className="show-area">
              <div className="title-and-search-containter">
                <h4 className="text-sub-title">Listado de tareas en punto de venta:</h4>

                <div className="search-input-containter mb-20">
                  <SearchInput
                    query={this.props.query}
                    onSearch={this.props.onSearchUpdate}
                    placeholder="Buscar  por código o descripción"
                  />
                </div>
              </div>
              <Table hover responsive>
                <thead className="table-header-gray">
                  <tr>
                    <th style={{ verticalAlign: 'middle', padding: '10px 25px' }}>
                      <span className="th-header-text th-header-text-pos">ESTADO</span>
                    </th>
                    <th style={{ verticalAlign: 'middle' }}>
                      <span className="th-header-text">CÓDIGO</span>
                    </th>
                    <th style={{ verticalAlign: 'middle' }}>
                      <span className="th-header-text">CLIENTE/ATRIBUTO(S)</span>
                    </th>
                    <th style={{ verticalAlign: 'middle' }}>
                      <span className="th-header-text th-header-text">TIPO DE TAREA</span>
                    </th>
                    <th style={{ verticalAlign: 'middle' }}>
                      <span className="th-header-text">DESCRIPCIÓN</span>
                    </th>
                    <th style={{ verticalAlign: 'middle' }}>
                      <span className="th-header-text">RECURRENCIA</span>
                    </th>
                    <th style={{ verticalAlign: 'middle' }}>
                      <span className="th-header-text">EXPIRA</span>
                    </th>
                    <th className="th-end-pos" style={{ verticalAlign: 'middle' }}>
                      <span className=" th-header-text-end">ACCIÓN</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ActionButtonProvider>
                    {data.length > 0 &&
                      data.map((model, index) => (
                        <tr
                          onClick={() => this.showModel(model['uuid'])}
                          className="cursor-pointer"
                          key={index}
                        >
                          <td className="td-text-limited td-text-limited-space">
                            {this.checkValue(STATUS_DIC[model['status']] || model['status'])}
                          </td>
                          <td className="td-text-limited" title={model['uuid']}>
                            {this.checkValue(model['short_uuid'])}
                          </td>
                          <td className="td-text-limited" style={{ maxWidth: '350px' }}>
                            <div className="text-limit-4-lines">
                              {this.checkValue(this.getClientOrAttribute(model))}
                            </div>
                          </td>
                          <td className="td-text-limited">
                            {this.checkValue(
                              TASK_TYPE_DIC[model['task_type']] || model['task_type']
                            )}
                          </td>
                          <td className="td-text-limited">
                            {this.checkValue(model['description'])}
                          </td>
                          <td className="td-text-limited">
                            {this.checkValue(
                              capitalizeFirstLetterFrom(model['recurrence_description'])
                            )}
                          </td>
                          <td className="td-text-limited">
                            {this.checkValue(this.getFormattedDateOnly(model['end_date']))}
                          </td>
                          <td style={{ display: '', justifyContent: 'center' }}>
                            <DropdownActionButton
                              id={`btn-dropdown-${model['uuid']}`}
                              list={[
                                {
                                  disabled: model['suspended'],
                                  text: 'Suspender',
                                  handler: () => {
                                    this.suspendTask(model?.uuid, model?.business_uid)
                                  }
                                },
                                {
                                  text: 'Crear copia',
                                  handler: () => {
                                    this.handleClone(model?.uuid)
                                  }
                                }
                              ]}
                            />
                          </td>
                        </tr>
                      ))}
                  </ActionButtonProvider>
                  {data.length === 0 && (
                    <tr>
                      <td colSpan="9" className="td-text-limited td-text-limited-space">
                        No hay resultados
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
          <nav className="index-table">
            {this.props.total_pages > 1 && (
              <Pagination
                current_page={this.props.current_page}
                total_pages={this.props.total_pages}
              />
            )}
          </nav>
        </div>
      )
    }
  }
}

export default PointOfSaleView
