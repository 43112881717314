import React from 'react'
import TabWithDropdown from '../../layout/TabWithDropdown'
import PosTaskDetailsTab from './PosTaskDetailsTab'

const activityObject = {}
export default class PosTaskView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showSuccessfulMessage: false,
      alertState: 'alert alert-success',
      createText: 'Guardar',
      isPosting: false,
      activity: props.defaultDetails || activityObject
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ details: nextProps.defaultDetails })
  }

  componentDidMount() {
    this.showSuccessfulMessageCreation()
  }

  showSuccessfulMessageCreation = () => {
    if (document.referrer.includes('nuevo_pedido')) {
      this.setState({ showSuccessfulMessage: true })
    } else {
      this.setState({ showSuccessfulMessage: false })
    }
  }

  hiddenAlert = () => {
    this.setState({
      alertState: 'alert alert-success hidden'
    })
  }

  disableButton = () => {
    this.setState({ createText: 'Guardando...', isPosting: true })
  }

  enableButton = () => {
    this.setState({ createText: 'Guardar', isPosting: false })
  }

  render() {
    return (
      <div>
        <div className={`main-view ${this.props.collapsed ? 'collapsed' : ''}`}>
          <div className="main-show-header">
            <h2>Tarea en punto de venta: {this.state.activity?.uid}</h2>
          </div>
          <div className="index-table">
            <div className="show-area">
              <div>
                <div>
                  <div>
                    {(this.state.activity?.status === 'received' ||
                      this.state.activity?.status === 'sent') && (
                      <ul className="nav nav-tabs">
                        <TabWithDropdown
                          name="Tarea en punto de venta"
                          activeTab={'Tarea en punto de venta'}
                          items={[]}
                        />
                      </ul>
                    )}
                  </div>

                  <div>
                    {this.state.activity && (
                      <PosTaskDetailsTab
                        activity={this.state.activity}
                        transactionUuid={this.state.activity.uuid}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
