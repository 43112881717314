import React from 'react'
import cookie from 'react-cookies'
import {
  capitalizeFirstLetterFrom,
  effectiveLocationWithAllowedLocation,
  formatMoney,
  calculateTaxRate
} from '../../helpers/utils'
import { Table } from 'react-bootstrap'

export default class RefundDetailsTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activity: this.props.activity || [],
      taxSettings: cookie.load('tax_settings')
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ activity: nextProps.activity })
  }

  getFormattedDate = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  getDiscountPercent = (_price = 0, _discountedPrice = 0) => {
    let price = parseFloat(_price)
    let discountedPrice = parseFloat(_discountedPrice)
    if (discountedPrice === 0) return '-'
    return `${((discountedPrice / price) * 100).toFixed(2)}%`
  }

  getRowSubtotal = (_extendedPrice = 0, _discountedPrice = 0) => {
    let subtotal = 0
    let extendedPrice = parseFloat(_extendedPrice)
    let discountedPrice = parseFloat(_discountedPrice)
    if (!discountedPrice || discountedPrice == 0) {
      subtotal = extendedPrice
    } else {
      subtotal = extendedPrice - discountedPrice
    }
    return subtotal
  }


  calculateSubTotal = () => {
    let total = 0
    this.state.activity.details.map((obj) => {
      const calculatedTaxPrice = obj.calculated_tax_price
        ? obj.calculated_tax_price
        : obj.extended_price
      return (total += parseFloat(calculatedTaxPrice))
    })
    return total
  }

  getWeight = (_weight = 0) => {
    let weight = Number(_weight).toFixed(2)
    if (weight == 0) return 'N/A'
    return weight
  }

  getTotalWeight = () => {
    let tw = Number(this.state.activity['total_weight']).toFixed(2)
    if (tw == 0) return 'N/A'
    return tw
  }

  handleRefundType = () => {
    let rt = this.state.activity?.refund_type
    switch (rt) {
      case 'refund':
        return 'rechazo total'
      case 'partial_refund':
        return 'rechazo parcial'
      default:
        return 'devolución'
    }
  }

  isDelivery = () => {
    let rt = this.state.activity?.refund_type
    if (rt && rt != 'salesman_refund') return true
    return false
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          {this.props.transactionUuid && (
            <>
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="margin-text-subtitle">Detalles de {this.handleRefundType()}:</h3>
                </div>
              </div>
              <hr />
            </>
          )}
          <div>
            <div className="show-product-header-ubiqua-app show-header">
              <div>
                <div className="order-client-name">{this.state.activity['client_name']}</div>
                <div className="order-client-db_ref">
                  Código: {this.state.activity['client_db_ref']}
                </div>
              </div>
              {/* {this.state.activity?.origin == 'UbiquaApp' && (
                <button className="float-right download-order-button" onClick={this.download}>
                  Descargar devolución
                  <FontAwesomeIcon icon={faArrowCircleDown} />
                </button>
              )} */}
            </div>
            <hr />
            <div>
              <table cellSpacing="10">
                <tbody>
                  <tr>
                    <td>
                      <div className="mr-85 mb-10">
                        <b>Fecha de creación:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">
                        {this.getFormattedDate(this.state.activity['created_date'])}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-10">
                        <b>{this.isDelivery() ? 'Número de rechazo' : 'Número de devolución'}:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">{this.state.activity['refund_db_ref']}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-10">
                        <b>Vendedor:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">{this.state.activity['salesman_name']}</div>
                    </td>
                  </tr>
                  {this.isDelivery() && (
                    <tr>
                      <td>
                        <div className="mr-85 mb-10">
                          <b>Tipo de cliente:</b>
                        </div>
                      </td>
                      <td>
                        <div className="mb-10">
                          {capitalizeFirstLetterFrom(this.state.activity['client_payment_type'])}
                        </div>
                      </td>
                    </tr>
                  )}
                  {this.handleRefundType() != 'rechazo parcial' && (
                    <tr>
                      <td>
                        <div className="mr-85 mb-10">
                          <b>Motivo de {this.isDelivery() ? 'rechazo' : 'devolución'}: </b>
                        </div>
                      </td>
                      <td>
                        <div className="mb-10">{this.state.activity['motive'] || '-'}</div>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <div className="mr-85 mb-10">
                        <b>Número de factura:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">{this.state.activity['invoice_number'] || '-'}</div>
                    </td>
                  </tr>
                  {!this.isDelivery() && (
                    <tr>
                      <td>
                        <div className="mr-85 mb-10">
                          <b>Comentarios generales:</b>
                        </div>
                      </td>
                      <td>
                        <div className="mb-10">{this.state.activity['comments'] || '-'}</div>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <div className="mr-85 mb-10">
                        <b>Dentro de geocerca:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">
                        {effectiveLocationWithAllowedLocation(
                          this.state.activity['effective_location'],
                          this.state.activity['has_allowed_location']
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <div className="mr-85 mb-10">
                <div>
                  <b>{this.isDelivery() ? 'Productos' : 'Productos en devolución'}:</b>
                </div>
              </div>

              <Table hover responsive> 
              <thead className="table-header-gray">
                  <tr>
                    <th style={{ verticalAlign: 'middle' }}>
                      <span className="th-header-text">CÓDIGO</span>
                    </th>
                    <th style={{ verticalAlign: 'middle' }}>
                      <span className="th-header-text">NOMBRE DEL PRODUCTO</span>
                    </th>
                    {!this.isDelivery() && <th style={{ verticalAlign: 'middle' }}>N° LOTE</th>}
                    {this.handleRefundType() != 'rechazo total' && (
                      <th style={{ verticalAlign: 'middle' }}>
                        MOTIVO {this.isDelivery() ? 'DE RECHAZO' : ''}
                      </th>
                    )}
                    {!this.isDelivery() && <th style={{ verticalAlign: 'middle' }}>PESO NETO</th>}
                    <th style={{ verticalAlign: 'middle' }}>UNIDAD DE VENTA</th>
                    <th style={{ verticalAlign: 'middle' }}>CANTIDAD</th>
                    <th style={{ verticalAlign: 'middle' }}>PRECIO</th>
                    <th style={{ verticalAlign: 'middle' }}>SUB-TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.activity['details'].map((model, index) => (
                    <tr key={index}>
                      <td>{model['product_db_ref']}</td>
                      <td>{model['product_name']}</td>
                      {!this.isDelivery() && <td>{model['batch_number'] || 'N/A'}</td>}
                      {this.handleRefundType() != 'rechazo total' && (
                        <td>{model['motive'] || 'N/A'}</td>
                      )}
                      {!this.isDelivery() && <td>{this.getWeight(model['weight'])}</td>}
                      <td>{model['price_unit']}</td>
                      <td>
                        {this.isDelivery()
                          ? parseInt(model['quantity'])
                          : Number(model['quantity']).toFixed(2)}
                      </td>
                      <td>{formatMoney(Number(model['unit_price']))}</td>
                      {/* <td>{formatMoney(model['extended_price']) }</td> */}
                      <td>
                        {formatMoney(
                          this.getRowSubtotal(model['extended_price'], model['discount_price'])
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <div className="totals-container">
                <div className="totals-card">
                  <div className="totals-line">
                    <div>
                      <b>SUB-TOTAL:</b>
                    </div>
                    <div>{formatMoney(this.state.activity.subtotal)}</div>
                  </div>
                  {this.state.taxSettings['tax_rate_2'] && (
                    <div className="totals-line">
                      <div>
                        <b>{this.state.taxSettings['tax_rate_2']}:</b>
                      </div>
                      <div>{formatMoney(0)}</div>
                    </div>
                  )}
                  <div className="totals-line">
                    <div>
                      <b>{this.state.taxSettings['tax_rate']}:</b>
                    </div>
                    <div>{formatMoney(this.state.activity.taxes)}</div>
                  </div>
                  <div className="totals-line">
                    <div>
                      <h4>
                        <b>TOTAL:</b>
                      </h4>
                    </div>
                    <div>{formatMoney(this.state.activity.total)}</div>
                  </div>
                  {!this.isDelivery() && (
                    <>
                      <hr />
                      <div className="totals-line">
                        <div>
                          <b>PESO TOTAL:</b>
                        </div>
                        <div>{this.getTotalWeight()}</div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
