import React, { useState, useEffect } from 'react'
import axios from 'axios'
import cookie from 'react-cookies'
import { RRule, SPANISH } from 'rrule'
import { fixDateString, getDay, getMonth } from '../../helpers/formatDate'
import SurveyImageTask from './surveys/SurveyImageTask'
import SurveyTextTask from './surveys/SurveyTextTask'
import SurveyNumberTask from './surveys/SurveyNumberTask'
import SurveyComponentMultiple from './surveys/SurveyComponentMultiple'
import SurveyComponentUnique from './surveys/SurveyComponentUnique'
import TaskDescription from './components/TaskDescription'
import TaskClientOrAttribute from './components/TaskClientOrAttribute'
import TaskScheduleConfig from './components/TaskScheduleConfig'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'

const SurveyTask = ({
  setCloningTask,
  cloningTask,
  taskData,
  readyToCollect,
  formDataCollector,
  setSaving,
  isStep3Completed,
  setIsStep3Completed,
  isRequired: parentIsRequired
}) => {
  const [description, setDescription] = useState('')
  const [clientOrAttribute, setClientOrAttribute] = useState('')
  const [clients, setClients] = useState([])
  const [qualifiers, setQualifiers] = useState({
    qualifier_2: [],
    qualifier_3: [],
    qualifier_4: [],
    qualifier_5: []
  })
  const [qualifierA, setQualifierA] = useState([])
  const [qualifierB, setQualifierB] = useState([])
  const [qualifierC, setQualifierC] = useState([])
  const [productsBase, setProductsBase] = useState({})
  const [unitOfSale, setUnitOfSale] = useState('')
  const [selectedOption, setSelectedOption] = useState('')
  const [surveyInstances, setSurveyInstances] = useState([])
  const [isSelectionValid, setIsSelectionValid] = useState(true)
  const [isRequired, setLocalRequired] = useState(parentIsRequired)
  const [showImageWarning, setShowImageWarning] = useState(false)
  const [newStartTime, setNewStartTime] = useState(null)
  const [newEndTime, setNewEndTime] = useState(null)
  const [recurrenceRule, setRecurrenceRule] = useState(null)
  const surveyInstanceKeys = Object.keys(surveyInstances);

  const surveySelectionType = [
    { value: '', text: 'Escoja una opción', hidden: true },
    { value: 'text', text: 'Texto' },
    { value: 'number', text: 'Números' },
    { value: 'multiplerb', text: 'Opción única' },
    { value: 'multiplechk', text: 'Selección múltiple' },
    { value: 'image', text: 'Subir imagen' }
  ]

  ////// Stops fetchs when page is changed
  const abortController = new AbortController()
  var abortClientController = new AbortController()
  const abortCurrentFetchs = () => {
    abortClientController.abort()
    abortController.abort()
  }
  window.addEventListener('beforeunload', abortCurrentFetchs)
  //////

  // Stops client fetch when is unfocus
  const abortClientFetchs = () => {
    abortClientController.abort()
  }
  //

  const handleChangeQualifierA = (event) => {
    if (event != null) {
      setQualifierA(event.map((e) => e.value))
    }
  }

  const handleChangeQualifierB = (event) => {
    if (event != null) {
      setQualifierB(event.map((e) => e.value))
    }
  }

  const handleChangeQualifierC = (event) => {
    if (event != null) {
      setQualifierC(event.map((e) => e.value))
    }
  }

  const handleRecurrenceRuleChange = (newRule) => {
    setRecurrenceRule(newRule)
  }

  const handleStartTimeChange = (newStartTime) => {
    setNewStartTime(newStartTime)
  }

  const handleEndTimeChange = (newEndTime) => {
    setNewEndTime(newEndTime)
  }

  const handleOptionValid = (event) => {
    const selectedValue = event.target.value
    setSelectedOption(selectedValue)
    setIsSelectionValid(selectedValue !== '')
    if (selectedValue === 'image' && hasImageType()) {
      setShowImageWarning(true)
    } else {
      setShowImageWarning(false)
    }
  }

  const isClientOrSomeQualifierSelected = () => {
    if (clients?.length > 0 || isSomeQualifierSelected()) {
      return true
    }
    return false
  }

  const isSomeQualifierSelected = () => {
    if (qualifierA?.length > 0 || qualifierB?.length > 0 || qualifierC?.length > 0) {
      return true
    }
    return false
  }

  const handleClientOrAttributeChange = (event) => {
    setClientOrAttribute(event.target.value)
    setClients([])
    setQualifierA([])
    setQualifierB([])
    setQualifierC([])
  }

  const getClient = async (inputValue) => {
    if (inputValue.length >= 3) {
      abortClientController = new AbortController()
      return fetch(
        `${BACKEND_URL}/businesses/${cookie.load('business_id')}/clients?q=${inputValue}&active=true`,
        {
          method: 'GET',
          signal: abortClientController.signal,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        }
      )
        .then((response) => response.json())
        .then((data) =>
          data.table.map((cl) => ({
            value: cl.name,
            label: `${cl.name} - ${cl.db_ref}`,
            client: cl
          }))
        )
        .catch((error) => {
          if (error.name != 'AbortError') {
            console.error('Error fetching clients:', error)
            return []
          }
        })
    }
  }

  const getQualifiers = () => {
    const url = USE_BUSINESS_URL
      ? `${BACKEND_URL}/businesses/${cookie.load('business_id')}/promo_qualifiers`
      : `${BACKEND_URL}/promo_qualifiers`

    fetch(url, {
      method: 'GET',
      signal: abortController.signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setQualifiers(data.result[0])
      })
      .catch((e) => console.log(e))
  }

  const validateAtLeastOneQualifier = (_qualifierA, _qualifierB, _qualifierC) => {
    if (_qualifierA?.length > 0 || _qualifierB?.length > 0 || _qualifierC?.length > 0) {
      return 'success'
    }
    return null
  }

  const getProductsBaseData = () => {
    fetch(`${BACKEND_URL}/businesses/${cookie.load('business_id')}/products/promo_necessary_data`, {
      method: 'GET',
      signal: abortController.signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setProductsBase(data)
      })
      .catch((e) => console.log(e))
  }

  const qualifierAOptions =
    qualifiers?.qualifier_2?.map((value) => ({
      value: value,
      label: value,
      qualifier_2: value
    })) || []

  const qualifierBOptions =
    qualifiers?.qualifier_3?.map((value) => ({
      value: value,
      label: value,
      qualifier_3: value
    })) || []

  const qualifierCOptions =
    qualifiers?.qualifier_4?.map((value) => ({
      value: value,
      label: value,
      qualifier_4: value
    })) || []

  const handleClientOption = (options) => {
    setClients(options)
  }

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, ms)
    })

  const collectData = () => {
    let rule = recurrenceRule

    let jsonData = {
      description: description,
      recurrence_format: rule ? rule.toString() : 'null',
      recurrence_interval: rule ? rule.options.interval : null,
      recurrence_description: rule.toText(SPANISH),
      start_date: fixDateString(newStartTime),
      end_date: fixDateString(newEndTime),
      qualifier_1: clients.map((c) => c.client.db_ref).sort(),
      qualifier_2: qualifierA,
      qualifier_3: qualifierB,
      qualifier_4: qualifierC,
      unit_of_sale: unitOfSale,
      task_quantity: Object.values(surveyInstances).length,
      surveys: Object.values(surveyInstances).map((instance) => ({
        uid: crypto.randomUUID(),
        type: instance.type,
        id: instance.id,
        type: instance.type,
        question: instance.question,
        descriptions: instance.descriptions,
        options: instance.options,
        response: ''
      }))
    }
    formDataCollector(jsonData)
  }

  const setUpTask = async () => {
    if (taskData) {
      setCloningTask(true)
      setDescription(taskData.description)

      if (taskData.client_names?.length > 0) {
        setClientOrAttribute('client')
        let _clients = []
        for (const clientName of taskData.qualifier_1) {
          let c = await getClient(clientName)
          if (c?.length > 0) {
            _clients.push(c[0])
          }
        }
        setClients(_clients)
      } else {
        setClientOrAttribute('qualifier')
        if (qualifierAOptions?.length > 0) {
          setQualifierA(taskData.qualifier_2)
        }
        if (qualifierBOptions?.length > 0) {
          setQualifierB(taskData.qualifier_3)
        }
        if (qualifierCOptions?.length > 0) {
          setQualifierC(taskData.qualifier_4)
        }
      }

      setUnitOfSale(taskData.unit_of_sale)
      if (taskData.surveys?.length > 0) {
        setLocalRequired(false)
        const clonedSurveyInstances = taskData.surveys.map((surveyInstance) => {
          return { ...surveyInstance }
        })
        setSurveyInstances(clonedSurveyInstances)
      }
      setCloningTask(false)
      await sleep(1000)
      setSaving(false)
    }
  }

  const handleUpdateQuestion = (id, question, value) => {
    setSurveyInstances((prevInstances) => ({
      ...prevInstances,
      [id]: {
        ...prevInstances[id],
        [question]: value
      }
    }))
  }

  const generateOptions = (type) => {
    switch (type) {
      case 'multiplechk':
        return [
          { id: 1, text: 'Opción 1', selected: false, editable: true },
          { id: 2, text: 'Opción 2', selected: false, editable: true }
        ]
      case 'multiplerb':
        return [
          { id: 1, text: 'Opción 1', selected: false, editable: true },
          { id: 2, text: 'Opción 2', selected: false, editable: true }
        ]
      default:
        return []
    }
  }
  const handleClickSelectSurvey = () => {
    const isSelectionEmpty = selectedOption.trim() === ''
    setLocalRequired(isSelectionEmpty)

    if (selectedOption === 'image' && hasImageType()) {
      setShowImageWarning(true)
      return
    } else {
      setShowImageWarning(false)
    }

    if (isSelectionValid && !isSelectionEmpty) {
      const lastId = surveyInstanceKeys.length > 0 ? Math.max(...surveyInstanceKeys.map(Number)) : 0;
      const newInstanceId = lastId + 1
      setSurveyInstances((prevInstances) => ({
        ...prevInstances,
        [newInstanceId]: {
          type: selectedOption,
          options: generateOptions(selectedOption),
          response: ''
        }
      }))
      setSelectedOption('')
    } else {
      setTimeout(() => {
        setLocalRequired(false)
      }, 2000)
    }
  }
  const duplicateSurvey = (instanceId, duplicatedData, previousData) => {
    const newId =
      surveyInstanceKeys.length > 0
        ? Math.max(...surveyInstanceKeys.map(Number)) + 1
        : 1;
    const duplicatedSurvey = { ...previousData, ...duplicatedData }
    setSurveyInstances((prevInstances) => ({
      ...prevInstances,
      [newId]: duplicatedSurvey
    }))
  }

  const deleteSurvey = (id) => {
    const { [id]: deletedInstance, ...restInstances } = surveyInstances
    setSurveyInstances(restInstances)
    if (deletedInstance.type === 'image') {
      setShowImageWarning(false)
    }
  }

  const hasImageType = () => {
    return Object.values(surveyInstances).some((instance) => instance.type === 'image')
  }

  useEffect(() => {
    const isStep3Completed =
      clients.length > 0 || qualifierA.length > 0 || qualifierB.length > 0 || qualifierC.length > 0

    setIsStep3Completed(isStep3Completed)
  }, [clients, qualifierA, qualifierB, qualifierC])

  useEffect(() => {
    getQualifiers()
    getProductsBaseData()
  }, [])

  useEffect(() => {
    if (taskData) {
      setUpTask()
    }
  }, [taskData, productsBase.brand, productsBase.categories])

  useEffect(() => {
    const isEmptySurveyInstances = surveyInstanceKeys.length === 0;
    setLocalRequired(isEmptySurveyInstances ? true : parentIsRequired);
  }, [surveyInstanceKeys, parentIsRequired]);

  useEffect(() => {
    if (readyToCollect) {
      collectData()
    }
  }, [readyToCollect])

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const items = Object.entries(surveyInstances)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    const newSurveyInstances = {}
    items.forEach(([key, value], index) => {
      newSurveyInstances[index + 1] = value
    })

    setSurveyInstances(newSurveyInstances)
  }

  useEffect(() => {}, [surveyInstances])

  if (cloningTask) {
    return null
  }
  return (
    <>
      <TaskDescription initialDescription={description} onDescriptionChange={setDescription} />

      <div>
        <TaskClientOrAttribute
          clientOrAttribute={clientOrAttribute}
          handleClientOrAttributeChange={handleClientOrAttributeChange}
          clients={clients}
          handleClientOption={handleClientOption}
          abortClientFetchs={abortClientFetchs}
          getClient={getClient}
          qualifierA={qualifierA}
          handleChangeQualifierA={handleChangeQualifierA}
          qualifierAOptions={qualifierAOptions}
          qualifierB={qualifierB}
          handleChangeQualifierB={handleChangeQualifierB}
          qualifierBOptions={qualifierBOptions}
          qualifierC={qualifierC}
          handleChangeQualifierC={handleChangeQualifierC}
          qualifierCOptions={qualifierCOptions}
          validateAtLeastOneQualifier={validateAtLeastOneQualifier}
          description={description}
        />

        <div className="promo-form-section">
          <div className="title-promos-form">
            <b class="bold-step">PASO 4: Crear encuesta</b>
          </div>
          <hr className="hr-promos-form" />
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
              {surveyInstanceKeys.map((instanceId, index) => (
                  <Draggable key={instanceId} draggableId={instanceId} index={index}>
                    {(provided) => (
                      <div className="survey-form-sections">
                        {surveyInstances[instanceId].type === 'text' && (
                          <SurveyTextTask
                            id={instanceId}
                            data={surveyInstances[instanceId]}
                            onDuplicate={(duplicatedData) =>
                              duplicateSurvey(
                                instanceId,
                                duplicatedData,
                                surveyInstances[instanceId]
                              )
                            }
                            onDelete={() => deleteSurvey(instanceId)}
                            onUpdateQuestion={handleUpdateQuestion}
                            questionNumber={index + 1}
                            showOptionsButton={true}
                            showDescriptionsCreate={true}
                            provided={provided}
                            index={index}
                          />
                        )}
                        {surveyInstances[instanceId].type === 'number' && (
                          <SurveyNumberTask
                            id={instanceId}
                            data={surveyInstances[instanceId]}
                            onDuplicate={(duplicatedData) =>
                              duplicateSurvey(
                                instanceId,
                                duplicatedData,
                                surveyInstances[instanceId]
                              )
                            }
                            onDelete={() => deleteSurvey(instanceId)}
                            onUpdateQuestion={handleUpdateQuestion}
                            questionNumber={index + 1}
                            showOptionsButton={true}
                            showDescriptionsCreate={true}
                            provided={provided}
                            index={index}
                          />
                        )}
                        {surveyInstances[instanceId].type === 'image' && (
                          <SurveyImageTask
                            id={instanceId}
                            data={surveyInstances[instanceId]}
                            onDuplicate={(duplicatedData) =>
                              duplicateSurvey(
                                instanceId,
                                duplicatedData,
                                surveyInstances[instanceId]
                              )
                            }
                            onDelete={() => deleteSurvey(instanceId)}
                            onUpdateQuestion={handleUpdateQuestion}
                            questionNumber={index + 1}
                            showOptionsButton={true}
                            showDescriptionsCreate={true}
                            provided={provided}
                            index={index}
                          />
                        )}

                        {surveyInstances[instanceId].type === 'multiplechk' && (
                          <SurveyComponentMultiple
                            id={instanceId}
                            data={surveyInstances[instanceId]}
                            onDuplicate={(duplicatedData) =>
                              duplicateSurvey(
                                instanceId,
                                duplicatedData,
                                surveyInstances[instanceId]
                              )
                            }
                            onDelete={() => deleteSurvey(instanceId)}
                            onUpdateQuestion={handleUpdateQuestion}
                            questionNumber={index + 1}
                            showOptionsButton={true}
                            showDescriptionsCreate={true}
                            provided={provided}
                            index={index}
                          />
                        )}

                        {surveyInstances[instanceId].type === 'multiplerb' && (
                          <SurveyComponentUnique
                            id={instanceId}
                            data={surveyInstances[instanceId]}
                            onDuplicate={(duplicatedData) =>
                              duplicateSurvey(
                                instanceId,
                                duplicatedData,
                                surveyInstances[instanceId]
                              )
                            }
                            onDelete={() => deleteSurvey(instanceId)}
                            onUpdateQuestion={handleUpdateQuestion}
                            questionNumber={index + 1}
                            showOptionsButton={true}
                            showDescriptionsCreate={true}
                            provided={provided}
                            index={index}
                          />
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="survey-form-sections">
          <p>Nueva pregunta</p>
          <div className="form-control-widths">
            <select
              className={`select-promo-type form-control custom-select form-control-widths ${
                !isClientOrSomeQualifierSelected() && 'inactive'
              }`}
              name="taskType"
              onChange={handleOptionValid}
              disabled={!isClientOrSomeQualifierSelected()}
              value={selectedOption}
              required={isRequired}
            >
              {surveySelectionType.map((option) => (
                <option key={option.value + 1} value={option.value} hidden={option.hidden}>
                  {option.text}
                </option>
              ))}
            </select>
            <button
              className={`btnsurvey ${!isClientOrSomeQualifierSelected() && 'inactive'}`}
              disabled={!isClientOrSomeQualifierSelected() || !isSelectionValid}
              onClick={handleClickSelectSurvey}
              type="button"
              noValidate
            >
              <span className="icon-survey-add">+</span>{' '}
              <span className="select-add-question">Agregar</span>
            </button>
          </div>
          {showImageWarning && (
            <p className="warning-text">
              Solo se permite agregar una pregunta de tipo imagen por encuesta.
            </p>
          )}
        </div>
      </div>

      <TaskScheduleConfig
        onRecurrenceChange={handleRecurrenceRuleChange}
        onStartChange={handleStartTimeChange}
        onEndChange={handleEndTimeChange}
      />
    </>
  )
}
export default SurveyTask
