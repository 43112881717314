import React, { useState } from 'react'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'

function TaskClientOrAttribute({
  clientOrAttribute,
  handleClientOrAttributeChange,
  clients,
  handleClientOption,
  getClient,
  abortClientFetchs,
  qualifierA,
  handleChangeQualifierA,
  qualifierAOptions,
  qualifierB,
  handleChangeQualifierB,
  qualifierBOptions,
  qualifierC,
  handleChangeQualifierC,
  qualifierCOptions,
  validateAtLeastOneQualifier,
  description
}) {
  const noAvailableQualifiers = () => {
    return (
      (!qualifierAOptions?.length || qualifierAOptions.length === 0) &&
      (!qualifierBOptions?.length || qualifierBOptions.length === 0) &&
      (!qualifierCOptions?.length || qualifierCOptions.length === 0)
    )
  }

  return (
    <div>
      <div className="promo-form-section">
        <div className="title-promos-form">
          <b class="bold-step">PASO 3: Escoja a qué clientes se debe aplicar esta tarea</b>
        </div>
        <hr className="hr-promos-form" />
        <p>Por cliente individual o Atributo(s)</p>

        <div>
          <div className="form-control-width">
            <select
              onChange={handleClientOrAttributeChange}
              className={`select-appliesType form-control custom-select form-control-width ${
                description === '' && 'inactive'
              }`}
              name="applies_target_to_type"
              value={clientOrAttribute}
              disabled={description === ''}
              required
            >
              <option value="" hidden>
                Escoja una opción
              </option>
              <option value="client">Cliente individual</option>
              {!noAvailableQualifiers() && <option value="qualifier">Atributo(s)</option>}
            </select>
          </div>
        </div>
      </div>

      {clientOrAttribute === 'client' && (
        <div className="promo-form-section">
          <div className="title-promos-form">
            <p>
              <b>PASO 3.1: Escoja los clientes que aplican para esta tarea</b>
            </p>
          </div>
          <div className="fake-validator-container">
            <input
              className="input-fake-validator"
              type="text"
              required
              value={clients.map((client) => client.label).join(', ')}
            />
            <AsyncSelect
              className="clients"
              placeholder={'Buscar cliente por nombre o código'}
              noOptionsMessage={() => 'No hay resultados'}
              loadingMessage={() => 'Cargando clientes...'}
              name="selectOptionClients"
              loadOptions={getClient}
              isClearable={true}
              searchParamName={'q'}
              isSearchable={true}
              isMulti
              value={clients}
              onChange={handleClientOption}
              onBlur={abortClientFetchs}
            />
          </div>
        </div>
      )}

      {clientOrAttribute === 'qualifier' && (
        <div className="promo-form-section">
          <div className="title-promos-form">
            <b>
              PASO 3.1: Escoja los atributos que deben de tener los clientes para activar esta
              tarea. Mínimo debe de escoger 1 atributo
            </b>
          </div>
          <div className="qualifier-title">Atributo A</div>
          <div className="fake-validator-container" style={{ zIndex: 3 }}>
            {qualifierAOptions.length > 0 && (
              <input
                className="input-fake-validator"
                type="text"
                required
                value={validateAtLeastOneQualifier(qualifierA, qualifierB, qualifierC)}
              />
            )}
            <Select
              placeholder={'Buscar o escoger una opcion'}
              noOptionsMessage={() => 'Buscar'}
              loadingMessage={() => 'Cargando...'}
              className="qualifier"
              isDisabled={qualifierAOptions?.length === 0}
              isClearable={true}
              isMulti
              onChange={handleChangeQualifierA}
              options={qualifierAOptions}
              value={qualifierA?.map((q) => ({ value: q, label: q }))}
            />
          </div>
          <div className="qualifier-title">Atributo B</div>
          <div className="fake-validator-container" style={{ zIndex: 2 }}>
            {qualifierBOptions.length > 0 && (
              <input
                className="input-fake-validator"
                type="text"
                required
                value={validateAtLeastOneQualifier(qualifierA, qualifierB, qualifierC)}
              />
            )}
            <Select
              placeholder={'Buscar o escoger una opcion'}
              noOptionsMessage={() => 'Buscar'}
              loadingMessage={() => 'Cargando...'}
              className="qualifier"
              isDisabled={qualifierBOptions?.length === 0}
              isClearable={true}
              isMulti
              onChange={handleChangeQualifierB}
              options={qualifierBOptions}
              value={qualifierB?.map((q) => ({ value: q, label: q }))}
            />
          </div>
          <div className="qualifier-title">Atributo C</div>
          <div className="fake-validator-container">
            {qualifierCOptions.length > 0 && (
              <input
                className="input-fake-validator"
                type="text"
                required
                value={validateAtLeastOneQualifier(qualifierA, qualifierB, qualifierC)}
              />
            )}
            <Select
              placeholder={'Buscar o escoger una opcion'}
              noOptionsMessage={() => 'Buscar'}
              loadingMessage={() => 'Cargando...'}
              className="qualifier"
              isDisabled={qualifierCOptions?.length === 0}
              isClearable={true}
              isMulti
              onChange={handleChangeQualifierC}
              options={qualifierCOptions}
              value={qualifierC?.map((q) => ({ value: q, label: q }))}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default TaskClientOrAttribute
