import { Modal } from 'react-bootstrap'
import React, { useState } from 'react'

const ConfirmRoleRemovalModal = ({ showModal, closeModal }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleConfirm = () => {
    closeModal(true)
  }

  const handleCancel = () => {
    closeModal(false)
  }

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  }

  return (
    <div>
      <Modal
        onHide={handleCancel}
        backdrop="static"
        keyboard={false}
        size="lg"
        show={showModal}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="title-green-blue-icon">
              <b className="subtitle-user-resetpass">¿Desactivar rol(es)?</b>
            </p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: '20px' }}>
          <div>
            <div className="container-fluid modal-container">
              <div className="form-row">
              <p>
                  <b>Nota: </b>
                  Tenga en cuenta que este usuario podría tener actividades en curso ¿Está seguro de que desea desactivar el rol(es) para este usuario? Las actividades en "Por enviar" no podrán ser recibidas.                </p>
              </div>
              <br />
              <div className="form-group">
                <div className="check-modal-user d-flex align-items-center">
                  <input
                    type="checkbox"
                    id="confirmCheckbox"
                    onChange={handleCheckboxChange}
                    style={{ transform: 'scale(1.5)', marginRight: '10px' }}
                  />
                  <label htmlFor="confirmCheckbox" style={{ color: '#27588D', marginTop:'10px' }}>
                  Sí, estoy seguro
                  </label>
                </div>
                <div className="col-12 float-right mt-3">
                  <button onClick={handleCancel} className="shadow-cancel-button">
                    Cancelar
                  </button>
                  <button
                    onClick={handleConfirm}
                    className={`btn float-right ${isChecked ? 'ubq-modal-button' : 'ubq-modal-button-disable'}`}
                    disabled={!isChecked}
                  >
                    Continuar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ConfirmRoleRemovalModal;
