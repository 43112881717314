import React, { createContext, useReducer } from 'react'
import { ScalePromoReducer } from './ScalePromoReducer'

export const ScalePromoContext = createContext()

const ScalePromoContextProvider = ({ children }) => {
  const [scaleDiscountProducts, dispatch] = useReducer(ScalePromoReducer, [], () => [])

  const addScaleDiscountProduct = (payload) => {
    dispatch({ type: 'ADD_TO_SCALE_DISCOUNT_PRODUCT', payload })
  }

  const changeBasePackageProduct = (payload) => {
    dispatch({ type: 'CHANGE_BASE_PACKAGE_PRODUCTS', payload })
  }

  const removeScaleDiscountProduct = (payload) => {
    dispatch({ type: 'REMOVE_SCALE_DISCOUNT_PRODUCT', payload })
  }

  const clearScaleDiscountProduct = () => {
    dispatch({ type: 'CLEAR_SCALE_DISCOUNT_PRODUCT' })
  }
  const contextValues = {
    addScaleDiscountProduct,
    removeScaleDiscountProduct,
    clearScaleDiscountProduct,
    changeBasePackageProduct,
    scaleDiscountProducts
  }

  return <ScalePromoContext.Provider value={contextValues}>{children}</ScalePromoContext.Provider>
}

export default ScalePromoContextProvider
