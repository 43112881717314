import React, { useState, useEffect } from 'react'
import NavBar from '../../layout/NavBar'
import TopBar from '../../layout/TopBar'
import ShowRefundView from './ShowRefundView'
import cookie from 'react-cookies'

const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
const BACKEND_URL = USE_BUSINESS_URL
  ? `${process.env.REACT_APP_BACKEND_URL}/businesses/${cookie.load('business_id')}`
  : process.env.REACT_APP_BACKEND_URL

const ShowRefundApp = (props) => {
  const [searchPlaceholder, setSearchPlaceholder] = useState('')
  const [id, setId] = useState(window.location.href.split('/')[window.location.href.split('/').length - 1])
  const [isFetching, setIsFetching] = useState(true)
  const [query, setQuery] = useState('')
  const [details, setDetails] = useState(null)
  const [defaultUuid, setDefaultUuid] = useState(null)
  const [defaultDetails, setDefaultDetails] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetchTransactionDetails()
  }, [id])

  const fetchTransactionDetails = () => {
    setIsFetching(true)

    fetch(`${BACKEND_URL}/refunds/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        if (response.status === 404) {
          return {
            error: true,
            message: 'No se encontró la actividad'
          }
        }
        return response.json()
      })
      .then((data) => {
        if (data.error) {
          setError(data.message)
          setIsFetching(false)
        } else {
          setDetails(data)
          setDefaultUuid(data?.uuid)
          setDefaultDetails(data)
          setIsFetching(false)
        }
      })
      .catch((e) => console.log(e))
  }

  const handleSearch = (event, isEditing) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      const query = event.target.value
      if (query !== '') {
        if (isEditing) {
          const isConfirmation = window.confirm(
            'Esta seguro que quiere continuar? Los cambios editados no seran guardados'
          )
          if (isConfirmation === true) {
            window.location = `/pedidos?query=${query}`
          }
        } else {
          window.location = `/pedidos?query=${query}`
        }
        event.target.value = ''
      }
    }
  }

  return (
    <div>
      {isFetching === false ? (
        <div>
          <div>
            <div>
              <div onKeyDown={(e) => handleSearch(e, false)}>
                <TopBar
                  searchPlaceholder={searchPlaceholder}
                  userName={props.userName}
                />
              </div>
              <div>
                {error ? (
                  <div className="main-view">
                    <div className="main-show-header">
                      <p>{error}</p>
                    </div>
                  </div>
                ) : (
                  <ShowRefundView
                    details={details}
                    position={props.match.params.id}
                    defaultDetails={defaultDetails}
                  />
                )}
              </div>
            </div>
            <div>
              <NavBar activeItem="Devolución" isEditing={false} />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <TopBar
            searchPlaceholder={searchPlaceholder}
            userName={props.userName}
          />
          <div className="main-view">
            <div className="show-title">
              <p>Obteniendo datos ....</p>
            </div>
          </div>
          <div>
            <NavBar activeItem="Devolución" isEditing={false} />
          </div>
        </div>
      )}
    </div>
  )
}

export default ShowRefundApp
