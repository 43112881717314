import React, { useContext, useEffect, useState } from 'react'
import cookie from 'react-cookies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMapMarkedAlt,
  faArrowCircleDown,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons'
import history from '../../helpers/history'
import Pagination from '../../layout/Pagination'
import { CSVLink } from 'react-csv'
import {
  getCurrentDate,
  getEndDate,
  getStartDate,
  getTimestampFromDateTime
} from '../../helpers/formatDate'
import { MapModalProvider, MapModalStore } from '../hook/MapModalProvider'
import { Button, Modal } from 'react-bootstrap'
import ShowMapModalContent from '../charges/map/ShowMapModalContent'
import ShowMapModalTimestampContent from '../charges/map/ShowMapModalTimestampContent'
import moment from 'moment'
import { GlobalContext } from '../../global_context/GlobalContext'
import OverviewCard from '../components/OverviewCard'
import GeoCard from '../components/GeoCard'
import GeoCardShowMap from '../components/GeoCardShowMap'
import ReportMotiveCard from '../components/ReportMotiveCard'
import AmountCard from '../components/AmountCard'
import TimestampControlOverviewCard from '../components/TimestampControlOverviewCard'
import {
  DicRoles,
  ShowTabs,
  DetailShowTabs,
  DetailDicRoles,
  DetailDicRolesTab
} from '../../helpers/reportConstants'
import {
  clearCoordinates,
  effectiveLocationStr,
  formatMoney,
  safePercent
} from '../../helpers/utils'
import { LocalTime, Duration } from '@js-joda/core'
import { validateAccessRole } from '../../helpers/userRole'
import { v5 as uuidv5 } from 'uuid'
import SimpleCard from '../components/SimpleCard'
import { TASK_TYPES, TASK_TYPES_KEYS } from '../../pos/constants/constants'
import { Table } from 'react-bootstrap'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default function ShowUserView(props) {
  const headers = [
    'FECHA Y HORA',
    'CÓDIGO',
    'CLIENTE',
    'DENTRO DE GEOCERCA',
    'ACTIVIDAD',
    'MOTIVO/DESCRIPCIÓN',
    'MONTO',
    'UBICACIÓN LAT.',
    'UBICACIÓN LONG.'
  ]

  const timestampHeaders = [
    'FECHA',
    'CÓDIGO DE USUARIO',
    'NOMBRE USUARIO',
    'CÓDIGO DE CLIENTE',
    'NOMBRE DE CLIENTE',
    'DURACIÓN',
    'HORA DE INICIO',
    'LATITUD INICIO',
    'LONGITUD INICIO',
    'HORA DE FINALIZACIÓN',
    'LATITUD FINAL',
    'LONGITUD FINAL',
    'DENTRO DE GEOCERCA'
  ]
  //
  //   label: Nombre del campo
  //   show: Array que define en qué pestañas mostrar el campo
  //

  const uiDetailHeaders = [
    {
      id: 'datetime',
      label: 'Fecha y hora',
      show: [DetailShowTabs.ResumenVisita]
    },
    {
      id: 'date',
      label: 'Fecha',
      show: [DetailShowTabs.ControlHorario]
    },
    {
      id: 'code',
      label: 'Código',
      show: [DetailShowTabs.ResumenVisita]
    },
    {
      id: 'client',
      label: 'Cliente',
      show: [DetailShowTabs.All]
    },
    {
      id: 'start_visit_time',
      label: 'Hora de inicio',
      show: [DetailShowTabs.ControlHorario]
    },
    {
      id: 'end_visit_time',
      label: 'Hora de finalizacíon',
      show: [DetailShowTabs.ControlHorario]
    },
    {
      id: 'visit_duration',
      label: 'Duración de visita',
      show: [DetailShowTabs.ControlHorario]
    },

    {
      id: 'activity',
      label: 'Actividad',
      show: [DetailShowTabs.ResumenVisita]
    },
    {
      id: 'motive',
      label: 'Motivo/descripción',
      show: [DetailShowTabs.ResumenVisita]
    },
    {
      id: 'total',
      label: 'Monto',
      show: [DetailShowTabs.ResumenVisita]
    },
    {
      id: 'geo',
      label: 'Dentro de geocerca',
      show: [DetailShowTabs.All]
    },
    {
      id: 'ubication',
      label: 'Ubicación',
      show: [DetailShowTabs.All]
    }
  ]

  const getSelectedTab = () => {
    const searchParams = new URLSearchParams(window.location.search)
    let selected_tab = searchParams.get('selected_tab')
    if (selected_tab) {
      return Number(selected_tab)
    }
    return DetailShowTabs.ResumenVisita
  }

  const [start_date, setStartDate] = useState(getStartDate())
  const [end_date, setEndDate] = useState(getEndDate)
  const [data, setData] = useState([])
  const [timestampData, setTimestampData] = useState([])
  const [overview, setOverview] = useState(false)
  const [userRoles, setUserRoles] = useState([])
  const [isFetching, setFetching] = useState(false)
  const [salesmanName, setSalesmanName] = useState('')
  const [invalidRangeLimit, setInvalidRangeLimit] = useState(false)
  const [invalidRangeFormat, setInvalidRangeFormat] = useState(false)
  const [invalidDateFormat, setInvalidDateFormat] = useState(false)
  const [invalidRangeOffset, setInvalidRangeOffset] = useState(false)
  const [currentPage, setPageInfo] = useState(1)
  const [total_pages, setTotalPages] = useState(1)
  const [per_page, setPerPages] = useState(40)
  const [downloadData, setDownloadData] = useState([])
  const [timestampDownloadData, setTimestampDownloadData] = useState([])
  const [show, setShow] = useState(false)
  const [timestampShow, setTimestampShow] = useState(false)
  const [dataSelected, setDataSelected] = useState(false)
  const [timestampDataSelected, setTimestampDataSelected] = useState(false)
  const [detailSelectedTab, setDetailSelectedTab] = useState(getSelectedTab())
  const [isTimestampSameDay, setIsTimestampSameDay] = useState(false)

  const [latitude, setLatitude] = useState(false)
  const [longitude, setLongitude] = useState(false)
  const [collapsed, setCollapsed] = useState(props.collapsed)

  const handleClose = () => setShow(false)

  const handleShow = (model, event) => {
    event.preventDefault()
    setDataSelected(model)
    setLatitude(clearCoordinates(model.latitude))
    setLongitude(clearCoordinates(model.longitude))
    setShow(true)
  }

  const handleTimestampClose = () => setTimestampShow(false)

  const handleTimestampShow = (model, event) => {
    event.preventDefault()
    setTimestampDataSelected(model)
    setTimestampShow(true)
  }

  useEffect(() => {
    async function fetchData() {
      await fetchDownloadData()
      if (start_date == end_date) {
        setIsTimestampSameDay(true)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    setCollapsed(props.collapsed)
  }, [props.collapsed])

  useEffect(() => {
    async function fetchData() {
      const page = currentPage
      await fetchDetailsFromServer(page)
    }

    fetchData().then(() => {
      setFetching(false)
    })
  }, [currentPage])

  const getSalesmanReferenceId = () => {
    const salesman_uid = window.location.href.split('user_db_ref=')
    return salesman_uid[salesman_uid.length - 1].split('&')[0]
  }

  const user_db_ref = getSalesmanReferenceId()

  const createText = 'Descargar reporte'

  const fetchDownloadData = () => {
    fetch(
      `${BACKEND_URL}/businesses/${cookie.load('business_id')}/reports/ubiqua_app/user?user_db_ref=${user_db_ref}&start_date=${start_date}&end_date=${end_date}&business_tenant_uid=${cookie.load('business_tenant_uid')}&page=1&per_page=1000000`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => {
        setOverview(data['overview'][0])
        let userRolesStr = parseUserRoles(data['overview'][0].user_active_roles)
        let roles = userRolesStr.split(', ')
        setUserRoles(roles)
        setSalesmanName(data?.overview[0].user_name)
        setDownloadData(data['table'])
        setTimestampDownloadData(data['timestamp_table'])
      })
      .catch((e) => console.log(e))
  }

  const fetchDetailsFromServer = async (page) => {
    setFetching(true)
    fetch(
      `${BACKEND_URL}/businesses/${cookie.load('business_id')}/reports/ubiqua_app/user?user_db_ref=${user_db_ref}&start_date=${start_date}&end_date=${end_date}&business_tenant_uid=${cookie.load('business_tenant_uid')}&page=${page}&per_page=${per_page}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => {
        setData(data['table'])
        setTimestampData(data['timestamp_table'])
        setTotalPages(setTotalPage(data))
      })
      .catch((e) => console.log(e))
  }

  const setTotalPage = (data) => {
    if (detailSelectedTab == DetailShowTabs.ControlHorario) {
      if (data['timestamp_table'].length > 0) {
        return Math.ceil(data['timestamp_table'][0]['total_count'] / per_page)
      }
    }
    if (detailSelectedTab == DetailShowTabs.ResumenVisita) {
      if (data['table'].length > 0) {
        return Math.ceil(data['table'][0]['total_count'] / per_page)
      }
    }
  }

  const handlePaginationClick = (event) => {
    // <a> = when pagination elements were clicked
    if (event.target.tagName === 'A') {
      handlePagination(event.target)
    } else if (event.target.tagName === 'svg') {
      handlePagination(event.target.parentNode)
    } else if (event.target.tagName === 'path') {
      handlePagination(event.target.parentNode.parentNode)
    }
  }

  const handlePagination = (target) => {
    if (target.id === 'first-page') {
      if (parseInt(currentPage) !== 1) {
        setPageInfo(1)
      }
    } else if (target.id === 'last-page') {
      if (parseInt(currentPage) !== total_pages) {
        setPageInfo(total_pages)
      }
    } else if (target.id === 'previous-page') {
      if (parseInt(currentPage) !== 1) {
        setPageInfo(parseInt(currentPage) - 1)
      }
    } else if (target.id === 'next-page') {
      if (parseInt(currentPage) !== total_pages) {
        setPageInfo(parseInt(currentPage) + 1)
      }
    }
  }

  const getListTabStyles = (tab) => {
    return detailSelectedTab == tab ? 'active' : 'nav-item'
  }

  const getAnchorTabStyles = (tab) => {
    return detailSelectedTab == tab ? 'active' : 'inactive-tab'
  }

  const showModel = (model, event) => {
    event.preventDefault()
    history.push({
      pathname: '/mapa',
      params: {
        salesmanName: salesmanName,
        latitude: model['latitude'],
        longitude: model['longitude'],
        hasAllowedLocation: model['has_allowed_location']
      }
    })
  }

  const formatActivityName = (activity_name) => {
    if (activity_name === 'NO VENTA') {
      return 'VISITA NO EFECTIVA'
    } else if (TASK_TYPES[activity_name]) {
      return TASK_TYPES[activity_name].shortName
    } else {
      return activity_name
    }
  }

  const buildData = () => {
    let array = downloadData.map((a) => [
      a.created_date,
      a.client_db_ref,
      a.client_name,
      effectiveLocationStr(a.effective_location, a.client_latitude, a.latitude),
      formatActivityName(a.activity_name),
      a.motive_text || '-',
      formatMoney(a.total),
      a.latitude,
      a.longitude
    ])
    array.splice(0, 0, headers)
    array.splice(0, 0, [])
    array.splice(0, 0, ['Fecha', start_date + ' - ' + end_date])
    array.splice(0, 0, ['Usuario', salesmanName])
    array.splice(0, 0, ['Supervisor', cookie.load('user_name')])
    array.splice(0, 0, ['Reporte:', 'Reporte resumen de usuario'])
    return array
  }

  const buildTimestampDownloadData = () => {
    let array = timestampDownloadData.map((a) => [
      moment(a.visited_date).format('DD/MM/YYYY'),
      a.salesman_db_ref,
      a.salesman_name,
      a.client_db_ref,
      a.client_name,
      a.visit_duration,
      formatTime(a.start_visit_time),
      a.start_latitude,
      a.start_longitude,
      formatTime(a.end_visit_time),
      a.end_latitude,
      a.end_longitude,
      effectiveLocationStr(a.effective_location, a.client_latitude, a.latitude)
    ])
    array.splice(0, 0, timestampHeaders)
    array.splice(0, 0, [])

    if (isTimestampSameDay) {
      array.splice(0, 0, ['Duración de visitas:', totalDayVisitDuration()])
      array.splice(0, 0, ['Hora de última visita:', formatTime(lastEndVisitTime())])
      array.splice(0, 0, ['Hora de primera visita:', formatTime(firstStartVisitTime())])
    } else {
      array.splice(0, 0, ['Tiempo promedio de visita:', averageVisitDuration()])
      array.splice(0, 0, ['Hora de última visita:', 'N/A'])
      array.splice(0, 0, ['Hora de primera visita:', 'N/A'])
    }

    array.splice(0, 0, [])
    array.splice(0, 0, ['Fecha:', start_date + ' - ' + end_date])
    array.splice(0, 0, ['Usuario:', salesmanName])
    array.splice(0, 0, ['Reporte:', 'Control de horario'])
    return array
  }

  const searchFromDates = (event) => {
    event.preventDefault()
    const today = new Date()
    const startDate = new Date(start_date)
    const endDate = new Date(end_date)
    const diff_in_time = endDate.getTime() - startDate.getTime()
    const diff_in_time_from_today = today.getTime() - startDate.getTime()
    // To calculate the no. of days between two dates
    const diff_in_days = diff_in_time / (1000 * 3600 * 24)
    const diff_in_days_from_today = diff_in_time_from_today / (1000 * 3600 * 24)
    // Validations
    if (!start_date || !end_date) {
      setInvalidRangeFormat(false)
      setInvalidRangeLimit(false)
      setInvalidDateFormat(true)
      setInvalidRangeOffset(false)
    } else if (startDate > endDate) {
      setInvalidRangeFormat(true)
      setInvalidRangeLimit(false)
      setInvalidDateFormat(false)
      setInvalidRangeOffset(false)
    } else if (start_date > today || end_date > today) {
      setInvalidRangeFormat(false)
      setInvalidRangeLimit(false)
      setInvalidDateFormat(false)
      setInvalidRangeOffset(true)
    } else if (diff_in_days > 366 || diff_in_days_from_today > 366) {
      setInvalidRangeFormat(false)
      setInvalidRangeLimit(true)
      setInvalidDateFormat(false)
      setInvalidRangeOffset(false)
    } else {
      setInvalidRangeFormat(false)
      setInvalidRangeLimit(false)
      setInvalidDateFormat(false)
      setInvalidRangeOffset(false)

      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set('start_date', start_date)
      searchParams.set('end_date', end_date)

      window.location.search = searchParams.toString()
    }
  }

  const selectStartDate = (event) => {
    event.preventDefault()
    setStartDate(event.target.value)
  }

  const selectEndDate = (event) => {
    event.preventDefault()
    setEndDate(event.target.value)
  }

  const firstStartVisitTime = () => {
    if (timestampDownloadData.length <= 0) return '-'
    return timestampDownloadData[timestampDownloadData.length - 1]?.start_visit_time || '-'
  }

  const lastEndVisitTime = () => {
    if (timestampDownloadData.length <= 0) return '-'

    const validVisit = timestampDownloadData.find((visitEntry) => visitEntry.status !== 'initiated')
    return validVisit?.end_visit_time || '-'
  }

  const averageVisitDuration = () => {
    if (timestampDownloadData.length <= 0) return '-'

    const times = timestampDownloadData.map((t) => t.visit_duration)

    const initTime = LocalTime.of(0, 0, 0, 0)
    const totalDuration = times.reduce((acc, timeStr) => {
      const time = LocalTime.parse(timeStr)
      const duration = Duration.between(initTime, time)
      return acc.plus(duration)
    }, Duration.ZERO)

    const averageDuration = totalDuration.dividedBy(times.length)

    const resultHours = averageDuration.toHours()
    const resultMinutes = averageDuration.toMinutes() % 60
    const resultSeconds = averageDuration.seconds() % 60

    return `${String(resultHours).padStart(2, '0')}:${String(resultMinutes).padStart(
      2,
      '0'
    )}:${String(resultSeconds).padStart(2, '0')}` == '00:00:00'
      ? '-'
      : `${String(resultHours).padStart(2, '0')}:${String(resultMinutes).padStart(2, '0')}:${String(
          resultSeconds
        ).padStart(2, '0')}`
  }

  const totalDayVisitDuration = () => {
    if (timestampDownloadData.length <= 0) return '-'

    const times = timestampDownloadData.map((t) => t.visit_duration)

    const initTime = LocalTime.of(0, 0, 0, 0)
    const totalDuration = times.reduce((acc, timeStr) => {
      const time = LocalTime.parse(timeStr)
      const duration = Duration.between(initTime, time)

      return acc.plus(duration)
    }, Duration.ZERO)

    const resultTime = initTime.plus(totalDuration)
    return resultTime.toString() == '00:00:00' ? '-' : resultTime.toString()
  }

  // Esta funcion transforma el string que recibimos del Backend  (00:00:00 AM) a (00:00 AM)
  const formatTime = (time) => {
    const timeRegex = /^\d{1,2}:\d{2}:\d{2} [APMapm]{2}$/
    if (!timeRegex.test(time)) {
      return time
    }
    const period = time.slice(-2).toUpperCase()
    const timeParts = time.slice(0, -3).split(':')
    // timeParts[0] = hours, timeParts[1] = minutes
    const hours = timeParts[0]
    const minutes = timeParts[1]
    return `${hours}:${minutes} ${period}`
  }

  const { shouldViewTransactionInfo } = useContext(GlobalContext)

  const handleTransactionClick = (event, model) => {
    event.preventDefault()
    const activityName = model['activity_name']?.toLowerCase()
    let businessUid = cookie.load('business_tenant_uid')
    let url = ''
    if (activityName.includes('pedido')) {
      let uuid = uuidv5(
        `${businessUid}~${model['activity_uid']}`,
        '302028d1-e384-4238-b832-e951f917e7d3'
      )
      url = '/pedidos/' + uuid
    } else if (activityName.includes('devoluci') || activityName.includes('rechazo')) {
      let uuid = uuidv5(
        `${businessUid}~${model['activity_uid']}`,
        '7ee9c328-ee67-49cd-9c19-57cfe29ad764'
      )
      url = '/devoluciones/' + uuid
    } else if (activityName.includes('no venta')) {
      let uuid = uuidv5(
        `${businessUid}~${model['activity_uid']}`,
        '1a40a53a-a01e-476b-b073-a29859befee6'
      )
      url = '/visitas-no-efectivas/' + uuid
    } else if (activityName.includes('entrega')) {
      let uuid = uuidv5(
        `${businessUid}~${model['activity_uid']}`,
        '3ddb40b2-6e3b-428c-bd03-6892c524ca1c'
      )
      url = '/entregas/' + uuid
    } else if (activityName.includes('cobro')) {
      let uuid = uuidv5(
        `${businessUid}~${model['activity_uid']}`,
        'd90e460b-8136-48a0-8d50-760d2fec9a39'
      )
      url = '/cobros/' + uuid
    } else if (TASK_TYPES_KEYS.includes(activityName)) {
      let uuid = uuidv5(
        `${businessUid}~${model['activity_uid']}`,
        '2493938c-95ac-45a5-b847-cde19d0ddb51'
      )
      url = '/tareas_en_sitio/' + uuid
    } else if (activityName.includes('liquida')) {
      let uuid = uuidv5(
        `${businessUid}~${model['activity_uid']}`,
        'e405a836-1394-4c9b-b9b6-5d9a0ec263b0'
      )
      url = '/liquidaciones/detalles/' + uuid
    }
    window.open(url, '_blank')
  }

  const parseUserRoles = (userActiveRoles) => {
    let rolesSet = new Set()
    let rolesRaw = userActiveRoles.split('~')
    for (const roleRaw of rolesRaw) {
      let roles = roleRaw?.replace(/{|}/g, '')?.split(',')
      for (const role of roles) {
        if (role) rolesSet.add(DicRoles[role])
      }
    }
    return Array.from(rolesSet).join(', ')
  }

  const shouldDisplayField = (showArray) => {
    return showArray.includes(DetailShowTabs.All) || showArray.includes(detailSelectedTab)
  }

  const buildKpiData = () => {
    if (!overview) return false
    let text = ''
    let overviewArr = [
      { text: 'Clientes visitados', value: `${overview?.total_percent_visited?.toFixed()}%` },
      {
        text: 'Visitas efectivas',
        value: `${
          overview
            ? safePercent(
                Number(overview?.total_effective_clients_count),
                Number(overview?.total_visited_clients_count)
              )
            : 0
        }%`
      }
    ]
    if (userRoles.includes(DicRoles.charge)) {
      text = 'Documentos entregados'
      if (userRoles.includes(DicRoles.delivery)) {
        text = 'Docs. entregados (Cobros)'
      }
      overviewArr.push({
        text,
        value: `${
          overview
            ? safePercent(
                Number(overview?.charge_documents_count),
                Number(overview?.charge_documents_total_count)
              )
            : 0
        }%`
      })
    }
    if (userRoles.includes(DicRoles.delivery)) {
      text = 'Documentos entregados'
      if (userRoles.includes(DicRoles.charge)) {
        text = 'Docs. entregados (Entregas)'
      }
      overviewArr.push({
        text,
        value: `${
          overview
            ? safePercent(
                overview?.delivery_documents_closed_count,
                overview?.delivery_documents_total_count
              )
            : 0
        }%`
      })
    }

    return {
      overview: overviewArr,
      geodata: `${overview?.geofence_effective_clients_count}/${overview?.geofence_location_available_count}`,
      orderDisplay: userRoles.includes(DicRoles.order),
      refundDisplay: userRoles.includes(DicRoles.refund),
      chargeDisplay: userRoles.includes(DicRoles.charge),
      deliveryDisplay: userRoles.includes(DicRoles.delivery),
      posTaskDisplay: userRoles.includes(DicRoles.pos_task_activity),
      posTask: `${overview?.pos_task_activity_done_task_codes_count}/${overview?.pos_task_activity_total_clients_count}`,
      totalOrder: formatMoney(Number(overview?.total_order)),
      totalRefund: formatMoney(Number(overview?.total_refund)),
      totalCharge: formatMoney(Number(overview?.total_charge)),
      totalDelivery: formatMoney(Number(overview?.total_delivery))
    }
  }

  const buildTimestampKpiData = () => {
    let timestampControlOverviewArr = []

    if (!timestampData) {
      timestampControlOverviewArr = [
        { text: 'Hora de primera visita', value: '-' },
        { text: 'Hora de última visita', value: '-' },
        { text: 'Duración total de visitas', value: '-' }
      ]
    } else if (isTimestampSameDay) {
      timestampControlOverviewArr = [
        { text: 'Hora de primera visita', value: formatTime(firstStartVisitTime()) },
        { text: 'Hora de última visita', value: formatTime(lastEndVisitTime()) },
        { text: 'Duración total de visitas', value: totalDayVisitDuration() }
      ]
    } else {
      timestampControlOverviewArr = [
        { text: 'Hora de primera visita', value: 'N/A' },
        { text: 'Hora de última visita', value: 'N/A' },
        { text: 'Tiempo promedio de visita', value: averageVisitDuration() }
      ]
    }

    return {
      timestampControlOverview: timestampControlOverviewArr
    }
  }

  const cardsSection = (kpiData) => {
    if (!data || data?.length == 0) {
      return (
        <>
          <div className="cards-container">
            <OverviewCard
              data={[
                { text: 'Clientes visitados', value: '0%' },
                {
                  text: 'Visitas efectivas',
                  value: '0%'
                }
              ]}
            />
            <GeoCardShowMap
              geoData={'0/0'}
              data={downloadData}
              user_name={overview.user_name}
              startDate={start_date}
              endDate={end_date}
            />
          </div>
          <div className="cards-container">
            <AmountCard
              title={'Total Pedidos'}
              subtitle={formatMoney(0)}
              icon={'icon_pedidos'}
              selectedTab={ShowTabs.Resumen}
              displayTab={[ShowTabs.Resumen, ShowTabs.Pedidos]}
            />
          </div>
        </>
      )
    }
    return (
      <>
        <div className="cards-container">
          <OverviewCard data={kpiData.overview} />
          {kpiData?.posTaskDisplay && (
            <SimpleCard
              title={'Tareas en punto \n de venta'}
              subtitle={kpiData?.posTask}
              selectedTab={ShowTabs.Resumen}
              displayTab={[ShowTabs.Resumen, ShowTabs.Pos]}
            />
          )}
          <GeoCardShowMap
            geoData={kpiData.geodata}
            data={downloadData}
            user_name={overview.user_name}
            startDate={start_date}
            endDate={end_date}
          />
        </div>
        <div className="cards-container">
          {kpiData?.orderDisplay && (
            <AmountCard
              title={'Total Pedidos'}
              subtitle={kpiData.totalOrder}
              icon={'icon_pedidos'}
              selectedTab={ShowTabs.Resumen}
              displayTab={[ShowTabs.Resumen, ShowTabs.Pedidos]}
            />
          )}
          {kpiData?.refundDisplay && (
            <AmountCard
              title={'Total Devoluciones'}
              subtitle={kpiData.totalRefund}
              icon={'icon_devoluciones'}
              selectedTab={ShowTabs.Resumen}
              displayTab={[ShowTabs.Resumen, ShowTabs.Devoluciones]}
            />
          )}
          {kpiData?.chargeDisplay && (
            <AmountCard
              title={'Total Cobros'}
              subtitle={kpiData.totalCharge}
              icon={'icon_cobros'}
              selectedTab={ShowTabs.Resumen}
              displayTab={[ShowTabs.Resumen, ShowTabs.Cobros]}
            />
          )}
          {kpiData?.deliveryDisplay && (
            <AmountCard
              title={'Total Entregas'}
              subtitle={kpiData.totalDelivery}
              icon={'icon_entregas'}
              selectedTab={ShowTabs.Resumen}
              displayTab={[ShowTabs.Resumen, ShowTabs.Entregas]}
            />
          )}
        </div>
      </>
    )
  }

  const timestampCardsSection = (kpiData) => {
    if (!kpiData) return null
    return (
      <div className="cards-container">
        <TimestampControlOverviewCard data={kpiData.timestampControlOverview} />
      </div>
    )
  }

  if (isFetching === false) {
    return (
      <MapModalProvider salesmanVisitData={data}>
        <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
          {invalidRangeOffset && (
            <div className="alert alert-danger" role="alert">
              <strong>
                ERROR: No se pueden seleccionar fechas posteriores a hoy. Por favor escoger otra
                fecha.
              </strong>
            </div>
          )}
          {invalidRangeLimit && (
            <div className="alert alert-danger" role="alert">
              <strong>
                ERROR: Rango de fechas escogidas pasan de los 12 meses. Por favor escoger otra
                fecha.
              </strong>
            </div>
          )}
          {invalidRangeFormat && (
            <div className="alert alert-danger" role="alert">
              <strong>ERROR: Fecha de inicio debe ser antes que la fecha de fin.</strong>
            </div>
          )}
          {invalidDateFormat && (
            <div className="alert alert-danger" role="alert">
              <strong>
                ERROR: Formato de fecha inválido. Por favor verifique que las fechas están escritas
                correctamente.
              </strong>
            </div>
          )}
          <div className="index-header">
            <h2 className="mb-20 float-unset">Reporte: {salesmanName}</h2>
          </div>
          <div className="index-table">
            <div className="show-area-tab">
              <div>
                <div className="mb-1">
                  <ul className="nav nav-tabs">
                    {Object.entries(DetailShowTabs).map(([key, value]) => {
                      {
                        if (
                          value &&
                          validateAccessRole(cookie.load('user_role')?.timestamp_control_tab)
                            ?.can_read
                        ) {
                          return (
                            <li
                              key={value}
                              onClick={() => setDetailSelectedTab(value)}
                              id="details_tab"
                              className={`nav-link ${getListTabStyles(value)} cursor-pointer`}
                            >
                              <a
                                className={getAnchorTabStyles(value)}
                                style={{ textDecoration: 'none' }}
                              >
                                {DetailDicRoles[key]}
                              </a>
                            </li>
                          )
                        } else if (value == 1) {
                          return (
                            <li
                              key={1}
                              onClick={() => setDetailSelectedTab(1)}
                              id="details_tab"
                              className={`nav-link ${getListTabStyles(1)} cursor-pointer`}
                            >
                              <a
                                className={getAnchorTabStyles(1)}
                                style={{ textDecoration: 'none' }}
                              >
                                Resumen de visita
                              </a>
                            </li>
                          )
                        }
                      }
                    })}
                  </ul>
                </div>
              </div>
              <div className="range-dates mt-25 mb-20">
                <span className='date-start-end-size'>
                  Desde
                </span>
                <input
                  max={getCurrentDate()}
                  onChange={selectStartDate}
                  defaultValue={start_date}
                  className="select-date-report"
                  type="date"
                  name="start_date"
                  required
                />
                <span className='date-start-end-size'>
                  - Hasta
                </span>
                <input
                  max={getCurrentDate()}
                  onChange={selectEndDate}
                  defaultValue={end_date}
                  className="select-date-report"
                  type="date"
                  name="end_date"
                  required
                />
                <button onClick={searchFromDates} className="search-range-dates-btn">
                  Buscar
                </button>
                {detailSelectedTab == DetailShowTabs.ResumenVisita && (
                  <CSVLink
                    className="btn btn-default download-report-button"
                    data={buildData()}
                    filename={`ReporteDeUsuario_${salesmanName.replace(/\s/g, '')}_${cookie.load(
                      'business_name'
                    )}_${getCurrentDate()}.csv`}
                  >
                    {createText}
                    <FontAwesomeIcon icon={faArrowCircleDown} />
                  </CSVLink>
                )}
                {detailSelectedTab == DetailShowTabs.ControlHorario && (
                  <CSVLink
                    className="btn btn-default download-report-button"
                    data={buildTimestampDownloadData()}
                    filename={`ControlDeHorario_${salesmanName.replace(/\s/g, '')}_${cookie.load(
                      'business_name'
                    )}_${getCurrentDate()}.csv`}
                  >
                    {createText}
                    <FontAwesomeIcon icon={faArrowCircleDown} />
                  </CSVLink>
                )}
              </div>
              <h7>
                <FontAwesomeIcon icon={faExclamationCircle} />
                Nota: Solo puedes escoger un rango de fechas de un máximo de 12 meses.
              </h7>
              <hr />

              <div className="show-header">
                <div className="col-12">
                  {detailSelectedTab == DetailShowTabs.ResumenVisita &&
                    cardsSection(buildKpiData())}
                  {detailSelectedTab == DetailShowTabs.ControlHorario &&
                    timestampCardsSection(buildTimestampKpiData())}
                  <hr style={{ marginLeft: '1rem' }} />
                </div>
                <div className="title-report-text">
                  <h2>Detalles de reporte:</h2>
                </div>
              </div>
              <hr className="mt-90" />
              <div>
                <Table hover responsive>
                  <thead className="table-header-gray">
                    <tr>
                      {uiDetailHeaders.map((header, index) => {
                        return shouldDisplayField(header.show) ? (
                          <th style={{ verticalAlign: 'middle' }} key={index}>
                            <span className="th-header-text">{header.label.toUpperCase()}</span>
                          </th>
                        ) : null
                      })}
                    </tr>
                  </thead>
                  {detailSelectedTab == DetailShowTabs.ResumenVisita && (
                    <tbody>
                      {data.length > 0 &&
                        data.map((model, index) => (
                          <tr key={index}>
                            <td
                              className="td-text-limited"
                              onClick={(e) => {
                                handleTransactionClick(e, model)
                              }}
                            >
                              {getTimestampFromDateTime(model['created_date'])}
                            </td>
                            <td
                              className="td-text-limited"
                              onClick={(e) => {
                                handleTransactionClick(e, model)
                              }}
                            >
                              {model['client_db_ref']}
                            </td>
                            <td
                              className="td-text-limited"
                              onClick={(e) => {
                                handleTransactionClick(e, model)
                              }}
                            >
                              {model['client_name']}
                            </td>

                            <td
                              className="td-text-limited"
                              onClick={(e) => {
                                handleTransactionClick(e, model)
                              }}
                              style={
                                model['activity_name'].includes('ANULAD') ||
                                model['activity_name'].includes('RECHAZAD')
                                  ? {
                                      maxWidth: '300px',
                                      color: '#FF2323'
                                    }
                                  : { maxWidth: '300px' }
                              }
                            >
                              {formatActivityName(model['activity_name'])}
                            </td>
                            <td
                              className="td-text-limited"
                              onClick={(e) => {
                                handleTransactionClick(e, model)
                              }}
                            >
                              {model['motive_text'] || '-'}
                            </td>
                            {model['total'] != null && (
                              <td
                                className="td-text-limited"
                                style={{ whiteSpace: 'nowrap' }}
                                onClick={(e) => {
                                  handleTransactionClick(e, model)
                                }}
                              >
                                {formatMoney(model['total'])}
                              </td>
                            )}
                            {model['total'] == null && <td>-</td>}

                            <td
                              className="td-text-limited td-text-limited-center"
                              onClick={(e) => {
                                handleTransactionClick(e, model)
                              }}
                            >
                              {model['activity_name'].includes('LIQUID') ? (
                                <>-</>
                              ) : (
                                <>
                                  {effectiveLocationStr(
                                    model['effective_location'],
                                    model['client_latitude'],
                                    model['latitude']
                                  )}
                                </>
                              )}
                            </td>
                            <td className="td-text-limited td-text-limited-center-maps">
                              {model['activity_name'].includes('LIQUID') ? (
                                <>-</>
                              ) : (
                                <button
                                  onClick={(e) => {
                                    handleShow(model, e)
                                  }}
                                  className="report-maps-activity-button"
                                  type="button"
                                >
                                  <FontAwesomeIcon icon={faMapMarkedAlt} />
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      {data.length === 0 && (
                        <tr>
                          <td colSpan="8" className="td-text-limited ">
                            No hay resultados
                          </td>
                        </tr>
                      )}
                    </tbody>
                  )}
                  {/* Tabla de Control de horario */}
                  {detailSelectedTab == DetailShowTabs.ControlHorario && (
                    <tbody>
                      {timestampData.length > 0 &&
                        timestampData.map((model, index) => (
                          <tr key={index}>
                            <td className="td-text-limited">
                            {moment(model['visited_date']).format('DD/MM/YYYY')}
                            </td>
                            <td className="td-text-limited">{model['client_name']}</td>
                            <td className="td-text-limited">
                              {formatTime(model['start_visit_time'])}
                            </td>
                            {model['status'] == 'initiated' && (
                              <>
                                <td className="td-text-limited">En curso</td>
                                <td className="td-text-limited">-</td>
                              </>
                            )}
                            {model['status'] == 'completed' && (
                              <>
                                <td className="td-text-limited">
                                  {formatTime(model['end_visit_time'])}
                                </td>
                                <td className="td-text-limited"> {model['visit_duration']}</td>
                              </>
                            )}
                            {model['effective_location'] !== null ? (
                              <>
                                <td className="td-text-limited">
                                  {effectiveLocationStr(
                                    model['effective_location'],
                                    model['client_latitude'],
                                    model['start_latitude']
                                  )}
                                </td>
                              </>
                            ) : (
                              <td className="td-text-limited">N/A</td>
                            )}
                            <td>
                              <button
                                onClick={(e) => {
                                  handleTimestampShow(model, e)
                                }}
                                className="report-maps-activity-button"
                                type="button"
                              >
                                <FontAwesomeIcon icon={faMapMarkedAlt} />
                              </button>
                            </td>
                          </tr>
                        ))}
                      {timestampData.length === 0 && (
                        <tr>
                          <td colSpan="8" className="td-text-limited">
                            No hay resultados
                          </td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </Table>
                <nav onClick={handlePaginationClick}>
                  {total_pages > 1 && (
                    <Pagination current_page={currentPage} total_pages={total_pages} />
                  )}
                </nav>
              </div>
              <>
                <Modal
                  show={show}
                  dialogClassName="modal-90w"
                  onHide={handleClose}
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Header closeButton className="no-border-bottom">
                    <Modal.Title id="example-modal-sizes-title-lg">
                      <b className="ft-16">Reporte: {salesmanName}</b>
                    </Modal.Title>
                  </Modal.Header>

                  <Modal.Header className="d-flex flex-column align-items-start">
                    <b className="mb-10">Detalles de captura de actividad</b>
                    <p className="text-title-modal-map">Cliente: {dataSelected.client_name}</p>
                    <p className="text-title-modal-map">
                      Fecha y hora: {moment(dataSelected.created_date).format('L')} -{' '}
                      {moment(dataSelected.created_date).format('LT')}
                    </p>
                    <p className="text-title-modal-map">Actividad: {dataSelected.activity_name}</p>
                  </Modal.Header>
                  <Modal.Body>
                    <div id="map-canvas">
                      <ShowMapModalContent
                        longitude={longitude}
                        latitude={latitude}
                        clientLatitude={dataSelected.client_latitude}
                        clientLongitude={dataSelected.client_longitude}
                        salesmanVisitData={dataSelected}
                        hasAllowedLocation={dataSelected.has_allowed_location}
                        errorCoordinatesCode={dataSelected.error_coordinates_code}
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="light" onClick={handleClose} className="custom-close-button">
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
              <>
                <Modal
                  show={timestampShow}
                  dialogClassName="modal-90w"
                  onHide={handleTimestampClose}
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Header closeButton className="no-border-bottom">
                    <Modal.Title id="example-modal-sizes-title-lg">
                      <b className="ft-16">Reporte: Control de horario - {salesmanName}</b>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Header className="d-flex flex-column align-items-start">
                    <b className="mb-10">Detalles de captura de cliente</b>
                    <p className="text-title-modal-map">
                      Cliente: {timestampDataSelected.client_name}
                    </p>
                    <p className="text-title-modal-map">
                      Fecha:{' '}
                      {moment(timestampDataSelected.visited_date).format('DD/MM/YYYY')}
                    </p>
                  </Modal.Header>
                  <Modal.Body>
                    <div id="map-canvas">
                      <ShowMapModalTimestampContent
                        startLongitude={timestampDataSelected.start_longitude}
                        startLatitude={timestampDataSelected.start_latitude}
                        endLongitude={timestampDataSelected.end_longitude}
                        endLatitude={timestampDataSelected.end_latitude}
                        clientLatitude={timestampDataSelected.client_latitude}
                        clientLongitude={timestampDataSelected.client_longitude}
                        salesmanVisitData={timestampDataSelected}
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="light" onClick={handleTimestampClose} className="custom-close-button">
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            </div>
          </div>
        </div>
      </MapModalProvider>
    )
  } else {
    return (
      <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
        <div className="show-title">
          <p>Obteniendo datos ....</p>
        </div>
      </div>
    )
  }
}
