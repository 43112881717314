import React, { useState, useEffect } from 'react';
import cookie from 'react-cookies'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import CreatePromo from './pages/CreatePromo'
import DiscountPromoContextProvider from './context/DiscountPromoContext'
import BonusPromoContextProvider, { BonusPromoContext } from './context/BonusPromoContext'
import BasePromoContextProvider, { BasePromoContext } from './context/BasePromoContext'
import ScalePromoContextProvider from './context/ScalePromoContext'
import AssortedPromoContextProvider from './context/AssortedPromoContext'
import AssortedBonusPromoContextProvider from './context/AssortedBonusPromoContext'
import AssortedScalePromoContextProvider from './context/AssortedScalePromoContext'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const CreatePromoApp = () => {
  const [searchPlaceholder, setSearchPlaceholder] = useState('Buscar promociones por código, descripción, o productos base');
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    isUserLoggedIn();
    const collapsedFromStorage = localStorage.getItem('collapsedState') === 'true';
    setCollapsed(collapsedFromStorage);
  }, []);

  const isUserLoggedIn = () => {
    fetch(`${BACKEND_URL}/users/current`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookie.load('token')}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (Object.keys(data)?.length === 0) {
          window.location = '/';
        }
      })
      .catch(e => console.log(e));
  };

  const handleSearch = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      const query = event.target.value;
      const val = window.confirm('¿Esta seguro que quiere continuar? Los cambios editados no seran guardados');
      if (val) {
        if (query !== '') {
          window.location = `/promociones?query=${query}`;
        }
      }
      event.target.value = '';
    }
  };

  const handleToggleCollapse = () => {
    const newCollapsedState = !collapsed;
    setCollapsed(newCollapsedState);
    localStorage.setItem('collapsedState', newCollapsedState);
  };

  return (
    <div>
      <div>
        <div onKeyDown={handleSearch}>
          <TopBar searchPlaceholder={searchPlaceholder}
                  onToggleCollapse={handleToggleCollapse} 
                  collapsed={collapsed} />
        </div>
        <div>
          <BasePromoContextProvider>
            <BonusPromoContextProvider>
              <ScalePromoContextProvider>
                <DiscountPromoContextProvider>
                  <AssortedPromoContextProvider>
                    <AssortedBonusPromoContextProvider>
                      <AssortedScalePromoContextProvider>
                        <CreatePromo collapsed={collapsed} />
                      </AssortedScalePromoContextProvider>
                    </AssortedBonusPromoContextProvider>
                  </AssortedPromoContextProvider>
                </DiscountPromoContextProvider>
              </ScalePromoContextProvider>
            </BonusPromoContextProvider>
          </BasePromoContextProvider>
        </div>
      </div>
      <div>
        <NavBar activeItem="Promociones" isEditing={true} collapsed={collapsed} />
      </div>
    </div>
  );
};

export default CreatePromoApp;
