import React, { createContext, useState } from 'react'

export const ActionButtonContext = createContext()

export default function ActionButtonProvider({ children }) {
  const [activeDropdown, setActiveDropdown] = useState(null)

  return (
    <ActionButtonContext.Provider value={{ activeDropdown, setActiveDropdown }}>
      {children}
    </ActionButtonContext.Provider>
  )
}
