import React, { useContext } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import LoginPage from './login/LoginPage'
import ResetPasswordPage from './login/ResetPasswordPage'
import ConfirmNewPasswordPage from './login/ConfirmNewPasswordPage'
import ProductIndexApp from './product/ProductIndexApp'
import PromoIndexApp from './promo/PromoIndexApp'
import ClientIndexApp from './client/ClientIndexApp'
import OrderIndexApp from './order/OrderIndexApp'
import DeliveryEffectivityIndexApp from './report/invoices/DeliveryEffectivityIndexApp'
import VisitEffectivityIndexApp from './report/visits/VisitEffectivityIndexApp'
import PriceListIndexApp from './price_list/PriceListIndexApp'
import LiquidacionIndexApp from './liquidacion/LiquidacionIndexApp'
import SalesmanCreateNewApp from './salesman/SalesmanCreateNewApp'
import CreateNewClientApp from './client/CreateNewClientApp'
import SalesmanIndexApp from './salesman/SalesmanIndexApp'
import ShowProductApp from './product/ShowProductApp'
import ShowClientApp from './client/ShowClientApp'
import ShowPriceListApp from './price_list/ShowPriceListApp'
import ShowPromoApp from './promo/ShowPromoApp'
import ShowLiquidacionApp from './liquidacion/ShowLiquidacionApp'
import ShowOrderApp from './order/ShowOrderApp'
import ShowSalesmanApp from './salesman/ShowSalesmanApp'
import ShowDeliverInvoicesApp from './report/invoices/ShowDeliverInvoicesApp'
import ShowSalesmanVisitsApp from './report/visits/ShowSalesmanVisitsApp'
import ShowInvoiceApp from './report/invoices/ShowInvoiceApp'
import CreatePromoApp from './promo/CreatePromoApp'
import './css/index.css'
import './css/styles.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import CreateDeliveryUser from './delivery_user/CreateDeliveryUser'
import DeliveryUserApp from './delivery_user/DeliveryUserIndex'
import DeliveryUserDetail from './delivery_user/DeliveryUserDetail'
import CreateDeliveryUserForm from './delivery_user/component/CreateDeliveryUserForm'
import { ClientProvider } from './client/context/ClientContext'
import history from './helpers/history'
import CreateOrderApp from './order/CreateOrderApp'
import ProtectedRoute from './ProtectedRoute'
import { GlobalContext } from './global_context/GlobalContext'
import NotFoundPage from './NoMatchPage'
import ChargeEffectivenessIndexApp from './report/charges/ChargeEffectivenessIndexApp'
import ShowUserChargeReportVisitsApp from './report/charges/details/ShowUserChargeReportVisitsApp'
import TabsUserConfig from './config_user/TabsUserConfig'
import { UserStepTabsProvider } from './config_user/context/UserStepTabsProvider'
import { UserInfoProvider } from './config_user/context/UserInfoProvider'
import { UserConfigProvider } from './config_user/context/UserConfigProvider'
import CreateUserConfigStep from './config_user/CreateUserConfigStep'
import { UserConfigCreateProvider } from './config_user/context/UserConfigCreateProvider'
import ConsolidationIndexApp from './report/consolidation/ConsolidationIndexApp'
import { nonWritePermissionsAlert, validateAccessRole } from './helpers/userRole'
import { UserFlagCreateProvider } from './config_user/context/UserFlagCreateProvider'
import { SubmitCreateUserProvider } from './config_user/context/SubmitCreateUserProvider'
import ShowSimpleOrderApp from './order/ShowSimpleOrderApp'
import CreateProductStep from './product/page/CreateProductStep'
import { ProductCreateProvider } from './product/context/ProductCreateProvider'
import { PriceListCreateProvider } from './product/context/PriceListCreateProvider'
import { SubmitCreateProductProvider } from './product/context/SubmitCreateProductProvider'
import UsersIndexApp from './config_user/UsersIndexApp'
import EffectivenessIndexApp from './report/unified/EffectivenessIndexApp'
import ShowUserApp from './report/unified/ShowUserApp'
import SegmentIndexApp from './segments/SegmentIndexApp'
import EssentialIndexApp from './essential/EssentialIndexApp'
import EssentialsAssociationsApp from './essential/EssentialsAssociationsApp'
import TableauIndexApp from './tableau_dashboard/TableauIndexApp'
import HomeIndexApp from './home/HomeIndexApp'
import ShowRefundApp from './report/refunds/ShowRefundApp'
import ShowNoEffectiveVisitApp from './report/no-effective-visit/ShowNoEffectiveVisitApp'
import ShowChargeApp from './report/charges-latest/ShowChargeApp'
import PointOfSaleApp from './pos/PointOfSaleApp'
import TaskLayout from './pos/forms/TaskLayout'
import PosDetailsApp from './pos/details/PosDetailsApp'
import PosTaskApp from './report/pos_tasks/PosTaskApp'
import PosTaskEffectivityApp from './pos/PosTaskEffectivityApp'
import ShowDeliveryApp from './report/delivery/ShowDeliveryApp'
import SettlementsIndex from './settlements/SettlementsIndex'
import SettlementsDetails from './settlements/SettlementsDetails'
import PosDownloadApp from './pos/PosDownloadApp'
import ClientApplicationApp from './client/ClientApplicationApp'
import ClientApplicationForm from './client/ClientApplicationForm'
import { reactRouterV5BrowserTracingIntegration, withSentryRouting, init } from '@sentry/react'
import {
  captureConsoleIntegration,
  contextLinesIntegration,
  extraErrorDataIntegration,
  httpClientIntegration
} from '@sentry/integrations'

const App = () => {
  const {
    userRole,
    shouldCreateOrder,
    shouldViewCoreInfo,
    shouldViewTransactionInfo,
    canMakePromoRole,
    configPermission,
    canViewReport,
    useAve,
    redirectPath
  } = useContext(GlobalContext)

  const consolidationPermission = userRole
    ? validateAccessRole(userRole.can_send_consolidation)
    : false

  // SentryRoute and ProtectedSentryRoute replace Route and ProtectedRoute to secure routes with Sentry alerts.
  const ProtectedSentryRoute = withSentryRouting(ProtectedRoute)
  const SentryRoute = withSentryRouting(Route)

  init({
    dsn: 'https://4fa6c6a24b1f413fb80360942af05457@o24019.ingest.us.sentry.io/1366115',
    integrations: [
      reactRouterV5BrowserTracingIntegration({ history }),
      captureConsoleIntegration({ levels: ['error'] }),
      contextLinesIntegration(),
      extraErrorDataIntegration(),
      httpClientIntegration({ failedRequestStatusCodes: [500, 503, 599, 400, 404, 403] })
    ],
    sendDefaultPii: true,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.HEROKU_APP_NAME
  })

  return (
    <div>
      <Router history={history}>
        {shouldViewCoreInfo != null && userRole != null && (
          <Switch>
            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.product_module).can_read}
              redirect={redirectPath}
              exact
              path="/productos"
              component={ProductIndexApp}
            />
            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.clients_module).can_read}
              redirect={redirectPath}
              exact
              path="/clientes"
              component={ClientIndexApp}
            />
            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.clients_module).can_read}
              redirect={redirectPath}
              exact
              path="/solicitud_cliente_nuevo"
              component={ClientApplicationApp}
            />
            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.clients_module).can_read}
              redirect={redirectPath}
              exact
              path="/crear_solicitud_cliente_nuevo"
              component={ClientApplicationForm}
            />
            
            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.segments_module).can_read}
              redirect={redirectPath}
              exact
              path="/segmentos"
              component={SegmentIndexApp}
            />
            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.essentials_module).can_read}
              redirect={redirectPath}
              exact
              path="/infaltables"
              component={EssentialIndexApp}
            />
            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.price_list_module).can_read}
              redirect={redirectPath}
              exact
              path="/listas_de_precios"
              component={PriceListIndexApp}
            />
            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.can_make_promo).can_read}
              redirect={redirectPath}
              exact
              path="/promociones"
              component={(props) => (
                <PromoIndexApp
                  successCreateForNewPromo={
                    props.location.params ? props.location.params.successCreateForNewPromo : false
                  }
                />
              )}
            />
            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.orders_modules).can_read}
              redirect={redirectPath}
              exact
              path="/pedidos"
              component={OrderIndexApp}
            />
            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.can_config_user).can_read}
              redirect={redirectPath}
              exact
              path="/usuarios"
              component={(props) => {
                const userPosition = props.location.pathname.replace('/usuarios/', '')
                return (
                  <UserInfoProvider
                    user={userPosition}
                    createUserParams={props.location.params ? props.location.params : null}
                  >
                    <UsersIndexApp />
                  </UserInfoProvider>
                )
              }}
            />
            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.collections_module)?.can_read}
              redirect={redirectPath}
              exact
              path="/liquidaciones"
              component={SettlementsIndex}
            />
            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.can_view_report)?.can_read}
              redirect={redirectPath}
              exact
              path="/reportes"
              component={EffectivenessIndexApp}
            />
            <SentryRoute exact path="/inicio">
              <Redirect to="/logout" />
            </SentryRoute>

            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.can_config_user).can_read}
              redirect={redirectPath}
              path="/usuarios/:id"
              component={() => (
                <UserInfoProvider>
                  <UserConfigProvider>
                    <UserStepTabsProvider>
                      <TabsUserConfig />
                    </UserStepTabsProvider>
                  </UserConfigProvider>
                </UserInfoProvider>
              )}
            />

            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.can_config_user).can_write}
              redirect={redirectPath}
              exact
              path="/crear_usuario_configuracion"
              component={() => (
                <UserConfigCreateProvider>
                  <UserFlagCreateProvider>
                    <SubmitCreateUserProvider>
                      <CreateUserConfigStep />
                    </SubmitCreateUserProvider>
                  </UserFlagCreateProvider>
                </UserConfigCreateProvider>
              )}
            />
            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.essentials_module).can_read}
              redirect={redirectPath}
              exact
              path="/asociar_infaltable"
              component={EssentialsAssociationsApp}
            />

            <ProtectedSentryRoute
              visible={shouldViewCoreInfo}
              redirect={redirectPath}
              exact
              path="/vendedores"
              component={SalesmanIndexApp}
            />
            <ProtectedSentryRoute
              visible={shouldViewCoreInfo}
              redirect={redirectPath}
              exact
              path="/entregadores"
              component={DeliveryUserApp}
            />

            {shouldViewCoreInfo && (
              <SentryRoute
                path="/entregadores/:id"
                render={(props) => {
                  const deliveryPosition = props.location.pathname.replace('/entregadores/', '')
                  return (
                    <DeliveryUserDetail
                      deliver={deliveryPosition}
                      successCreateForNewDeliverer={
                        props.location.params
                          ? props.location.params.successCreateForNewDeliverer
                          : false
                      }
                    />
                  )
                }}
              />
            )}

            <ProtectedSentryRoute
              visible={shouldViewCoreInfo}
              redirect={redirectPath}
              exact
              path="/crear_vendedor"
              component={SalesmanCreateNewApp}
            />
            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.clients_module).can_write}
              redirect={redirectPath}
              exact
              path="/crear_cliente"
              component={CreateNewClientApp}
            />
            <ProtectedSentryRoute
              visible={shouldViewCoreInfo}
              redirect={redirectPath}
              exact
              path="/efectividad_de_entregas"
              component={DeliveryEffectivityIndexApp}
            />

            <ProtectedSentryRoute
              visible={shouldViewCoreInfo}
              redirect={redirectPath}
              exact
              path="/crear_entregadores"
              component={CreateDeliveryUser}
            />
            <ProtectedSentryRoute
              visible={shouldViewCoreInfo}
              redirect={redirectPath}
              exact
              path="/crear_ecommerce"
              component={CreateDeliveryUserForm}
            />

            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.product_module).can_write}
              redirect={redirectPath}
              exact
              path="/crear_producto"
              component={() => (
                <ProductCreateProvider>
                  <PriceListCreateProvider>
                    <SubmitCreateProductProvider>
                      <CreateProductStep />
                    </SubmitCreateProductProvider>
                  </PriceListCreateProvider>
                </ProductCreateProvider>
              )}
            />

            {shouldViewCoreInfo && (
              <SentryRoute
                path="/vendedores/:id"
                render={(props) => (
                  <ShowSalesmanApp
                    variants={useAve}
                    successCreateForNewSalesman={
                      props.location.params
                        ? props.location.params.successCreateForNewSalesman
                        : false
                    }
                  />
                )}
              />
            )}

            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.product_module).can_read}
              redirect={redirectPath}
              path="/productos/:id"
              component={(props) => (
                <ShowProductApp
                  successCreateForNewProduct={
                    props.location.params ? props.location.params.successCreateForNewProduct : false
                  }
                />
              )}
            />

            <ProtectedSentryRoute
              exact
              visible={validateAccessRole(userRole.clients_module).can_write}
              redirect={redirectPath}
              path="/clientes/:id/edit_ecommerce"
              component={() => {
                const clientPosition =
                  window.location.href.split('/')[window.location.href.split('/').length - 2]
                return (
                  <ClientProvider>
                    <ShowClientApp editForm={true} match={'/clientes/'} client={clientPosition} />
                  </ClientProvider>
                )
              }}
            />

            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.clients_module).can_write}
              redirect={redirectPath}
              exact
              path="/clientes/:id/create_ecommerce"
              component={(props) => {
                const clientPosition =
                  window.location.href.split('/')[window.location.href.split('/').length - 2]
                return (
                  <ClientProvider>
                    <ShowClientApp editForm={true} match={'/clientes/'} client={clientPosition} />
                  </ClientProvider>
                )
              }}
            />

            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.clients_module).can_read}
              redirect={redirectPath}
              exact
              path="/clientes/:id/detalles"
              component={(props) => {
                const clientPosition =
                  window.location.href.split('/')[window.location.href.split('/').length - 2]
                return (
                  <ClientProvider>
                    <ShowClientApp
                      successCreateForNewClient={
                        props.location.params
                          ? props.location.params.successCreateForNewClient
                          : false
                      }
                      editForm={false}
                      match={'/clientes/'}
                      client={clientPosition}
                    />
                  </ClientProvider>
                )
              }}
            />

            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.clients_module).can_read}
              redirect={redirectPath}
              exact
              path={'/clientes/:id/ecommerce'}
              component={(props) => {
                const clientPosition =
                  window.location.href.split('/')[window.location.href.split('/').length - 2]
                return (
                  <ClientProvider>
                    <ShowClientApp
                      successCreate={
                        props.location.params ? props.location.params.successCreate : false
                      }
                      editForm={false}
                      match={'/clientes/'}
                      client={clientPosition}
                    />
                  </ClientProvider>
                )
              }}
            />

            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.price_list_module).can_read}
              redirect={redirectPath}
              exact
              path="/listas_de_precios/:id"
              component={ShowPriceListApp}
            />
            <ProtectedSentryRoute
              visible={shouldViewCoreInfo}
              redirect={redirectPath}
              exact
              path="/facturas/:id"
              component={ShowInvoiceApp}
            />

            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.can_make_promo).can_read}
              redirect={redirectPath}
              exact
              path="/promociones/:id"
              component={ShowPromoApp}
            />
            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.collections_module).can_read}
              redirect={redirectPath}
              exact
              path="/liquidaciones/:id"
              component={ShowLiquidacionApp}
            />
            <ProtectedSentryRoute
              visible={shouldViewCoreInfo}
              redirect={redirectPath}
              exact
              path="/facturas_por_entregador"
              component={ShowDeliverInvoicesApp}
            />

            <ProtectedSentryRoute
              visible={canViewReport}
              redirect={redirectPath}
              path="/visitas_por_vendedor"
              component={ShowSalesmanVisitsApp}
            />

            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.can_view_report).can_read}
              redirect={redirectPath}
              path="/usuario_reportes"
              component={ShowUserApp}
            />

            <ProtectedSentryRoute
              visible={canViewReport}
              redirect={redirectPath}
              path="/visitas_de_cobro_por_usuario"
              component={ShowUserChargeReportVisitsApp}
            />

            <ProtectedSentryRoute
              visible={canViewReport}
              redirect={redirectPath}
              exact
              path="/efectividad_de_visitas"
              component={VisitEffectivityIndexApp}
            />

            <ProtectedSentryRoute
              visible={canViewReport}
              redirect={redirectPath}
              exact
              path="/efectividad_de_cobros"
              component={ChargeEffectivenessIndexApp}
            />

            <ProtectedSentryRoute
              visible={consolidationPermission.can_read}
              redirect={redirectPath}
              exact
              path="/consolidaciones"
              component={ConsolidationIndexApp}
            />

            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.can_make_promo).can_write}
              redirect={redirectPath}
              exact
              path="/nueva_promo"
              component={CreatePromoApp}
            />

            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.orders_modules).can_write}
              redirect={redirectPath}
              exact
              path="/nuevo_pedido"
              component={CreateOrderApp}
            />

            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.orders_modules).can_read}
              redirect={redirectPath}
              exact
              path="/pedidos/:id/:state"
              component={ShowOrderApp}
            />

            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.orders_modules).can_read}
              redirect={redirectPath}
              exact
              path="/pedidos/:id"
              component={ShowOrderApp}
            />
            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.orders_modules).can_read}
              redirect={redirectPath}
              exact
              path="/devoluciones/:id"
              component={ShowRefundApp}
            />
            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.orders_modules).can_read}
              redirect={redirectPath}
              exact
              path="/visitas-no-efectivas/:id"
              component={ShowNoEffectiveVisitApp}
            />
            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.can_view_report).can_read}
              redirect={redirectPath}
              exact
              path="/cobros/:id"
              component={ShowChargeApp}
            />
            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.pos_module).can_read}
              redirect={redirectPath}
              exact
              path="/tareas_en_sitio/:id"
              component={PosTaskApp}
            />
            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.orders_modules).can_read}
              redirect={redirectPath}
              exact
              path="/entregas/:id"
              component={ShowDeliveryApp}
            />
            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.pos_module).can_read}
              redirect={redirectPath}
              exact
              path="/ejecucion_punto_venta/detalles/:id"
              component={PosDetailsApp}
            />
            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.collections_module).can_read}
              redirect={redirectPath}
              exact
              path="/liquidaciones/detalles/:id"
              component={SettlementsDetails}
            />

            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.orders_modules).can_read}
              redirect={redirectPath}
              exact
              path="/orden/:id"
              component={ShowSimpleOrderApp}
            />

            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.tableau_dashboard_module).can_read}
              redirect={redirectPath}
              exact
              path="/dashboard"
              component={TableauIndexApp}
            />

            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.pos_module).can_read}
              redirect={redirectPath}
              exact
              path="/ejecucion_punto_venta"
              component={PointOfSaleApp}
            />

            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.pos_module).can_read}
              redirect={redirectPath}
              exact
              path="/efectividad_de_tareas"
              component={PosTaskEffectivityApp}
            />

            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.pos_module).can_write}
              redirect={redirectPath}
              exact
              path="/crear_tarea_punto_venta"
              component={TaskLayout}
            />

            <ProtectedSentryRoute
              visible={validateAccessRole(userRole.pos_module).can_read}
              redirect={redirectPath}
              exact
              path="/descarga_analisis_pdv"
              component={PosDownloadApp}
            />

            <SentryRoute exact path="/" component={LoginPage} />
            <SentryRoute exact path="/reset_done" component={LoginPage} />
            <SentryRoute exact path="/cambiar-contrasena" component={ResetPasswordPage} />
            <SentryRoute path="/nueva-contrasena" component={ConfirmNewPasswordPage} />
            <SentryRoute exact path="/logout" component={LoginPage} />

            <SentryRoute render={() => <NotFoundPage redirect={redirectPath} />} />
          </Switch>
        )}
      </Router>
    </div>
  )
}
export default App
