import React from 'react'
import TabWithDropdown from '../../layout/TabWithDropdown'
import DeliveryDetailsTab from './DeliveryDetailsTab'


export default class ShowDeliveryView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showSuccessfulMessage: false,
      alertState: 'alert alert-success',
      createText: 'Guardar',
      isPosting: false,
      activity: props.defaultDetails
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ details: nextProps.defaultDetails })
  }

  componentDidMount() {
    this.showSuccessfulMessageCreation()
  }

  showSuccessfulMessageCreation = () => {
    if (document.referrer.includes('nuevo_pedido')) {
      this.setState({ showSuccessfulMessage: true })
    } else {
      this.setState({ showSuccessfulMessage: false })
    }
  }

  hiddenAlert = () => {
    this.setState({
      alertState: 'alert alert-success hidden'
    })
  }

  disableButton = () => {
    this.setState({ createText: 'Guardando...', isPosting: true })
  }

  enableButton = () => {
    this.setState({ createText: 'Guardar', isPosting: false })
  }

  render() {
    return (
      <div>
        <div className="main-view">
          <div className="main-show-header">
            <h2>Entrega: {this.state.activity?.uid}</h2>
          </div>
          <div className="index-table">
            <div className="show-area">
              <div>
              <div className="status-orders">
              <b className="order-status-b">
              Estado:
                  </b>
                  {this.state.activity?.status === 'received' &&(
                    <span className="order-status received-style"> RECIBIDO</span>
                  )}
                  {this.state.activity?.status === 'sent' &&(
                    <span className="order-status sent-style"> ENVIADO</span>
                  )}
                  {this.state.activity?.status === 'dispatched' && (
                    <span className="order-status dispatched-style"> DESPACHADO</span>
                  )}
                  {this.state.activity?.status === 'invoiced' && (
                    <span className="order-status billed-style"> FACTURADO</span>
                  )}       
                </div>
                <div>
                  <div>
                    {this.state.activity?.status === 'received' || this.state.activity?.status === 'sent' && (
                      <ul className="nav nav-tabs">
                        <TabWithDropdown
                          name="Entrega"
                          activeTab={"Entrega"}
                          items={[]}
                        />
                      </ul>
                    )}
                  </div>

                  <div>
                    {this.state.activity && (
                      <DeliveryDetailsTab
                        activity={this.state.activity}
                        transactionUuid={this.props.details.uuid}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
