import React, { useState, useEffect } from 'react'

const NoEffectiveVisitDetailsTab = ({ activity: initialActivity = {}, transactionUuid }) => {
  const [activity, setActivity] = useState(initialActivity)

  useEffect(() => {
    setActivity(initialActivity)
  }, [initialActivity])

  const getFormattedDate = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  return (
    <div className="container-fluid">
      <div className="row">
        {transactionUuid && (
          <>
            <div className="show-product-header show-header mt-20">
              <div>
                <h2>Detalles de visita:</h2>
              </div>
            </div>
            <hr />
          </>
        )}
        <div>
          <div>
            <div className="order-client-name">{activity.client_name}</div>
            <div className="order-client-db_ref">Código: {activity.client_db_ref}</div>
          </div>
          <hr />
          <div>
            <table cellSpacing="10">
              <tbody>
                <tr>
                  <td>
                    <div className="mr-85 mb-10">
                      <b>Fecha de creación:</b>
                    </div>
                  </td>
                  <td>
                    <div className="mb-10">{getFormattedDate(activity.created_date)}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="mr-85 mb-10">
                      <b>Número de la visita:</b>
                    </div>
                  </td>
                  <td>
                    <div className="mb-10">{activity.no_effective_visit_db_ref}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="mr-85 mb-10">
                      <b>Vendedor:</b>
                    </div>
                  </td>
                  <td>
                    <div className="mb-10">{activity.salesman_name}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="mr-85 mb-10">
                      <b>Tipo de reporte:</b>
                    </div>
                  </td>
                  <td>
                    <div className="mb-10">{activity.report_type || '-'}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="mr-85 mb-10">
                      <b>Motivo / descripción:</b>
                    </div>
                  </td>
                  <td>
                    <div className="mb-10">{activity.motive || 'N/A'}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoEffectiveVisitDetailsTab
