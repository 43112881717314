import React from 'react'
import cookie from 'react-cookies'
import { formatMoney } from '../../helpers/utils'
import { Table } from 'react-bootstrap'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class OrderInvoiceDetailsTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orderDetails: this.props.orderDetails || [],
      invoiceAppDetails: this.props.invoiceAppDetails || []
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    this.setState({ orderDetails: nextProps.orderDetails })
  }

  getFormattedDate = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  getDiscountPercent = (_discount = 0) => {
    let discount = Number(_discount)
    if (discount === 0) return '-'
    return `${discount.toFixed(2)}%`
  }

  calculateTaxRate = () => {
    let total = 0
    this.state.invoiceAppDetails.details.map(
      (obj) => (total += parseFloat(obj.extended_price) * parseFloat(obj.tax_rate))
    )
    return total
  }

  calculateTotal = () => {
    let total = 0
    this.state.orderDetails.details.map((obj) => {
      const calculatedTaxPrice = obj.calculated_tax_price
        ? obj.calculated_tax_price
        : obj.extended_price
      return (total += parseFloat(calculatedTaxPrice))
    })
    return total
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          {this.props.transactionUuid && (
            <div>
                <div>
                    <div className="row show-product-header">
                    <div className="col-sm-6">
                        <h3 className="margin-text-subtitle">Detalles de factura:</h3>
                    </div>
                    </div>
                    <hr />
                </div>
            </div>
          )}
          <div>        
            <div className="show-product-header-ubiqua-app show-header">
              <div>
                <div className="order-client-name">{this.state.orderDetails['client_name']}</div>
                <div className="order-client-db_ref">
                  Código: {this.state.orderDetails['client_db_ref']}
                </div>
              </div>
            </div>
            <hr />
            <div>
            <table cellSpacing="10">
                <tbody>
                  <tr>
                    <td>
                      <div className="mr-85 mb-10">
                        <b>Fecha de creación:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">
                        {this.getFormattedDate(this.state.invoiceAppDetails['created_date'])}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-10">
                        <b>Número de factura:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">{this.state.invoiceAppDetails['invoice_db_ref']}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-10">
                        <b>Vendedor:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">{this.state.orderDetails['salesman_name']}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <div className="show-data-details">
                <div>
                  <b>Productos en factura:</b>
                </div>
              </div>

              <Table hover responsive>
              <thead className="table-header-gray">
              <tr>
                    <th>CÓDIGO</th>
                    <th>NOMBRE DEL PRODUCTO</th>
                    <th>PESO NETO</th>
                    <th>UNIDAD DE VENTA</th>
                    <th>CANTIDAD</th>
                    <th>PRECIO</th>
                    <th>% DESCUENTO</th>
                    <th>SUB-TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.invoiceAppDetails['details'].map((model, index) => (
                    <tr key={index}>
                      <td>{model['product_db_ref']}</td>
                      <td>{model['product_name']}</td>
                      <td>{Number(model['net_weight']).toFixed(2) == 0.00 ? "N/A": Number(model['net_weight']).toFixed(2)}</td>
                      <td>{model['unit']}</td>
                      <td>{Number(model['quantity']).toFixed(2)}</td>
                      <td>{formatMoney(Number(model['unit_price']))}</td>
                      <td>{this.getDiscountPercent(model['discount'])}</td>
                      <td>{formatMoney(Number(model['extended_price']))}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <hr className="mt-0"/>
              <div className="totals-container">
                <div className="totals-card">
                  <div className="totals-line">
                    <div>
                      <b>SUB-TOTAL:</b>
                    </div>
                    <div>{formatMoney(Number(this.state.invoiceAppDetails['sub_total']))}</div>
                  </div>
                  <div className="totals-line">
                    <div>
                      <b>DESCUENTOS:</b>
                    </div>
                    <div>-{formatMoney(this.state.invoiceAppDetails['discount'] || 0)}</div>
                  </div>
                  <div className="totals-line">
                    <div>
                      <b>TOTAL ITBMS:</b>
                    </div>
                    <div>{formatMoney(Number(this.calculateTaxRate()))}</div>
                  </div>
                  <div className="totals-line">
                    <div>
                      <h4>
                        <b>TOTAL:</b>
                      </h4>
                    </div>
                    <div><b>{formatMoney(Number(this.state.invoiceAppDetails['invoice_total']))}</b></div>
                  </div>
                  <hr />
                  <div className="totals-line">
                    <div>
                      <b>PESO TOTAL:</b>
                    </div>
                    <div>{Number(this.state.invoiceAppDetails['total_weight']).toFixed(2) == 0.00 ? "N/A": Number(this.state.invoiceAppDetails['total_weight']).toFixed(2)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}