import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrashAlt,
  faEllipsisH,
  faGripVertical,
  faGripHorizontal
} from '@fortawesome/free-solid-svg-icons'
import '../../../css/survey.scss'
import autosize from 'autosize'
const SurveyComponentUnique = ({
  id,
  onDuplicate,
  onDelete,
  onUpdateQuestion,
  data,
  questionNumber,
  classNames,
  showOptionsButton,
  disableQuestion,
  isDetailsSurvey,
  showDescriptionsCreate,
  showDescriptionsDetails,
  showresponseDetails,
  showresponsePostask,
  provided
}) => {
  const optionsContainerRef = useRef(null)
  const [isVisible, setIsVisible] = useState(true)
  const [formData, setFormData] = useState({
    question: data.question || '',
    descriptions: data.descriptions || '',
    options: [
      { id: 1, text: 'Opción 1', selected: false, editable: true },
      { id: 2, text: 'Opción 2', selected: false, editable: true }
    ]
  })
  const [isDuplicating, setIsDuplicating] = useState(false)
  const [showOptions, setShowOptions] = useState(false)
  const [editingIndex, setEditingIndex] = useState(null)
  const newOptionInputRef = useRef(null)
  const [isRequiredAlertActive, setIsRequiredAlertActive] = useState(false)
  const [rows, setRows] = useState(1)
  const textareaRefQuestion = useRef(null)
  const textareaRefDescription = useRef(null)

  const [optionIdCounter, setOptionIdCounter] = useState(
    data.options && data.options.length > 0
      ? Math.max(...data.options.map((option) => option.id)) + 1
      : 1
  )

  useEffect(() => {
    autosize(textareaRefQuestion.current)
    autosize(textareaRefDescription.current)
    return () => {
      autosize(textareaRefQuestion.current)
      autosize(textareaRefDescription.current)
    }
  }, [])

  useEffect(() => {
    if (editingIndex !== null && newOptionInputRef.current) {
      newOptionInputRef.current.focus()
    }
  }, [editingIndex, formData.options])

  useEffect(() => {
    if (!isDuplicating) {
      setFormData({
        question: data.question || '',
        descriptions: data.descriptions || '',
        options: data.options || '',
        response: data.response || ''
      })
    }
  }, [id, isDuplicating])

  useEffect(() => {
    setFormData({
      question: data.question || '',
      descriptions: data.descriptions || '',
      options: data.options || '',
      response: data.response || ''
    })
  }, [data])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsContainerRef.current && !optionsContainerRef.current.contains(event.target)) {
        setShowOptions(false)
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleSelectOption = (index) => {
    setFormData((prevFormData) => {
      const updatedOptions = prevFormData.options.map((option, i) => ({
        ...option,
        selected: i === index
      }))
      return { ...prevFormData, options: updatedOptions }
    })
  }

  const handleOptionChange = (index, newText) => {
    const isFieldEmpty = newText.trim() === ''
    setIsRequiredAlertActive(isFieldEmpty)
    setFormData((prevFormData) => {
      const updatedOptions = [...prevFormData.options]
      updatedOptions[index] = { ...updatedOptions[index], text: newText }
      onUpdateQuestion(id, 'options', updatedOptions)
      return { ...prevFormData, options: updatedOptions }
    })
    setTimeout(() => {
      setEditingIndex(index)
      if (newOptionInputRef.current) {
        newOptionInputRef.current.focus()
      }
    }, 0)
  }

  const handleKeyDown = (e, index) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleAddOption()
    } else if (e.key === 'Tab') {
      e.preventDefault()
      const nextIndex = index + 1
      if (nextIndex < formData.options.length) {
        setEditingIndex(nextIndex)
      } else {
        handleAddOption()
      }
    }
  }
  const handleAddOption = () => {
    setFormData((prevFormData) => {
      const newOption = {
        id: optionIdCounter,
        text: `Opción ${optionIdCounter}`,
        selected: false,
        editable: true
      }
      setOptionIdCounter((prevCounter) => prevCounter + 1)
      const updatedOptions = [...prevFormData.options, newOption]
      onUpdateQuestion(id, 'options', updatedOptions)
      setEditingIndex(updatedOptions.length - 1)
      return { ...prevFormData, options: updatedOptions }
    })
  }

  const handleRemoveOption = (index) => {
    event.preventDefault()
    setFormData((prevFormData) => {
      const updatedOptions = prevFormData.options
        .filter((_, i) => i !== index)
        .map((option, i) => {
          if (option.text.includes('Opción')) {
            return { ...option, id: i + 1, text: `Opción ${i + 1}` }
          }
          return option
        })
      const newEditingIndex = editingIndex === index ? null : editingIndex
      onUpdateQuestion(id, 'options', updatedOptions)
      setEditingIndex(newEditingIndex)
      setOptionIdCounter(updatedOptions.length + 1)
      return { ...prevFormData, options: updatedOptions }
      if (newOptionInputRef.current) {
        newOptionInputRef.current.focus()
      }
    })
  }

  const handleToggleOptions = () => {
    setShowOptions(!showOptions)
  }

  const handleDuplicate = () => {
    const duplicatedData = { ...formData }
    onDuplicate(duplicatedData)
    setShowOptions(false)
    setIsDuplicating(true)
  }

  const handleDelete = () => {
    setIsVisible(false)
    setShowOptions(false)
    onDelete(id)
  }

  const handleInputChange = (name, value, scrollHeight) => {
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
    onUpdateQuestion(id, name, value)
    const calculatedRows = Math.floor(scrollHeight / 20)
    setRows(calculatedRows)
  }
  const handleTextareaClick = () => {
    autosize.update(textareaRefQuestion.current)
  }
  const handleDescriptionClick = () => {
    autosize.update(textareaRefDescription.current)
  }

  const calculateTextareaRows = (text) => {
    const charactersPerLine = 100
    const lineCount = Math.ceil(text.length / charactersPerLine)
    return Math.min(lineCount, 3)
  }

  return showOptionsButton ? (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      className={`${classNames?.container || 'survey-text-task-container'}`}
    >
      <div className={`${classNames?.headers || 'headers'}`}>
        {showOptionsButton && (
          <div className="question-text">
            <p>Pregunta&nbsp;{questionNumber}:</p>
          </div>
        )}
        {showOptionsButton && (
          <div
            className="drag-handle drag-handle-move"
            {...provided.dragHandleProps}
            title="Arrastra para reubicar la pregunta"
          >
            <FontAwesomeIcon icon={faGripHorizontal} />
          </div>
        )}
        {showOptionsButton && (
          <div className="options-container" ref={optionsContainerRef}>
            <FontAwesomeIcon
              icon={faEllipsisH}
              className="ellipsis-icon"
              onClick={handleToggleOptions}
            />
            {showOptions && (
              <div className="options-card-surveys">
                <button onClick={handleDuplicate}>Duplicar</button>
                <div className="divider-options-card"></div>
                <button onClick={handleDelete}>Eliminar</button>
              </div>
            )}
          </div>
        )}
      </div>

      {showDescriptionsCreate && (
        <div className={`${classNames?.formsgroup || 'form-group blue-border-input'}`}>
          <div className="question-container">
            {disableQuestion && (
              <div className="">
                <b>{questionNumber}.</b>
              </div>
            )}
            <textarea
              ref={textareaRefQuestion}
              className={`${classNames?.fontsquestion || 'form-control '}`}
              id={`question-${id}`}
              name="question"
              maxLength="250"
              placeholder="Escriba el título de su pregunta"
              value={formData.question}
              onChange={(e) => handleInputChange('question', e.target.value)}
              onClick={handleTextareaClick}
              required
              disabled={disableQuestion}
              style={{ resize: 'none', minHeight: '30px', height: 'auto' }}
              rows={1}
            />
          </div>
        </div>
      )}

      {showDescriptionsCreate && (
        <div className={`${classNames?.formsgroup || 'form-group blue-border-input'}`}>
          <textarea
            ref={textareaRefDescription}
            className={`${classNames?.fontsdescriptions || 'form-control  form-control-details'}`}
            id={`descriptions-${id}`}
            name="descriptions"
            maxLength="250"
            placeholder={isDetailsSurvey ? '' : 'Descripción opcional'}
            value={formData.descriptions}
            onChange={(e) => handleInputChange('descriptions', e.target.value)}
            disabled={disableQuestion}
            onClick={handleDescriptionClick}
            style={{ resize: 'none', minHeight: '30px', height: 'auto' }}
            rows={1}
          />
        </div>
      )}

      {showDescriptionsCreate && (
        <>
          {formData.options.map((option, index) => (
            <div className={`${classNames?.surveysdetails || 'answer-container '}`} key={index}>
              <div className={`editable-option`}>
                <div className={`${classNames?.checkbox || 'radio-and-input blue-border-input '}`}>
                  <input
                    type="radio"
                    checked={option.selected}
                    onChange={() => handleSelectOption(index)}
                    className="radio-b"
                    disabled
                  />
                  <input
                    ref={(el) => {
                      if (index === editingIndex) {
                        newOptionInputRef.current = el
                      }
                    }}
                    className={`${classNames?.checkradtext || 'inputline '}`}
                    type="text"
                    maxLength="50"
                    value={formData.options[index].text}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onClick={() => setEditingIndex(index)}
                    disabled={disableQuestion}
                    required
                  />
                </div>
              </div>
              {showOptionsButton && (
                <div className="delete-button-container">
                  {formData.options.length > 1 && (
                    <button className="delete-button" onClick={() => handleRemoveOption(index)}>
                      <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                    </button>
                  )}
                </div>
              )}
            </div>
          ))}

          <div>
            {showOptionsButton && (
              <input
                className="btnsurveyoption"
                type="button"
                value="+ Agregar opción"
                onClick={handleAddOption}
              />
            )}
          </div>
        </>
      )}
    </div>
  ) : (
    <div className={`${classNames?.container || 'survey-text-task-container'}`}>
      <div className={`${classNames?.headers || 'headers'}`}>
        {showOptionsButton && (
          <div className="question-text">
            <p>Pregunta&nbsp;{questionNumber}:</p>
          </div>
        )}
      </div>

      {showDescriptionsDetails && formData.question && (
        <div className={`${classNames?.formsgroup || 'form-group blue-border-input'}`}>
          <div className="question-container">
            {disableQuestion && (
              <div className="question-number-chk-radio">
                <b>{questionNumber}.</b>
              </div>
            )}
            <div className={`${classNames?.formsgroup || 'form-group blue-border-input'}`}>
              <textarea
                className={`${classNames?.fontsquestion || 'form-control form-control-details'}`}
                id={`question-${id}`}
                name="question"
                placeholder="Escriba el título de su pregunta"
                value={formData.question}
                disabled={disableQuestion}
                style={{
                  height: 'auto',
                  wordWrap: 'break-word',
                  whiteSpace: 'pre-wrap',
                  resize: 'none'
                }}
                rows={calculateTextareaRows(formData.question)}
              />
            </div>
          </div>
        </div>
      )}

      {showDescriptionsDetails && formData.descriptions && (
        <div className={`${classNames?.formsgroup || 'form-group blue-border-input'}`}>
          <textarea
            className={`${classNames?.fontsdescriptions || 'form-control  form-control-details'}`}
            id={`descriptions-${id}`}
            name="descriptions"
            placeholder={isDetailsSurvey ? '' : 'Descripción opcional'}
            value={formData.descriptions}
            disabled={disableQuestion}
            style={{
              height: 'auto',
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
              resize: 'none'
            }}
            rows={calculateTextareaRows(formData.descriptions)}
          />
        </div>
      )}

      {showresponseDetails && (
        <>
          {formData.options.map((option, index) => (
            <div className={`${classNames?.surveysdetails || 'answer-container '}`} key={index}>
              <div className={`editable-option`}>
                <div className={`${classNames?.checkbox || 'radio-and-input blue-border-input '}`}>
                  <input
                    type="radio"
                    checked={option.selected}
                    onChange={() => handleSelectOption(index)}
                    className="radio-b"
                    disabled
                  />
                  <input
                    ref={(el) => {
                      if (index === editingIndex) {
                        newOptionInputRef.current = el
                      }
                    }}
                    className={`${classNames?.checkradtext || 'inputline '}`}
                    type="text"
                    maxLength="50"
                    value={formData.options[index].text}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onClick={() => setEditingIndex(index)}
                    disabled={disableQuestion}
                    required
                  />
                </div>
              </div>
              {showOptionsButton && (
                <div className="delete-button-container">
                  {formData.options.length > 1 && (
                    <button className="delete-button" onClick={() => handleRemoveOption(index)}>
                      <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                    </button>
                  )}
                </div>
              )}
            </div>
          ))}

          <div>
            {showOptionsButton && (
              <input
                className="btnsurveyoption"
                type="button"
                value="+ Agregar opción"
                onClick={handleAddOption}
              />
            )}
          </div>
        </>
      )}

      {showresponsePostask && (
        <>
          {formData.options.map((option, index) => (
            <div className={`${classNames?.surveysdetails || 'answer-container '}`} key={index}>
              <div className={`editable-option`}>
                <div className={`${classNames?.checkbox || 'radio-and-input blue-border-input '}`}>
                  <input
                    type="radio"
                    checked={option.selected}
                    onChange={() => handleSelectOption(index)}
                    className="radio-b"
                    disabled
                  />
                  <input
                    ref={(el) => {
                      if (index === editingIndex) {
                        newOptionInputRef.current = el
                      }
                    }}
                    className={`${classNames?.checkradtext || 'inputline '}`}
                    type="text"
                    maxLength="50"
                    value={formData.options[index].text}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onClick={() => setEditingIndex(index)}
                    disabled={!option.selected}
                    required
                  />
                </div>
              </div>
              {showOptionsButton && (
                <div className="delete-button-container">
                  {formData.options.length > 1 && (
                    <button className="delete-button" onClick={() => handleRemoveOption(index)}>
                      <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                    </button>
                  )}
                </div>
              )}
            </div>
          ))}

          <div>
            {showOptionsButton && (
              <input
                className="btnsurveyoption"
                type="button"
                value="+ Agregar opción"
                onClick={handleAddOption}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}
export default SurveyComponentUnique