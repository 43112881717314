import React, { useState, useEffect, useRef } from 'react'
import { FixedSizeList as List } from 'react-window'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { replaceAccentedCharacters } from '../../helpers/utils'

export default function ReactCheckboxFilter({
  title,
  options,
  identifier,
  resetWatcher,
  isRequired,
  onSelectionChange,
  disabled
}) {
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState(new Set())
  const [search, setSearch] = useState('')
  const filterContainer = useRef(null)

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => document.removeEventListener('mousedown', handleOutsideClick)
  }, [])

  useEffect(() => {
    if (disabled) {
      setDropdownOpen(false)
    }
  }, [disabled])

  useEffect(() => {
    // Reset states when resetWatcher changes
    setSelectedOptions(new Set())
  }, [resetWatcher])

  const handleOutsideClick = (e) => {
    if (filterContainer.current && !filterContainer.current.contains(e.target)) {
      setDropdownOpen(false)
    }
  }

  const selectOption = (option) => {
    if (disabled) return
    const newSelectedOptions = new Set(selectedOptions)
    if (newSelectedOptions.has(option)) {
      newSelectedOptions.delete(option)
    } else {
      newSelectedOptions.add(option)
    }
    setSelectedOptions(newSelectedOptions)

    onSelectionChange(identifier, newSelectedOptions)
  }

  const filteredOptions = options.filter(
    (option) =>
      option != null &&
      replaceAccentedCharacters(option.toString()).includes(replaceAccentedCharacters(search))
  )

  const Row = ({ index, style }) => {
    const option = filteredOptions[index]
    return (
      <div style={style} className="react-filter-option" onClick={() => selectOption(option)}>
        <input
          type="checkbox"
          className="filter-checkbox"
          readOnly
          checked={selectedOptions.has(option)}
          disabled={disabled}
        />
        <div className="filter-checkbox-label">{option}</div>
      </div>
    )
  }

  return (
    <div
      className={`react-filter-box ${disabled ? 'react-filter-disabled' : ''}`}
      ref={filterContainer}
    >
      <div
        title={title}
        onClick={() => !disabled && setDropdownOpen(!isDropdownOpen)}
        className="react-filter-box-clickable-zone"
      >
        <div className="react-filter-titles">
          <div className="filter-title-overflow">{title.toUpperCase()}</div>
          {isRequired && selectedOptions.size == 0 && <span className="text-red">*</span>}
          {selectedOptions.size > 0 && (
            <span className="text-orange">({selectedOptions.size})</span>
          )}
          <FontAwesomeIcon icon={!isDropdownOpen ? faChevronDown : faChevronUp} />
        </div>
      </div>
      {isDropdownOpen && (
        <div className="react-filter-dropdown-box box-transition" style={{ maxHeight: `135px` }}>
          <div className="react-filter-option-nohover">
            <div className="filter-subtitle">Filtrar por:</div>
          </div>
          <List height={300} itemCount={filteredOptions.length} itemSize={35} width="100%">
            {Row}
          </List>
        </div>
      )}
    </div>
  )
}
