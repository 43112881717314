import React, { useState, useEffect } from 'react'

const GlobalContext = React.createContext()

const GlobalProvider = ({ children, location, globalEdit, stateEdit }) => {
  const [editGlobal, setEditGlobal] = useState(false)
  const [ecommerceEdit, setEcommerceEdit] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [activeTab, setActiveTab] = useState(location === 'detalles' ? 'Detalles' : 'E-Commerce')
  const [dbRef, setDbRef] = useState('')
  const [customName, setCustomName] = useState('')
  const [id, setId] = useState(
    window.location.href.split('/')[window.location.href.split('/').length - 2]
  )

  useEffect(() => {
    setEditGlobal(globalEdit)
  }, [globalEdit])

  useEffect(() => {
    setEditGlobal(stateEdit)
  }, [stateEdit])

  // Este es un botón (cancelar)
  const handleTabClick = (event, data) => {
    // Verifica si el elemento clicado es un enlace (<a>) para redirigir a rutas
    if (event.target.tagName === 'A') {
      if (!editGlobal) {
        setActiveTab(event.target.innerText)
      } else {
        const confirmation = window.confirm(
          '¿Está seguro que quiere continuar? Los cambios editados no serán guardados'
        )
        if (confirmation) {
          setActiveTab(event.target.innerText)
          setEditGlobal(false)
        } else {
          event.preventDefault()
        }
      }
    }
  }

  const handleEditingClient = (event) => {
    if (event.target.value === 'Actualizar cliente') {
      setEditGlobal(true)
    } else if (event.target.value === 'Cancelar') {
      const confirmation = window.confirm(
        '¿Está seguro que quiere continuar? Los cambios editados no serán guardados'
      )
      if (confirmation) {
        setEditGlobal(false)
      }
    }
  }

  return (
    <GlobalContext.Provider
      value={{
        globalEdit: editGlobal,
        dbRef: dbRef,
        customName: customName,
        activeTab: activeTab,
        handleTabClick: handleTabClick,
        handleEditingClient: handleEditingClient,
        isEditClient: isEditing
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

const GlobalConsumer = GlobalContext.Consumer

export { GlobalProvider, GlobalConsumer, GlobalContext }
