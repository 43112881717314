import React, { useState, useEffect } from 'react'
import NavBar from '../../layout/NavBar'
import TopBar from '../../layout/TopBar'
import cookie from 'react-cookies'
import PosDetailsView from './PosDetailsView'

const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
const BACKEND_URL = USE_BUSINESS_URL
  ? `${process.env.REACT_APP_BACKEND_URL}/businesses/${cookie.load('business_id')}`
  : process.env.REACT_APP_BACKEND_URL

const PosDetailsApp = (props) => {
  const [searchPlaceholder, setSearchPlaceholder] = useState('')
  const [id, setId] = useState(window.location.href.split('/').pop())
  const [isFetching, setIsFetching] = useState(true)
  const [query, setQuery] = useState('')
  const [details, setDetails] = useState(null)
  const [defaultUuid, setDefaultUuid] = useState(null)
  const [defaultDetails, setDefaultDetails] = useState(null)
  const [collapsed, setCollapsed] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetchTransactionDetails()
    const collapsedState = localStorage.getItem('collapsedState') === 'true'
    setCollapsed(collapsedState)
  }, [])

  const fetchTransactionDetails = () => {
    setIsFetching(true)

    fetch(`${BACKEND_URL}/pos_tasks/${props.match.params.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookie.load('token')}`
      }
    })
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        if (response.status === 404) {
          return {
            error: true,
            message: 'No se encontró la tarea'
          }
        }
        return response.json()
      })
      .then((data) => {
        if (data.error) {
          setError(data.message)
          setIsFetching(false)
        } else {
          setDetails(data)
          setDefaultUuid(data?.uuid)
          setDefaultDetails(data)
          setIsFetching(false)
        }
      })
      .catch((e) => console.log(e))
  }

  const handleSearch = (event, isEditing) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      const queryValue = event.target.value
      if (queryValue !== '') {
        if (isEditing) {
          const isConfirmation = window.confirm(
            'Esta seguro que quiere continuar? Los cambios editados no seran guardados'
          )
          if (isConfirmation === true) {
            window.location = `/pedidos?query=${queryValue}`
          }
        } else {
          window.location = `/pedidos?query=${queryValue}`
        }
        event.target.value = ''
      }
    }
  }

  const handleToggleCollapse = (collapsed) => {
    setCollapsed(collapsed)
    localStorage.setItem('collapsedState', collapsed)
  }

  return (
    <div>
      {!isFetching ? (
        <div>
          <div>
            <div>
              <div onKeyDown={(e) => handleSearch(e, false)}>
                <TopBar
                  searchPlaceholder={searchPlaceholder}
                  userName={props.userName}
                  onToggleCollapse={handleToggleCollapse}
                  collapsed={collapsed}
                />
              </div>
              <div>
                {error ? (
                  <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
                    <div className="main-show-header">
                      <p>{error}</p>
                    </div>
                  </div>
                ) : (
                  <PosDetailsView
                    details={details}
                    position={props.match.params.id}
                    defaultDetails={defaultDetails}
                    collapsed={collapsed}
                  />
                )}
              </div>
            </div>
            <div>
              <NavBar
                activeItem="Ejecución en punto de venta"
                isEditing={false}
                collapsed={collapsed}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <TopBar
            searchPlaceholder={searchPlaceholder}
            userName={props.userName}
            onToggleCollapse={handleToggleCollapse}
            collapsed={collapsed}
          />
          <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
            <div className="show-title">
              <p>Obteniendo datos ....</p>
            </div>
          </div>
          <div>
            <NavBar
              activeItem="Ejecución en punto de venta"
              isEditing={false}
              collapsed={collapsed}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default PosDetailsApp
