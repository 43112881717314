import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import ShowPriceListView from './ShowPriceListView'

export default function ShowPriceListApp() {
  const [userName, setUserName] = useState('Ana Sofia Lau Hou');
  const [searchPlaceholder, setSearchPlaceholder] = useState('Buscar listas de precios por código o nombre');
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const collapsedState = localStorage.getItem('collapsedState') === 'true';
    setCollapsed(collapsedState);
  }, []);

  const handleSearch = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      const query = event.target.value;
      if (query !== '') {
        window.location = `/listas_de_precios?query=${query}`;
        event.target.value = '';
      }
    }
  };

  const handleToggleCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed);
    localStorage.setItem('collapsedState', newCollapsed);
  };

  return (
    <div>
      <div>
        <div onKeyDown={handleSearch}>
          <TopBar
            searchPlaceholder={searchPlaceholder}
            userName={userName}
            onToggleCollapse={handleToggleCollapse}
            collapsed={collapsed}
          />
        </div>
        <div>
          <ShowPriceListView collapsed={collapsed} />
        </div>
      </div>
      <div>
        <NavBar activeItem="Listas de Precios" isEditing={false} collapsed={collapsed} />
      </div>
    </div>
  );
}