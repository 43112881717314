import React, { useState, useEffect } from 'react'
import cookie from 'react-cookies'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import ClientApplicationDetails from './ClientApplicationDetails'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const ClientApplicationApp = () => {
  const [isFetching, setIsFetching] = useState(true)
  const [data, setData] = useState(null)
  const [collapsed, setCollapsed] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  ////// Stops fetchs when page is changed
  const abortController = new AbortController()
  const abortCurrentFetchs = () => {
    abortController.abort()
  }
  window.addEventListener('beforeunload', abortCurrentFetchs)
  //////

  const setQueryParams = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const success = urlParams.get('success') || ''
    if (success) {
      setShowSuccessMessage(true)
    }
  }

  const hideSuccessAlert = () => {
    setShowSuccessMessage(false)
  }

  const handleToggleCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed)
    localStorage.setItem('collapsedState', newCollapsed)
  }

  useEffect(() => {
    const collapsedFromStorage = localStorage.getItem('collapsedState') === 'true'
    setCollapsed(collapsedFromStorage)
    setQueryParams()
  }, [])

  useEffect(() => {
    async function fetchAll() {
      await fetchDataFromServer()
    }
    fetchAll()
  }, [])

  const fetchDataFromServer = async () => {
    setIsFetching(true)
    const response = await fetch(
      `${BACKEND_URL}/businesses/${cookie.load('business_id')}/clients_applications`,
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
    if (!response.ok) {
      return console.error(`Error in ClientApplicationApp.js: ${response.status}`)
    }
    const data = await response.json()
    if (!Array.isArray(data) || data.length === 0) {
      return (window.location = `/crear_solicitud_cliente_nuevo`)
    } else {
      setData(data)
      setIsFetching(false)
    }
  }

  return (
    <div>
      {isFetching && (
        <div>
          <TopBar
            searchPlaceholder={''}
            onToggleCollapse={handleToggleCollapse}
            collapsed={collapsed}
          />
          <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
            <div className="show-title">
              <p>Obteniendo datos ....</p>
            </div>
          </div>
          <div>
            <NavBar activeItem="Clientes" isEditing={false} collapsed={collapsed} />{' '}
          </div>
        </div>
      )}
      {!isFetching && (
        <div>
          <div>
            <div>
              <TopBar
                searchPlaceholder={''}
                onToggleCollapse={handleToggleCollapse}
                collapsed={collapsed}
              />
            </div>
            <div>
              <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
                {showSuccessMessage && (
                  <div className="alert alert-success" role="alert">
                    <button
                      type="button"
                      className="btn-close"
                      onClick={hideSuccessAlert}
                      aria-label="Close"
                    ></button>
                    <strong className="success-message">
                      La solicitud de cliente nuevo fue creada exitosamente
                    </strong>
                  </div>
                )}
                <div className="main-show-header">
                  <h2>Solicitud de cliente nuevo</h2>
                </div>
                <div className="index-table">
                  <div className="show-area">
                    <div>
                      <ClientApplicationDetails data={data} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <NavBar activeItem="Clientes" isEditing={false} collapsed={collapsed} />{' '}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ClientApplicationApp
