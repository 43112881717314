import React, { createContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { handleErrors } from '../services/apiProductService'
import { handleTabClick } from '../helpers/UserConfigHandlers'
import * as apiStatusCodes from '../../constants/apiStatusCodes'
import cookie from 'react-cookies'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { getProductInfoToCreate } from '../services/apiProductService'

const initialState = {}

const ProductCreateContext = createContext(initialState)

const ProductCreateProvider = (props) => {
  const [failedCreationInfo, setFailedCreationInfo] = useState(null)
  const [formIsValid, setFormIsValid] = useState(false)

  const [isFetching, setFetching] = useState(true)
  const [newProductInfo, setNewProductInfo] = useState({})
  const [formMessage, setFormMessage] = useState('')

  const [images, setImages] = React.useState([])
  const maxNumber = 1

  // Form validation rules
  const validationSchema = Yup.object().shape({
    active: Yup.bool().default(true),
    code: Yup.string()
      .required('Este es un campo obligatorio.')
      .min(3, 'Asegúrese de cumplir con el mínimo de los 3 caracteres'),
    barcode: Yup.string()
      .required('Este es un campo obligatorio.')
      .min(3, 'Asegúrese de cumplir con el mínimo de los 3 caracteres'),
    name: Yup.string()
      .required('Este es un campo obligatorio.')
      .min(3, 'Asegúrese de cumplir con el mínimo de los 3 caracteres'),
    description: Yup.string().notRequired().nullable(),
    itbms: Yup.string().required('Este es un campo obligatorio.'),
    brand: Yup.string().required('Este es un campo obligatorio.'),
    unit: Yup.string().required('Este es un campo obligatorio.'),
    unitQuantity: Yup.number()
      .typeError('Este es un campo obligatorio.')
      .min(1, 'Debe ser mayor a 0'),
    splitUnit: Yup.bool().when('unitQuantity', {
      is: (val) => parseInt(val) <= 1 || isNaN(parseInt(val)),
      then: (schema) => schema.oneOf([false], 'La unidad de venta debe ser mayor a 1'),
      otherwise: (schema) => schema
    }),
    unitWeight: Yup.string().notRequired().nullable(),
    weight: Yup.number()
      .transform((value) => (isNaN(value) ? 0 : value))
      .notRequired()
      .nullable(),
    category: Yup.string().required('Este es un campo obligatorio.'),
    subCategory: Yup.string().notRequired().nullable(),
    image: Yup.string().notRequired().nullable()
  });

  const formOptions = { resolver: yupResolver(validationSchema) }
  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors, isValid } = formState

  const history = useHistory()

  useEffect(() => {
    getProductInfo()
  }, [])

  const getProductInfo = () => {
    setFetching(true)
    getProductInfoToCreate()
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((data) => {
        setFetching(false)
        setNewProductInfo(data)
      })
      .catch((error) => {
        if (error.status === apiStatusCodes.UNAUTHORIZED) {
          cookie.remove('token', { path: '/' })
          window.location = '/'
        }
      })
  }

  const handleCancelStep = (event) => {
    const value = handleTabClick(event, true);
    if (value?.confirm) {
      window.location.href = '/productos';
    }
  };
  const onChangeImage = (imageList, addUpdateIndex) => {
    setImages(imageList)
  }

  return (
    <ProductCreateContext.Provider
      value={{
        failedCreationInfo,
        formIsValid,
        newProductInfo,
        formMessage,
        errors,
        register,
        handleSubmit,
        isValid,
        onChangeImage,
        images,
        maxNumber,
        handleCancelStep
      }}
    >
      {props.children}
    </ProductCreateContext.Provider>
  )
}
export { ProductCreateContext, ProductCreateProvider }
