import React, { useState, useEffect } from 'react'

function TaskDescription({ initialDescription, onDescriptionChange }) {
  const [description, setDescription] = useState(initialDescription);


  useEffect(() => {console.log(initialDescription)}, [initialDescription])

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    onDescriptionChange(event.target.value);
  };

  return (
    <div className="promo-form-section">
      <div className="title-promos-form">
      <b class="bold-step">
          PASO 2: Escriba una descripción de la tarea que será visible para sus colaboradores en
          la aplicación
        </b>
      </div>
      <hr className="hr-promos-form" />
      <div className="form-group form-control-width">
        <input
          type="text"
          maxLength="40"
          className="form-control"
          id="description"
          name="description"
          onChange={handleDescriptionChange}
          value={initialDescription}
          placeholder="Ejemplo Tarea marca Ubiqua"
          required
        />
        <p className="small text-muted mt-10 pull-right">
          Cantidad máxima de caracteres {Math.abs(description.length - 40)}/40
        </p>
      </div>
    </div>
  );
}

export default TaskDescription;
