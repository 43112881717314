import React, { createContext, useReducer } from 'react'
import { BasePromoReducer } from './BasePromoReducer'

export const BasePromoContext = createContext()

const BasePromoContextProvider = ({ children }) => {
  const [baseProducts, dispatch] = useReducer(BasePromoReducer, [], () => [])

  const addBaseProduct = (payload) => {
    dispatch({ type: 'ADD_TO_BASE_PRODUCT', payload })
  }

  const changeBaseQuantityProduct = (payload) => {
    dispatch({ type: 'CHANGE_BASE_QUANTITY_PRODUCT', payload })
  }

  const changeBasePackageProduct = (payload) => {
    dispatch({ type: 'CHANGE_BASE_PACKAGE_PRODUCT', payload })
  }

  const removeBaseProduct = (payload) => {
    dispatch({ type: 'REMOVE_BASE_PRODUCT', payload })
  }

  const clearBaseProduct = () => {
    dispatch({ type: 'CLEAR_BASE_PRODUCT' })
  }

  const contextValues = {
    addBaseProduct,
    removeBaseProduct,
    changeBaseQuantityProduct,
    changeBasePackageProduct,
    clearBaseProduct,
    baseProducts
  }

  return <BasePromoContext.Provider value={contextValues}>{children}</BasePromoContext.Provider>
}

export default BasePromoContextProvider
