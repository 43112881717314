const validate = (value, rules, valueToCompare) => {
  let isValid = true
  let message = ''

  for (const rule in rules) {
    switch (rule) {
      case 'minLength':
        isValid = isValid && minLengthValidator(value, rules[rule])
        message = 'Por favor introduzca los campos obligatorios.'
        break
      case 'isRequired':
        isValid = isValid && requiredValidator(value)
        message = 'Por favor introduzca los campos obligatorios.'
        break
      case 'isNumRequired':
        isValid = isValid && requiredNumValidator(value)
        message = 'Por favor introduzca los campos obligatorios.'
        break
      case 'isEmail':
        isValid = isValid && emailValidator(value)
        message = 'En el campo de correo electrónico, el formato no es válido'
        break
      case 'isPassword':
        isValid = isValid && minLengthValidator(value, rules[rule])
        message = 'En el campo de contraseña, el formato no es válido.'
        break
      case 'isPasswordMatch':
        isValid = isValid && value === valueToCompare
        message =
          'El valor en los campos de contraseña y reescribir contraseña deben de ser iguales.'
        break
      default:
        isValid = true
    }
  }
  return {
    isValid: isValid,
    message: message
  }
}

/**
 * minLength Val
 * @param  value
 * @param  minLength
 * @return
 */
const minLengthValidator = (value, minLength) => value.length >= minLength

/**
 * Check to confirm that feild is required
 *
 * @param  value
 * @return
 */
const requiredValidator = (value) => {
  return value.trim() !== ''
}

/**
 * Check to confirm that feild is required
 *
 * @param  value
 * @return
 */
const requiredNumValidator = (value) => value !== 0 || value !== null
/**
 * Email validation
 *
 * @param value
 * @return
 */
const emailValidator = (value) => {
  var re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(value).toLowerCase())
}

export default validate
