import React, { useState, useEffect } from 'react'
import { STATUS_DIC, TASK_TYPE_DIC, PROD_TYPE_DIC } from '../constants/constants'
import { capitalizeFirstLetterFrom } from '../../helpers/utils'
import cookie from 'react-cookies'
import ImageModal from '../../components/image_modal/ImageModal'
import PosDetailsSurvey from './PosDetailsSurvey'

const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
const BACKEND_URL = USE_BUSINESS_URL
  ? `${process.env.REACT_APP_BACKEND_URL}/businesses/${cookie.load('business_id')}`
  : process.env.REACT_APP_BACKEND_URL

const PosDetailsTab = ({ activity: initialActivity, transactionUuid }) => {
  const [activity, setActivity] = useState(initialActivity || [])
  const [suspending, setSuspending] = useState(false)
  const [showImage, setShowImage] = useState(false)

  useEffect(() => {
    setActivity(initialActivity)
  }, [initialActivity])

  const getFormattedDateOnly = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleDateString(undefined, { timeZone: 'utc' })
  }

  const getFormattedDate = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  const getClients = (clientNames) => {
    return clientNames?.length > 0 ? clientNames.join(', ') : 'No se encontraron clientes'
  }

  const getQualifiers = (qualifiers) => {
    return qualifiers?.length > 0 ? `${qualifiers.join(', ')} ` : '-'
  }

  const handleShowImage = (value) => {
    setShowImage(value)
  }

  const suspendTask = () => {
    setSuspending(true)
    const letsGo = confirm('¿Está seguro de que desea continuar con la suspensión de esta tarea?')
    if (letsGo) {
      fetch(`${BACKEND_URL}/pos_tasks/${activity['uuid']}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          business_uid: activity['business_uid'],
          suspended: true,
          uuid: activity['uuid']
        })
      })
        .then((response) => {
          if (response.status === 401) {
            window.location = '/'
          }
          if (response.status === 404) {
            return {
              error: true,
              message: 'No se encontró la tarea'
            }
          }
          return response.json()
        })
        .then((data) => {
          //Elimina del url success=true por lo que permite desaparecer el mensaje Exitoso
          if (data['suspended']) {
            const urlParams = new URLSearchParams(window.location.search)
            const successParam = urlParams.get('success')
            if (successParam === 'true') {
              const newURL = window.location.href.replace('?success=true', '')
              window.history.replaceState({}, document.title, newURL)
              window.location.reload()
            }
            window.location.reload()
          }
        })
        .catch((e) => console.log(e))
        .finally(() => setSuspending(false))
    } else {
      setSuspending(false)
    }
  }

  const cloneTask = () => {
    const letsGo = confirm('¿Está seguro de crear una copia a partir de esta tarea?')
    if (letsGo) window.location = `/crear_tarea_punto_venta?clone=${activity['uuid']}`
  }

  return (
    <div className="container-fluid">
      <div className="row">
        {transactionUuid && (
          <>
            <div className="d-flex align-flex-center justify-flex-between">
              <p className="details-title">Detalles de tarea en punto de venta:</p>
              <div>
                {!activity['suspended'] && (
                  <button
                    disabled={suspending}
                    onClick={suspendTask}
                    type="submit"
                    className="ubiqua-red-btn-outline mr-10"
                  >
                    Suspender tarea
                  </button>
                )}
                <button
                  disabled={suspending}
                  type="submit"
                  onClick={cloneTask}
                  className="ubiqua-primary-btn"
                >
                  Crear copia de tarea
                </button>
              </div>
            </div>
          </>
        )}
        <div>
          <hr />
          <div>
            <table cellSpacing="10">
              <tbody>
                <tr>
                  <td>
                    <div className="mr-95 mb-20">
                      <b>Fecha de la tarea:</b>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex">
                      <div className="mr-50 mb-10">
                        Inicio: <br />
                        {getFormattedDateOnly(activity['start_date'])}
                      </div>
                      <div className="mb-20">
                        Final: <br />
                        {activity['end_date']
                          ? getFormattedDateOnly(activity['end_date'])
                          : 'Indefinida'}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="mr-95 mb-20">
                      <b>Código de la tarea:</b>
                    </div>
                  </td>
                  <td>
                    <div className="mb-20">{activity['short_uuid']}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="mr-95 mb-20">
                      <b>Tipo de tarea:</b>
                    </div>
                  </td>
                  <td>
                    <div className="mb-20">
                      {TASK_TYPE_DIC[activity['task_type']] || activity['task_type']}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="mr-95 mb-20">
                      <b>Descripción de la tarea:</b>
                    </div>
                  </td>
                  <td>
                    <div className="mb-20">{activity['description']}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="mr-95 mb-20">
                      <b>Cantidad de subtareas:</b>
                    </div>
                  </td>
                  <td>
                    <div className="mb-20">{activity['task_quantity'] || 0}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="mr-95 mb-20">
                      <b>Cliente o Atributo(s):</b>
                    </div>
                  </td>
                  <td>
                    <div className="mb-20">
                      {activity['qualifier_1'].length > 0 && <span>Cliente individual</span>}
                      {(!activity['qualifier_1'] || activity['qualifier_1'].length === 0) && (
                        <span>Atributo(s)</span>
                      )}
                    </div>
                  </td>
                </tr>
                {activity['qualifier_1'].length > 0 && (
                  <tr>
                    <td>
                      <div className="mr-95 mb-20">
                        <b>Nombre de cliente(s):</b>
                      </div>
                    </td>
                    <td>
                      <div className="line-space-postask">{getClients(activity['client_names'])}</div>
                    </td>
                  </tr>
                )}
                {activity['qualifier_2'].length > 0 && (
                  <tr>
                    <td>
                      <div className="mr-95 mb-20">
                        <b>Atributo A:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">{getQualifiers(activity['qualifier_2'])}</div>
                    </td>
                  </tr>
                )}
                {activity['qualifier_3'].length > 0 && (
                  <tr>
                    <td>
                      <div className="mr-95 mb-20">
                        <b>Atributo B:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">{getQualifiers(activity['qualifier_3'])}</div>
                    </td>
                  </tr>
                )}
                {activity['qualifier_4'].length > 0 && (
                  <tr>
                    <td>
                      <div className="mr-95 mb-20">
                        <b>Atributo C:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">{getQualifiers(activity['qualifier_4'])}</div>
                    </td>
                  </tr>
                )}
                {activity['task_type'] !== 'survey' &&
                  activity['task_type'] !== 'price_capture' && (
                    <>
                      <tr>
                        <td>
                          <div className="mr-95 mb-20">
                            <b>Tipo de productos:</b>
                          </div>
                        </td>
                        <td>
                          <div className="mb-20">
                            {PROD_TYPE_DIC[activity['products_selection_type']] ||
                              (activity['products_selection_type']
                                ? activity['products_selection_type']
                                : 'No se han agregado productos')}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="mr-95 mb-20">
                            <b>Producto(s) de la tarea:</b>
                          </div>
                        </td>
                        <td>
                          <div className="mb-20">
                            {activity['products_selection_type'] === 'brand' &&
                              'Marca ' + activity['brand_name']}
                            {activity['products_selection_type'] === 'category' &&
                              'Categoría ' + activity['category_name']}
                            {activity['products_selection_type'] === 'individual_products' &&
                              activity['product_names'].join(', ')}
                            {activity['products_selection_type'] === '' && '-'}
                          </div>
                        </td>
                      </tr>
                    </>
                  )}

                {activity['task_type'] === 'price_capture' && (
                  <>
                    <tr>
                      <td>
                        <div className="mr-95 mb-20">
                          <b>Producto(s) propio(s):</b>
                        </div>
                      </td>
                      <td>
                        <div className="line-space-postask">
                          {activity.product_names.length > 0
                            ? activity.product_names
                                .map((product) => product.replace(/^(\w+)/, '$1 -'))
                                .join(', ')
                            : 'N/A'}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="mr-95 mb-20">
                          <b>Producto(s) competencia:</b>
                        </div>
                      </td>
                      <td>
                        <div className="line-space-postask">
                          {activity.competition_products.length > 0
                            ? activity.competition_products
                                .map(
                                  (product) =>
                                    `${product.name.toUpperCase()} - ${product.package.toUpperCase()}`
                                )
                                .join(', ')
                            : 'N/A'}
                        </div>
                      </td>
                    </tr>
                  </>
                )}

                {activity['task_type'] === 'planimetry' && (
                  <>
                    <tr>
                      <td>
                        <div className="mr-95 mb-20">
                          <b>Imagen:</b>
                        </div>
                      </td>
                      <td>
                        <div className="mb-20">
                          {activity['image_url'] ? (
                            <button
                              type="button"
                              className="img-view-buttom"
                              style={{ margin: 0, padding: 0 }}
                              onClick={() => handleShowImage(true)}
                            >
                              Ver imagen
                            </button>
                          ) : (
                            'No se ha agregado ninguna imagen'
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="vertical-align-top">
                        <div className="mr-95 mb-20">
                          <b>Instrucciones:</b>
                        </div>
                      </td>
                      <td>
                        <div className="mb-20" style={{ whiteSpace: 'pre-line' }}>
                          {activity['instructions']
                            ? activity['instructions']
                            : 'No se han agregado instrucciones'}
                        </div>
                      </td>
                    </tr>
                  </>
                )}
                <tr>
                  <td>
                    <div className="mr-95 mb-20">
                      <b>Recurrencia:</b>
                    </div>
                  </td>
                  <td>
                    <div className="mb-20">
                      {capitalizeFirstLetterFrom(activity['recurrence_description'])}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="mr-95 mb-20">
                      <b>Estado de la tarea:</b>
                    </div>
                  </td>
                  <td>
                    <div className="mb-20">
                      {STATUS_DIC[activity['status']] || activity['status']}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="mr-95 mb-20">
                      <b>Fecha de creación:</b>
                    </div>
                  </td>
                  <td>
                    <div className="mb-20">{getFormattedDate(activity['created_at'])}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="mr-95 mb-20">
                      <b>Fecha de actualización:</b>
                    </div>
                  </td>
                  <td>
                    <div className="mb-20">{getFormattedDate(activity['updated_at'])}</div>
                  </td>
                </tr>
              </tbody>
            </table>
            {activity['task_type'] === 'survey' && (
              <div>
                <PosDetailsSurvey data={activity.surveys} />
              </div>
            )}
          </div>
        </div>
      </div>
      {activity['task_type'] === 'planimetry' && activity['image_url'] && (
        <div>
          <ImageModal
            imgSrc={activity['image_url']}
            showImage={showImage}
            setShowImage={handleShowImage}
          />
        </div>
      )}
    </div>
  )
}

export default PosDetailsTab
