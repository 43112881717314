import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { formatMoney } from '../../../helpers/utils'

const MapPoint = (props) => {
  const {
    color,
    name,
    isReport,
    createdDate,
    activityName,
    effectiveLocation,
    effectiveClientLocation,
    id,
    monto
  } = props


  const isEffectiveLocation = () => {
    return !effectiveClientLocation && effectiveLocation;
  }
  
  const getPinClass = () => {

    if (effectiveClientLocation) {
      return 'none-geofence';
    }
    const effective = isEffectiveLocation();    
    return effective ? 'inside-geofence' : 'outside-geofence';
  };

  const pinClass = getPinClass()

  const markerVariants = {
    hidden: {
      opacity: 0,
      scale: 0,
      transformOrigin: 'center center'
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.1,
        duration: 0.1,
        delay: 0.2
      }
    },
    exit: {
      opacity: 0,
      scale: 0,
      transformOrigin: 'center center',
      transition: {
        when: 'afterChildren',
        staggerChildren: 0.05,
        staggerDirection: -1,
        delay: 0.1
      }
    }
  }

  const formatDate = (dateString) => {
    const parsedDate = Date.parse(dateString);
    if (!dateString || isNaN(parsedDate)) {
      return dateString;
    }
    const date = new Date(parsedDate);
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    return date.toLocaleDateString('es-ES', options);
  }
  
  return (
    <div className="map-point-container">
      <div
        className={`pin-activity-map bounce ${pinClass}`}
        style={{ backgroundColor: color, cursor: '' }}
      >
        <div className={`pin-id ${pinClass}`}>{id}</div>
      </div>
      <div className="pulse-map" />

      <span>
        <AnimatePresence mode="wait">
          {(props.$hover || props.hover) && (
            <motion.div
              variants={markerVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="sale-marker-info-wrapper-1"
              style={{ textAlign: 'left' }}
            >
              <motion.div>
              <div>
              <b className="text-tilte-modal-point">Cliente: {name}</b>
                </div>
                <div className="small-text-modal-point">Fecha y hora: {formatDate(createdDate)}</div>
                <div className="small-text-modal-point">Tipo de actividad: {activityName}</div>
                {monto !== null && <div className="small-text-modal-point">Monto: {formatMoney(monto)}</div>}
                <div className="small-text-modal-point">
                  Dentro de geocerca: <span className="small-text-modal-point">{effectiveClientLocation ? 'N/A' : isEffectiveLocation() ? 'Sí' : 'No'}
                  </span>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
        <motion.span whileTap={{ scale: 1.1 }} className="marker-indicator" />
      </span>
    </div>
  )
}

export default MapPoint