import React, { useState, useEffect } from 'react'
import cookie from 'react-cookies'
import { endsVowel } from '../helpers/utils'

const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
const BACKEND_URL = USE_BUSINESS_URL
  ? `${process.env.REACT_APP_BACKEND_URL}/businesses/${cookie.load('business_id')}`
  : process.env.REACT_APP_BACKEND_URL

const ShowPromoView = (props) => {
  const [isFetching, setIsFetching] = useState(true)
  const [detailsData, setDetailsData] = useState(null)
  const [id, setId] = useState(window.location.href.split('/').pop())

  useEffect(() => {
    fetchDetailsFromServer()
  }, [id])

  const fetchDetailsFromServer = () => {
    setIsFetching(true)
    fetch(`${BACKEND_URL}/promos/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookie.load('token')}`
      }
    })
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => {
        setDetailsData(data)
        setIsFetching(false)
      })
      .catch((e) => console.log(e))
  }

  const suspendPromotion = () => {
    const response = window.confirm('Desea continuar con la suspensión de la promoción?')
    if (response === true) {
      fetch(`${BACKEND_URL}/promos/${id}`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookie.load('token')}`
        }
      })
        .then(() => (window.location = `/promociones/${id}`))
        .catch((e) => console.log(e))
    }
  }

  const copyPromotion = () => {
    const response = window.confirm('¿Está seguro de crear una copia a partir de esta promoción?')
    if (response === true) {
      window.location = `/nueva_promo?clone=${id}`
    }
  }

  const promotionDetails = () => {
    if (detailsData['applies_to_type'] === 'client') {
      if (detailsData['applies_to'][0]) {
        return `[${detailsData['external_promo_db_ref']}] ${detailsData['applies_to'][0]['db_ref']} - ${detailsData['applies_to'][0]['name']}`
      }
      return ' '
    }

    if (detailsData['applies_to_type'] === 'qualifier') {
      return `[${detailsData['external_promo_db_ref']}] Atributo(s)`
    }

    return ''
  }

  const getFormattedDate = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  const getPromoStatus = (status) => {
    switch (status) {
      case 'active':
        return 'Activa'
      case 'expired':
        return 'Caducada'
      case 'scheduled':
        return 'No Iniciada'
      case 'suspended':
        return 'Suspendida'
      default:
        return 'Desconocido'
    }
  }

  const promoStatus = detailsData ? getPromoStatus(detailsData['status']) : 'Desconocido'

  const formatDate = (dateString) => {
    return new Date(dateString).toISOString().split('T')[0].replace(/-/g, '/')
  }

  if (isFetching) {
    return (
      <div className={`main-view ${props.collapsed ? 'collapsed' : ''}`}>
        <div className="show-title">
          <p>Obteniendo datos ....</p>
        </div>
      </div>
    )
  }

  return (
    <div className={`main-view ${props.collapsed ? 'collapsed' : ''}`}>
      <div className="main-show-header">
        <h2>Promoción: {promotionDetails()}</h2>
      </div>
      <div>
        <div className="index-table">
          <div className="show-area">
            <div className="show-header">
              <div className="d-flex align-flex-center justify-flex-between">
                <p className="details-title-promo">Detalles de promoción:</p>
                <div className="show-buttons-promo">
                  {(detailsData['status'] === 'active' ||
                    detailsData['status'] === 'scheduled') && (
                    <input
                      type="submit"
                      className="suspend-button"
                      onClick={suspendPromotion}
                      value="Suspender promoción"
                    />
                  )}
                  <input
                    type="submit"
                    className="copy-promo-button"
                    onClick={copyPromotion}
                    value="Crear copia de promoción"
                  />
                </div>
              </div>
            </div>
            <hr className="mt--30" />
            <div>
              <div className="promo-show-data">
                <div>
                  <b>Fecha de la promoción:</b>
                </div>
                <div style={{ height: 'auto' }}>
                  <div>Inicio</div>
                  <div>{formatDate(detailsData['start_time'])}</div>
                </div>
                <div style={{ height: 'auto' }}>
                  <div>Fin</div>
                  <div>{formatDate(detailsData['end_time'])}</div>
                </div>
              </div>
              <div className="promo-show-data">
                <div>
                  <b>Código de promoción:</b>
                </div>
                <div style={{ height: 'auto' }}>{detailsData['external_promo_db_ref']}</div>
              </div>
              <div className="promo-show-data">
                <div>
                  <b>Descripción:</b>
                </div>
                <div style={{ height: 'auto' }}>
                  {detailsData.description ? detailsData.description : '-'}
                </div>
              </div>
              <div className="promo-show-data">
                <div>
                  <b>Tipo de promoción:</b>
                </div>
                {detailsData['promo_type'] === 'bonus_product' && (
                  <div>Bonificación de una referencia</div>
                )}
                {detailsData['promo_type'] === 'assorted_bonus_product' && (
                  <div>Bonificación surtida</div>
                )}
                {(detailsData['promo_type'] === 'discount' ||
                  detailsData['promo_type'] === 'assorted_scale_discount_product' ||
                  detailsData['promo_type'] === 'scale_prices_reduction') && (
                  <div>Descuento de una referencia / Descuento surtido</div>
                )}
              </div>
              <div className="promo-show-data">
                <div>
                  <b>Cliente o Atributo(s):</b>
                </div>
                <div style={{ height: 'auto' }}>
                  {detailsData['applies_to_type'] === 'client' && <div>Cliente individual</div>}
                  {detailsData['applies_to_type'] === 'qualifier' && <div>Atributo(s)</div>}
                </div>
              </div>
              {detailsData['applies_to_type'] === 'client' && (
                <div className="promo-show-data">
                  <div>
                    <b>Nombre de Cliente: </b>
                  </div>
                  <div style={{ height: 'auto' }}>
                    {detailsData['applies_to'][0]
                      ? detailsData['applies_to'][0]['db_ref'] +
                        ' - ' +
                        detailsData['applies_to'][0]['name']
                      : ' '}
                  </div>
                </div>
              )}
              {detailsData['applies_to_type'] === 'qualifier' && (
                <>
                  <div className="promo-show-data">
                    <div>
                      <b>Atributo A: </b>
                    </div>
                    <div style={{ height: 'auto', overflowWrap: 'break-word' }}>
                      {detailsData['qualifier_2'] ? detailsData['qualifier_2'].join(', ') : '-'}
                    </div>
                  </div>
                  <div className="promo-show-data">
                    <div>
                      <b>Atributo B: </b>
                    </div>
                    <div style={{ height: 'auto', overflowWrap: 'break-word' }}>
                      {detailsData['qualifier_3'] ? detailsData['qualifier_3'].join(', ') : '-'}
                    </div>
                  </div>
                  <div className="promo-show-data">
                    <div>
                      <b>Atributo C: </b>
                    </div>
                    <div style={{ height: 'auto', overflowWrap: 'break-word' }}>
                      {detailsData['qualifier_4'] ? detailsData['qualifier_4'].join(', ') : '-'}
                    </div>
                  </div>
                </>
              )}
              <div className="promo-show-data" style={{ height: 'auto' }}>
                <div>
                  <b>Producto(s) Base:</b>
                </div>
                {detailsData['promo_type'] === 'bonus_product' && (
                  <div>
                    {detailsData['promotion']['base_products'][0]['db_ref'] +
                      ' - ' +
                      detailsData['promotion']['base_products'][0]['name']}
                  </div>
                )}

                {detailsData['promo_type'] === 'assorted_bonus_product' && (
                  <div style={{ height: 'auto' }}>
                    {detailsData['promotion']['base_products'].map((product, index) => (
                      <div key={index}>
                        {detailsData['promotion']['base_products'][index]['db_ref'] +
                          ' - ' +
                          detailsData['promotion']['base_products'][index]['name']}
                      </div>
                    ))}
                  </div>
                )}

                {detailsData['promo_type'] === 'assorted_scale_discount_product' && (
                  <div style={{ height: 'auto' }}>
                    {detailsData['promotion']['products'].map((product, index) => (
                      <div key={index}>
                        {detailsData['promotion']['products'][index]['db_ref'] +
                          ' - ' +
                          detailsData['promotion']['products'][index]['name']}
                      </div>
                    ))}
                  </div>
                )}

                {detailsData['promo_type'] === 'scale_prices_reduction' && (
                  <div style={{ height: 'auto' }}>
                    {detailsData['promotion']['products'][0]['db_ref'] +
                      ' - ' +
                      detailsData['promotion']['products'][0]['name']}
                  </div>
                )}
              </div>
              {detailsData['promo_type'] === 'scale_prices_reduction' && (
                <div className="promo-show-data">
                  <div>
                    <b>Regla de promoción:</b>
                  </div>
                  <div style={{ height: 'auto' }}>
                    {detailsData['promotion']['scale_discount_hash']?.map((scale) => (
                      <div key={scale.uuid}>
                        {scale.min} - {scale.max} {detailsData['promotion']['base_package']}{' '}
                        {scale.discount}%
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {detailsData['promo_type'] === 'assorted_scale_discount_product' && (
                <div className="promo-show-data">
                  <div>
                    <b>Regla de promoción:</b>
                  </div>
                  <div style={{ height: 'auto' }}>
                    {detailsData['promotion']['scale_discount_hash']?.map((scale) => (
                      <div key={scale.uuid}>
                        {scale.min} - {scale.max}{' '}
                        {detailsData['promotion']['base_package_units']
                          .filter((v, i, a) => a.indexOf(v) === i)
                          .join(', ')
                          .replace(/, ([^,]*)$/, ' y/o $1')}{' '}
                        {scale.discount}%
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {detailsData['promo_type'] === 'bonus_product' && (
                <>
                  <div className="promo-show-data">
                    <div>
                      <b>Producto de bonificación:</b>
                    </div>
                    <div>
                      {detailsData['promotion']['bonus_products'][0]['db_ref'] +
                        ' - ' +
                        detailsData['promotion']['bonus_products'][0]['name']}
                    </div>
                  </div>
                  <div className="promo-show-data">
                    <div>
                      <b>Regla de promoción:</b>
                    </div>
                    {detailsData['promotion']['promo_rule_type'] === 'one_increment' && (
                      <div className="width-500">
                        {detailsData['promotion']['base_quantity'] +
                          ' ' +
                          detailsData['promotion']['base_package'] +
                          ' ' +
                          ' + ' +
                          detailsData['promotion']['bonus_quantity'] +
                          ' ' +
                          detailsData['promotion']['bonus_package']}
                      </div>
                    )}
                    {detailsData['promotion']['promo_rule_type'] === 'scale' && (
                      <div className="width-500">
                        {detailsData['promotion']['promo_scale_array']?.map((scale) => (
                          <div key={scale.uuid}>
                            {scale.min} - {scale.max} {detailsData['promotion']['base_package']}
                            {', por cada '}
                            {scale.inc_factor} {detailsData['promotion']['base_package']}
                            {', se regala '}
                            {scale.bonus_qty} {detailsData['promotion']['bonus_package']}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="promo-show-data">
                    <div>
                      <b>Máximo a bonificar:</b>
                    </div>
                    <div>
                      {detailsData['promotion']['bonus_limit'] == '0'
                        ? '-'
                        : detailsData['promotion']['bonus_limit']}
                    </div>
                  </div>
                </>
              )}
              {detailsData['promo_type'] === 'assorted_bonus_product' && (
                <>
                  <div className="promo-show-data">
                    <div>
                      <b>Producto de bonificación:</b>
                    </div>
                    <div style={{ height: 'auto' }}>
                      {detailsData['promotion']['bonus_products'].map((product, index) => (
                        <div key={index}>
                          {detailsData['promotion']['bonus_products'][index]['db_ref'] +
                            ' - ' +
                            detailsData['promotion']['bonus_products'][index]['name']}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="promo-show-data">
                    <div>
                      <b>Regla de promoción:</b>
                    </div>
                    {detailsData['promotion']['promo_rule_type'] === 'one_increment' && (
                      <div className="width-500">
                        {detailsData['promotion']['base_quantity'] +
                          ' ' +
                          detailsData['promotion']['base_package_units']
                            .filter((v, i, a) => a.indexOf(v) === i)
                            .join(', ')
                            .replace(/, ([^,]*)$/, ' y/o $1') +
                          ' ' +
                          ' + ' +
                          detailsData['promotion']['bonus_quantity'] +
                          ' ' +
                          detailsData['promotion']['bonus_package']}
                      </div>
                    )}
                    {detailsData['promotion']['promo_rule_type'] === 'scale' && (
                      <div className="width-500">
                        {detailsData['promotion']['promo_scale_array']?.map((scale) => (
                          <div key={scale.uuid}>
                            {scale.min} - {scale.max}{' '}
                            {detailsData['promotion']['base_package_units']
                              .filter((v, i, a) => a.indexOf(v) === i)
                              .join(', ')
                              .replace(/, ([^,]*)$/, ' y/o $1')}
                            {', por cada '}
                            {scale.inc_factor}{' '}
                            {detailsData['promotion']['base_package_units']
                              .filter((v, i, a) => a.indexOf(v) === i)
                              .join(', ')
                              .replace(/, ([^,]*)$/, ' y/o $1')}
                            {', se regala '}
                            {scale.bonus_qty} {detailsData['promotion']['bonus_package']}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="promo-show-data">
                    <div>
                      <b>Máximo a bonificar:</b>
                    </div>
                    <div>
                      {detailsData['promotion']['bonus_limit'] == '0'
                        ? '-'
                        : detailsData['promotion']['bonus_limit']}
                    </div>
                  </div>
                </>
              )}
              <div className="promo-show-data">
                <div>
                  <b>Estado de promoción:</b>
                </div>
                <div>{promoStatus}</div>
              </div>
              <div className="promo-show-data">
                <div>
                  <b>Fecha de creación:</b>
                </div>
                <div>{getFormattedDate(detailsData['created_at'])}</div>
              </div>
              <div className="promo-show-data">
                <div>
                  <b>Fecha de actualización:</b>
                </div>
                <div>{getFormattedDate(detailsData['updated_at'])}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShowPromoView
