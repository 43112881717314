import React, { useContext, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import cookie from 'react-cookies'
import ReactFilter from '../../components/inputs/ReactFilter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import SegmentContext from '../context/SegmentContext'
import Pagination from '../../layout/Pagination'
import SearchInput from '../../layout/SearchInput'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default function DetailsView(props) {
  const [searchInput, setSearchInput] = useState('')
  const [qualifiers, setQualifiers] = useState(new Map())
  const [qualifiersDic, setQualifiersDic] = useState(new Map())
  const {
    qualifiersData,
    clientsData,
    pagination,
    fetchDataStatus,
    promoQualifiersData,
    setQualifierQuery,
    setSearchQuery,
    qualifierQuery,
    searchQuery,
    handlePaginationClick
  } = useContext(SegmentContext)
  const [resetReactFilters, setResetReactFilters] = useState(false)
  const [allSelectedOptions, setAllSelectedOptions] = useState(new Map())

  useEffect(() => {
    buildQualifiersQuery()
  }, [allSelectedOptions])

  useEffect(() => {
    handleQualifierData()
  }, [qualifiersData, promoQualifiersData])

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
  }

  const handleEnterSearch = (e) => {
    if (e.key === 'Enter') {
      buildSearchQuery()
    }
  }

  const isFilterSelected = () => {
    if (searchQuery && searchQuery.length > 0) return true

    for (const [key, value] of allSelectedOptions) {
      if (value && value.length > 0) return true
    }

    return false
  }
  const buildSearchQuery = () => {
    let queryParam = ''
    if (searchInput) {
      queryParam = `&q=${searchInput}`
    }
    setSearchQuery(queryParam)
  }

  const buildQualifiersQuery = () => {
    let queryParam = '&search_qualifier=true'
    if (allSelectedOptions)
      for (const [key, value] of allSelectedOptions) {
        queryParam += `&${qualifiersDic.get(key)}=${value.join(',')}`
      }
    setQualifierQuery(queryParam)
  }

  const handleQualifierData = async () => {
    let availableQualifiers = qualifiersData?.available_qualifiers || []
    let savedQualifiers = qualifiersData?.saved_qualifiers?.[0] || {}
    let mapAvailableQualifiers = new Map()
    let mapSavedQualifiers = new Map()
    let mapQualifiersDic = new Map()

    for (const availableQualifier of availableQualifiers) {
      mapAvailableQualifiers.set(availableQualifier.value, availableQualifier.label)
    }

    if (savedQualifiers?.qualifier_2) {
      mapSavedQualifiers.set(
        mapAvailableQualifiers.get(savedQualifiers.qualifier_2),
        promoQualifiersData?.[0]?.qualifier_2 || []
      )
      mapQualifiersDic.set(mapAvailableQualifiers.get(savedQualifiers.qualifier_2), 'qualifier_2')
    }

    if (savedQualifiers?.qualifier_3) {
      mapSavedQualifiers.set(
        mapAvailableQualifiers.get(savedQualifiers.qualifier_3),
        promoQualifiersData?.[0]?.qualifier_3 || []
      )
      mapQualifiersDic.set(mapAvailableQualifiers.get(savedQualifiers.qualifier_3), 'qualifier_3')
    }

    if (savedQualifiers?.qualifier_4) {
      mapSavedQualifiers.set(
        mapAvailableQualifiers.get(savedQualifiers.qualifier_4),
        promoQualifiersData?.[0]?.qualifier_4 || []
      )
      mapQualifiersDic.set(mapAvailableQualifiers.get(savedQualifiers.qualifier_4), 'qualifier_4')
    }

    if (savedQualifiers?.qualifier_5) {
      mapSavedQualifiers.set(
        mapAvailableQualifiers.get(savedQualifiers.qualifier_5),
        promoQualifiersData?.[0]?.qualifier_5 || []
      )
      mapQualifiersDic.set(mapAvailableQualifiers.get(savedQualifiers.qualifier_5), 'qualifier_5')
    }

    setQualifiers(mapSavedQualifiers)
    setQualifiersDic(mapQualifiersDic)
  }

  const resetFilters = () => {
    setSearchInput('')
    setAllSelectedOptions(new Map())
    setSearchQuery('')
    setQualifierQuery('&search_qualifier=true')
    setResetReactFilters(!resetReactFilters)
  }
  return (
    <>
      {qualifiers && qualifiers.size > 0 && (
        <>
          <h4 className="mt-50 text-sub-title">Filtrar clientes por atributos:</h4>
          <div className="filters-container mb-10">
            <FontAwesomeIcon className="filter-icon mr-10" icon={faFilter} />
            {fetchDataStatus?.qualifiers ? (
              <span>Obteniendo filtros...</span>
            ) : (
              Array.from(qualifiers.entries()).map((qualifier, indx) => (
                <div key={`qlf-${indx}`} className="mr-10">
                  <ReactFilter
                    className="mr-10"
                    title={qualifier[0]}
                    identifier={qualifier[0]}
                    options={qualifier[1]}
                    resetWatcher={resetReactFilters}
                    onChange={(e) => {
                      let so = new Map(allSelectedOptions);
                      so.set(e.identifier, e.selectedOptions);
                      setAllSelectedOptions(so);
                    }}
                  />
                </div>
              ))
            )}
          </div>
        </>
      )}
      <div className="search-input-containter mb-10 mt-20">
        <SearchInput
          query={searchInput}
          setSearchInput={setSearchInput}
          onSearch={buildSearchQuery}
          placeholder="Buscar por código o nombre"
          onClearSearch={() => {
            setSearchInput('');
            setSearchQuery('');
          }}
        />
      </div>
      <div className="mb-20">
        {isFilterSelected() && (
          <a onClick={resetFilters} className="reset-filter cursor-pointer mb-20">
            Restablecer filtros aplicados
          </a>
        )}
      </div>
      <div>
        <Table hover responsive>
          <thead className="table-header-gray">
            <tr>
              <th style={{ verticalAlign: 'middle', padding: '10px 25px' }}>
                <span className="th-header-text">CÓDIGO</span>
              </th>
              <th style={{ verticalAlign: 'middle' }}>
                <span className="th-header-text">CLIENTE</span>
              </th>
              {Array.from(qualifiers?.entries() || []).map((qualifier, indx) => (
                <th key={indx} style={{ verticalAlign: 'middle' }}>
                  <span className="th-header-text">{qualifier[0]?.toUpperCase()}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {(clientsData || []).length === 0 && searchInput !== '' ? (
              <tr>
                <td colSpan={(qualifiers?.size || 0) + 2} className="td-text-limited td-text-limited-space">
                  No hay resultados
                </td>
              </tr>
            ) : (
              clientsData?.map((client, indx) => (
                <tr key={indx}>
                  <td className="td-text-limited td-text-limited-space">{client.db_ref}</td>
                  <td className="td-text-limited">{client.name}</td>
                  {qualifiersData?.saved_qualifiers?.[0]?.qualifier_2 !== undefined && (
                    <td className="td-text-limited">{client.qualifier_2 || 'N/A'}</td>
                  )}
                  {qualifiersData?.saved_qualifiers?.[0]?.qualifier_3 !== undefined && (
                    <td className="td-text-limited">{client.qualifier_3 || 'N/A'}</td>
                  )}
                  {qualifiersData?.saved_qualifiers?.[0]?.qualifier_4 !== undefined && (
                    <td className="td-text-limited">{client.qualifier_4 || 'N/A'}</td>
                  )}
                  {qualifiersData?.saved_qualifiers?.[0]?.qualifier_5 !== undefined && (
                    <td className="td-text-limited">{client.qualifier_5 || 'N/A'}</td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </Table>
        {fetchDataStatus?.clients && <span>Obteniendo datos...</span>}
        {pagination.total_pages > 1 && (
          <div onClick={handlePaginationClick}>
            <Pagination
              current_page={pagination.current_page}
              total_pages={pagination.total_pages}
            />
          </div>
        )}
      </div>
    </>
  );
}
