import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import cookie from 'react-cookies'
import ProductsPriceListTab from './ProductsPriceListTab'
import ClientsPriceListTab from './ClientsPriceListTab'

const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
const BACKEND_URL = USE_BUSINESS_URL
  ? `${process.env.REACT_APP_BACKEND_URL}/businesses/${cookie.load('business_id')}`
  : process.env.REACT_APP_BACKEND_URL

const ShowPriceListView = (props) => {
  const { id } = useParams()
  const history = useHistory()
  const [activeTab, setActiveTab] = useState('Clientes')
  const [dbRef, setDbRef] = useState('')
  const [description, setDescription] = useState('')

  useEffect(() => {
    fetchPriceListDetails()
  }, [])

  const fetchPriceListDetails = () => {
    fetch(`${BACKEND_URL}/price_lists/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookie.load('token')}`
      }
    })
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => {
        setDbRef(data['db_ref'])
        setDescription(data['description'])
      })
      .catch((e) => console.log(e))
  }

  const handleTabClick = (event) => {
    if (event.target.tagName === 'A') {
      setActiveTab(event.target.innerText)
    }
  }

  return (
    <div className={`main-view ${props.collapsed ? 'collapsed' : ''}`}>
      <div className="main-show-header">
        <h2>
          Lista de Precio: {dbRef} - {description}
        </h2>
      </div>
      <div className="index-table">
        <div className="show-area">
          <div>
            <div onClick={handleTabClick}>
              <ul className="nav nav-tabs">
                <li id="clientes_tab" className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === 'Clientes' ? 'active' : 'inactive-tab'
                    } cursor-pointer`}
                  >
                    Clientes
                  </a>
                </li>
                <li id="productos_tab" className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === 'Productos' ? 'active' : 'inactive-tab'
                    } cursor-pointer`}
                  >
                    Productos
                  </a>
                </li>
              </ul>
            </div>
            <div>
              {activeTab === 'Clientes' && (
                <div>
                  <ClientsPriceListTab id={id} />
                </div>
              )}
              {activeTab === 'Productos' && (
                <div>
                  <ProductsPriceListTab id={id} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShowPriceListView
