import React, { useContext, useState, useEffect } from 'react'
import _ from 'lodash'
import { BasePromoContext } from '../../context/BasePromoContext'
import { AssortedPromoContext } from '../../context/AssortedPromoContext'
import { AssortedBonusPromoContext } from '../../context/AssortedBonusPromoContext'
import { BonusPromoContext } from '../../context/BonusPromoContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'

const BonusPromoRuleSection = ({
  cloningPromo,
  isAssorted,
  promoRule,
  setPromoRule,
  promoScaleArray,
  setPromoScaleArray,
  checkedMaxBonus,
  setCheckedMaxBonus
}) => {
  const [failedAddScale, setFailedAddScale] = useState(false)
  const [failedAddScaleMessage, setFailedAddScaleMessage] = useState('')
  const [isAddScaleDisabled, setIsAddScaleDisabled] = useState(false)
  const [showExceedLimitAlert, setShowExceedLimitAlert] = useState(false)
  const [showBonusExceedLimitAlert, setShowBonusExceedLimitAlert] = useState(false)
  const [showMaxBonusExceedLimitAlert, setShowMaxBonusExceedLimitAlert] = useState(false)

  const promoRuleOptions = [
    { value: '', text: 'Escoja una opción', hidden: true },
    { value: 'one_increment', text: 'Un solo factor de incremento' },
    { value: 'scale', text: 'Escalas' }
  ]

  const { baseProducts, changeBaseQuantityProduct } = useContext(BasePromoContext)

  const { assortedProducts, addAssortedProductQuantity, assortedBonusProductQuantity } =
    useContext(AssortedPromoContext)

  const { bonusProducts, changeBonusQuantityProduct, changeLimitToBonus } =
    useContext(BonusPromoContext)

  const handlePromoRuleChange = (event) => {
    event.preventDefault()
    const obj = {}
    obj[event.target.name] = event.target.value
    setPromoScaleArray([{ min: 1, max: 0, inc_factor: 0, bonus_qty: 0 }])
    setPromoRule(obj.promoRule)
  }

  const handleBaseQuantityInputChange = (event, isAssorted) => {
    let baseQuantity = event.target.value
    setShowExceedLimitAlert(baseQuantity >= 999999)

    if (isAssorted) {
      addAssortedProductQuantity({
        baseQuantity: baseQuantity,
        id: assortedProducts[0].id
      })
    } else {
      changeBaseQuantityProduct({
        baseQuantity: baseQuantity,
        id: baseProducts[0].id
      })
    }
  }

  const handleBonusQuantityInputChange = (event, product) => {
    let bonusQuantity = event.target.value
    setShowBonusExceedLimitAlert(bonusQuantity >= 999999)

    changeBonusQuantityProduct({
      bonusQuantity: bonusQuantity,
      id: product.id
    })
  }

  const handleMaxBonusLimitQuantityInputChange = (event, product) => {
    let maxLimit = event.target.value
    setShowMaxBonusExceedLimitAlert(maxLimit >= 999999)

    changeLimitToBonus({
      maxLimit: maxLimit,
      id: product.id
    })
  }

  const isMaxBonusLimitChecked = (event) => {
    if (!event.target.checked || bonusProducts[0].bonus_limit != 0) {
      changeLimitToBonus({
        maxLimit: 0,
        id: bonusProducts[0].id
      })
    }
    setCheckedMaxBonus(event.target.checked)
  }

  const handleExceededInputValues = (value) => {
    setIsAddScaleDisabled(value >= 999998)

    if (value >= 999999) {
      setFailedAddScaleMessage(
        'La cantidad excede el máximo permitido de 999999. Ingresa una cantidad igual o menor para continuar.'
      )
      setFailedAddScale(true)
    } else {
      setFailedAddScale(false)
    }
  }

  const handleScaleMinChange = (event, index) => {
    event.preventDefault()
    const minValue = event.target.valueAsNumber
    handleExceededInputValues(minValue)

    const arrays = [...promoScaleArray]
    const array = { ...arrays[index] }
    array.min = minValue
    arrays[index] = array
    setPromoScaleArray([...arrays])
  }

  const handleScaleMaxChange = (event, index) => {
    event.preventDefault()
    const maxValue = event.target.valueAsNumber
    handleExceededInputValues(maxValue)

    if (promoScaleArray.length === index + 1) {
      const arrays = [...promoScaleArray]
      const array = { ...arrays[index] }
      array.max = maxValue
      arrays[index] = array
      setPromoScaleArray([...arrays])
    } else {
      const arrays = [...promoScaleArray]
      const currentArray = { ...arrays[index] }
      const nextArray = { ...arrays[index + 1] }
      currentArray.max = maxValue
      nextArray.min = maxValue + 1
      arrays[index] = currentArray
      arrays[index + 1] = nextArray
      setPromoScaleArray([...arrays])
    }
  }

  const handleScaleIncFactorChange = (event, index) => {
    event.preventDefault()
    const incFactorValue = event.target.valueAsNumber
    handleExceededInputValues(incFactorValue)

    const arrays = [...promoScaleArray]
    const array = { ...arrays[index] }
    array.inc_factor = incFactorValue
    arrays[index] = array
    setPromoScaleArray([...arrays])
  }

  const handleScaleBonusQtyChange = (event, index) => {
    event.preventDefault()
    const bonusQtyValue = event.target.valueAsNumber
    handleExceededInputValues(bonusQtyValue)

    const arrays = [...promoScaleArray]
    const array = { ...arrays[index] }
    array.bonus_qty = bonusQtyValue
    arrays[index] = array
    setPromoScaleArray([...arrays])
  }

  const changeScaleMinValue = (filteredArray) => {
    const arrays = [...filteredArray]
    arrays.map((obj, index) => {
      if (index !== 0) {
        if (arrays[index - 1].max !== arrays[index].min + 1) {
          obj.min = arrays[index - 1].max + 1
          arrays[index] = obj
        }
      }
    })
    setPromoScaleArray([...arrays])
  }

  const deleteScale = (event, elementToDelete) => {
    event.preventDefault()
    changeScaleMinValue(promoScaleArray.filter((item) => item !== elementToDelete))
  }

  const addScale = (event, lastScaleMax) => {
    event.preventDefault()
    if (
      promoScaleArray[promoScaleArray.length - 1].min >=
      promoScaleArray[promoScaleArray.length - 1].max
    ) {
      setFailedAddScaleMessage(
        'Por favor agregue un máximo de venta mayor al mínimo de venta de la escala ' +
          promoScaleArray.length +
          '.'
      )
      setFailedAddScale(true)
    } else {
      setFailedAddScale(false)
      setPromoScaleArray((promoScaleArray) => [
        ...promoScaleArray,
        { min: lastScaleMax + 1, max: 0, inc_factor: 0, bonus_qty: 0 }
      ])
    }
  }

  useEffect(() => {
    const lastScale = promoScaleArray[promoScaleArray.length - 1]

    if (lastScale) {
      const isDisabled = lastScale.max >= 999998 || lastScale.min >= 999999
      setIsAddScaleDisabled(isDisabled)
    }
  }, [promoScaleArray])

  if (cloningPromo) {
    return null
  }
  return (
    <div className="promo-card">
      <div className="title-promos-form">
        <b>5. Regla de la promoción</b>
      </div>
      <hr className="hr-promo-card" />

      {bonusProducts.length === 0 && (
        <>
          <p className="promo-card-subtitle">
            5.1: Escoja cómo aplicar la promoción, si es por un solo factor de incremento por varios
            rangos
          </p>
          <select
            onChange={(e) => handlePromoRuleChange(e)}
            value={promoRule}
            className="select-promo-type form-control custom-select inactive"
            name="promoRule"
            required
            disabled
          >
            {promoRuleOptions.map((option) => (
              <option key={option.value + 1} value={option.value} hidden={option.hidden}>
                {option.text}
              </option>
            ))}
          </select>
        </>
      )}

      {bonusProducts.length > 0 && (
        <>
          <p className="promo-card-subtitle">
            5.1: Escoja cómo aplicar la promoción, si es por un solo factor de incremento por varios
            rangos
          </p>
          <select
            onChange={(e) => handlePromoRuleChange(e)}
            value={promoRule}
            className="select-promo-type form-control custom-select"
            name="promoRule"
            required
          >
            {promoRuleOptions.map((option) => (
              <option key={option.value + 1} value={option.value} hidden={option.hidden}>
                {option.text}
              </option>
            ))}
          </select>
        </>
      )}
      {promoRule === 'one_increment' && bonusProducts.length > 0 && (
        <>
          <div className="promo-card-heading mt-20">
            <b>Nota: </b>Actualmente las promociones de bonificación son recurrentes. Cada vez que
            el usuario llega al mínimo de la regla, se aplica la bonificación.
          </div>
          <p className="promo-card-subtitle mt-20">5.2: Establece la base de esta promoción</p>

          <div>
            <input
              min="1"
              max="999999"
              step="1"
              type="number"
              autoComplete="off"
              name="base_quantity"
              onChange={(e) => handleBaseQuantityInputChange(e, isAssorted)}
              value={isAssorted ? assortedBonusProductQuantity : baseProducts[0]?.base_quantity}
              className="promo-number-input"
              placeholder="0"
              required
            />
            {showExceedLimitAlert && (
              <p className="warning-text">
                La cantidad excede el máximo permitido de 999999. Ingresa una cantidad igual o menor
                para continuar.
              </p>
            )}
          </div>

          <p className="promo-card-subtitle mt-33">
            5.3: Establece la bonificación de esta promoción
          </p>

          <div>
            <input
              min="1"
              max="999999"
              step="1"
              type="number"
              autoComplete="off"
              name="bonus_quantity"
              onChange={(e) => handleBonusQuantityInputChange(e, bonusProducts[0])}
              value={bonusProducts[0].bonus_quantity}
              className="promo-number-input"
              placeholder="0"
              required
            />
            {showBonusExceedLimitAlert && (
              <p className="warning-text">
                La cantidad excede el máximo permitido de 999999. Ingresa una cantidad igual o menor
                para continuar.
              </p>
            )}
          </div>

          <p className="promo-card-subtitle mt-33" style={{ fontStyle: 'italic' }}>
            Opcional 5.4: ¿Desea establecer una cantidad máxima de producto para bonificar en cada
            pedido?
          </p>
          <div className="promo-card-heading mt-8">
            <b>Nota: </b>Aplica en caso de que desee limitar el número de productos regalados a cada
            cliente en su pedido.
          </div>
          <div className="row">
            <div className="form-group col-md-12">
              <input
                type="checkbox"
                className="max-bonus-limit-check"
                onChange={(e) => isMaxBonusLimitChecked(e)}
                checked={checkedMaxBonus}
              />
              <label className="max-bonus-limit-label">Si, activar máximo de bonificación</label>
            </div>
          </div>
          {checkedMaxBonus == true && (
            <div>
              <input
                min="0"
                max="999999"
                step="1"
                type="number"
                autoComplete="off"
                name="max_bonus_quantity"
                onChange={(e) => handleMaxBonusLimitQuantityInputChange(e, bonusProducts[0])}
                value={bonusProducts[0].bonus_limit}
                className="promo-number-input"
                required
              />
              {showMaxBonusExceedLimitAlert && (
                <p className="warning-text">
                  La cantidad excede el máximo permitido de 999999. Ingresa una cantidad igual o
                  menor para continuar.
                </p>
              )}
            </div>
          )}
        </>
      )}
      {promoRule === 'scale' && bonusProducts.length > 0 && (
        <>
          {promoScaleArray.map((element, index) => (
            <div className="row" style={{ paddingLeft: 16, paddingBottom: 20 }} key={index}>
              <div className="col-xs-1" style={{ marginTop: 33, width: 80 }}>
                <span className="scale-number-circle">{index + 1}</span>
              </div>
              <div className="scale-text-separator col-sm-1" style={{ marginLeft: -35 }}>
                Desde:
              </div>
              {index > 0 ? (
                <div className="col-sm-1" style={{ padding: 0 }}>
                  <input
                    min="0"
                    max="999999"
                    type="number"
                    step="1"
                    autoComplete="off"
                    name="min-quantity"
                    className="scale-input inactive"
                    placeholder={element.min}
                    value={element.min}
                    disabled
                  />
                  <div className="scale-input-subtitle">Mínimo de venta</div>
                </div>
              ) : (
                <div className="col-sm-1" style={{ padding: 0 }}>
                  <input
                    min="0"
                    max="999999"
                    type="number"
                    step="1"
                    autoComplete="off"
                    name="min-quantity"
                    onChange={(e) => handleScaleMinChange(e, index)}
                    className="scale-input"
                    placeholder={element.min}
                    value={element.min}
                    required
                  />
                  <div className="scale-input-subtitle">Mínimo de venta</div>
                </div>
              )}
              <div className="scale-text-separator col-sm-1" style={{ marginLeft: -25 }}>
                hasta:
              </div>
              <div className="col-sm-1" style={{ padding: 0 }}>
                <input
                  min="0"
                  max="999999"
                  type="number"
                  step="1"
                  autoComplete="off"
                  name="max-quantity"
                  onChange={(e) => handleScaleMaxChange(e, index)}
                  className="scale-input"
                  placeholder="0"
                  value={element.max}
                  required
                />
                <div className="scale-input-subtitle">Máximo de venta</div>
              </div>
              <div className="scale-text-separator col-sm-1">por cada:</div>
              <div className="col-sm-1" style={{ padding: 0 }}>
                <input
                  min="0"
                  max="999999"
                  type="number"
                  step="1"
                  autoComplete="off"
                  name="inc-factor"
                  onChange={(e) => handleScaleIncFactorChange(e, index)}
                  className="scale-input"
                  placeholder="0"
                  value={element.inc_factor}
                  required
                />
                <div className="scale-input-subtitle">Factor de incremento</div>
              </div>
              <div className="scale-text-separator col-sm-1">se regala:</div>
              <div className="col-sm-1" style={{ padding: 0 }}>
                <input
                  min="0"
                  max="99999"
                  type="number"
                  step="1"
                  autoComplete="off"
                  name="bonus-qty"
                  onChange={(e) => handleScaleBonusQtyChange(e, index)}
                  className="scale-input"
                  placeholder="0"
                  value={element.bonus_qty}
                  required
                />
                <div className="scale-input-subtitle">Cantidad bonificada</div>
              </div>
              {promoScaleArray.length > 1 && (
                <div className="scale-column col-sm-1" style={{ padding: 0 }}>
                  <button
                    className="delete-scale-button d-flex align-items-center"
                    onClick={(e) => deleteScale(e, element)}
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                    <b className="delete-scale-title">Borrar escala</b>
                  </button>
                </div>
              )}
            </div>
          ))}
          {failedAddScale === true && <p className="alert-text">{failedAddScaleMessage}</p>}
          <div className="row" style={{ paddingLeft: 16 }}>
            <button
              className="add-scale-button"
              onClick={(e) => addScale(e, promoScaleArray[promoScaleArray.length - 1].max)}
              disabled={isAddScaleDisabled}
            >
              <p className="text-add-scale-colors note-essentials">Agregar escala</p>
            </button>
          </div>
        </>
      )}
    </div>
  )
}
export default BonusPromoRuleSection
