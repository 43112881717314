import React, { useState, useEffect } from 'react';
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import CreateNewClientView from './CreateNewClientView'
import * as keyboardCodes from '../constants/keyboardCodes'
import { DISCARD_CHANGES_CONFIRMATION } from '../constants/appMessages'

const CreateNewClientApp = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [searchPlaceholder, setSearchPlaceholder] = useState('Buscar clientes por código o nombre');
  const [title, setTitle] = useState('Cliente nuevo');
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    setIsFetching(false);
    const collapsedState = localStorage.getItem('collapsedState') === 'true';
    setCollapsed(collapsedState);
  }, []);

  const handleSearch = (event) => {
    if (event.keyCode === 13 && window.confirm('¿Está seguro que quiere continuar? Los cambios editados no serán guardados')) { 
      event.preventDefault();
      const query = event.target.value;
      if (query !== '') {
        window.location = `/clientes?query=${query}`;
      }
    }
  };

  const handleToggleCollapse = (collapsed) => {
    setCollapsed(collapsed);
    localStorage.setItem('collapsedState', collapsed);
  };

  return (
    <div>
      <div onKeyDown={handleSearch}>
        <TopBar
          searchPlaceholder={searchPlaceholder}
          userName={title} 
          onToggleCollapse={handleToggleCollapse}
          collapsed={collapsed}
        />
      </div>
      <div onClick={() => { }}>
        <CreateNewClientView data={{}} title={title} collapsed={collapsed} /> 
      </div>
      <div>
        <NavBar activeItem="Clientes" isEditing={true} collapsed={collapsed} />
      </div>
    </div>
  );
};

export default CreateNewClientApp;