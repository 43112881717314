import { components } from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

const { Option } = components

const IconOption = (props) => (
  <Option {...props}>
    {props.data.label}
    <span style={{ float: 'right' }}>
      <FontAwesomeIcon icon={faPlusCircle} />
    </span>
  </Option>
)
export default IconOption
